import { UserMenu } from './UserMenu';
import { useState } from 'react';
import { useUserContext } from '../../contexts/userContext';
import { InitialsAvatar } from './InitialsAvatar';

export const UserBlock = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const user = useUserContext().state;

  if (!user.isAuthenticated) return <></>;

  return (
    <>
      <InitialsAvatar onClick={() => setMenuOpen(!menuOpen)} theme="light"/>
      <UserMenu
        display={menuOpen}
        setDisplay={setMenuOpen}
      />
    </>
  );
};
