import { useRouteMatch } from "react-router-dom";
import { useFetchProjectStaircasesQuery, useFetchStairClimberScriptQuery, useProjectFloorsQuery, useProjectsQuery } from "../../../hooks/projectQueries"
import { PATH_STRINGS } from "../../../hooks/useGeneratedPaths";
import { FormContainer, FormHeader, SaveButtonContainer, StyledInput, StyledLabel, StyledTextArea } from "../../common/FormControls";
import { LoadingIndicator } from "../../common/LoadingIndicator";
import { Button } from "../../common/Inputs/Button";
import { useState, useCallback, useEffect, useReducer, useMemo } from "react";
import { createStairClimberScript, initialStairClimberScript, StairClimberScript, updateStairClimberScript } from "../../../api/stairClimberScripts";
import { Select } from "../../common/Inputs/Select";
import { Project } from "../../../api/projects";
import { DashboardCheckbox, DashboardSelect } from "../project-setup/DashboardFormControls";
import { ProjectFloor } from "../../../api/projectFloors";
import { useNotifications } from "../../../contexts/notificationProvider";
import { useStairsNavigation } from "../../../hooks/useNavigation";
import styled from 'styled-components';

type ClimbDirection = "Ascend" | "Descend";

const UPDATE_STAIR_CLIMBER_SCRIPT = 'UPDATE_STAIR_CLIMBER_SCRIPT';
type StairClimberScriptAction = { type: typeof UPDATE_STAIR_CLIMBER_SCRIPT, payload: Partial<StairClimberScript> };

const stairClimberScriptReducer = (state: StairClimberScript, action: StairClimberScriptAction) => {
  switch (action.type) {
    case UPDATE_STAIR_CLIMBER_SCRIPT:
      return {
        ...state,
        ...action.payload
      }
    default:
      return {...state};
  }
}

export const EditStairClimberScript = () => {
  const {
    addNotification,
  } = useNotifications();

  const {
    navigateToStairClimberScript,
    navigateToStairClimberScripts,
  } = useStairsNavigation();

  const scriptMatch = useRouteMatch<{scriptId: string}>(PATH_STRINGS.stairClimberScript);
  const scriptId = scriptMatch?.params.scriptId;
  const scriptIsNew = scriptId === 'new';

  const [script, dispatch] = useReducer(stairClimberScriptReducer, initialStairClimberScript);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [floorsSelectExpanded, setFloorsSelectExpanded] = useState<boolean>(false);
  const [floorSearchText, setFloorSearchText] = useState<string>('');
  const [climbDirection, setClimbDirection] = useState<ClimbDirection>("Ascend");
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

  const isAscending = climbDirection === 'Ascend';

  const validForSave = useMemo(() => {
    return (
      !!script.name &&
      !!script.script &&
      !!script.staircase &&
      !!script.floors &&
      !!script.floors.length
    );
  }, [script]);

  const scriptOnSuccess = (retrievedScript: StairClimberScript) => {
    retrievedScript.script = prettyPrint(retrievedScript.script);

    dispatch({type: UPDATE_STAIR_CLIMBER_SCRIPT, payload: retrievedScript});
  }

  const {isLoading: scriptLoading} = useFetchStairClimberScriptQuery(scriptId, scriptOnSuccess);
  const {data: projects, isLoading: projectsLoading} = useProjectsQuery();
  const {data: floors, isLoading: floorsLoading} = useProjectFloorsQuery(selectedProject?.public_id);
  const {data: staircases, isLoading: staircasesLoading} = useFetchProjectStaircasesQuery(selectedProject?.public_id);

  const dataLoaded = !!script && !scriptLoading;

  useEffect(() => {
    if (script.staircase && !!projects && !projectsLoading && !selectedProject) {
      const project = projects.find((project: Project) => project.public_id === script.staircase?.project.public_id);

      if (!!project) {
        setSelectedProject(project);
      }
    }
  }, [projects, projectsLoading, script.staircase, selectedProject]);

  const projectOptions = useMemo(() => {
    if (projects && !projectsLoading) {
      return projects.map((project: Project) => project.name);
    }

    return [];
  }, [projects, projectsLoading]);

  const staircaseOptions = useMemo(() => {
    if (staircases && !staircasesLoading) {
      return staircases.map((staircase: any) => staircase.name);
    }

    return [];
  }, [staircases, staircasesLoading]);

  const onChangeStaircaseName = (updatedName: string) => {
    dispatch({
      type: UPDATE_STAIR_CLIMBER_SCRIPT,
      payload: {
        name: updatedName,
      }
    });

    if (updatedName.toLowerCase().includes("ascend")) {
      setClimbDirection("Ascend");
    } else if (updatedName.toLowerCase().includes("descend")) {
      setClimbDirection("Descend");
    }
  }

  const onSelectProject = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedProjectName = e.target.value;
    const selectedProject = projects?.find((project: Project) => project.name === selectedProjectName);

    if (selectedProject) {
      setSelectedProject(selectedProject);
      dispatch({type: UPDATE_STAIR_CLIMBER_SCRIPT, payload: { staircase: null}});
    }
  }

  const onSelectStaircase = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedStaircaseName = e.target.value;
    const selectedStaircase = staircases?.find((staircase: any) => staircase.name === selectedStaircaseName);

    dispatch({type: UPDATE_STAIR_CLIMBER_SCRIPT, payload: { staircase: selectedStaircase}});
  }

  const scriptIsValidJson = (obj: string) => {
    try {
      JSON.parse(obj);

      return true;
    } catch (err) {
      return false;
    }
  }

  const prettyPrint = (obj: string) => {
    if (scriptIsValidJson(obj)) {
      const parsedScript = JSON.parse(obj);
      return JSON.stringify(parsedScript, null, 2);
    } else {
      return obj;
    }
  }

  const createScript = async () => {
    try {
      const createdScript = await createStairClimberScript(script);
      
      addNotification('Script created successfully', 'success');
      navigateToStairClimberScript(createdScript.id);
    } catch (e) {
      addNotification('Error creating script', 'error');
      
      console.error(e);
    }
  }

  const updateScript = async () => {
    try {
      const updatedScript = await updateStairClimberScript(script.id, script);

      addNotification('Script updated successfully', 'success');
      scriptOnSuccess(updatedScript);
    } catch (e) {
      addNotification('Error updating script', 'error');
      
      console.error(e);
    }
  }

  const onClickSave = async () => {
    if (validForSave) {
      setSaveInProgress(true);

      try {
        if (scriptIsNew) {
          await createScript();
        } else {
          await updateScript();
        }
      } catch (e) {
        console.error(e);
      } finally {
        setSaveInProgress(false);
      }
    }
  }

  const onCheckFloorCheckbox = useCallback((projectFloor: ProjectFloor) => {
    const scriptFloors = [...script.floors || []];
    const floorIndex = scriptFloors.findIndex(floor => floor.project_floor.floor_code === projectFloor.floor_code);

    if (floorIndex > -1) {
      scriptFloors.splice(floorIndex, 1);
    } else {
      const initialX = isAscending ? script.staircase?.default_ascend_pose_x : script.staircase?.default_descend_pose_x;
      const initialY = isAscending ? script.staircase?.default_ascend_pose_y : script.staircase?.default_descend_pose_y;

      scriptFloors.push({
        project_floor: {
          floor_code: projectFloor.floor_code,
          id: projectFloor.id
        },
        approach_pose_x: initialX ?? 0,
        approach_pose_y: initialY ?? 0
      });
    }

    dispatch({type: UPDATE_STAIR_CLIMBER_SCRIPT, payload: { floors: scriptFloors }});
  }, [isAscending, script.floors, script.staircase?.default_ascend_pose_x, script.staircase?.default_ascend_pose_y, script.staircase?.default_descend_pose_x, script.staircase?.default_descend_pose_y]);

  const floorCheckboxes = useMemo(() => {
    if (floors && !floorsLoading) {
      const searchedFloors = floors.filter((floor: ProjectFloor) => floor.floor_code.toLowerCase().includes(floorSearchText.toLowerCase()));

      return searchedFloors.map((floor: ProjectFloor) => {
        const scriptFloorIndex = script.floors?.findIndex((scriptFloor) => scriptFloor.project_floor.floor_code === floor.floor_code);
        const scriptFloor = scriptFloorIndex >= 0 ? script.floors[scriptFloorIndex] : undefined;
        const checked = !!scriptFloor;

        const onChangeApproachPose = (type: 'x' | 'y', value: number) => {
          const scriptFloors = [...script.floors || []];

          if (scriptFloor) {
            scriptFloors[scriptFloorIndex] = {...scriptFloor, [`approach_pose_${type}`]: value};
          }

          dispatch({type: UPDATE_STAIR_CLIMBER_SCRIPT, payload: { floors: scriptFloors }});
        }

        return (
          <DashboardCheckbox
            key={floor.floor_code}
            checked={checked}
            onChangeChecked={() => onCheckFloorCheckbox(floor)}
            label={floor.floor_code}
            checkmarkTop={3}
            checkmarkLeft={4}
          >
            {checked && (
              <FloorCoordinatesContainer>
                <FloorCoordinatesField
                  type="x"
                  value={scriptFloor.approach_pose_x}
                  onChange={newValue => onChangeApproachPose('x', newValue)}
                />
                <FloorCoordinatesField
                  type="y"
                  value={scriptFloor.approach_pose_y}
                  onChange={newValue => onChangeApproachPose('y', newValue)}
                />
              </FloorCoordinatesContainer>
            )}
          </DashboardCheckbox>
        );
      });
    }
  }, [floorSearchText, floors, floorsLoading, script.floors, onCheckFloorCheckbox]);

  const selectedFloorsDisplay = useMemo(() => {
    if (floors && !floorsLoading) {
      const selectedFloors = floors.filter((floor: ProjectFloor) => !!script.floors?.find(scriptFloor => scriptFloor.project_floor.floor_code === floor.floor_code));

      return selectedFloors.map((floor: ProjectFloor) => floor.floor_code).join(', ');
    }

    return '';
  }, [script.floors, floors, floorsLoading]);

  return (
    <FormContainer>
      {!dataLoaded &&
        <LoadingIndicator/>
      }
      {dataLoaded &&
        <>
          <HeaderContainer>
            <EmptyDiv/>
            <FormHeader
              style={{marginBottom: '0px'}}
            >
              Stair Climber Script Editor
            </FormHeader>
            <ReturnToScriptsContainer>
              <Button
                primary
                text="Return To Scripts"
                onClick={() => navigateToStairClimberScripts()}
              />
            </ReturnToScriptsContainer>
          </HeaderContainer>

          <StyledLabel htmlFor='name'>Name:</StyledLabel>
          <StyledInput
            id='name'
            value={script.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeStaircaseName(e.target.value)}
          />

          <StyledLabel htmlFor='staircase'>Project:</StyledLabel>
          <Select
            useInitialEmptyOption
            options={projectOptions}
            value={selectedProject?.name ?? ''}
            onSelect={onSelectProject}
            selectStyles={{
              width: '400px',
              marginBottom: '20px',
            }}
          />

          <StyledLabel htmlFor='staircase'>Staircase:</StyledLabel>
          <Select
            disabled={!selectedProject || !staircaseOptions.length}
            useInitialEmptyOption
            options={staircaseOptions}
            value={script.staircase?.name ?? ''}
            onSelect={onSelectStaircase}
            selectStyles={{
              width: '400px',
              marginBottom: '20px',
            }}
          />

          <StyledLabel htmlFor='ascend/descend'>Ascend/Descend:</StyledLabel>
          <Select
            disabled={!script.staircase}
            options={["Ascend", "Descend"]}
            value={climbDirection}
            onSelect={e => setClimbDirection(e.target.value)}
            selectStyles={{
              width: '400px',
              marginBottom: '20px',
            }}
          />

          {!!selectedProject &&
            <>
              <StyledLabel htmlFor='floors'>Starting Floors:</StyledLabel>
              <DashboardSelect
                title={selectedFloorsDisplay}
                expanded={floorsSelectExpanded}
                setExpanded={setFloorsSelectExpanded}
                searchText={floorSearchText}
                setSearchText={setFloorSearchText}
                searchPlaceholder='Filter Floors'
                containerStyle={{
                  marginBottom: '20px',
                }}
              >
                {floorCheckboxes}
              </DashboardSelect>
            </>
          }

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '400px',
              marginBottom: '5px'
            }}
          >
            <StyledLabel
              htmlFor='script'
              style={{
                width: 'auto'
              }}
            >
              Script:
            </StyledLabel>
            <Button
              disabled={!scriptIsValidJson(script.script)}
              text="Pretty Print"
              onClick={() => dispatch({type: UPDATE_STAIR_CLIMBER_SCRIPT, payload: { script: prettyPrint(script.script) }})}
              style={{
                padding: '0.5em'
              }}
            />
          </div>
          <StyledTextArea
            id='script'
            value={script.script}
            spellCheck={false}
            onFocus={e => e.preventDefault()}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => dispatch({type: UPDATE_STAIR_CLIMBER_SCRIPT, payload: { script: e.target.value }})}
            style={{
              height: '400px',
              maxWidth: '400px',
              minWidth: '400px',
            }}
          />

          <SaveButtonContainer>
            {!saveInProgress &&
              <Button
                primary
                disabled={!validForSave}
                text="Save"
                onClick={onClickSave}
              />
            }
            {saveInProgress &&
              <LoadingIndicator/>
            }
          </SaveButtonContainer>
        </>
      }
    </FormContainer>
  )
}

interface IFloorCoordinatesFieldProps {
  type: 'x' | 'y';
  value: number;
  onChange: (newValue: number) => void;
}

const FloorCoordinatesField = ({
  type,
  value,
  onChange
}: IFloorCoordinatesFieldProps) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <label
        style={{
          marginRight: '5px'
        }}
      >
        {`Floor Plan ${type}:`}
      </label>
      <input
        value={value}
        type="number"
        min={0}
        onChange={e => onChange(parseInt(e.target.value))}
        style={{width: '65px'}}
      />
    </div>
  )
}

const EmptyDiv = styled.div`
  flex: 1;
`;

const ReturnToScriptsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
`;

const FloorCoordinatesContainer = styled.div`
  margin-left: 10px;
  display: flex;
  gap: 10px;
`;
