import { uploadProjectClientLogo, uploadProjectImage, uploadProjectRenderingImage, uploadProjectRenderingVectors, uploadProjectWhiteRenderingImage } from "../../../../api/projects"
import { useProjectContext } from "../../../../contexts/projectContext";
import { FileUpload } from "./FileUpload";

interface IImangeUploadProps {
  imagePropertyName: string;
  uploadImage: (projectId: string, image: File) => Promise<any>;
}

const ImageUpload = ({
  imagePropertyName,
  uploadImage,
}: IImangeUploadProps) => {
  const {
    updateProject,
    state: projectState,
  } = useProjectContext();

  const project = projectState.project;

  const upload = async (image: File) => {
    const updatedProject = await uploadImage(project.public_id, image);

    updateProject(updatedProject);
  }

  return (
    <FileUpload
      imageSourceUrl={project[imagePropertyName]}
      imageAlt={project.name}
      onSaveFile={upload}
    />
  )
}

export const ProjectImage = () => {
  return (
    <ImageUpload
      imagePropertyName="image_url"
      uploadImage={uploadProjectImage}
    />
  )
}

export const ProjectClientLogo = () => {
  return (
    <ImageUpload
      imagePropertyName="client_logo_url"
      uploadImage={uploadProjectClientLogo}
    />
  )
}

export const ProjectRendering = () => {
  return (
    <ImageUpload
      imagePropertyName="rendering_url"
      uploadImage={uploadProjectRenderingImage}
    />
  )
}

export const ProjectWhiteRendering = () => {
  return (
    <ImageUpload
      imagePropertyName="white_rendering_url"
      uploadImage={uploadProjectWhiteRenderingImage}
    />
  )
}

export const ProjectRenderingVectors = () => {
  return (
    <ImageUpload
      imagePropertyName="rendering_vector_url"
      uploadImage={uploadProjectRenderingVectors}
    />
  )
}