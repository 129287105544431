import styled from 'styled-components';

export const Content = styled.div`
  position: absolute;
  right: 60px;
  left: 60px;
  top: 68px;
  bottom: 0;
  margin-top: 1em;
`;

export const LoginCard = styled.div`
  width: 350px;
  border-radius: 2px;
`;

export const LoginButton = styled.input`
  border: none;
  background-color: #073c7a;
  color: white;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  padding: 9px 20px;
  margin-bottom: 1em;
  margin: auto;
  width: 100%;
`;

export const LoginCardBody = styled.div`
  padding: 20px;
`;

export const LoginLabel = styled.label`
  font-size: 0.9em;
`;

export const LoginError = styled.div`
  color: #ee2d23;
  margin-bottom: 10px;
  font-size: 0.9em;
`;
