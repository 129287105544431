import axios from "axios";
import { IManageUser } from "./users";
import { API_BASE } from "./constants";

export interface ProjectUser {
  user: IManageUser;
  handles_floor_transitions: boolean;
}

export const fetchProjectUsers = async (projectId: string) => {
  const res = await axios.get(
    `${API_BASE}/project/${projectId}/workers`
  );
  const response = await res.data;
  return response.data;
}

export const addProjectUserMapping = async (projectId: string, userId: string) => {
  const res = await axios.post(
    `${API_BASE}/project/${projectId}/worker`, {
      user: userId
    }
  );
  const response = await res.data;
  return response.data;
}

export const updateProjectUser = async (projectId: string, userId: string, handlesFloorTransitions: boolean) => {
  const res = await axios.patch(
    `${API_BASE}/project/${projectId}/worker/${userId}`, {
      handles_floor_transitions: handlesFloorTransitions
    }
  );
  const response = await res.data;
  return response.data;
}

export const deleteProjectUserMapping = async (projectId: string, userId: string) => {
  const res = await axios.delete(
    `${API_BASE}/project/${projectId}/worker/${userId}`
  );
  const response = await res.data;
  return response.data;
}