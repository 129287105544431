import axios, {AxiosResponse} from "axios";
import { Company } from "./companies";
import { API_BASE } from "./constants";
import { Location } from "./locations";
import { uploadToS3 } from "./s3ImageUpload";

export const listProjects = async (fields?: string[]): Promise<{data: Project[]}> => {
    return await axios.get(`${API_BASE}/projects`, {
        params: {
            fields: fields?.join(',')
        }
    })
        .then( (r: AxiosResponse) => r.data)
        .catch( e => console.log(e))
}

export const fetchProject = async (projectId: string) => {
    const res = await axios.get(
        `${API_BASE}/project/${projectId}`
    )
    const response = await res.data;
    return response.data;
}

export const createNewProject = async (projectData: Project) => {
    const res = await axios.post(
        `${API_BASE}/project`,
        {
            name: projectData.name,
            company: projectData.company,
            location: projectData.location,
            time_zone: projectData.time_zone,
            has_floor_transitions: projectData.has_floor_transitions,
        }
    )
    const response = await res.data;
    return response.data;
}

export const updateProject = async (projectPublicId: string, projectData: Partial<Project>) => {
    const res = await axios.patch(
        `${API_BASE}/project/${projectPublicId}`,
        projectData,
    )
    const response = await res.data;
    return response.data;
}

const uploadImage = async (projectId: string, image: File, endPoint: string, propName: string) => {
    try {
        const imageKey: string = await uploadToS3(image, `project/${projectId}/${endPoint}`);
        
        const res = await axios.patch(
            `${API_BASE}/project/${projectId}`,
            {
                [propName]: imageKey
            }
        );
        const response = await res.data;
        return response.data;
    } catch (err) {
        return err;
    }
}

export const uploadProjectImage = async (projectId: string, image: File) => {
    return await uploadImage(projectId, image, 'upload_project_image', 'image_s3_key');
}

export const uploadProjectClientLogo = async (projectId: string, image: File) => {
    return await uploadImage(projectId, image, 'upload_logo', 'client_logo_s3_key');
}

export const uploadProjectRenderingImage = async (projectId: string, image: File) => {
    return await uploadImage(projectId, image, 'upload_rendering', 'rendering_s3_key');
}

export const uploadProjectWhiteRenderingImage = async (projectId: string, image: File) => {
    return await uploadImage(projectId, image, 'upload_rendering', 'white_rendering_s3_key');
}

export const uploadProjectRenderingVectors = async (projectId: string, image: File) => {
    return await uploadImage(projectId, image, 'upload_rendering', 'rendering_vectors_s3_key');
}

type ProjectStatus = 'active' | 'hold' | 'done';

export interface Project {
    public_id: string;
    name: string;
    company: Company;
    location: Location;
    is_verified: boolean;
    image_url: string | null;
    latest_data_received_on: string;
    client_logo_url: string | null;
    rendering_url: string | null;
    rendering_vector_url: string | null;
    white_rendering_url: string | null;
    forge_translations: string | null;
    status: ProjectStatus;
    time_zone: string;
    has_floor_transitions: boolean;
}