import axios from "axios";
import { API_BASE } from "./constants";

interface Staircase {
  id: number;
  name: string;
  default_ascend_pose_x: number;
  default_ascend_pose_y: number;
  default_descend_pose_x: number;
  default_descend_pose_y: number;
  project: {
    id: number;
    name: string;
    public_id: string;
  }
}

export interface StairClimberScriptFloor {
  project_floor: {
    id: number;
    floor_code: string;
  }
  approach_pose_x: number;
  approach_pose_y: number;
}

export interface StairClimberScript {
  floors: StairClimberScriptFloor[];
  id: number;
  name: string;
  script: string;
  staircase: Staircase | null;
}

export const initialStairClimberScript: StairClimberScript = {
  floors: [],
  id: 0,
  name: '',
  script: '',
  staircase: null,
}

export const fetchStairClimberScripts = async (): Promise<StairClimberScript[]> => {
  const res = await axios.get(`${API_BASE}/stair-climber-scripts`);
  const response = await res.data;
  
  return response.data;
}

export const fetchStairClimberScript = async (scriptId: string | number): Promise<StairClimberScript> => {
  const res = await axios.get(`${API_BASE}/stair-climber-script/${scriptId}`);
  const response = await res.data;
  
  return response.data;
}

export const fetchProjectStaircases = async (projectId: string | number): Promise<Staircase[]> => {
  const res = await axios.get(`${API_BASE}/project/${projectId}/staircases`);
  const response = await res.data;
  
  return response.data;
}

export const createStairClimberScript = async (script: StairClimberScript): Promise<StairClimberScript> => {
  const res = await axios.post(
    `${API_BASE}/stair-climber-script`,
    script
  );
  const response = await res.data;
  
  return response.data;
}

export const updateStairClimberScript = async (scriptId: string | number, script: Partial<StairClimberScript>): Promise<StairClimberScript> => {
  const res = await axios.patch(
    `${API_BASE}/stair-climber-script/${scriptId}`,
    script
  );
  const response = await res.data;
  
  return response.data;
}