import React from "react";
import { PanZoomContext } from "./PanZoomContext";

const ZoomStep = 0.2;

type PanZoomProps = {
    mapContainerRef: React.RefObject<HTMLDivElement>;
    minScale?: number;
    maxScale?: number;
    interactive?: boolean;
    preventDoubleTouches?: boolean;
    x: number;
    y: number;
    updateX: (newX: number) => void;
    updateY: (newY: number) => void;
  };

class PanZoom extends React.Component<PanZoomProps> {
    static contextType = PanZoomContext;
    context!: React.ContextType<typeof PanZoomContext>;

    private isDraggingDown = false;
    private isScaling = false;
    private dragStartX = 0;
    private dragStartY = 0;
    private dragStartScale = 0;
    private interactive = false;
    private lastTouch = 0;

    constructor(props: Readonly<PanZoomProps> | PanZoomProps) {
        super(props);

        this.interactive = this.props.interactive ?? true;

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onTouchDown = this.onTouchDown.bind(this);
        this.onTouchUp = this.onTouchUp.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onWheel = this.onWheel.bind(this);

        this.onDragStart = this.onDragStart.bind(this);

        this.onGestureStart = this.onGestureStart.bind(this);
        this.onGestureEnd = this.onGestureEnd.bind(this);
        this.onGestureChange = this.onGestureChange.bind(this);
    }

    public getZoom(): number {
        return this.context.scale;
    }

    public setZoom(newZoom: number) {
        const mainDiv = this.props.mapContainerRef.current!;
        const rect = mainDiv.getBoundingClientRect();

        this.setZoomRelativeTo(newZoom, rect.width / 2, rect.height / 2);
    }

    public setZoomRelativeTo(newZoom: number, x: number, y: number) {
        if (this.props.maxScale != null && newZoom > this.props.maxScale) newZoom = this.props.maxScale;
        if (this.props.minScale != null && newZoom < this.props.minScale) newZoom = this.props.minScale;

        const scaleMult = newZoom / this.context.scale;

        this.props.updateX(x - (x - this.props.x) * scaleMult);
        this.props.updateY(y - (y - this.props.y) * scaleMult);
        this.context.setScale(this.context.scale * scaleMult);
    }

    public componentDidMount() {
        const mainDiv = this.props.mapContainerRef.current!;

        if (this.interactive) {
        document.addEventListener('mouseup', this.onMouseUp, true);
        document.addEventListener('mousemove', this.onMouseMove, true);
        document.addEventListener('touchend', this.onTouchUp, true);
        document.addEventListener('touchmove', this.onTouchMove, true);
        mainDiv.addEventListener('wheel', this.onWheel, false);
        mainDiv.addEventListener('dragstart', this.onDragStart, false);
        mainDiv.addEventListener('gesturestart', this.onGestureStart, false);
        mainDiv.addEventListener('gestureend', this.onGestureEnd, false);
        mainDiv.addEventListener('gesturechange', this.onGestureChange, false);
        }
    }

    public componentWillUnmount() {
        const mainDiv = this.props.mapContainerRef.current!;

        if (this.interactive) {
        document.removeEventListener('mouseup', this.onMouseUp, false);
        document.removeEventListener('mousemove', this.onMouseMove, false);
        document.removeEventListener('touchend', this.onTouchUp, false);
        document.removeEventListener('touchmove', this.onTouchMove, false);
        mainDiv.removeEventListener('wheel', this.onWheel, false);
        mainDiv.removeEventListener('dragstart', this.onDragStart, false);
        mainDiv.removeEventListener('gesturestart', this.onGestureStart, false);
        mainDiv.removeEventListener('gestureend', this.onGestureEnd, false);
        mainDiv.removeEventListener('gesturechange', this.onGestureChange, false);
        }
    }

    private onMouseDown(event: React.MouseEvent<HTMLDivElement>) {
        this.isDraggingDown = true;
        this.dragStartX = event.clientX;
        this.dragStartY = event.clientY;
    }

    private onTouchDown(event: React.TouchEvent) {
        const currentTouch = new Date().getTime();
        const isDoubleTouch: boolean = currentTouch - this.lastTouch <= 200;
        const eligibleToProceed = !isDoubleTouch || !this.props.preventDoubleTouches;

        if (eligibleToProceed && event.touches.length === 1) {
        this.isDraggingDown = true;
        this.dragStartX = event.touches[0].clientX;
        this.dragStartY = event.touches[0].clientY;
        }

        this.lastTouch = currentTouch;
    }

    private onMouseUp() {
        this.isDraggingDown = false;
    }

    private onTouchUp() {
        this.isDraggingDown = false;
    }

    private onMouseMove(event: MouseEvent) {
        event.preventDefault();
        if (this.isDraggingDown) {
            this.props.updateX(this.props.x + (event.clientX - this.dragStartX));
            this.props.updateY(this.props.y + (event.clientY - this.dragStartY));
            this.dragStartX = event.clientX;
            this.dragStartY = event.clientY;
        }
    }

    private onTouchMove(event: TouchEvent) {
        event.preventDefault();
        if (this.isDraggingDown) {
        // eslint-disable-next-line
        if (event.touches.length == 1) {
            this.props.updateX(this.props.x + (event.touches[0].clientX - this.dragStartX));
            this.props.updateY(this.props.y + (event.touches[0].clientY - this.dragStartY));
            this.dragStartX = event.touches[0].clientX;
            this.dragStartY = event.touches[0].clientY;
        }
        }
    }

    private onWheel(e: WheelEvent) {
        e.preventDefault();
        const mainDiv = this.props.mapContainerRef.current!;

        const dir = e.deltaY > 0 ? 1 : -1;

        if (e.target) {
        const rect = mainDiv.getBoundingClientRect();

        const delta = (e as any).wheelDeltaY;
        const zoomStep = 1 + ZoomStep * Math.max(0, Math.min(1.5, Math.abs(delta) / 120));

        const mouseX = e.clientX - rect.x;
        const mouseY = e.clientY - rect.y;
        const scaleMult = dir === 1 ? 1 / zoomStep : zoomStep;

        this.setZoomRelativeTo(this.context.scale * scaleMult, mouseX, mouseY);
        }
    }

    private onDragStart(e: Event) {
        e.preventDefault();
    }

    private onGestureStart(e: any) {
        e.preventDefault();
        this.dragStartScale = e.scale;
        this.isScaling = true;
    }

    private onGestureEnd(e: any) {
        e.preventDefault();
        this.isScaling = false;
    }

    private onGestureChange(e: any) {
        e.preventDefault();
        if (this.isScaling) {
        const diff = e.scale / this.dragStartScale;
        this.dragStartScale = e.scale;

        const mainDiv = this.props.mapContainerRef.current!;

        if (e.target) {
            const rect = mainDiv.getBoundingClientRect();

            const mouseX = e.clientX - rect.x;
            const mouseY = e.clientY - rect.y;

            this.setZoomRelativeTo(this.context.scale * diff, mouseX, mouseY);
        }
        }
    }

    render() {
        return (
            <div
                ref={this.props.mapContainerRef}
                style={{ width: '100%', height: '100%', overflow: 'hidden' }}
                onMouseDown={this.onMouseDown}
                onTouchStart={this.onTouchDown}
            >
                <div style={{ position: 'relative', display: 'inline-block', transform: `translate(${this.props.x}px, ${this.props.y}px) translate(-50%,-50%) scale(${this.context.scale}) translate(50%,50%)` }}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

  export default PanZoom;