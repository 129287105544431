import {SidebarCard} from "../../../common/SidebarCard/SidebarCard";
import {useEditorContext} from "../../../../contexts/editorContext";
import styled from "styled-components";
import {useNotifications} from "../../../../contexts/notificationProvider";
import axios from "axios";


export const ImageDeatilCard = () => {
    const {state: EditorState, updateEditor} = useEditorContext()
    const { addNotification } = useNotifications();

    const markReprocess = (image: any) => {
        axios
            .patch(`https://api.nexterarobotics.com/api/processed-image/${image.id}`, { reprocess: !image.reprocess })
            .then(r => {
                updateEditor({refreshImage: true})
                addNotification(`${r.data.data.reprocess ? 'Marked' : 'Unmarked'} for Reprocessing`, 'success')
            })
            .catch(e => addNotification('Error! Not Marked for Reprocessing', 'error'));
    };

    const markRetrain = (image: any) => {
        axios
            .patch(`https://api.nexterarobotics.com/api/processed-image/${image.id}`, { retrain: !image.retrain })
            .then(r => {
                updateEditor({refreshImage: true})
                addNotification(`${r.data.data.retrain ? 'Marked' : 'Unmarked'} for Retraining`, 'success')
            })
            .catch(e => addNotification('Error! Not Marked for Retraining', 'error'));
    };

    const markVerified = (image: any) => {
        if (!image.verified) {
            axios
                .patch(`https://api.nexterarobotics.com/api/processed-image/${image.id}`, { verified: !image.verified })
                .then(r => {
                    updateEditor({refreshImage: true})
                    addNotification('Marked as Verified', 'success')
                })
                .catch(e => addNotification('Error! Not Marked as verified', 'error'));
        } else {
            addNotification('Image already verified', 'warning');
        }
    };
    return(
        <SidebarCard heading="Image Details" style={{height: "40%"}}>
            {EditorState.activeImage?.viewpoints_image ? (
                <div style={{height: "100%", overflowY: "auto"}}>
                    <p>{EditorState.activeImage.viewpoints_image.project}</p>
                    <p>{EditorState.activeImage.viewpoints_image.project_floor}</p>
                    <p>Point {EditorState.activeImage.viewpoints_image.sub_point_id}</p>
                    <p>{EditorState.activeImage.viewpoints_image.taken_on}</p>
                    <br/>
                    <p>Base Img ID {EditorState.activeImage.base_image}</p>
                    <p>Processed Img ID {EditorState.activeImage.id}</p>
                    <div style={{marginTop: "20px"}}>
                        <ActionButton className={EditorState.activeImage.verified ? "value-true" : ''}
                                      onClick={() => markVerified(EditorState.activeImage)}>
                            Safety Verified
                        </ActionButton>
                        <ActionButton className={false ? "value-true" : ''}
                                      onClick={() => null}>
                            Progress Verified
                        </ActionButton>
                        <ActionButton className={EditorState.activeImage.reprocess ? "value-true" : ''}
                                      onClick={() => markReprocess(EditorState.activeImage)}>
                            Reprocess
                        </ActionButton>
                        <ActionButton className={EditorState.activeImage.retrain ? "value-true" : ''}
                                      onClick={() => markRetrain(EditorState.activeImage)}>
                            Retrain
                        </ActionButton>
                    </div>
                </div>) : <p>No Active Image</p>}
        </SidebarCard>
    )
}

const ActionButton = styled.div`
    margin: 10px 0;
  width: 100%;
  padding: 7px 10px;
  background: #ccc;
  color: #222;
  cursor: pointer;
  text-align: center;
  border-radius: 2px;

  &.value-true{
    background: #0f0;
  }
`;
