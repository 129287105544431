import { Autocomplete, AutocompleteProps, Box } from "@mui/material";
import { ProjectsAutocomplete } from "../../common/Dropdowns";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useProgressTrackingNavigation } from "../../../hooks/useNavigation";
import { HeadCell, Table, TableOrder } from "../../common/Table";
import { ProjectTracker, TrackerType } from "../../../api/trackers";

export interface IDropdownManagedTableProps<T> {
  columns: readonly HeadCell<T>[];
  rows: T[] | undefined;
  initialOrderedColumnName?: keyof T;
  renderVisibleRows: (row: T, index: number) => JSX.Element;
  loading?: boolean;
  customComparator?: (order: TableOrder, orderBy: keyof T) => ((a: T, b: T) => number) | undefined;
  autocompleteProps: AutocompleteProps<any, boolean | undefined, boolean | undefined, boolean | undefined>;
}

export const DropdownManagedTable = <T extends TrackerType | ProjectTracker>({
  columns,
  rows=[],
  initialOrderedColumnName='id',
  renderVisibleRows,
  loading,
  customComparator,
  autocompleteProps,
}: IDropdownManagedTableProps<T>) => {
  const {
    navigateToProgressTrackingTrackers,
  } = useProgressTrackingNavigation();

  const [projectsLoading, setProjectsLoading] = useState<boolean>(true);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const projectParam = params.get('project');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <ProjectsAutocomplete
          selectedProjectId={projectParam}
          setSelectedProjectId={(newValue: string | null) => navigateToProgressTrackingTrackers(newValue)}
          setProjectsLoading={setProjectsLoading}
          width={300}
        />
        <Autocomplete
          {...autocompleteProps}
        />
      </Box>
      <Table
        columns={columns}
        rows={[...rows]}
        initialOrderedColumnName={initialOrderedColumnName}
        renderVisibleRows={renderVisibleRows}
        loading={loading || projectsLoading}
        customComparator={customComparator}
      />
    </Box>
  );
}