import React, { useEffect, useState } from 'react';
import Flex from '@react-css/flex';
import {Link} from 'react-router-dom';
import { Button } from '../../../common/Inputs/Button';
import { SafetyAITable } from './SafetyAITable/SafetyAITable';
import { listProcessedImages } from '../../../../api/processedImages';
import axios from 'axios';

export const SafetyDashboard = () => {
  const [unverifiedCount, setCount] = useState()

  const processLabels = () => {
    axios.post('https://services.nexterarobotics.com/services/labels/process/')
  }

  useEffect(()=>{
    listProcessedImages({limit: 1, verified: false}).then(d => setCount(d.meta.count))
  }, [])
  return (
    <div style={{marginTop: '2em'}}>
      <Flex style={{width: '90%', margin: '1em auto'}} justifySpaceBetween>
        <div style={{height: '100px', width: '33%', padding: '15px', background: "#073C7A", color: "#fff", display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <h1 style={{fontSize: '1.4em'}}>You have {unverifiedCount} new images to label</h1>
          <Link to="/editor">
            <Button primary={false} text={'Start Labeling'} onClick={() => null} style={{marginRight: "1em"}}/>
          </Link>
        </div>
        <div style={{height: '100px', width: '33%', padding: '15px', background: "#eee", color: "#073C7A", display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <h1 style={{fontSize: '1.4em'}}>All done?</h1>
          <Button primary={true} text={'Process Labels'} onClick={processLabels} style={{marginRight: "1em"}}/>
        </div>
      </Flex>
      <div style={{width: '100%', marginTop: '2em'}}>
        <SafetyAITable />
      </div>
    </div>
  );
};
