import React from 'react';
import { useUserContext } from '../../contexts/userContext';
import styled from 'styled-components';
import { Icon } from './Icon';
import imgSignOut from '../../assets/images/icons/icon_sign_out.svg';
import Flex from '@react-css/flex';

const Dropdown = styled.div`
  position: absolute;
  margin-top: 90px;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
`;

const DropdownLink = styled.p`
  cursor: pointer;
`;

interface UserMenuProps {
  display: boolean;
  setDisplay: (value: boolean) => void;
}

export const UserMenu = ({
  display,
  setDisplay,
}: UserMenuProps) => {
  const {
    resetUser,
  } = useUserContext();

  const logout = () => {
    localStorage.removeItem('db_tkn');
    resetUser();
    setDisplay(false);
  }

  if (display) {
    return (
      <Dropdown>
        <Flex flexDirection="row">
          <Icon icon={imgSignOut} size={16} style={{ marginRight: '6px' }} />
          <DropdownLink onClick={logout}>Logout</DropdownLink>
        </Flex>
      </Dropdown>
    );
  }
  return null;
};
