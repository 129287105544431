import { useCallback, useMemo } from "react";
import { Table } from "../../../../common/Table/Table";
import { Balance, CheckBox, DisabledByDefault, DoNotDisturb, Download, Draw, Grading, Transform, Upload } from "@mui/icons-material";
import { LidarMap, updateLidarMap } from "../../../../../api/projectFloorLidarMaps";
import { Checkbox, SpeedDial, SpeedDialAction } from "@mui/material";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useGeneratedPaths } from "../../../../../hooks/useGeneratedPaths";
import { useNotifications } from "../../../../../contexts/notificationProvider";
import { ProjectFloorSection } from "../../../../../api/projectFloorSections";
import { useProjectContext } from "../../../../../contexts/projectContext";
import { runBuildWeights } from "../../../../../api/cartographer";

interface IProjectFloorLidarMapsTableProps {
  containerStyles?: React.CSSProperties;
  sections: ProjectFloorSection[];
  showRejected?: boolean;
}

interface SpeedDialActionMetadata {
  icon: JSX.Element;
  name: string;
  onClick?: (e: React.MouseEvent, item: LidarMap) => any;
  hidden?: boolean;
}

export const ProjectFloorLidarMapsTable = ({
  containerStyles,
  sections,
  showRejected = false,
}: IProjectFloorLidarMapsTableProps) => {
  const {addNotification} = useNotifications();

  const {
    generateProjectFloorLidarMapUploadPath,
    generateLidarReviewPath,
    generateLidarTransformationPath,
    generateLidarEditingPath,
  } = useGeneratedPaths();

  const {
    state: projectState,
    updateFloor,
  } = useProjectContext();

  const {
    projectId,
    floorId,
    floor,
  } = projectState;

  const updateLidarMaps = useCallback((maps: LidarMap[]) => {
    updateFloor({
      lidarMaps: maps,
    });
  }, [updateFloor]);

  const activeLidarMaps = useMemo(() => {
    const lidarMapsSet = new Set<number>();

    if (sections) {
      sections.forEach(section => {
        const activeLidarMap = section.active_lidar_map;

        if (activeLidarMap) {
          lidarMapsSet.add(activeLidarMap);
        }
      })
    }

    return lidarMapsSet;
  }, [sections]);

  const onClickRejectMap = useCallback(async (e: React.MouseEvent, item: LidarMap) => {
    preventTableClick(e);

    try {
      const updatedLidarMap = await updateLidarMap(projectId, floorId, item.sub_id, {is_rejected: true});
      const updatedLidarMaps = floor.lidarMaps.map((map: LidarMap) => map.id === updatedLidarMap.id ? updatedLidarMap : map);

      addNotification('Lidar map rejected successfully', 'success');
      updateLidarMaps(updatedLidarMaps);
    } catch {
      addNotification('Error rejecting lidar map', 'error');
    } 
  }, [addNotification, floor.lidarMaps, floorId, projectId, updateLidarMaps]);

  const preventTableClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }

  const onClickDownloadBag = useCallback((e: React.MouseEvent, item: LidarMap) => {
    preventTableClick(e);

    if (item.mission_bag_url) {
      window.open(item.mission_bag_url);
    }
  }, []);

  const onClickBuildWeights = useCallback(async (e: React.MouseEvent, item: LidarMap) => {
    preventTableClick(e);

    try {
      const buildWeightsResponse = await runBuildWeights(item.id);

      if (buildWeightsResponse.failure > 0) {
        addNotification("Error sending build weights request", "error");
      } else {
        addNotification("Build weights request sent successfully", "success");
      }
    } catch {
      addNotification("Error sending build weights request", "error");
    }
  }, [addNotification]);

  const generateLinkSpeedDialIcon = useCallback((icon: JSX.Element, path: string) => {
    return (
      <Link
        to={path}
        style={{
          textDecoration: 'none',
          color: 'inherit',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {icon}
      </Link>
    )
  }, []);

  const getSpeedDialActions = useCallback((item: LidarMap) => {
    const speedDialActions: SpeedDialActionMetadata[] = [];

    speedDialActions.push({
      icon: generateLinkSpeedDialIcon(<Upload/>, generateProjectFloorLidarMapUploadPath(projectId, floorId, item.sub_id)),
      name: 'Upload',
      hidden: item.is_rejected
    });

    speedDialActions.push({
      icon: generateLinkSpeedDialIcon(<Grading/>, generateLidarReviewPath(projectId, floorId, item.sub_id.toString())),
      name: 'Review',
      hidden: item.is_rejected
    });

    speedDialActions.push({
      icon: <DoNotDisturb/>,
      name: 'Reject',
      onClick: onClickRejectMap,
      hidden: item.is_rejected
    });


    speedDialActions.push({
      icon: generateLinkSpeedDialIcon(<Transform/>, generateLidarTransformationPath(projectId, floorId, item.sub_id)),
      name: 'Transform',
      hidden: item.is_rejected
    });


    speedDialActions.push({
      icon: generateLinkSpeedDialIcon(<Draw/>, generateLidarEditingPath(projectId, floorId, item.sub_id)),
      name: 'Draw Lines',
      hidden: item.is_rejected
    });

    speedDialActions.push({
      icon: <Balance/>,
      name: 'Build Weights',
      onClick: onClickBuildWeights,
      hidden: item.is_rejected || !item.transformation_from_abs || !item.transformation_to_abs
    });

    speedDialActions.push({
      icon: <Download/>,
      name: 'Download Bag',
      onClick: onClickDownloadBag,
      hidden: item.mission_bag_url === null,
    });

    return speedDialActions;
  }, [floorId, generateLidarEditingPath, generateLidarReviewPath, generateLidarTransformationPath, generateLinkSpeedDialIcon, onClickBuildWeights, onClickDownloadBag, onClickRejectMap, projectId]);


  const renderActionSpeedDial = useCallback((item: LidarMap) => {
    const speedDialActions = getSpeedDialActions(item);

    return (
      <SpeedDial
        ariaLabel="Lidar Map Actions"
        direction="right"
        open
        hidden
      >
        {speedDialActions.map((action) => {
          if (!action.hidden) {
            return (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={e => {
                  if (action.onClick) {
                    action.onClick(e, item);
                  }
                }}
              />
            )
          } else {
            return (
              <div
                style={{
                  width: '56px'
                }}
              />
            )
          }
        })}
      </SpeedDial>
    )
  }, [getSpeedDialActions]);

  const renderTableCheckbox = useCallback((checked: boolean) => {
    return (
      <TableButtonContainer>
        <Checkbox
          checked
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: 28,
              color: checked ? "#073C7A" : 'red',
            },
            "&.MuiCheckbox-root": {
              borderRadius: 0,
              padding: 0,
            },
            "&.Mui-checked": {
              backgroundColor: "white",
            },
            "& svg": {
              scale: "1.4",
            },
          }}
          checkedIcon={checked ? <CheckBox/> : <DisabledByDefault/>}
        />
      </TableButtonContainer>
    )
  }, []);

  const columns = useMemo(() => [
    { Header: 'ID', accessor: 'id' },
    // { Header: 'Sub ID', accessor: 'sub_id' },
    { Header: 'Section', accessor: 'floor_section.name'},
    { Header: 'Processed Via', accessor: 'processed_via'},
    { Header: 'Usable?', accessor: (item: LidarMap) => renderTableCheckbox(item.is_usable)},
    { Header: 'Transformed?', accessor: (item: LidarMap) => renderTableCheckbox(!!item.transformation_from_abs && !!item.transformation_to_abs)},
    { Header: 'Lines?', accessor: (item: LidarMap) => renderTableCheckbox(!!item.lines_svg_s3_key)},
    { Header: 'Weights?', accessor: (item: LidarMap) => renderTableCheckbox(!!item.weights_s3_key)},
    { Header: 'Actions', accessor: (item: LidarMap) => renderActionSpeedDial(item) }
  ], [renderActionSpeedDial, renderTableCheckbox]);

  const data = useMemo(() => {
    return floor.lidarMaps.filter((map: LidarMap) => showRejected || !map.is_rejected);
  }, [floor.lidarMaps, showRejected]);

  return (
    <Table
      containerStyles={{...containerStyles}}
      columns={columns}
      data={data}
      initialSortBy={[{id: 'id', desc: true}]}
      styleCell={(cell) => {
        return {
          ...cell.row.original.is_rejected ? {background: '#7E858E'} : {},
          ...activeLidarMaps.has(cell.row.original.id) ? {background: '#50C67F'} : {},
        }
      }}
    />
  );
}

const TableButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;