import React from 'react';
import styled from 'styled-components';
import { Flex } from '@react-css/flex';
import {Link} from "react-router-dom";

const ViewMenuButton = styled.div`
  font-size: 14px;
  color: #a7aabd;
  text-align: center;
  padding: 6px 11px;
  font-weight: 400;

  &:not(last-child) {
    margin-right: 10px;
  }

  cursor: pointer;
  user-select: none;

  &.active {
    font-weight: 700;
    color: #f8f8f8;
    background-color: #073c7a;

    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }
`;

interface IViewMenuItem {
  route: string;
  label: string;
}

export const ViewMenu = (props: {
  items: IViewMenuItem[];
  activeItem: any;
  containerStyle?: React.CSSProperties;
}) => (
  <Flex style={props.containerStyle}>
    {props.items.map((item, index) =>
      <Link to={{pathname: item.route, state: { prevPath: window.location.pathname }}} key={item.label}>
        <ViewMenuButton className={item.label === props.activeItem?.label ? "active" : ''}>
          {item.label}
        </ViewMenuButton>
      </Link>
    )}
  </Flex>
);
