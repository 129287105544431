import axios from "axios";
import { API_BASE } from "./constants";

export interface IEmployer {
  is_verified: boolean;
  logo_url?: string;
  name: string;
  public_id: string;
  website_url: string;
}

export interface IManageUser {
  date_of_birth: string | null;
  email: string;
  employer: IEmployer;
  account_owner: IEmployer;
  first_name: string;
  gender: string;
  is_verified: boolean;
  joined_on: string | null;
  last_name: string;
  public_id: string;
  user_type: string;
  password?: string;
  confirm_password?: string;
  is_demo_account: boolean;
}

export const fetchUsers = async () => {
  const res = await axios.get(
    `${API_BASE}/users`
  )
  const response = await res.data;
  return response.data;
}

export const fetchUser = async (userId: string) => {
  const res = await axios.get(
    `${API_BASE}/user/${userId}`
  )
  const response = await res.data;
  return response.data;
}

export const createUser = async (userData: IManageUser) => {
  const res = await axios.post(
    `${API_BASE}/user`,
    userData
  )
  const response = await res.data;
  return response.data;
}

export const updateUser = async (userData: IManageUser) => {
  const res = await axios.patch(
    `${API_BASE}/user/${userData.public_id}`,
    userData
  )
  const response = await res.data;
  return response.data;  
}