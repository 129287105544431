import styled from "styled-components";
import { Modal } from "../../common/Modal/Modal";
import { Button } from "../../common/Inputs/Button";

interface ClearAllPointsModalProps {
  onClose: () => void;
  open: boolean;
  onConfirm: () => void;
}

export const ClearAllPointsModal = ({
  onClose,
  open,
  onConfirm,
}: ClearAllPointsModalProps) => {
  const confirmClearAllPoints = () => {
    onConfirm();
    onClose();
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      modalStyle={{
        width: '400px',
        height: '150px'
      }}
    >
      <ModalContent>
        <Title>
          Confirm Clear All Points
        </Title>
        <ButtonContainer>
          <Button
            text="Cancel"
            onClick={onClose}
          />
          <Button
            primary
            text="Confirm"
            onClick={confirmClearAllPoints}
          />
        </ButtonContainer>
      </ModalContent>
    </Modal>
  );
}

const Title = styled.div`
  font-weight: bold;
  flex: 1;
`;

const ModalContent = styled.div`
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;