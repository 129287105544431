import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

interface IIconButtonBaseProps {
  style?: CSSProperties;
  className: string;
  onClick?: () => void;
  hoverCSS?: string;
}

const IconButtonBase = styled.div<IIconButtonBaseProps>`
  position: absolute;
  bottom: 8px;
  right: 8px;

  background-color: white;
  transition: background-color 0.3s;
  padding: 8px;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  user-select: none;

  &.primary {
    background-color: #073c7a;
  }
`;

export function IconButton(props: {
  icon: string;
  hoverIcon?: string;
  size: number;
  tooltip?: string;
  primary?: boolean;
  style?: CSSProperties;
  classNames?: string;
  onClick?: () => void;
  hoverStyle?: CSSProperties;
  disabled?: boolean;
}) {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const baseStyles: CSSProperties = {
    width: `${props.size}px`,
    height: `${props.size}px`,
  };

  const currentIcon: string = (props.hoverIcon && isHovering) ? props.hoverIcon : props.icon;

  const disabledStyles: CSSProperties = {
    backgroundColor: 'lightgray',
    pointerEvents: 'none',
  }

  return (
    <IconButtonBase
      style={{ ...baseStyles, ...props.style, ...isHovering ? props.hoverStyle : {}, ...props.disabled ? disabledStyles : {} }}
      className={classNames({ primary: props.primary }) + ' ' + props.classNames}
      onClick={props.onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      data-tip={props.tooltip}
    >
      <img src={currentIcon} alt="" />
    </IconButtonBase>
  );
}