import {useState} from "react";
import styled from "styled-components";
import {Flex} from "@react-css/flex";
import {Icon} from "../../common/Icon";
import iconZoomIn from "../../../assets/images/icons/icon_zoom_in.svg"
import iconZoomOut from "../../../assets/images/icons/icon_zoom_out.svg"
import iconPan from "../../../assets/images/icons/icon_pan.svg"
import iconStar from "../../../assets/images/icons/icon_star.svg"
import iconRectangle from "../../../assets/images/icons/icon_rectangle.svg"
import iconReset from "../../../assets/images/icons/icon_overscan.svg"
import {useEditorContext} from "../../../contexts/editorContext";

const ZoomStep = 0.2;

interface IAnnotoriousToolbarProps {
    anno: any;
    viewer: any;
}
export const AnnotoriousToolbar = ({anno, viewer}: IAnnotoriousToolbarProps) => {
    const [ tool, setTool ] = useState('rect');
    const {state: EditorState, updateEditor} = useEditorContext()

    const toggleBlur = () => {
        updateEditor({showBlur: !EditorState.showBlur})
    }

    // Toggles current tool + button label
    const toggleTool = (selection: string) => {
        if(anno.current && viewer) {
            if (selection === 'polygon') {
                setTool('polygon');
                // @ts-ignore
                anno.current.setDrawingTool('polygon');
            } else if (selection === 'rect') {
                setTool('rect');
                // @ts-ignore
                anno.current.setDrawingTool('rect');
            } else if(selection === "pan") {
                setTool('pan');
            } else if (selection == "zoomIn") {
                viewer.viewport.zoomTo(viewer.viewport.getZoom()+1)
            } else if (selection == "zoomOut") {
                viewer.viewport.zoomTo(viewer.viewport.getZoom()-1 || 1)
            } else if (selection === "reset"){
                viewer.viewport.goHome()
            }
        }
    }
    return(
        <div style={{width: "100%", background: "#444", padding: "2px 0", display: "flex", flexDirection: "row", justifyContent: "space-between", position: 'relative', zIndex: 100}}>
            <div style={{textAlign: "start", padding: "5px 0"}}>
                <ToolbarButton active={EditorState.showBlur} className="textual" onClick={toggleBlur}>
                    {EditorState.showBlur ? "Hide Blur" : "Show Blur"}
                </ToolbarButton>
            </div>
        <Flex flexDirection="row" style={{background: "#444", padding: "5px 0",}}>
            <ToolbarButton
                active={tool === 'rect'}
                onClick={() => toggleTool('rect')}>
                <Icon icon={iconRectangle} size={22} />
            </ToolbarButton>
            <ToolbarButton
                active={tool === 'polygon'}
                onClick={() => toggleTool('polygon')}>
                <Icon icon={iconStar} size={22} />
            </ToolbarButton>
            {/*<ToolbarButton*/}
            {/*    active={tool === 'pan'}*/}
            {/*    onClick={() => toggleTool('pan')}>*/}
            {/*    <Icon icon={iconPan} size={22} />*/}
            {/*</ToolbarButton>*/}
            <ToolbarButton
                active={tool === 'zoomIn'}
                onClick={() => toggleTool('zoomIn')}>
                <Icon icon={iconZoomIn} size={22} />
            </ToolbarButton>
            <ToolbarButton
                active={tool === 'zoomOut'}
                onClick={() => toggleTool('zoomOut')}>
                <Icon icon={iconZoomOut} size={22} />
            </ToolbarButton>
            <ToolbarButton
                active={tool === 'reset'}
                onClick={() => toggleTool('reset')}>
                <Icon icon={iconReset} size={22} />
            </ToolbarButton>
        </Flex>
        </div>
    )
}

const ToolbarButton = styled.div<{ active: boolean}>`
    color: ${props => props.active ? '#222' : '#ccc'};
    background: ${props => props.active ? '#ccc' : '#222'};
    border: 1px solid #ccc;
    margin: 0 2px;
    padding: 2px;
    border-radius: 2px;
    cursor: pointer;
    &.textual{
        padding: 5px;
        font-size: 12px;
        margin: auto;
    }
    &:active {
        color: #222;
        background: #ccc; 
        fill: #222!important;  
    }
`;
