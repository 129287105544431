import { Route, Switch } from "react-router-dom"
import { useProjectContext } from "../../../../contexts/projectContext"
import { PATH_STRINGS } from "../../../../hooks/useGeneratedPaths"
import { LoadingIndicator } from "../../../common/LoadingIndicator"
import { AutodeskUpload } from "../../autodesk/AutodeskUpload"
import { ProjectAssetsMenu } from "./ProjectAssetsMenu"
import { ProjectClientLogo, ProjectImage, ProjectRendering, ProjectRenderingVectors, ProjectWhiteRendering } from "./ProjectUploads"


export const ManageProjectAssets = () => {
  const {
    state: projectState,
  } = useProjectContext();

  if (!projectState.projectId) {
    return <LoadingIndicator/>;
  }
  
  return (
    <>
      <div 
        style={{
           position: 'absolute',
           marginLeft: '25px'
        }}
      >
        <ProjectAssetsMenu/>
      </div>

      <Switch>
        <Route exact path={PATH_STRINGS.projectImage}>
          <ProjectImage/>
        </Route>
        <Route exact path={PATH_STRINGS.projectClientLogo}>
          <ProjectClientLogo/>
        </Route>
        <Route exact path={PATH_STRINGS.projectRendering}>
          <ProjectRendering/>
        </Route>
        <Route exact path={PATH_STRINGS.projectWhiteRendering}>
          <ProjectWhiteRendering/>
        </Route>
        <Route exact path={PATH_STRINGS.projectRenderingVectors}>
          <ProjectRenderingVectors/>
        </Route>
        <Route exact path={PATH_STRINGS.projectForgeModel}>
          <AutodeskUpload/>
        </Route>
      </Switch>
    </>
  )
}