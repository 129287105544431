import styled from "styled-components";
import { ProjectLidarMapConfiguration } from "../../../../api/projectFloorLidarMaps";
import { useProjectContext } from "../../../../contexts/projectContext";
import { useFetchProjectLidarMapConfigurationsQuery } from "../../../../hooks/projectQueries";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { Table } from "../../../common/Table/Table";
import { useCallback, useMemo } from "react";
import { Checkbox } from "../../../common/FormControls";
import { Button } from "../../../common/Inputs/Button";
import { useProjectNavigation } from "../../../../hooks/useNavigation";

export const LidarMapConfigurationsDashboard = () => {
  const {
    navigateToProjectLidarMapConfiguration,
  } = useProjectNavigation();

  const {
    state: projectState,
    updateState,
  } = useProjectContext();

  const {
    project,
    projectId,
    lidarMapConfigurations,
  } = projectState;

  const onUpdateLidarMapConfigurations = (updatedConfigurations: ProjectLidarMapConfiguration[]) => {
    updateState({ lidarMapConfigurations: updatedConfigurations });
  }

  const {
    isLoading: configurationsLoading
  } = useFetchProjectLidarMapConfigurationsQuery(projectState.projectId, onUpdateLidarMapConfigurations);

  const generateCheckboxColumn = useCallback((checked: boolean) => {
    return (
      <Checkbox
        checked={checked}
        onChangeChecked={() => {}}
        checkmarkLeft={0}
        labelStyle={{
          display: 'block',
        }}
      />
    )
  }, []);

  // const generateButtonColumn = useCallback((text: string, onClick: () => void) => {
  //   return (
  //     <Button
  //       primary
  //       text={text}
  //       onClick={onClick}
  //     />
  //   )
  // }, []);

  const columns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'Run Cartographer ROS?',
      accessor: (config: ProjectLidarMapConfiguration) => generateCheckboxColumn(config.run_cartographer_ros)
    },
    {
      Header: 'Run SLAM Toolbox?',
      accessor: (config: ProjectLidarMapConfiguration) => generateCheckboxColumn(config.run_slam_toolbox)
    }
  ], [generateCheckboxColumn]);

  const data = useMemo(() => lidarMapConfigurations, [lidarMapConfigurations]);

  const onClickAddNewConfig = () => {
    navigateToProjectLidarMapConfiguration(projectId, "new");
  }

  const onClickRow = (row: ProjectLidarMapConfiguration) => {
    navigateToProjectLidarMapConfiguration(projectId, row.id);
  }

  if (!lidarMapConfigurations || configurationsLoading) {
    return <LoadingIndicator/>
  }

  return (
    <LidarMapConfigurationsDashboardContainer>
      <ProjectName>
        {project.name}
      </ProjectName>

      <AddNewConfigButtonContainer>
        <Button
          primary
          text='Add New Config'
          onClick={onClickAddNewConfig}
        />
      </AddNewConfigButtonContainer>

      <Table
        columns={columns}
        data={data}
        onClickRow={onClickRow}
      />
    </LidarMapConfigurationsDashboardContainer>
  );
}

const LidarMapConfigurationsDashboardContainer = styled.div`
  margin-top: 1em;
`;

const AddNewConfigButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 20px 20px 0;
  margin-bottom: 2em;
`;

const ProjectName = styled.h1`
  text-align: center;
  font-size: 30px;
  font-weight: 700;
`;