import { useEffect, useMemo } from "react";
import { useProjectFloorsQuery, useProjectsQuery } from "../../hooks/projectQueries";
import { Project } from "../../api/projects";
import { Autocomplete, TextField } from "@mui/material";
import { ProjectFloor } from "../../api/projectFloors";

interface IBaseProjectProperties {
  name: string;
  public_id: string;
}

interface IBaseProjectAutocompleteProps<T extends IBaseProjectProperties> {
  selectedProjectId?: string | null;
  setSelectedProjectId?: (newValue: string | null) => void;
  selectedProject?: T | null;
  setSelectedProject?: (newValue: T | null) => void;

  label?: string;
  width?: number;
  minWidth?: number;
  includeAllOption?: boolean;
  includeUnassignedOption?: boolean;
  projectsQueryFields?: string[];
  setProjectsLoading?: (newValue: boolean) => void;
  disabled?: boolean;
}

interface IProjectAutocompletePropsWithPublicId<T extends IBaseProjectProperties> extends IBaseProjectAutocompleteProps<T> {
  selectedProjectId: string | null;
  setSelectedProjectId: (newValue: string | null) => void;
}

interface IProjectAutocompletePropsWithFullObject<T extends IBaseProjectProperties> extends IBaseProjectAutocompleteProps<T> {
  selectedProject: T | null;
  setSelectedProject: (newValue: T | null) => void;
}

type IProjectAutocompleteProps<T extends IBaseProjectProperties> = IProjectAutocompletePropsWithPublicId<T> | IProjectAutocompletePropsWithFullObject<T>;

export const projectDropdownAllKey = 'All';
export const projectDropdownUnassignedKey = 'Unassigned'

export const ProjectsAutocomplete = <T extends IBaseProjectProperties>({
  selectedProject,
  setSelectedProject,
  selectedProjectId,
  setSelectedProjectId,
  label,
  width,
  minWidth,
  includeAllOption,
  includeUnassignedOption,
  projectsQueryFields,
  setProjectsLoading,
  disabled=false,
}: IProjectAutocompleteProps<T>) => {
  const {data: projects, isLoading: projectsLoading} = useProjectsQuery(projectsQueryFields);
  const projectsLoaded = !!projects && !projectsLoading;

  useEffect(() => {
    if (setProjectsLoading) {
      setProjectsLoading(projectsLoading);
    }
  }, [projectsLoading, setProjectsLoading]);

  const projectOptions = useMemo(() => {
    if (projectsLoaded) {
      return [
        ...includeAllOption ? [{name: projectDropdownAllKey, public_id: projectDropdownAllKey}] : [],
        ...includeUnassignedOption ? [{name: projectDropdownUnassignedKey, public_id: projectDropdownUnassignedKey}] : [],
        ...projects
      ]
    }

    return [];
  }, [projectsLoaded, includeAllOption, includeUnassignedOption, projects]);

  const projectForDropdown = useMemo(() => {
    if (selectedProjectId !== undefined) {
      if (projects) {
        return projects.filter(project => project.public_id === selectedProjectId)[0];
      } 
      
      return null;
    } else if (selectedProject !== undefined) {
      return selectedProject;
    }

    return null;
  }, [projects, selectedProject, selectedProjectId]);

  const onChangeSelectedProject = (e: React.SyntheticEvent, value: Project | IBaseProjectProperties | null) => {
    if (setSelectedProjectId) {
      setSelectedProjectId(value ? value.public_id : null);
    } else if (setSelectedProject) {
      setSelectedProject(value as T);
    }
  }

  return (
    <Autocomplete
      disabled={!projectsLoaded || disabled}
      value={projectForDropdown}
      onChange={onChangeSelectedProject}
      options={projectOptions ?? []}
      getOptionLabel={option => option.name}
      sx={{ width: width, minWidth: minWidth }}
      renderInput={(params) => <TextField {...params} label={label ?? "Projects"} />}
    />
  )
}

interface ProjectFloorsAutoCompleteProps {
  projectId?: string | null;
  selectedProjectFloor: ProjectFloor | null;
  setSelectedProjectFloor: (newValue: ProjectFloor | null) => void;
  loading?: boolean;
  width?: number;
  disabled?: boolean;
}

export const ProjectFloorsAutoComplete = ({
  projectId,
  selectedProjectFloor,
  setSelectedProjectFloor,
  loading,
  width,
  disabled=false,
}: ProjectFloorsAutoCompleteProps) => {
  const {data: floors, isLoading: floorsLoading} = useProjectFloorsQuery(projectId);

  return (
    <Autocomplete
      options={floors ?? []}
      loading={loading || floorsLoading}
      disabled={!projectId || disabled}
      value={selectedProjectFloor}
      getOptionLabel={option => option.floor_code}
      isOptionEqualToValue={(option, value) => option.floor_code === value?.floor_code}
      onChange={(event, newValue) => {
        setSelectedProjectFloor(newValue);
      }}
      renderInput={(params) => {
        return (
          <TextField {...params} label="Floor" />
        )
      }}
      sx={{ width: width }}
    />
  );
}