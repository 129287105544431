import { RefreshOutlined, Upload } from "@mui/icons-material";
import { Autocomplete, Button, TextField, Tooltip } from "@mui/material";
import styled from "styled-components";
import { DashboardCheckbox } from "../../DashboardFormControls";
import { useCallback, useMemo } from "react";
import { ProjectFloor } from "../../../../../api/projectFloors";
import { useProjectContext } from "../../../../../contexts/projectContext";
import { useProjectNavigation } from "../../../../../hooks/useNavigation";
import { Link } from "react-router-dom";
import { useGeneratedPaths } from "../../../../../hooks/useGeneratedPaths";

const tableActionButtonsSx = {
  backgroundColor: "#073C7A",
  minWidth: '40px',
  minHeight: '40px',
  maxWidth: '40px',
  maxHeight: '40px',
}

interface IProjectFloorLidarMapsDashboardControlsProps {
  refetchLidarMaps: () => void;
  refetchSections: () => void;
  showRejected: boolean;
  setShowRejected: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProjectFloorLidarMapsDashboardControls = ({
  refetchLidarMaps,
  refetchSections,
  showRejected,
  setShowRejected,
}: IProjectFloorLidarMapsDashboardControlsProps) => {
  const {
    state: projectState,
  } = useProjectContext();

  const {
    generateProjectFloorLidarMapUploadPath,
  } = useGeneratedPaths();

  const {
    navigateToLidarMaps,
  } = useProjectNavigation();

  const {
    projectId,
    project,
    floorId,
  } = projectState;

  const floorDropdownOptions = useMemo(() => {
    if (project?.floors) {
      return project.floors.map((floor: ProjectFloor) => floor.floor_code);
    }

    return [];
  }, [project.floors]);

  const onClickRefresh = useCallback(() => {
    refetchLidarMaps();
    refetchSections();
  }, [refetchLidarMaps, refetchSections]);

  return (
    <HeaderContainer>
      <TableActionsContainer>
        <Tooltip
          title="Refresh"
          placement="top"
          arrow
        >
          <Button
            onClick={onClickRefresh}
            variant="contained"
            sx={tableActionButtonsSx}
          >
            <RefreshOutlined/>
          </Button>
        </Tooltip>
        <Tooltip
          title="Upload New Map"
          placement="top"
          arrow
        >
          <Link
            to={generateProjectFloorLidarMapUploadPath(projectId, floorId, "new")}
          >
            <Button
              variant="contained"
              sx={tableActionButtonsSx}
            >
              <Upload/>
            </Button>
          </Link>
        </Tooltip>
      </TableActionsContainer>
      <ProjectFloorLidarMapFiltersContainer>
        <DashboardCheckbox
          checked={showRejected}
          onChangeChecked={() => setShowRejected(prevValue => !prevValue)}
          label={"Include Rejected"}
          containerStyle={{
            width: 'auto',
            border: 'none'
          }}
        />
        <Autocomplete
          disableClearable
          value={floorId}
          onChange={(e, value) => navigateToLidarMaps(projectId, value)}
          options={floorDropdownOptions}
          renderInput={(params) => {
            return (
              <TextField {...params} label="Floor" />
            )
          }}
          sx={{
            width: '150px',
          }}
        />
      </ProjectFloorLidarMapFiltersContainer>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const TableActionsContainer = styled.div`
  margin-left: 235px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ProjectFloorLidarMapFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-right: 10px;
`;