import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useProjectContext } from "../../../../contexts/projectContext";
import { useGeneratedPaths } from "../../../../hooks/useGeneratedPaths";
import { IViewSelectorOption, OptionPicker } from "../../../common/ViewSelector/components/OptionPicker";
import { ViewSelector } from "../../../common/ViewSelector/ViewSelector";

export const ProjectAssetsMenu = () => {
  const {
    state: projectState
  } = useProjectContext();

  const projectId = projectState.projectId;

  const location = useLocation();

  const {
    generateProjectImagePath,
    generateProjectClientLogoPath,
    generateProjectRenderingPath,
    generateProjectWhiteRenderingPath,
    generateProjectRenderingVectorsPath,
    generateProjectForgeModelPath,
  } = useGeneratedPaths();

  const viewingItemsOptions = useMemo(() => [
    { Id: generateProjectImagePath(projectId), Content: 'Project Image', Options: [] },
    { Id: generateProjectClientLogoPath(projectId), Content: 'Client Logo', Options: [] },
    { Id: generateProjectRenderingPath(projectId), Content: 'Rendering', Options: [] },
    { Id: generateProjectWhiteRenderingPath(projectId), Content: 'White Rendering', Options: [] },
    { Id: generateProjectRenderingVectorsPath(projectId), Content: 'Rendering Vectors', Options: [] },
    { Id: generateProjectForgeModelPath(projectId), Content: 'Forge Model', Options: [] }
  ], [
    projectId,
    generateProjectImagePath,
    generateProjectClientLogoPath,
    generateProjectRenderingPath,
    generateProjectWhiteRenderingPath,
    generateProjectRenderingVectorsPath,
    generateProjectForgeModelPath,
  ]);

  const [viewingExpanded, setViewingExpanded] = useState<boolean>(true);
  const [selectedItem, setSelectedItem] = useState<IViewSelectorOption | undefined>(viewingItemsOptions[0]);
  const history = useHistory();

  useEffect(() => {
    viewingItemsOptions.forEach((option) => {
      if (option.Id === location.pathname) {
        setSelectedItem(option);
      }
    })
  }, [location.pathname, viewingItemsOptions]);

  let onSelectChild = (selectedChild: IViewSelectorOption) => {
    history.push(selectedChild.Id);
  };

  const viewingItems: IViewSelectorOption = { 
    Id: 'root',
    Content: 'Asset',
    Options: viewingItemsOptions,
    OnSelectChild: onSelectChild,
  }

  const onClickSelectItem = (selectedOption: IViewSelectorOption) => {
    if (viewingItems.OnSelectChild) {
      viewingItems.OnSelectChild(selectedOption);
      setSelectedItem(selectedOption);
    }
  };

  const onHover = (hoverOption: IViewSelectorOption | null) => {
    if (viewingItems.OnHoverChild) {
      viewingItems.OnHoverChild(hoverOption)
    }
  }

  return (
    <ViewSelector
      initialViewSelectorExpanded
      title='Assets'
      viewingExpanded={viewingExpanded}
      setViewingExpanded={setViewingExpanded}
    >
      <OptionPicker
        expanded={viewingExpanded}
        setExpanded={setViewingExpanded}
        viewingItems={viewingItems}
        selectedItem={selectedItem}
        hoverItem={null}
        onSelectOption={onClickSelectItem}
        onHover={onHover}
      />
    </ViewSelector>
  )
}