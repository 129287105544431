import axios from "axios";

export const fetchCompanies = async () => {
  const res = await axios.get(
    `https://api.nexterarobotics.com/api/companies`
  )
  const response = await res.data;
  return response.data;
}

export const fetchCompany = async (companyId: string) => {
  const res = await axios.get(
    `https://api.nexterarobotics.com/api/company/${companyId}`
  )
  const response = await res.data;
  return response.data;
}

export const createCompany = async (companyData: Company) => {
  const res = await axios.post(
    `https://api.nexterarobotics.com/api/company`,
    companyData
  )
  const response = await res.data;
  return response.data;
}

export const updateCompany = async (companyData: Company) => {
  const res = await axios.patch(
    `https://api.nexterarobotics.com/api/company/${companyData.public_id}`,
    companyData
  )
  const response = await res.data;
  return response.data;
}

export interface Company {
  public_id: string;
  name: string;
  logo_url?: string;
  website_url: string;
  is_verified: boolean;
}