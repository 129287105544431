import { useState } from 'react';
import styled from 'styled-components';
import { ContentContainer, SectionHeader } from '../ViewSelector';
import { IconButtonExpand } from './IconButtonExpand';
import { SelectItem } from './SelectItem';

export interface IViewSelectorOption {
  Id: string;
  Content: string | JSX.Element;
  Disclaimer?: string;
  SubTitle?: string;
  OnSelectChild?: (selectedChild: IViewSelectorOption) => void;
  OnHoverChild?: (hoverOption: IViewSelectorOption | null) => void;
  Options: IViewSelectorOption[];
};

export const OptionPicker = (props: {
  expanded: boolean;
  setExpanded: (newValue: boolean) => void;
  viewingItems: IViewSelectorOption;
  selectedItem: IViewSelectorOption | undefined;
  hoverItem: IViewSelectorOption | null;
  onSelectOption: (option: IViewSelectorOption) => void;
  onHover: (option: IViewSelectorOption | null) => void;
}) => {
  const [childExpanded, setChildExpanded] = useState<boolean>(false);
  const [childSelectedItem, setChildSelectedItem] = useState<IViewSelectorOption | undefined>();
  const [childHoverOption, setChildHoverOption] = useState<IViewSelectorOption | null>(null);

  const onClickExpand = (newValue: boolean) => {
    props.setExpanded(newValue);
    setChildExpanded(false);
  }

  const onSelectItem = (option: IViewSelectorOption) => {
    props.onSelectOption(option);
    setChildSelectedItem(undefined);
    setChildHoverOption(null);
  }

  const onSelectChild = (option: IViewSelectorOption) => {
    setChildExpanded(false); 
    setChildSelectedItem(option);

    if (props.selectedItem?.OnSelectChild) {
      props.selectedItem.OnSelectChild(option);
    }
  }

  const onHover = (option: IViewSelectorOption | null) => {
    props.onHover(option);
  }

  const onHoverChild = (option: IViewSelectorOption | null) => {
    setChildHoverOption(option);

    if (props.selectedItem?.OnHoverChild) {
      props.selectedItem.OnHoverChild(option);
    }
  }

  return (
    <>
      <OptionContainer className={`${(props.selectedItem && props.selectedItem.Options.length > 0) ? 'has-children' : 'last-container'}`}>
        <SectionHeader>{props.viewingItems.SubTitle ?? props.viewingItems.Content}</SectionHeader>
        
        <HeaderContainer>
          <SelectedOption>{props.selectedItem ? props.selectedItem.Content : 'Select'}</SelectedOption>
          
          <IconButtonExpand
            expanded={props.expanded}
            onClick={onClickExpand}
            style={{display: 'flex'}}
            size={20}
          />
        </HeaderContainer>

        <ContentContainer aria-expanded={props.expanded}>
          { props.viewingItems.Options.map((option, i) => (
            <SelectItem
              key={i}
              content={option.Content}
              disclaimer={option.Disclaimer}
              onClick={() => onSelectItem(option)}
              onMouseEnter={() => onHover(option)}
              onMouseLeave={() => onHover(null)}
              selected={props.selectedItem?.Id === option.Id || props.hoverItem?.Id === option.Id}
              style={{border: '1px solid #E8E8E8', borderRadius: '4px'}}
            />
          ))
          }
        </ContentContainer>
      </OptionContainer>

      { (props.selectedItem && props.selectedItem.Options.length > 0) &&
        <OptionPicker
          expanded={childExpanded}
          setExpanded={setChildExpanded}
          viewingItems={props.selectedItem}
          selectedItem={childSelectedItem}
          hoverItem={childHoverOption}
          onSelectOption={onSelectChild}
          onHover={onHoverChild}
        />
      }
    </>
  );
}

const SelectedOption = styled.span`
  font-size: 14px;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const OptionContainer = styled.div`  
  padding: 16px 8px 8px 8px;
  border-radius: 4px;
  
  &.has-children {
    border-bottom: 1px solid #E8E8E8;
    padding: 16px 8px;
  }
`