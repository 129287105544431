import { useProjectContext } from "../../../../../contexts/projectContext";
import { useFetchProjectFloorLidarMapsQuery, useProjectFloorSectionsQuery, useProjectFloorsQuery } from "../../../../../hooks/projectQueries";
import styled from "styled-components";
import { useCallback, useState } from "react";
import { LidarMap } from "../../../../../api/projectFloorLidarMaps";
import { LoadingIndicator } from "../../../../common/LoadingIndicator";
import { ProjectFloor } from "../../../../../api/projectFloors";
import { ProjectFloorLidarMapsTable } from "./ProjectFloorLidarMapsTable";
import { ProjectFloorLidarMapsDashboardControls } from "./ProjectFloorLidarMapsDashboardControls";

export const ProjectFloorLidarMapsDashboard = () => {
  const {
    state: projectState,
    updateFloor,
    updateProject,
  } = useProjectContext();

  const {
    projectId,
    project,
    floor,
  } = projectState;

  const updateLidarMaps = useCallback((maps: LidarMap[]) => {
    updateFloor({
      lidarMaps: maps,
    });
  }, [updateFloor]);

  const onUpdateFloors = (updatedFloors: ProjectFloor[]) => {
    updateProject({ floors: updatedFloors });
  }

  const { isLoading: floorsLoading } = useProjectFloorsQuery(projectId, onUpdateFloors);
  const {isLoading: lidarMapsLoading, refetch: refetchLidarMaps, isRefetching: lidarMapsRefetchting} = useFetchProjectFloorLidarMapsQuery(updateLidarMaps);
  const {data: sections, isLoading: sectionsLoading, refetch: refetchSections, isRefetching: sectionsRefetching} = useProjectFloorSectionsQuery();

  const dataLoaded = !floorsLoading && !lidarMapsLoading && !lidarMapsRefetchting && !sectionsLoading && !sectionsRefetching;

  const [showRejected, setShowRejected] = useState<boolean>(false);

  return (
    <div style={{marginTop: '1em'}}>
      <ProjectName>{project.name} - {floor.floor_code}</ProjectName>

      <ProjectFloorLidarMapsDashboardControls
        refetchLidarMaps={refetchLidarMaps}
        refetchSections={refetchSections}
        showRejected={showRejected}
        setShowRejected={setShowRejected}
      />

      {!dataLoaded &&
        <LoadingIndicator/>
      }
      {dataLoaded &&
        <div>
          <ProjectFloorLidarMapsTable
            sections={sections ?? []}
            showRejected={showRejected}
            containerStyles={{ padding: '0px 20px 20px 230px'}}
          />
        </div>
      }
    </div>
  )
}

const ProjectName = styled.h1`
  text-align: center;
  font-size: 30px;
  font-weight: 700;
`;