import React from 'react';
import { Switch, Route, Link, BrowserRouter, useLocation, Redirect } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Flex } from '@react-css/flex';
import styled from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';

import { UserProvider, useUserContext } from '../contexts/userContext';
import { NotificationsProvider } from '../contexts/notificationProvider';
import { AppErrorFallback } from './common/Errors/AppErrorFallback';
import { LoginPage } from './views/login/Login';

import imgLogoWhite from '../assets/images/logos/logo-white.png';
import { Dashboard } from './views/dashboard/Dashboard';
import { Hoop } from './views/hoop/Hoop';
import {Editor} from "./views/editor/Editor";
import {EditorProvider} from "../contexts/editorContext";
import {Observation} from "./views/observation/Observation";
import { UserDashboard } from './views/user-management/UserDashboard';
import { ManageUser } from './views/user-management/ManageUser';
import { ManageProject } from './views/project-setup/ManageProject';
import { PATH_STRINGS } from '../hooks/useGeneratedPaths';
import { ProjectProvider } from '../contexts/projectContext';
import { LoadingIndicator } from './common/LoadingIndicator';
import { ManageCompanies } from './views/company-management/ManageCompanies';
import { ManageLocations } from './views/location-management/ManageLocations';
import { CompanyDetails } from './views/company-management/CompanyDetails';
import { LocationDetails } from './views/location-management/LocationDetails'
import { LidarManagement } from './views/lidar-management/LidarManagement';
import { Sitewalks } from './views/sitewalk/Sitewalks';
import { ManageStairs } from './views/stair-climber-scripts/ManageStairs';
import { PermissionRoute } from './common/PermissionRoute/PermissionRoute';
import { UserBlock } from './common/UserBlock';
import { EntitiesDashboard } from './views/entities/EntitiesDashboard';
import { ProgressPage } from './views/progress/ProgressPage';
import { ProgressProvider } from '../contexts/progressContext';
import { InstanceManagementPage } from './views/instance-management/InstanceManagementPage';

const Header = styled.div`
  flex: 0 1 50px;
  background: #073c7a;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 60px;
  padding-right: 60px;
  a {
    color: white;
  }
`;

const Logo = styled.img.attrs(() => ({
  src: imgLogoWhite,
}))`
  width: 36px;
  height: 31px;
  margin-top: -1px;
`;

const Content = styled.div`
  height: 100%;
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 5000,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <BrowserRouter>
      <ErrorBoundary
        FallbackComponent={AppErrorFallback}
        onReset={() => {
          window.location.reload();
        }}>
        <UserProvider>
        <QueryClientProvider client={queryClient}>
        <NotificationsProvider>
          <Flex flexDirection="column" style={{height: "100%"}}>
            <Switch>
              <CCHeader />
            </Switch>
            <Content>
              <Switch>
                <PrivateRoute path="/" exact>
                  <Dashboard />
                </PrivateRoute>
                <PermissionRoute
                  permission="aims_tables.create_label"
                  path="/hoop"
                  component={Hoop}
                />
              </Switch>
              <PermissionRoute
                permission="aims_tables.create_label"
                path="/editor"
                component={WrappedEditor}
              />
              <PermissionRoute
                permission="aims_tables.create_label"
                path="/observation"
                component={WrappedObservation}
              />
              <PermissionRoute
                permission={[
                  'nextera_sys_tables.create_user',
                  'nextera_sys_tables.create_company',
                  'project_tables.create_location',
                  'project_tables.create_project_stair_climber_script'
                ]}
                useOrInPermissionCheck
                path={PATH_STRINGS.entities}
                component={EntitiesDashboard}
              />
              <PermissionRoute
                permission="nextera_sys_tables.create_user"
                path={PATH_STRINGS.manageUser}
                component={ManageUser}
              />
              <PermissionRoute
                exact
                permission="nextera_sys_tables.create_user"
                path={PATH_STRINGS.userManagementDashboard}
                component={UserDashboard}
              />
              <PermissionRoute
                permission="project_tables.create_project"
                path={PATH_STRINGS.projectsDashboard}
                component={WrappedProjectPage}
              />
              <PermissionRoute
                exact
                permission="nextera_sys_tables.create_company"
                path={PATH_STRINGS.companies}
                component={ManageCompanies}
              />
              <PermissionRoute
                permission="nextera_sys_tables.create_company"
                path={PATH_STRINGS.company}
                component={CompanyDetails}
              />
              <PermissionRoute
                exact
                permission="project_tables.create_location"
                path={PATH_STRINGS.locations}
                component={ManageLocations}
              />
              <PermissionRoute
                permission="project_tables.create_location"
                path={PATH_STRINGS.location}
                component={LocationDetails}
              />
              <PermissionRoute
                permission="project_tables.create_project_floor_lidar_map"
                path={PATH_STRINGS.lidarManagement}
                component={WrappedLidarMaps}
              />
              <PermissionRoute
                permission="project_tables.create_project_stair_climber_script"
                path={PATH_STRINGS.stairClimberScripts}
                component={ManageStairs}
              />
              <PrivateRoute path={PATH_STRINGS.sitewalk}>
                <Sitewalks/>
              </PrivateRoute>
              <PermissionRoute
                permission="progress_tables.read_project_tracker"
                path={PATH_STRINGS.progress}
                component={WrappedProgressPage}
              />
              <PermissionRoute
                permission="old_standard.create_instance_command_history"
                path={PATH_STRINGS.instances}
                component={InstanceManagementPage}
              />
              <Route path="/login" exact>
                <LoginPage />
              </Route>
            </Content>
          </Flex>
        </NotificationsProvider>
        </QueryClientProvider>
        </UserProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

const WrappedProjectPage = () => {
  return (
    <ProjectProvider>
      <ManageProject/>
    </ProjectProvider>
  )
}

const WrappedLidarMaps = () => {
  return (
    <ProjectProvider>
      <LidarManagement/>
    </ProjectProvider>
  )
}

const WrappedEditor = () => {
  return (
    <EditorProvider>
      <Editor />
    </EditorProvider>
  )
}

const WrappedObservation = () => {
  return (
    <EditorProvider>
      <Observation />
    </EditorProvider>
  )
}

const WrappedProgressPage = () => {
  return (
    <ProgressProvider>
      <ProgressPage />
    </ProgressProvider>
  )
}

const CCHeader = () => {
  let location = useLocation();

  if(location.pathname === "/editor")
    return <></>

  return (
      <Header>
        <Flex style={{ width: '100px' }} alignItems={'center'} justifyContent={'flex-start'}>
          <Link
            to="/"
            style={{zIndex: 2}}
          >
            <Logo />
          </Link>
        </Flex>
        <Flex style={{ width: '125px' }} alignItems={'center'} justifyContent={'center'}>
          <Switch>
            <Route path={["/editor", "/observation"]}>
              <Link to={(location.state as any)?.prevPath || "/hoop/labeling"}>
                <h1>Back</h1>
              </Link>
            </Route>
            <Route path={["/", "/hoop"]}>
              <Link
                style={{zIndex: 2}}
                to="/"
              >
                <h1>Control Center</h1>
              </Link>
            </Route>
          </Switch>
        </Flex>
        <Flex style={{ width: '125px', zIndex: 2 }} alignItems={'center'} justifyContent={'flex-end'}>
          <Switch>
           <Route path={"/"}>
             <UserBlock />
           </Route>
          </Switch>
        </Flex>
      </Header>
  )
}
export default App;

// @ts-ignore
function PrivateRoute({ children, ...rest }) {
  const { state: authState } = useUserContext();

  if (authState.loading) return <LoadingIndicator fb={false} />;

  return (
    <Route
      {...rest}
      render={props =>
        authState.isAuthenticated ? (
          <>
          {children}
          </>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
