import axios from "axios";
import { API_BASE } from "./constants";

interface ProjectFloorSectionPoint {
  x: string;
  y: string;
  id: number;
}

export interface ProjectFloorSection {
  id: number;
  sub_id: number;
  name: string;
  active_lidar_map: number | null;
  points: ProjectFloorSectionPoint[];
  use_project_config: boolean;
  project_lidar_map_configuration_id: number | null;
  run_cartographer_ros: boolean
  cartographer_ros_configuration_s3_key: string | null;
  cartographer_ros_configuration_url: string | null;
  run_slam_toolbox: boolean
  slam_toolbox_configuration_s3_key: string | null;
  slam_toolbox_configuration_url: string | null;
}

export const initialProjectFloorSection: ProjectFloorSection = {
  id: 0,
  sub_id: 0,
  name: '',
  active_lidar_map: null,
  points: [],
  use_project_config: false,
  project_lidar_map_configuration_id: null,
  run_cartographer_ros: true,
  cartographer_ros_configuration_s3_key: null,
  cartographer_ros_configuration_url: null,
  run_slam_toolbox: true,
  slam_toolbox_configuration_s3_key: null,
  slam_toolbox_configuration_url: null,
};

export const fetchProjectFloorSections = async (projectId: string, floor_code: string): Promise<ProjectFloorSection[]> => {
  const res = await axios.get(
    `${API_BASE}/project/${projectId}/floor/${floor_code}/sections`
  );
  const response = await res.data;
  return response.data;
}

export const fetchProjectFloorSection = async (projectId: string, floor_code: string, sectionSubId: string | number) => {
  const res = await axios.get(
    `${API_BASE}/project/${projectId}/floor/${floor_code}/section/${sectionSubId}`
  );
  const response = await res.data;
  return response.data;
}

export const createProjectFloorSection = async (projectId: string, floor_code: string, sectionData: ProjectFloorSection) => {
  const res = await axios.post(
    `${API_BASE}/project/${projectId}/floor/${floor_code}/section`,
    sectionData
  );
  const response = await res.data;
  return response.data;
}

export const updateProjectFloorSection = async (projectId: string, floor_code: string, sectionData: Partial<ProjectFloorSection>) => {
  const res = await axios.patch(
    `${API_BASE}/project/${projectId}/floor/${floor_code}/section/${sectionData.sub_id}`,
    sectionData,
  );
  const response = await res.data;
  return response.data;
}