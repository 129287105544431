import { Route, Switch } from "react-router-dom"
import { PATH_STRINGS } from "../../../../hooks/useGeneratedPaths"
import { ManageFloorPlan } from "./ManageFloorPlan"
import { ManageProjectFloor } from "./ManageProjectFloor"
import { ProjectFloorMenu } from "./ProjectFloorMenu"
import { ProjectFloorsDashboard } from "./ProjectFloorsDashboard"
import { ProjectFloorSectionsDashboard } from "./ProjectFloorSectionsDashboard"
import { useProjectContext } from "../../../../contexts/projectContext"
import { useProjectFloorQuery } from "../../../../hooks/projectQueries"
import { LoadingIndicator } from "../../../common/LoadingIndicator"
import { ManageProjectFloorSection } from "./ManageProjectFloorSection"
import { ProjectFloorLidarPointPicker } from "./LidarPointPicker/ProjectFloorLidarPointPicker"
import { ProjectFloorLidarMapsDashboard } from "./ProjectFloorLidarMapsDashboard/ProjectFloorLidarMapsDashboard"
import { ManageLidarMap } from "./LidarMapUpload/ManageLidarMap"
import { LidarMapEditing } from "../../lidar-management/LidarMapEditing/LidarMapEditing"
import { LidarMapReview } from "../../lidar-management/LidarMapReview"

export const ManageFloors = () => {
  const {
    updateFloor,
    state: projectState,
  } = useProjectContext();

  const floorIsNew = projectState.floorId === 'new';

  const { isLoading: floorLoading, isIdle: floorIdle } = useProjectFloorQuery(projectState.projectId, projectState.floorId, updateFloor);

  if (floorLoading) {
    return <LoadingIndicator/>
  }

  return (
    <>
      { (!!projectState.floorId && !floorIsNew) &&
        <Route
          path={[PATH_STRINGS.floor]}
        >
          <div 
            style={{
              position: 'absolute',
              marginLeft: '25px',
              zIndex: 11,
            }}
          >
            <ProjectFloorMenu/>
          </div>
        </Route>
      }

      <Switch>
        <Route path={PATH_STRINGS.projectFloors}>
          <ProjectFloorsDashboard/>
        </Route>
        { !floorIdle &&
          <>
            <Route exact path={PATH_STRINGS.floor}>
              <ManageProjectFloor/>
            </Route>
            <Route exact path={PATH_STRINGS.projectFloorPlan}>
              <ManageFloorPlan/>
            </Route>
            <Route path={PATH_STRINGS.projectFloorSections}>
              <ProjectFloorSectionsDashboard/>
            </Route>
            <Route path={PATH_STRINGS.section}>
              <ManageProjectFloorSection/>
            </Route>
            <Route path={PATH_STRINGS.projectFloorLidarMaps}>
              <ProjectFloorLidarMapsDashboard/>
            </Route>
            <Route exact path={PATH_STRINGS.projectFloorLidarMap}>
              <ManageLidarMap/>
            </Route>
            <Route exact path={PATH_STRINGS.lidarTransformation}>
              <ProjectFloorLidarPointPicker/>
            </Route>
            <Route exact path={PATH_STRINGS.projectFloorLidarMapEditing}>
              <LidarMapEditing/>
            </Route>
            <Route exact path={PATH_STRINGS.lidarReview}>
              <LidarMapReview/>
            </Route>
          </>
        }
      </Switch>
    </>
  )
}