import { useState } from "react"
import { createProjectFloor, updateProjectFloor } from "../../../../api/projectFloors"
import { useNotifications } from "../../../../contexts/notificationProvider"
import { useProjectContext } from "../../../../contexts/projectContext"
import { useProjectNavigation } from "../../../../hooks/useNavigation"
import { FormContainer, FormHeader, SaveButtonContainer, StyledInput, StyledLabel } from "../../../common/FormControls"
import { Button } from "../../../common/Inputs/Button"
import { LoadingIndicator } from "../../../common/LoadingIndicator"

export const ManageProjectFloor = () => {
  const {
    state: projectState,
    updateFloor,
  } = useProjectContext();

  const {
    floorId,
    floor,
    project,
  } = projectState;

  const floorIsNew = floorId === 'new';

  const {
    navigateToFloor,
  } = useProjectNavigation();

  const { addNotification } = useNotifications();

  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

  const validForSubmit: boolean = !!floor.name && !!floor.name.trim() && !!floor.floor_code && !!floor.floor_code.trim();
  const saveButtonText: string = floorIsNew ? 'Create' : 'Save';

  const handleError = (err: any, notificationMessage: string) => {
    let notificationSent = false;

    if (err.response?.data?.error?.details) {
      const errorDetails = err.response.data.error.details;
      errorDetails.forEach((message: any) => {
        const errorMessage = message.message;

        if (errorMessage.includes("project_floor.uq_project_project_floor_order")) {
          addNotification('Floor order already exists', 'error');
          notificationSent = true;
        } else if (errorMessage.includes("project_floor.uq_project_floor_code")) {
          addNotification('Floor code already exists', 'error');
          notificationSent = true;
        }
      });
    }

    if (!notificationSent) {
      addNotification(notificationMessage, 'error');
    }
  }

  const onCreateNewFloor = async () => {
    try {
      const createdFloor = await createProjectFloor(project.public_id, floor);
      navigateToFloor(createdFloor.floor_code);
      addNotification('Floor created successfully', 'success');
    } catch (err) {
      handleError(err, 'Error creating new floor');
    }
  }

  const onUpdateExistingFloor = async () => {
    try {
      const updatedFloor = await updateProjectFloor(project.public_id, floor);
      navigateToFloor(updatedFloor.floor_code);
      addNotification('Floor updated successfully', 'success');
    } catch (err) {
      handleError(err, 'Error updating floor');
    }
  }

  const onClickSave = async () => {
    if (validForSubmit) {
      setSaveInProgress(true);

      if (floorIsNew) {
        await onCreateNewFloor();
      } else {
        await onUpdateExistingFloor();
      }

      setSaveInProgress(false);
    }
  }

  return (
    <FormContainer>
      <FormHeader>
        {floorIsNew ? 'New Floor' : 'Edit Floor'}
      </FormHeader>

      <StyledLabel htmlFor='name'>Name:</StyledLabel>
      <StyledInput
        id='name'
        value={floor.name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFloor( { name: e.target.value })}
      />

      <StyledLabel htmlFor='floor_code'>Floor Code:</StyledLabel>
      <StyledInput
        id='floor_code'
        value={floor.floor_code}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFloor( { floor_code: e.target.value })}
      />

      <StyledLabel htmlFor='floor_code'>Order:</StyledLabel>
      <StyledInput
        id='floor_code'
        type="number"
        value={floor.order}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFloor( { order: e.target.value })}
      />

      <SaveButtonContainer>
        {!saveInProgress &&
          <Button
            primary={validForSubmit}
            disabled={!validForSubmit}
            text={saveButtonText}
            onClick={onClickSave}
            style={{ display: 'inline-block' }}
          />
        }
        {saveInProgress &&
          <LoadingIndicator/>
        }
      </SaveButtonContainer>
    </FormContainer>
  )
}