import { useMemo } from "react";
import { useProgressRegionsContext } from "../../../../../../contexts/progressRegionsContext";
import { ProgressRegionsEditorMapViewSVGRegion } from "./ProgressRegionsEditorMapViewRegion";
import { ProgressRegionsEditorMapViewSVGGhostPoints } from "./ProgressRegionsEditorMapViewSVGGhostPoints";
import { ProgressRegionsEditorMapViewSVGBoundaryPoints } from "./ProgressRegionsEditorMapViewSVGBoundaryPoints";

export const ProgressRegionsEditorMapViewSVGRegions = () => {
  const {
    regionsWithEdits,
    regionsLoading,
    touched,
  } = useProgressRegionsContext();

  const sortedRegionsWithEdits = useMemo(() => {
    if (regionsLoading) {
      return [];
    }

    return regionsWithEdits
    .sort((a,b) => {
      const lastEditedA = touched[a.id] ?? -Infinity;
      const lastEditedB = touched[b.id] ?? -Infinity;

      return lastEditedA - lastEditedB;
    });
  }, [regionsLoading, regionsWithEdits, touched]);

  if (regionsLoading) {
    return <></>
  }

  return (
    <>
      {sortedRegionsWithEdits.map(region => {
        return (
          <>
            <ProgressRegionsEditorMapViewSVGRegion
              key={`region-${region.id}`}
              region={region}
            />
            <ProgressRegionsEditorMapViewSVGGhostPoints
              key={`ghost-points-${region.id}`}
              region={region}
            />
            <ProgressRegionsEditorMapViewSVGBoundaryPoints
              key={`boundary-points-${region.id}`}
              region={region}
            />
          </>
        )
      })}
    </>
  );
}