import { Autocomplete, Checkbox, FormControlLabel, TableCell, TextField, Typography } from "@mui/material";
import { HeadCell, TableOrder } from "../../../common/Table";
import { useCallback, useMemo, useState } from "react";
import { TableRow } from "../../../common/TableRow";
import { TrackerType, TrackerTypeCategory, createTrackerType, updateTrackerType } from "../../../../api/trackers";
import { CreateEditTable, TrackerTableEditingMode } from "../CreateEditTable";

interface ITrackerTypesTableProps {
  trackerTypes: TrackerType[];
  trackerTypesLoading: boolean;
}

export const TrackerTypesTable = ({
  trackerTypes,
  trackerTypesLoading,
}: ITrackerTypesTableProps) => {
  const [editingMode, setEditingMode] = useState<TrackerTableEditingMode>(TrackerTableEditingMode.none);
  const [edits, setEdits] = useState<Record<number, Partial<TrackerType>>>({});

  const inCreateMode = editingMode === TrackerTableEditingMode.create;
  const inUpdateMode = editingMode === TrackerTableEditingMode.update;
  const readOnly = editingMode === TrackerTableEditingMode.none;

  const initialNewTrackerType: TrackerType = useMemo(() => {
    let displayOrder = 0;

    if (trackerTypes) {
      displayOrder = Math.max(...trackerTypes.map(type => type.display_order)) + 1;
    }

    return { id: -1, name: '', is_sequential: false, display_order: displayOrder, category: 'pt' };
  }, [trackerTypes]);

  const columns: readonly HeadCell<any>[] = useMemo(() => [
    { id: 'id', label: 'ID' },
    { id: 'name', label: 'Name' },
    { id: 'is_sequential', label: 'Sequential?' },
    { id: 'display_order', label: 'Display Order' },
    { id: 'category', label: 'Category' },
  ], []);

  const renderVisibleRows = useCallback((row: TrackerType, index: number) => {
    const isNewItem = row.id === -1;
    const fieldCanBeEdited = (isNewItem && inCreateMode) || (!isNewItem && inUpdateMode);
    const itemToDisplay = {...row, ...edits[row.id]};
    const {
      id,
      name,
      is_sequential,
      display_order,
      category,
    } = itemToDisplay;

    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={index}
      >
        <TableCell align="left">
          {(!readOnly && isNewItem) &&
            <Typography fontWeight={700}>New</Typography>
          }
          {(readOnly || !isNewItem) &&
            <Typography>{id}</Typography>
          }
        </TableCell>

        <TableCell>
          {fieldCanBeEdited &&
            <TextField
              value={name}
              onChange={(e) => {setEdits(prevEdits => ({...prevEdits, [row.id]: {name: e.target.value}}))}}
              sx={{
                width: '100%'
              }}
            />
          }
          {!fieldCanBeEdited &&
            <>{name}</>
          }
        </TableCell>
        
        <TableCell align="left">
          {fieldCanBeEdited &&
            <FormControlLabel
              control={(
                <Checkbox
                  checked={is_sequential}
                  onChange={(e, checked) => {setEdits(prevEdits => ({...prevEdits, [row.id]: {is_sequential: checked}}))}}
                />
              )}
              label={is_sequential ? 'Yes' : 'No'}
            />
          }
          {!fieldCanBeEdited &&
            <>{is_sequential ? 'Yes' : 'No'}</>
          }
        </TableCell>
        <TableCell align="left">
          {fieldCanBeEdited &&
            <TextField
              type="number"
              value={display_order}
              onChange={e => {setEdits(prevEdits => ({...prevEdits, [row.id]: {display_order: parseInt(e.target.value)}}))}}
              sx={{
                width: '100%'
              }}
            />
          }
          {!fieldCanBeEdited &&
            <>{display_order}</>
          }
        </TableCell>
        <TableCell align="left">
          {fieldCanBeEdited &&
            <Autocomplete
              disableClearable
              options={['pt', 'safety']}
              value={category}
              onChange={(e, value) => {setEdits(prevEdits => ({...prevEdits, [row.id]: {category: value as TrackerTypeCategory}}))}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    width: '100%'
                  }}
                />
              )}
            />
          }
          {!fieldCanBeEdited &&
            <>{category}</>
          }
        </TableCell>
      </TableRow>
    );
  }, [edits, inCreateMode, inUpdateMode, readOnly]);

  const compareVersions = useCallback((order: TableOrder, a: TrackerType, b: TrackerType) => {
    if (a.id === -1) {
      return -1;
    } else if (b.id === -1) {
      return 1;
    } else {
      return order === 'asc' ? a.display_order - b.display_order : b.display_order - a.display_order;
    }
  }, []);

  const trackerTypesComparator = useCallback((order: TableOrder, orderBy: string | number | symbol) => {
    if (orderBy === 'display_order') {
      return (a: TrackerType, b: TrackerType) => compareVersions(order, a, b);
    }

    return undefined;
  }, [compareVersions]);

  const clearEdits = useCallback(() => {
    setEdits({});
  }, []);

  const onCreateItem = useCallback(async (createdItem: TrackerType) => {
    return await createTrackerType(createdItem);
  }, []);

  const onEditItem = useCallback(async (id: number, editedItem: Partial<TrackerType>) => {
    return await updateTrackerType(id, editedItem);
  }, []);

  const isValidForSubmit = useCallback((tableData: TrackerType[]) => {
    const fieldsValid = tableData.every(row => {
      return (
        !!row.name
      )
    });

    const displayOrderSet = new Set(tableData.filter(row => row.display_order >= 0).map(row => row.display_order));
    const nameSet = new Set(tableData.map(row => row.name));

    const displayOrdersUnique = displayOrderSet.size === tableData.length;
    const namesUnique = nameSet.size === tableData.length;

    return fieldsValid && displayOrdersUnique && namesUnique;
  }, []);

  return (
    <CreateEditTable
      columns={columns}
      rows={trackerTypes ?? []}
      initialOrderedColumnName='display_order'
      renderVisibleRows={renderVisibleRows}
      loading={trackerTypesLoading}
      onCreateItem={onCreateItem}
      onEditItem={onEditItem}
      initialNewItem={{...initialNewTrackerType}}
      isValidForSubmit={isValidForSubmit}
      editingMode={editingMode}
      setEditingMode={setEditingMode}
      edits={edits}
      clearEdits={clearEdits}
      customComparator={trackerTypesComparator}
      readModeCreateButtonText="Create Tracker Type"
      createModeCreateButtonText="Save Tracker Type"
      readModeEditButtonText="Edit Tracker Types"
      editModeEditButtonText="Save Tracker Types"
    />
  );
}