import { Button } from "../../../common/Inputs/Button"
import { Table } from "../../../common/Table/Table"
import styled from "styled-components";
import { useMemo } from "react";
import { ProjectFloor } from "../../../../api/projectFloors";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { useProjectNavigation } from "../../../../hooks/useNavigation";
import { useProjectContext } from "../../../../contexts/projectContext";
import { useProjectFloorsQuery } from "../../../../hooks/projectQueries";

export const ProjectFloorsDashboard = () => {
  const {
    updateProject,
    state: projectState
  } = useProjectContext();

  const {
    project,
  } = projectState;

  const floors = project.floors;

  const {
    navigateToFloor,
  } = useProjectNavigation();

  const onUpdateFloors = (updatedFloors: ProjectFloor[]) => {
    updateProject({ floors: updatedFloors });
  }

  const { isLoading: floorsLoading } = useProjectFloorsQuery(projectState.projectId, onUpdateFloors);

  const columns = useMemo(() => [
    { Header: 'Name', accessor: 'name' },
    { Header: 'Floor Code', accessor: 'floor_code' },
    { Header: 'Order', accessor: 'order' },
  ], []);

  const data = useMemo(() => floors, [floors]);

  if (!floors || floorsLoading) {
    return <LoadingIndicator/>
  }

  const onClickAddNewFloor = () => {
    navigateToFloor('new');
  }

  const onClickRow = (originalRow: ProjectFloor) => {
    navigateToFloor(originalRow.floor_code)
  }

  return (
    <ProjectFloorDashboardContainer>
      <ProjectName>{project.name}</ProjectName> 

      <AddNewFloorButtonContainer>
        <Button
          primary
          text='Add New Floor'
          onClick={onClickAddNewFloor}
        />
      </AddNewFloorButtonContainer>

      <Table
        columns={columns}
        data={data}
        onClickRow={onClickRow}
      />
    </ProjectFloorDashboardContainer>
  )
}

const ProjectFloorDashboardContainer = styled.div`
  margin-top: 1em;
`;

const AddNewFloorButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 20px 20px 0;
`;

const ProjectName = styled.h1`
  text-align: center;
  font-size: 30px;
  font-weight: 700
`