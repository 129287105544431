import { useCallback } from 'react';
import { TableRow as MUITableRow, TableRowProps as MUITableRowProps } from "@mui/material"
import { useHistory } from 'react-router-dom';

interface TableRowProps extends MUITableRowProps {
  redirectTo?: string;
}

export const TableRow = ({
  redirectTo,
  onClick,
  children,
  ...rest
}: TableRowProps) => {
  const history = useHistory();

  const tableRowOnClick = useCallback((e: React.MouseEvent<HTMLTableRowElement>) => {
    if (redirectTo) {
      if (e.ctrlKey || e.metaKey) {
        window.open(redirectTo, "_blank");
      } else {
        history.push(redirectTo)
      }
    } else if (onClick) {
      onClick(e);
    }
  }, [history, onClick, redirectTo]);


  return (
    <MUITableRow
      onClick={tableRowOnClick}
      {...rest}
    >
      {children}
    </MUITableRow>
  );
}