import React, { useEffect, useState } from 'react';
import {useLocation} from 'react-router-dom';
import styled from "styled-components";

import {Annotoirous} from "../../third_party/AnnotoriousOS/Annotorious";
import {ObservationSidebar} from "./ObservationSidebar/ObservationSidebar";
import {retrieveObservation} from "../../../api/observations";
import { useEditorContext } from '../../../contexts/editorContext';
import { LabelingSidebar } from './LabelingSidebar/LabelingSidebar';


export const Observation = () => {
    const location = useLocation()
    const {updateEditor} = useEditorContext()
    const [observation, setObservation] = useState()
    const [projectId, setProjectId] = useState()

    useEffect( () => {
        let q_check = new URLSearchParams(location.search)
        if(!q_check.get("observation_id"))
            return // redirect to /hoop/safety-observations
        else {
          //@ts-ignore
          retrieveObservation(q_check.get("project"), parseInt(q_check.get("observation_id")))
            .then((data: any) => {
              let observation = data.data
              setObservation(observation)
              //@ts-ignore
              setProjectId(q_check.get("project"))
              updateEditor({ activeImage: observation.image.processed_image })
            })
            .catch((e: Error) => console.log(e))
        }
    }, [])

    return(
        <ObservationContainer className="editor">
            <ObservationSidebar observation={observation} projectId={projectId}/>
            <Annotoirous />
            <LabelingSidebar />
        </ObservationContainer>
    )
}

const ObservationContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flexDirection: row;
    background: #000;
`
