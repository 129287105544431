import axios from "axios";
import { Project } from "./projects";

export interface Bucket {
  bucketKey: string;
  createdDate: number;
  policyKey: 'transient' | 'temporary' | 'persistent'
}

export const getBuckets = async (): Promise<{items: Bucket[]}> => {
  const res = await axios.get(
    `https://api.nexterarobotics.com/api/forge/get_buckets`
  );

  const response = await res.data;
  return response.data;
}

export const getRefreshToken = async (refreshToken: string): Promise<any> => {
  const res = await axios.post(
    `https://api.nexterarobotics.com/api/forge/get_refresh_token`,
    {
      refresh_token: refreshToken,
    },
  )
  const response = await res.data;
  return response.data;
}

interface ForgePresignedPost {
  uploadKey: string;
  uploadExpiration: string;
  urlExpiration: string;
  urls: string[];
}

export const getForgePresignedPost = async (objectKey: string): Promise<ForgePresignedPost> => {
  const res = await axios.post(
    `https://api.nexterarobotics.com/api/forge/get_presigned_post`,
    {
      object_key: objectKey,
    }
  );
  const response = await res.data;
  return response.data;
}

export interface UploadProgressEvent {
  loaded: number;
  total: number;
}

export const uploadModel = async (url: string, file: File, onUploadProgress: (e: UploadProgressEvent) => void) => {
  const authHeader = axios.defaults.headers.common['Authorization'];
  delete axios.defaults.headers.common['Authorization'];

  try {
    await axios.put(
      url,
      file,
      {
        onUploadProgress: onUploadProgress
      }
    );
  } finally {
    axios.defaults.headers.common['Authorization'] = authHeader;
  }
}

interface CompleteUpload {
  bucketKey: string;
  contentType: string;
  location: string;
  objectId: string;
  objectKey: string;
  size: number;
  base64_encoded_urn: string;
}

export const completeUpload = async (objectKey: string, uploadKey: string): Promise<CompleteUpload> => {
  const res = await axios.post(
    `https://api.nexterarobotics.com/api/forge/complete_upload`,
    {
      upload_key: uploadKey,
      object_key: objectKey,
    }
  );
  const response = await res.data;
  return response.data;
}

interface StartTranslationJobsResponse {
  result: string;
  urn: string;
  registerKeys: string[];
  acceptedJobs: {
    output: {
      destination: {
          region: string;
      },
      formats: [
        {
          type: string;
          views: string[];
        }
      ]
    }
  }
}

export const startTranslationJob = async (urn: string): Promise<StartTranslationJobsResponse> => {
  const res = await axios.post(
    `https://api.nexterarobotics.com/api/forge/start_translation_job`,
    {
      urn: urn,
    }
  );
  const response = await res.data;
  return response.data;
}

export const updateProjectForgeModelUrn = async (projectId: string, urn: string): Promise<Project> => {
  const res = await axios.patch(
    `https://api.nexterarobotics.com/api/project/${projectId}`,
    {
      forge_model_urn: urn
    }
  );
  const response = await res.data;
  return response.data;
}

export interface IManifest {
  derivatives: any[];
  hasThumbnail: boolean;
  progress: '0% complete' | '99% complete' | 'complete';
  region: string;
  status: 'pending' | 'success' | 'inprogress' | 'failed' | 'timeout';
  type: string;
  urn: string;
  version: string;
}

export const getManifest = async (urn: string): Promise<IManifest> => {
  const res = await axios.post(
    `https://api.nexterarobotics.com/api/forge/get_manifest`,
    {
      urn: urn
    }
  );
  const response = await res.data;
  return response.data;
}

export interface IObjectDetails {
  bucketKey: string;
  objectId: string;
  objectKey: string;
  sha1: string;
  size: number;
  contentType: string;
  location: string;
}

export const getObjectDetails = async (urn: string): Promise<IObjectDetails> => {
  const res = await axios.post(
    `https://api.nexterarobotics.com/api/forge/get_object_details`,
    {
      urn: urn
    }
  );
  const response = await res.data;
  return response.data;
}

export const deleteObject = async (urn: string): Promise<any> => {
  const res = await axios.post(
    `https://api.nexterarobotics.com/api/forge/delete_object`,
    {
      urn: urn
    }
  );
  const response = await res.data;
  return response.data;
}

export interface IPresignedDownload {
  status: string;
  url: string;
  params: {
    "content-type": string;
    "content-disposition": string;
  };
  size: number;
  sha1: string;
}

export const getPresignedDownload = async (urn: string): Promise<IPresignedDownload> => {
  const res = await axios.post(
    `https://api.nexterarobotics.com/api/forge/get_presigned_download`,
    {
      urn: urn
    }
  );
  const response = await res.data;
  return response.data;
}