import { useMemo } from "react";
import styled from "styled-components";
import { Project } from "../../../../api/projects";
import { useProjectsQuery } from "../../../../hooks/projectQueries";
import { useProjectNavigation } from "../../../../hooks/useNavigation";
import { Button } from "../../../common/Inputs/Button";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { Table } from "../../../common/Table/Table";

export const ProjectSetupDashboard = () => {
  const {
    navigateToProject,
  } = useProjectNavigation();

  const { data: projects, isLoading: projectsLoading } = useProjectsQuery();

  const columns = useMemo(() => [
    { Header: 'Project Name', accessor: 'name' },
    { Header: 'Company Name', accessor: 'company.name' },
    { Header: 'Location', accessor: (originalRow: Project) => `${originalRow.location.city}, ${originalRow.location.state_code}, ${originalRow.location.country_code}`}
  ], []);

  const data = useMemo(() => projects, [projects]);

  const onClickRow = (originalRow: Project) => {
    navigateToProject(originalRow.public_id);
  }

  if (projectsLoading) {
    return <LoadingIndicator/>
  }

  const navigateToNewProjectPage = () => {
    navigateToProject('new');
  }

  return (
    <ProjectSetupDashboardContainer>
      <AddNewProjectButtonContainer>
        <Button
          primary
          text='Add New Project'
          onClick={navigateToNewProjectPage}
        />
      </AddNewProjectButtonContainer>

      <Table
        columns={columns}
        data={data}
        onClickRow={onClickRow}
      />
    </ProjectSetupDashboardContainer>
  )
}

const ProjectSetupDashboardContainer = styled.div`
  margin-top: 1em;
`;

const AddNewProjectButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 20px 20px 0;
`;