import { useCallback, useMemo } from "react";
import { EditableProgressRegion, useProgressRegionsContext } from "../../../../../../contexts/progressRegionsContext";

interface IProgressRegionsEditorMapViewSVGGhostPointsProps {
  region: EditableProgressRegion;
}

export const ProgressRegionsEditorMapViewSVGGhostPoints = ({
  region,
}: IProgressRegionsEditorMapViewSVGGhostPointsProps) => {
  const {
    dispatch: dispatchProgressRegions,
    onUpdateRegion,
    onUpdateTouched,
  } = useProgressRegionsContext();

  const onMouseDownGhostPoint = useCallback((e: React.MouseEvent<SVGGElement, MouseEvent>, index: number, x: number, y: number) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    const regionToUpdate = {...region};
    const newCoordinates = [...region.position];
    newCoordinates.splice(index, 0, {id: -index-1, x: x, y: y, order: index - 1 });
    newCoordinates.forEach((point, i) => point.order = i-1);
    regionToUpdate.position = newCoordinates;

    onUpdateRegion(regionToUpdate);
    onUpdateTouched(regionToUpdate.id)

    dispatchProgressRegions({
      type: 'UPDATE_STATE',
      payload: {
        currentlyEditingRegionId: region.id,
        currentlyEditingCoodinateIndex: index,
      },
    });
  }, [dispatchProgressRegions, onUpdateRegion, onUpdateTouched, region]);

  const expandedCoordinates = useMemo(() => {
    let coordinates = region.position.map(point => [point.x, point.y]);

    if (region.position.length > 0) {
      coordinates.push([region.position[0].x, region.position[0].y]);
    }
    return coordinates;
  }, [region.position]);

  if (expandedCoordinates.length === 0) {
    return <></>
  }

  return (
    <>
      {expandedCoordinates.map((point, index, arr) => {
        if (index === 0) {
          return <></>
        }

        const currentPoint = arr[index];
        const previousPoint = arr[index - 1];

        const x = (currentPoint[0] + previousPoint[0]) / 2;
        const y = (currentPoint[1] + previousPoint[1]) / 2;

        return (
          <g
            key={`${region.id}-${index}-ghost-point-g`}
            onMouseDown={e => onMouseDownGhostPoint(e, index, x, y)}
            cursor="cell"
          >
            <circle
              cx={x}
              cy={y}
              r={5}
              fill="black"
              stroke="black"
            />
            <text
              x={x}
              y={y}
              textAnchor="middle"
              stroke="white"
              strokeWidth={0.25}
              fontSize="6px"
              fill="white"
              dy="1.5px"
            >
              +
            </text>
          </g>
        );
      })};
    </>
  );
}