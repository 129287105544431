import { Box } from "@mui/material";
import { ProgressItemsEditorHeader } from "./ProgressItemsEditorHeader/ProgressItemsEditorHeader";
import { ProgressItemsEditorMapView } from "./ProgressItemsEditorMapView/ProgressItemsEditorMapView";

export const ProgressItemsEditor = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        height: '100%'
      }}
    >
      <ProgressItemsEditorHeader/>
      <ProgressItemsEditorMapView/>
    </Box>
  );
}

