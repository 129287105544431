import { useCallback, useMemo } from "react";
import { EditableProgressRegion, useProgressRegionsContext } from "../../../../../../contexts/progressRegionsContext";

interface IProgressRegionsEditorMapViewSVGBoundaryPointsProps {
  region: EditableProgressRegion;
}

export const ProgressRegionsEditorMapViewSVGBoundaryPoints = ({
  region,
}: IProgressRegionsEditorMapViewSVGBoundaryPointsProps) => {
  const {
    state: progressRegionsState,
    dispatch: dispatchProgressRegions,
    onUpdateRegion,
    onUpdateTouched,
  } = useProgressRegionsContext();

  const {
    currentlyEditingCoodinateIndex,
  } = progressRegionsState;

  const onMouseDownBoundaryPoint = useCallback((e: React.MouseEvent<SVGCircleElement, MouseEvent>, regionId: number, index: number) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    dispatchProgressRegions({
      type: 'UPDATE_STATE',
      payload: {
        currentlyCreatingRegionId: null,
        currentlyEditingRegionId: regionId,
        currentlyEditingCoodinateIndex: index,
        dragStartX: e.nativeEvent.offsetX,
        dragStartY: e.nativeEvent.offsetY
      },
    });

    onUpdateTouched(regionId);
  }, [dispatchProgressRegions, onUpdateTouched]);

  const onMouseUpBoundaryPoint = useCallback(() => {
    dispatchProgressRegions({
      type: 'UPDATE_STATE',
      payload: {
        currentlyEditingRegionId: null,
        currentlyEditingCoodinateIndex: null,
        currentlyCreatingRegionId: null,
        dragStartX: null,
        dragStartY: null,
      },
    });
  }, [dispatchProgressRegions]);

  const onContextMenuBoundaryPoint = useCallback((e: React.MouseEvent, region: EditableProgressRegion, index: number) => {
    e.preventDefault();
    
    const regionToUpdate = {...region};
    let newCoordinates = [...region.position];
    
    if (newCoordinates.length > 2) {
      newCoordinates.splice(index, 1);
    } else {
      newCoordinates = [];
    }

    newCoordinates.forEach((point, i) => point.order = i-1);

    regionToUpdate.position = newCoordinates;

    onUpdateRegion(regionToUpdate);
    onUpdateTouched(regionToUpdate.id);
  }, [onUpdateRegion, onUpdateTouched]);

  const coordinates = useMemo(() => {
    return region.position.map(point => [point.x, point.y]);
  }, [region.position]);

  if (coordinates.length === 0) {
    return (
      <></>
    )
  }

  return (
    <>
      {coordinates.map((point, index) => (
        <circle
          key={`${region.id}-${index}-boundary-point-circle`}
          cursor={!!currentlyEditingCoodinateIndex ? 'grabbing' : 'grab'}
          cx={point[0]}
          cy={point[1]}
          r={5}
          fill="white"
          stroke="black"
          onMouseDown={e => onMouseDownBoundaryPoint(e, region.id, index)}
          onMouseUp={onMouseUpBoundaryPoint}
          onContextMenu={e => onContextMenuBoundaryPoint(e, region, index)}
        />
      ))};
    </>
  );
}