import { Box, Button, IconButton, Modal, Typography, styled } from "@mui/material";
import { ItemType } from "../../../../../../api/items";
import { useProgressContext } from "../../../../../../contexts/progressContext";
import { ProjectTrackersAutoComplete } from "./ProjectTrackersAutoComplete";
import { ItemTypesAutocomplete } from "./ItemTypesAutocomplete";
import { Close, CloudUpload, Map as MapIcon } from "@mui/icons-material";
import { useCallback, useState } from "react";
import { createSTLFloorPlan, fetchSTLFloorPlanPresignedPost } from "../../../../../../api/stlFloorPlans";
import { uploadToPresignedPost } from "../../../../../../api/s3ImageUpload";
import { useNotifications } from "../../../../../../contexts/notificationProvider";
import { useProgressItemsContext } from "../../../../../../contexts/progressItemsContext";

interface ISTLFloorPlanUploadModalProps {
  open: boolean;
  handleClose: () => void;
  onChangeSelectedTracker: (newValue: string | null | undefined) => void;
  onChangeSelectedItemType: (newValue: ItemType | null) => void;
}

export const STLFloorPlanUploadModal = ({
  open,
  handleClose,
  onChangeSelectedTracker,
  onChangeSelectedItemType,
}: ISTLFloorPlanUploadModalProps) => {
  const {
    addNotification,
  } = useNotifications();

  const {
    state: progressState,
  } = useProgressContext();

  const {
    projectId,
    floorId,
    trackerName,
    selectedItemType,
  } = progressState;

  const {
    stlFloorPlans,
    setSTLFloorPlans,
  } = useProgressItemsContext();

  const [stlFloorPlan, setSTLFloorPlan] = useState<File | null>(null);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSTLFloorPlan(e.target.files[0]);
    }
  }

  const onCloseModal = () => {
    setSTLFloorPlan(null);
    handleClose();
  }

  const onUploadSTLFloorPlan = useCallback(async () => {
    if (projectId && floorId && trackerName && stlFloorPlan) {
      try {
        const presignedPost = await fetchSTLFloorPlanPresignedPost(projectId, floorId);
        const s3Key = await uploadToPresignedPost(stlFloorPlan, presignedPost);
        const createdFloorPlanEntry = await createSTLFloorPlan(projectId, floorId, {
          tracker_type: trackerName,
          item_type: selectedItemType?.name,
          stl_floor_plan_s3_key: s3Key,
        });
        
        const prevSTLFloorPlan = stlFloorPlans.find(plan => plan.id === createdFloorPlanEntry.id);

        if (prevSTLFloorPlan) {
          setSTLFloorPlans((prev) => prev.map(plan => plan.id === createdFloorPlanEntry.id ? createdFloorPlanEntry : plan));
        } else {
          setSTLFloorPlans((prev) => [...prev, createdFloorPlanEntry]);
        }
      } catch {
        addNotification("Error uploading STL floor plan", "error");
      }
    }
  }, [addNotification, floorId, projectId, selectedItemType?.name, setSTLFloorPlans, stlFloorPlan, stlFloorPlans, trackerName]);

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'center'
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Upload STL Floor Plan
        </Typography>
        <Box
          display="flex"
          gap="10px"
        >
          <ProjectTrackersAutoComplete
            projectId={projectId}
            selectedTrackerName={trackerName}
            setSelectedTrackerName={onChangeSelectedTracker}
          />
          <ItemTypesAutocomplete
            disabled={!trackerName}
            selectedItemType={selectedItemType}
            setSelectedItemType={onChangeSelectedItemType}
            selectedTrackerName={trackerName ?? ''}
          />
        </Box>
        <Box
          display="flex"
          gap="10px"
        >
          <InputFileUpload
            disabled={!trackerName}
            onSelectFile={onSelectFile}
          />
          <Button
            onClick={onUploadSTLFloorPlan}
            disabled={!stlFloorPlan || !trackerName}
            variant="contained"
            startIcon={<CloudUpload />}
          >
            Submit
          </Button>
        </Box>
        {!!stlFloorPlan &&
          <Box
            display="flex"
            alignItems="center"
          >
            <Typography
              id="modal-modal-description"
              variant="body1"
              component="p"
            >
              {stlFloorPlan.name}
            </Typography>
            <IconButton
              size="small"
              onClick={() => setSTLFloorPlan(null)}
            >
              <Close />
            </IconButton>
          </Box>
        }
      </Box>
    </Modal>
  )
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface IInputFileUploadProps {
  disabled?: boolean;
  onSelectFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputFileUpload = ({
  disabled=false,
  onSelectFile,
}: IInputFileUploadProps) => {
  return (
    <Button
      disabled={disabled}
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<MapIcon />}
    >
      Select file
      <VisuallyHiddenInput
        type="file"
        onChange={onSelectFile}
      />
    </Button>
  );
}