import { ManageLidarMap } from '../../../api/projectFloorLidarMaps';
import { useProjectNavigation } from '../../../hooks/useNavigation';
import { LidarMapTable } from './LidarMapTable';

export const MapEditingDashboard = () => {
  const {
    navigateToLidarEditing,
  } = useProjectNavigation();
  
  const onClickRow = (row: ManageLidarMap) => {
    navigateToLidarEditing(row.project_public_id, row.floor_code, row.sub_id);
  }
  
  return (
    <LidarMapTable
      title="Maps To Edit"
      checkedIsLatest
      checkedIsUsable
      onClickRow={onClickRow}
      noRowsMessage="No Maps To Edit"
      checkboxLabel="Usable Maps Only"
      initialSortBy={[{id: 'id', desc: true}]}
    />
  )
}