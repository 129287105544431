import { Box, Tab, Tabs } from "@mui/material";
import { PermissionRoute } from "../../common/PermissionRoute/PermissionRoute";
import { Switch, matchPath, useHistory, useLocation } from "react-router-dom";
import { PATH_STRINGS, useGeneratedPaths } from "../../../hooks/useGeneratedPaths";
import { useCallback, useMemo } from "react";
import { ProgressTrackersPage } from "./ProgressTrackers/ProgressTrackersPage";
import { ProgressJobsPage } from "./ProgressJobs/ProgressJobsPage";
import { ProgressItemsPage } from "./ProgressItems/ProgressItemsPage";
import { ProgressRegionsPage } from "./ProgressRegions/ProgressRegionsPage";
import { ProgressItemsProvider } from "../../../contexts/progressItemsContext";
import { useProgressContext } from "../../../contexts/progressContext";
import { ProgressRegionsProvider } from "../../../contexts/progressRegionsContext";

type ProgressTabType = 'trackers' | 'jobs' | 'items' | 'regions';

interface ProgressTabData {
  type: ProgressTabType;
  path: string;
  additionalMatches?: string[];
}

export const ProgressPage = () => {
  const history = useHistory();
  const location = useLocation();

  const {
    generateProgressTrackingItemsPath,
    generateProgressTrackingRegionsPath
  } = useGeneratedPaths();

  const {
    state: progressState
  } = useProgressContext();

  const {
    projectId,
    floorId,
  } = progressState;

  const tabs: ProgressTabData[] = useMemo(() => {
    return [
      { type: 'trackers', path: PATH_STRINGS.progressTrackers, additionalMatches: [PATH_STRINGS.progress]},
      // { type: 'jobs', path: PATH_STRINGS.progressJobs},
      { type: 'items', path: generateProgressTrackingItemsPath(projectId, floorId), additionalMatches: [PATH_STRINGS.progressItems]},
      { type: 'regions', path: generateProgressTrackingRegionsPath(projectId, floorId), additionalMatches: [PATH_STRINGS.progressRegions]},
    ];
  }, [floorId, generateProgressTrackingItemsPath, generateProgressTrackingRegionsPath, projectId]);

  const selectedTab = useMemo(() => {
    const currentLocation = location.pathname;
    
    return tabs.findIndex(tab => {
      const pathMatch = !!matchPath(currentLocation, { path: tab.path, exact: true });
      const additionalMatch = tab.additionalMatches?.some(match => !!matchPath(currentLocation, { path: match, exact: true }));

      return pathMatch || additionalMatch;
    });
  }, [location.pathname, tabs]);

  const onChangeProgressTab = useCallback((e: React.ChangeEvent<{}>, newValue: number) => {
    history.push(tabs[newValue].path);
  }, [history, tabs]);

  return (
    <Box
      sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}
    >
      <Box
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tabs
          value={selectedTab}
          onChange={onChangeProgressTab}
          aria-label="basic tabs example"
        >
          {tabs.map(tab => {
            return (
              <Tab
                key={tab.type}
                label={tab.type}
                style={{width: `${Math.round(1 / tabs.length * 100)}%`, maxWidth: 'none'}}
              />
            )
          })}
        </Tabs>
      </Box>
      <Box
        flex={1}
        padding='5px 20px 20px 20px'
      >
        <Switch>
          <PermissionRoute
            permission="progress_tables.read_project_tracker"
            path={PATH_STRINGS.progressJobs}
            component={ProgressJobsPage}
            exact
          />
          <PermissionRoute
            permission="progress_tables.read_project_tracker"
            path={PATH_STRINGS.progressItems}
            component={WrappedProgressItemsPage}
            exact
          />
          <PermissionRoute
            permission="progress_tables.read_project_tracker"
            path={PATH_STRINGS.progressRegions}
            component={WrappedProgressRegionsPage}
            exact
          />
          <PermissionRoute
            permission="progress_tables.read_project_tracker"
            path={PATH_STRINGS.progress}
            component={ProgressTrackersPage}
          />
        </Switch>
      </Box>
    </Box>
  );
}

const WrappedProgressItemsPage = () => {
  return (
    <ProgressItemsProvider>
      <ProgressItemsPage/>
    </ProgressItemsProvider>
  );
}

const WrappedProgressRegionsPage = () => {
  return (
    <ProgressRegionsProvider>
      <ProgressRegionsPage/>
    </ProgressRegionsProvider>
  );
}