import axios from 'axios';
import { API_BASE } from './constants';

export const login = (email: string, password: string) => {
  return axios
    .post(`${API_BASE}/token/generate`, {
      email: email,
      password: password,
    })
    .then(r => r.data)
    .then(resp => {
      let data = resp.data;
      return {
        email: data.user.email,
        token: data.token,
        first_name: data.user.first_name,
        last_name: data.user.last_name,
        public_id: data.user.public_id,
        user_type: data.user.user_type,
      };
    })
    .catch(e => {
      if (e === 500) throw Error('Error connecting to server. Try Again.');
      else throw Error('Email or password is incorrect');
    });
};

export const validate = (token: string) => {
  return axios
    .post(`${API_BASE}/token/validate`, {
      token: token,
    })
    .then(r => r.data)
    .then(resp => {
      return resp.data.token_status === 'valid';
    })
    .catch(e => {
      throw Error('Error validating token');
    });
};

export const introspect = (token: string) => {
  return axios
    .post(`${API_BASE}/token/introspect`, {
      token: token,
    })
    .then(r => r.data)
    .then(resp => {
      let data = resp.data.payload;
      return {
        email: data.user.email,
        token: token,
        first_name: data.user.first_name,
        last_name: data.user.last_name,
        public_id: data.user.public_id,
        user_type: data.user.user_type,
      };
    })
    .catch(e => {
      if (e.response.status === 500) throw Error('Error connecting to server. Try Again.');
      else throw Error('token invalid');
    });
};

export const permissions = (user_id: string) => {
  return axios
    .get(`${API_BASE}/user/${user_id}/permissions`)
    .then(r => r.data)
    .catch(e => {
    });
};

export const logout = () => {};
