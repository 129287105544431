import {usePagination, useTable, useSortBy, useRowSelect, useFilters, useGlobalFilter} from 'react-table';

import iconBack from '../../../assets/images/icons/icon_back_dark.svg';
import iconForward from '../../../assets/images/icons/icon_forward.svg';
import iconUp from '../../../assets/images/icons/icon_up.svg';
import iconDown from '../../../assets/images/icons/icon_down.svg';
import { Icon } from '../Icon';
import { Flex } from '@react-css/flex';
import { RowCheck } from './components/RowCheck';
import {useEffect, useMemo} from "react";
import {DefaultColumnFilter, GlobalFilterTextbox} from "./components/TableFilters";

export interface SortBy {
  id: any;
  desc: boolean
}


interface ITableProps {
  data: any;
  columns: any;
  rowSelect?: boolean;
  rowSelectHeader?: string;
  onRowSelected?: (rows: any) => void;
  pageSize?: number;
  filters?: boolean;
  onClickRow?: (originalRow: any) => void;
  preventAutoResetPage?: boolean;
  useGlobalFilterTextBox?: boolean;
  globalFilterPlaceholder?: string;
  hideColumnFilters?: boolean;
  preventAutoResetGlobalFilter?: boolean;
  initialSortBy?: SortBy[];
  noRowsMessage?: string;
  containerStyles?: React.CSSProperties;
  styleCell?: (cell: any) => React.CSSProperties;
}
export const Table = ({
  data,
  columns,
  rowSelect = false,
  pageSize = 10,
  rowSelectHeader,
  onRowSelected,
  filters=false,
  onClickRow,
  preventAutoResetPage,
  useGlobalFilterTextBox,
  globalFilterPlaceholder,
  hideColumnFilters,
  preventAutoResetGlobalFilter,
  initialSortBy,
  noRowsMessage,
  containerStyles,
  styleCell,
}: ITableProps) => {

  const defaultColumn = useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
  )
  // @ts-ignore
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    state: { pageIndex, selectedRowIds, globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { 
        pageSize: pageSize,
        sortBy: initialSortBy ?? []
      },
      autoResetPage: !preventAutoResetPage,
      autoResetGlobalFilter: !preventAutoResetGlobalFilter,
    },
      ...useGlobalFilterTextBox ? [useGlobalFilter] : [],
      useSortBy,
      usePagination,
      useRowSelect,
        hooks => {
        rowSelect &&
          hooks.visibleColumns.push(columns => [
            // Let's make a column for selection
            {
              id: 'selection',
              Header: rowSelectHeader,
              Cell: ({ row }) => (
                <div>
                  <RowCheck {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ]);
    }
  );

  useEffect( () => {
    if(onRowSelected)
      onRowSelected(selectedRowIds)
  }, [selectedRowIds])

  return (
    <Flex flexDirection={'column'} style={{ width: '100%', ...containerStyles }}>
      { useGlobalFilterTextBox &&
        <div
          style={{display: 'flex', justifyContent: 'end'}}
        >
          <GlobalFilterTextbox
            placeholder={globalFilterPlaceholder}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            gotoPage={gotoPage}
            style={{
              marginRight: '25px',
              marginBottom: '30px'
            }}
          />
        </div>
      }

      <table {...getTableProps()} style={{ width: '100%', margin: 'auto' }}>
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    background: 'none',
                    color: '#7E858E',
                    fontWeight: 'bold',
                    padding: '5px',
                    textAlign: 'center',
                    width: column.width
                  }}>
                  {column.render('Header')}
                  {column.canSort && (
                    <span>
                      {' '}
                      <Icon
                        icon={iconDown}
                        size={10}
                        style={{ cursor: 'pointer' }}
                        onClick={() => column.toggleSortBy(true, false)}
                      />{' '}
                      <Icon
                        icon={iconUp}
                        size={10}
                        style={{ cursor: 'pointer' }}
                        onClick={() => column.toggleSortBy(false, false)}
                      />
                    </span>
                  )}
                  <div>{(column.canFilter && !hideColumnFilters) ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, rowIndex: number) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => {
                  if (onClickRow) {
                    onClickRow(row.original);
                  }
                }}
                style={{cursor: !!onClickRow ? 'pointer' : 'auto'}}
              >
                {row.cells.map((cell: any) => {
                  const cellStyle = styleCell ? styleCell(cell) : {};

                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: '15px',
                        textAlign: 'center',
                        background: rowIndex % 2 ? '#F8F8F8' : 'white',
                        ...cellStyle
                      }}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {(data.length === 0 && !!noRowsMessage) && 
        <div style={{textAlign: 'center', marginTop: '10px'}}>
          {noRowsMessage}
        </div>
      }
      <Flex justifyCenter style={{marginTop: '1em'}}>
        {canPreviousPage && (
          <Icon icon={iconBack} size={16} onClick={previousPage} style={{ cursor: 'pointer' }} />
        )}
        <p style={{ padding: '0 5px', color: '#073C7A', fontWeight: 'bold' }}>
          {pageIndex * pageSize + 1} - {pageIndex * pageSize + page.length}
        </p>
        {canNextPage && (
          <Icon icon={iconForward} size={16} onClick={nextPage} style={{ cursor: 'pointer' }} />
        )}
      </Flex>
    </Flex>
  );
};
