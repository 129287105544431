import { Box, Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { ProjectFloorsAutoComplete, ProjectsAutocomplete } from "../../../../../common/Dropdowns";
import { ProjectFloor } from "../../../../../../api/projectFloors";
import { useCallback, useMemo, useState } from "react";
import { useProgressTrackingNavigation } from "../../../../../../hooks/useNavigation";
import { useProgressContext } from "../../../../../../contexts/progressContext";
import { EditableItem, useProgressItemsContext } from "../../../../../../contexts/progressItemsContext";
import { Item, ItemType, createItem, deleteItems, updateItem } from "../../../../../../api/items";
import { useNotifications } from "../../../../../../contexts/notificationProvider";
import { getRandomHexColor } from "../../../../../../api/utils";
import { Map as MapIcon, UploadFile } from "@mui/icons-material";
import { STLFloorPlanUploadModal } from "./ProgressItemsEditorHeaderModal";
import { ProjectTrackersAutoComplete } from "./ProjectTrackersAutoComplete";
import { ItemTypesAutocomplete } from "./ItemTypesAutocomplete";

export const ProgressItemsEditorHeader = () => {
  const {
    addNotification,
  } = useNotifications();

  const {
    navigateToProgressTrackingItems
  } = useProgressTrackingNavigation();

  const {
    state: progressState,
    dispatch: updateProgressState
  } = useProgressContext();

  const {
    projectId,
    floorId,
    floor,
    trackerName,
    selectedItemType,
  } = progressState;

  const {
    state: progressItemsState,
    dispatch: updateProgressItemsState,
    itemEdits,
    setItems,
    setItemEdits,
    selectedStlFloorPlan,
  } = useProgressItemsContext();

  const {
    showSTLFloorPlan,
  } = progressItemsState;

  const [projectsLoading, setProjectsLoading] = useState<boolean>(false);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [stlFloorPlanUploadModalOpen, setSTLFloorPlanUploadModalOpen] = useState<boolean>(false);

  const itemEditingInProgress = useMemo(() => {
    return !!itemEdits && Object.getOwnPropertyNames(itemEdits).length > 0;
  }, [itemEdits]);

  const onChangeProjectFloor = useCallback((incomingFloor: ProjectFloor | null) => {
    navigateToProgressTrackingItems(projectId, incomingFloor?.floor_code, trackerName);
  }, [navigateToProgressTrackingItems, projectId, trackerName]);

  const onChangeSelectedTracker = useCallback((incomingTrackerName: string | null | undefined) => {
    navigateToProgressTrackingItems(projectId, floorId, incomingTrackerName);
  }, [floorId, navigateToProgressTrackingItems, projectId]);

  const onChangeSelectedItemType = useCallback((incomingItemType: ItemType | null) => {
    navigateToProgressTrackingItems(projectId, floorId, trackerName, incomingItemType?.id);
    
    updateProgressState({
      type: 'UPDATE_STATE',
      payload: {
        selectedItemType: incomingItemType,
      }
    })
  }, [floorId, navigateToProgressTrackingItems, projectId, trackerName, updateProgressState]);

  const onClickRevert = useCallback(() => {
    setItemEdits({});
    setItems(prevItems => prevItems.filter(item => item.id > 0));
  }, [setItemEdits, setItems]);

  const handleItemsToUpdate = useCallback(async (items: EditableItem[]) => {
    if (!!projectId && !!trackerName) {
      const itemSavePromises: Promise<Item>[] = items.map(item => {
        return updateItem(projectId, trackerName, item.id, {position: item.position});
      });

      return await Promise.all(itemSavePromises);
    }

    return [];
  }, [projectId, trackerName]);

  const handleItemsToCreate = useCallback(async (items: EditableItem[]) => {
    if (!!projectId && !!trackerName) {
      const itemSavePromises: Promise<Item>[] = items.map(item => {
        let itemData = {
          type: item.type.id,
          project: projectId,
          tracker: trackerName,
          viewpoint: item.viewpoint,
          region: item.region,
          stl_file_key: "",
          position: item.position,
          is_vertical: false,
        };

        return createItem(itemData);
      });

      return await Promise.all(itemSavePromises);
    }

    return [];
  }, [projectId, trackerName]);

  const onClickSave = useCallback(async () => {
    if (!!projectId && !!trackerName) {
      try {
        setSaveInProgress(true);
        const itemsToUpdate = Object.values(itemEdits);

        const itemsToCreate = itemsToUpdate.filter(item => item.id < 0);
        const itemsToSave = itemsToUpdate.filter(item => item.id > 0 && item.position.length > 0);
        const itemsToDelete = itemsToUpdate.filter(item => item.position.length === 0);

        const createdItems = await handleItemsToCreate(itemsToCreate);

        const updatedItems = await handleItemsToUpdate(itemsToSave);
        const updatedItemsMap = new Map<number, Item>();

        updatedItems.forEach(item => {
          updatedItemsMap.set(item.id, item);
        });

        const itemIdsToDelete = itemsToDelete.map(item => item.id);
        await deleteItems(projectId, trackerName, itemIdsToDelete);
        
        const deletedItemIds = new Set(itemIdsToDelete);

        setItems(prevItems => {
          let updatedItems = [...prevItems];
          updatedItems = updatedItems.filter(item => item.id > 0);
          updatedItems = updatedItems.filter(item => !deletedItemIds.has(item.id));
          updatedItems = updatedItems.concat(createdItems.map(item => ({...item, color: getRandomHexColor()})));

          return updatedItems.map(item => {
            const savedItem = updatedItemsMap.get(item.id);

            if (savedItem) {
              return {
                ...savedItem,
                color: getRandomHexColor(),
              };
            }

            return item;
          });
        });

        setItemEdits({});

        addNotification('Items Updated Successfully', 'success');
      } catch {
        addNotification('Error updating items', 'error');
      } finally {
        setSaveInProgress(false);
      }
    }
  }, [addNotification, handleItemsToCreate, handleItemsToUpdate, itemEdits, projectId, setItemEdits, setItems, trackerName]);

  const onClickMapButton = useCallback(() => {
    updateProgressItemsState({
      type: 'UPDATE_STATE',
      payload: {
        showSTLFloorPlan: !showSTLFloorPlan,
      }
    });
  }, [showSTLFloorPlan, updateProgressItemsState]);

  const onClickUploadFileButton = useCallback(() => {
    setSTLFloorPlanUploadModalOpen(true);
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        alignItems="center"
        gap="10px"
      >
        <ProjectsAutocomplete
          label="Project"
          disabled={itemEditingInProgress}
          selectedProjectId={projectId}
          setSelectedProjectId={(newValue: string | null) => navigateToProgressTrackingItems(newValue)}
          setProjectsLoading={setProjectsLoading}
          width={300}
        />
        <ProjectFloorsAutoComplete
          projectId={projectId}
          disabled={itemEditingInProgress}
          selectedProjectFloor={floor}
          setSelectedProjectFloor={onChangeProjectFloor}
          loading={projectsLoading}
          width={200}
        />
        <ProjectTrackersAutoComplete
          projectId={projectId}
          disabled={itemEditingInProgress}
          selectedTrackerName={trackerName}
          setSelectedTrackerName={onChangeSelectedTracker}
          loading={projectsLoading}
        />
        {!!trackerName &&
          <ItemTypesAutocomplete
            selectedItemType={selectedItemType}
            setSelectedItemType={onChangeSelectedItemType}
            selectedTrackerName={trackerName}
            loading={projectsLoading}
          />
        }
        {!!trackerName &&
          <Tooltip
            title="Toggle STL Floorplan"
          >
            <IconButton
              disabled={!selectedStlFloorPlan}
              color={showSTLFloorPlan ? 'primary' : 'default'}
              onClick={onClickMapButton}
            >
              <MapIcon/>
            </IconButton>
          </Tooltip>
        }
        {!!trackerName &&
          <Tooltip
            title="Upload STL Floorplan"
          >
            <IconButton
              onClick={onClickUploadFileButton}
            >
              <UploadFile/>
            </IconButton>
          </Tooltip>
        }
      </Box>
      {Object.getOwnPropertyNames(itemEdits).length > 0 && (
        <>
          {!saveInProgress &&
            <Box
              display="flex"
              alignItems="center"
              gap="10px"
            >
              <Button
                variant="outlined"
                onClick={onClickRevert}
              >
                Revert
              </Button>
              <Button
                variant="contained"
                onClick={onClickSave}
              >
                Save
              </Button>
            </Box>
          }
          {saveInProgress &&
            <CircularProgress/>
          }
        </>
      )}
      <STLFloorPlanUploadModal
        open={stlFloorPlanUploadModalOpen}
        handleClose={() => setSTLFloorPlanUploadModalOpen(false)}
        onChangeSelectedTracker={onChangeSelectedTracker}
        onChangeSelectedItemType={onChangeSelectedItemType}
      />
    </Box>
  );
}