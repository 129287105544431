import { Box, Button } from "@mui/material"
import { HeadCell, Table, TableOrder } from "../../common/Table";
import { ProjectsAutocomplete } from "../../common/Dropdowns";
import { useLocation } from "react-router-dom";
import { useProgressTrackingNavigation } from "../../../hooks/useNavigation";
import { useEffect, useMemo, useState } from "react";
import { LoadingIndicator } from "../../common/LoadingIndicator";
import { useNotifications } from "../../../contexts/notificationProvider";

export enum TrackerTableEditingMode {
  none,
  update,
  create,
}

interface ICreateEditTableProps<T> {
  columns: readonly HeadCell<T>[];
  rows: T[] | undefined;
  initialOrderedColumnName?: keyof T;
  renderVisibleRows: (row: T, index: number) => JSX.Element;
  loading?: boolean;
  onEditItem: (id: number, editedItem: Partial<T>) => Promise<T>;
  onCreateItem: (createdItem: T) => Promise<T>;
  initialNewItem: T;
  isValidForSubmit: (tableData: T[]) => boolean;
  editingMode: TrackerTableEditingMode;
  setEditingMode: (mode: TrackerTableEditingMode) => void;
  edits: Record<number, Partial<T>>;
  clearEdits: () => void;
  customComparator?: (order: TableOrder, orderBy: keyof T) => ((a: T, b: T) => number) | undefined;
  readModeCreateButtonText?: string;
  createModeCreateButtonText?: string;
  readModeEditButtonText?: string;
  editModeEditButtonText?: string;
}

export const CreateEditTable = <T extends {id: number}>({
  columns,
  rows = [],
  initialOrderedColumnName='id',
  renderVisibleRows,
  loading,
  onCreateItem,
  onEditItem,
  initialNewItem,
  isValidForSubmit,
  editingMode,
  setEditingMode,
  edits,
  clearEdits,
  customComparator,
  readModeCreateButtonText="Create",
  createModeCreateButtonText="Save",
  readModeEditButtonText="Edit",
  editModeEditButtonText="Save"
}: ICreateEditTableProps<T>) => {
  const {
    navigateToProgressTrackingTrackers,
  } = useProgressTrackingNavigation();

  const { addNotification } = useNotifications();

  const [projectsLoading, setProjectsLoading] = useState<boolean>(true);
  const [originalData, setOriginalData] = useState<T[]>([...rows]);
  const [tableData, setTableData] = useState<T[]>([...rows]);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

  const inCreateMode = editingMode === TrackerTableEditingMode.create;
  const inUpdateMode = editingMode === TrackerTableEditingMode.update;
  const readOnly = editingMode === TrackerTableEditingMode.none;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const projectParam = params.get('project');

  const originalDataMap = useMemo(() => {
    const dataMap = new Map<number, T>();

    originalData.forEach(entry => {
      dataMap.set(entry.id, entry);
    })

    return dataMap;
  }, [originalData]);

  const editsMap = useMemo(() => {
    const editsMap = new Map<number, Partial<T>>();

    Object.keys(edits).forEach(key => {
      const id = parseInt(key);
      editsMap.set(id, edits[id]);
    })

    return editsMap;
  }, [edits]);

  useEffect(() => {
    setOriginalData([...rows]);
    setTableData([...rows]);
  }, [rows]);

  useEffect(() => {
    setTableData(prevTableData => {
      return prevTableData.map(tracker => {
        return {...tracker, ...editsMap.get(tracker.id)};
      });
    });
  }, [editsMap]);

  const onClickCancelButton = () => {
    setTableData([...originalData]);
    setEditingMode(TrackerTableEditingMode.none);
    clearEdits();
  }

  const onClickCreateButton = async () => {
    if (!inCreateMode) {
      setEditingMode(TrackerTableEditingMode.create);
      setTableData(prevTableData => {
        return [
          {...initialNewItem, id: -1} as T,
          ...prevTableData,
        ]
      });
    } else {
      const itemToSave = tableData.find(tracker => tracker.id === -1);

      if (itemToSave) {
        try {
          setSaveInProgress(true);

          const createdItem = await onCreateItem(itemToSave);

          const updatedTableData = tableData.map(tracker => {
            if (tracker.id === -1) {
              return createdItem;
            } else {
              return tracker;
            }
          });
    
          setTableData(updatedTableData);
          setOriginalData(updatedTableData);
          addNotification(`Tracker creation success`, 'success');
          setEditingMode(TrackerTableEditingMode.none);
          clearEdits();
        } catch {
          addNotification('Error creating new tracker', 'error');
        } finally {
          setSaveInProgress(false);
        }
      }
    }
  }

  const saveEditedItems = async () => {
    const promiseArr: Promise<T>[] = [];

    tableData.forEach(tracker => {
      const originalTracker = originalDataMap.get(tracker.id);

      if (!!originalTracker && !deepObjectCompare(tracker, originalTracker)) {
        const {id: trackerId, ...rest} = tracker;

        const editItemPromise = onEditItem(trackerId, rest as Partial<T>);

        promiseArr.push(editItemPromise);
      }
    });

    return Promise.allSettled(promiseArr)
      .then(resolvedPromises => {
        const successes: T[] = [];
        
        resolvedPromises.forEach(trackerPromise => {
          if (trackerPromise.status === 'fulfilled') {
            successes.push(trackerPromise.value);
          }
        })

        return successes;
      });
  }

  const onClickEditTrackersButton = async () => {
    if (!inUpdateMode) {
      setEditingMode(TrackerTableEditingMode.update);
    } else {
      try {
        setSaveInProgress(true);

        const savedItems = await saveEditedItems();
        const savedItemsMap = new Map<number, T>();

        savedItems.forEach(tracker => {
          savedItemsMap.set(tracker.id, tracker);
        })

        const updatedTableData = tableData.map(tracker => {
          const updatedTracker = savedItemsMap.get(tracker.id);

          if (!!updatedTracker) {
            return {...updatedTracker}
          } else {
            return tracker;
          }
        });

        setTableData(updatedTableData);
        setOriginalData(updatedTableData);
        setEditingMode(TrackerTableEditingMode.none);
        clearEdits();

        addNotification(`Tracker${savedItems.length !== 1 ? 's' : ''} updated successfully`, 'success')
      } catch {
        addNotification('Error updating trackers', 'error');
      } finally {
        setSaveInProgress(false);
      }
    }
  }

  const validForSubmit = useMemo(() => {
    if (editingMode === TrackerTableEditingMode.none) {
      return true;
    }

    return isValidForSubmit(tableData);
  }, [editingMode, isValidForSubmit, tableData]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <ProjectsAutocomplete
          selectedProjectId={projectParam}
          setSelectedProjectId={(newValue: string | null) => navigateToProgressTrackingTrackers(newValue)}
          setProjectsLoading={setProjectsLoading}
          width={300}
        />
        <Box
          display="flex"
          justifyContent="flex-end"
          gap={2}
        >
          {saveInProgress &&
            <LoadingIndicator
              containerStyle={{
                width: '50px'
              }}
            />
          }
          {!saveInProgress &&
            <>
              {!readOnly &&
                <Button
                  onClick={onClickCancelButton}
                >
                  Cancel
                </Button>
              }
              {!inUpdateMode &&
                <Button
                  disabled={!validForSubmit}
                  variant="contained"
                  onClick={onClickCreateButton}
                >
                  {inCreateMode ? createModeCreateButtonText : readModeCreateButtonText}
                </Button>
              }
              {(!inCreateMode && tableData.length > 0) &&
                <Button
                  disabled={!validForSubmit}
                  variant="contained"
                  onClick={onClickEditTrackersButton}
                >
                  {inUpdateMode ? editModeEditButtonText : readModeEditButtonText}
                </Button>
              }
            </>
          }
        </Box>
      </Box>
      <Table
        columns={columns}
        rows={[...tableData]}
        initialOrderedColumnName={initialOrderedColumnName}
        renderVisibleRows={renderVisibleRows}
        loading={loading || projectsLoading}
        customComparator={customComparator}
      />
    </Box>
  );
}

const deepObjectCompare = (obj1: any, obj2: any): boolean => {
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return obj1 === obj2;
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    }

    for (let i = 0; i < obj1.length; i++) {
      if (!deepObjectCompare(obj1[i], obj2[i])) {
        return false;
      }
    }

    return true;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      if (!deepObjectCompare(obj1[key], obj2[key])) {
        return false;
      }
    } else {
      return false;
    }
  }

  return true;
}