import axios from "axios";
import { SERVICES_API_BASE } from "./constants";

export type InstanceCommandType = "WIREGUARD_REBOOT" | "STREAMERS_REBOOT" | "RESTART_IPERF" | "STREAMERS_SERVICES_RESTART";
export const instanceCommandTypes: InstanceCommandType[] = ["WIREGUARD_REBOOT", "STREAMERS_REBOOT", "RESTART_IPERF", "STREAMERS_SERVICES_RESTART"];

export interface InstanceCommandHistory {
  id: number;
  instance_id: string;
  command_type: InstanceCommandType;
  executed_on: string;
  executed_by: string;
  last_edited_by: {
    id: number;
    public_id: string;
    email: string;
    first_name: string;
    last_name: string;
  }
}

export const retrieveMostRecentCommandExecutions = async (): Promise<InstanceCommandHistory[]> => {
  const res = await axios.get(
    `${SERVICES_API_BASE}/instance-commands/history/`,
  );
  return await res.data;
};

export const executeInstanceCommand = async (commandType: InstanceCommandType, userId: string): Promise<InstanceCommandHistory> => {
  const res = await axios.post(
    `${SERVICES_API_BASE}/instance-commands/history/`,
    {
      command_type: commandType,
      executed_by: userId
    }
  );
  return await res.data;
}