import styled from "styled-components";
import iconExpand from '../../../../assets/images/icons/icon_expand.svg';
import iconClose from '../../../../assets/images/icons/icon_close.svg';
import { useState } from "react";

export const ProjectForgeTransformationKey = () => {
  const [keyExpanded, setKeyExpanded] = useState<boolean>(false);

  return (
    <KeyContainer aria-expanded={keyExpanded}>
      <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={() => setKeyExpanded(prevValue => !prevValue)}>        
        <img src={keyExpanded ? iconClose : iconExpand} alt='Expand Key'/>
        &nbsp;Controls
      </div>
      {keyExpanded &&
        <>
          <div style={{marginTop: '10px'}}><u>Ctrl + click forge model</u>:</div>
          <div>Place point affixed to item clicked in the model</div>
          <div style={{marginTop: '10px'}}><u>Click forge coordinate or floor height listing</u>:</div>
          <div>Selects point</div>
          <div style={{marginTop: '10px'}}><u>Ctrl + click coordinate or floor height listing</u>:</div>
          <div>Teleports to point</div>
        </>
      }
    </KeyContainer>
  )
}

const KeyContainer = styled.div`
  position: absolute;
  left: 10px;
  bottom: 10px;
  background-color: white;
  padding: 10px;
`