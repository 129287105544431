import { useQuery } from "react-query";
import { ProjectTracker, TrackerType, listProjectTrackers, listTrackerTypes } from "../api/trackers";
import { Item, ItemParams, ItemTypeParams, fetchItemTypes, fetchItems } from "../api/items";
import { listJobTypes, listProjectJobTypes } from "../api/jobs";
import { ProgressRegion } from "../api/viewpoints";
import { fetchProgressRegions } from "../api/progressRegions";
import { STLFloorPlan, fetchSTLFloorPlans } from "../api/stlFloorPlans";

export const useProjectTrackersQuery = (projectId?: string | null, onSuccess?: (data: ProjectTracker[]) => void) => {
  return useQuery(
    ['project-trackers', projectId],
    async () => {
      return await listProjectTrackers(projectId ?? '');
    },
    {
      enabled: !!projectId,
      onSuccess: onSuccess
    }
  );
}

export const useTrackerTypesQuery = (onSuccess?: (data: TrackerType[]) => any) => {
  return useQuery(
    ['tracker-types'],
    async () => {
      return await listTrackerTypes();
    },
    { onSuccess: onSuccess}
  );
}

export const useItemsQuery = (projectId: string | null, trackerName: string | null, params?: ItemParams, onSuccess?: (data: Item[]) => any) => {
  return useQuery(
    ['items', projectId, trackerName, params?.viewpoint, params?.floor, params?.type, params?.limit, params?.offset],
    async () => {
      if (projectId && trackerName){
        return await fetchItems(projectId, trackerName, params);
      }

      return [];
    },
    {
      onSuccess: onSuccess
    }
  );
}

export const useItemTypesQuery = (params?: ItemTypeParams) => {
  return useQuery(
    ['item-types', params?.tracker],
    async () => {
      return await fetchItemTypes(params);
    }
  );
}

export const useJobTypesQuery = () => {
  return useQuery(
    ['job-types'],
    async () => {
      return await listJobTypes();
    }
  );
}

export const useProjectJobTypesQuery = (projectId?: string | null) => {
  return useQuery(
    ['project-job-types', projectId],
    async () => {
      return await listProjectJobTypes(projectId ?? '');
    },
    { enabled: !!projectId }
  );
}

export const useProjectFloorProgressRegionsQuery = (projectId: string | null, floorId: string | null, onSuccess?: (data: ProgressRegion[]) => void) => {
  return useQuery(
    ['regions', projectId, floorId],
    async () => {
      return await fetchProgressRegions(projectId ?? '', {floor_code: floorId ?? ''});
    },
    { enabled: !!projectId && !!floorId, onSuccess: onSuccess }
  );
}

export const useFetchSTLFloorPlansQuery = (projectPublicId: string, floorCode: string, onSuccess?: (data: STLFloorPlan[]) => void) => {
  return useQuery(
    ['stl-floor-plans', projectPublicId, floorCode],
    async () => {
      return await fetchSTLFloorPlans(projectPublicId, floorCode);
    },
    { enabled: !!projectPublicId && !!floorCode, onSuccess: onSuccess }
  );
}