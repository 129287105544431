import { uploadProjectFloorPlan } from "../../../../api/projectFloors";
import { useProjectContext } from "../../../../contexts/projectContext"
import { FormContainer, FormHeader } from "../../../common/FormControls";
import { FileUpload } from "../project-assets/FileUpload";

export const ManageFloorPlan = () => {
  const {
    updateFloor,
    state: projectState
  } = useProjectContext();

  const {
    project,
    floor,
  } = projectState;

  const onSaveImage = async (image: File) => {
    const updatedFloor = await uploadProjectFloorPlan(project.public_id, floor, image);
    updateFloor(updatedFloor);
  }

  return (
    <>
      <FormContainer>
        <FormHeader
          style={{marginBottom: '0px'}}
        >
          {`${project.name} - ${floor.name}`}
        </FormHeader>
      </FormContainer>

      <FileUpload
        imageSourceUrl={floor.latest_floor_plan?.web_image_url}
        imageAlt={`${project.name} ${floor.floor_code} floor plan`}
        onSaveFile={onSaveImage}
      />
    </>
  )
}