import { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom";
import styled from "styled-components"
import { IManageUser, fetchUsers } from "../../../api/users";
import { Button } from "../../common/Inputs/Button";
import { LoadingIndicator } from "../../common/LoadingIndicator";
import { Table } from '../../common/Table/Table';

export const UserDashboard = () => {
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [users, setUsers] = useState<IManageUser[]>([]);
  const history = useHistory();

  useEffect(() => {
    fetchUsers().then((fetchedUsers: IManageUser[]) => {
      setUsers(fetchedUsers);
      setDataLoaded(true);
    });
  }, []);

  const navigateToNewUserPage = () => {
    history.push('user-management/user/new');
  }

  const columns = useMemo(() => [
    { Header: 'First Name', accessor: 'first_name' },
    { Header: 'Last Name', accessor: 'last_name' },
    { Header: 'Employer', accessor: 'employer.name' },
    { Header: 'User Type', accessor: 'user_type' }
  ], []);

  const data = useMemo(() => users, [users]);

  if (!dataLoaded) {
    return <LoadingIndicator/>
  }

  const onClickRow = (row: IManageUser) => {
    history.push(`user-management/user/${row.public_id}`);
  }

  return (
    <UserDashboardContainer>
      <AddNewUserButtonContainer>
        <Button
          primary
          text='Add New User'
          onClick={navigateToNewUserPage}
        />
      </AddNewUserButtonContainer>

      <Table
        columns={columns}
        data={data}
        onClickRow={onClickRow}
      />
    </UserDashboardContainer>
  )
}

const UserDashboardContainer = styled.div`
  margin-top: 1em;
`;

const AddNewUserButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 20px 20px 0;
`