import { Flex } from '@react-css/flex';
import { Card } from '../../common/Card/Card';

import cardBg from '../../../assets/images/card-bg-1.jpg';
import cardBg2 from '../../../assets/images/card-bg-2.jpg';
import cardBg3 from '../../../assets/images/card-bg-3.jpg';
import { PATH_STRINGS } from '../../../hooks/useGeneratedPaths';
import { PermissionWrappedComponent } from '../../common/PermissionWrapper/PermissionWrapper';

export const Dashboard = () => {
  return (
    <div>
      <Flex justifyCenter={true} wrap>
        <PermissionWrappedComponent permission="aims_tables.create_label">
          <Card
            link={'/hoop/safety'}
            img={cardBg3}
            name={'HOOP'}
          />
        </PermissionWrappedComponent>

        {/* <Card link={'test'} img={cardBg2} name={'Autodesk'} /> */}
        {/* <Card
          link={'http://digital-bridge-staging.s3-website-us-east-1.amazonaws.com'}
          img={cardBg2}
          name={'Image Correction'}
        /> */}
        <PermissionWrappedComponent permission="project_tables.create_project">
          <Card
            link={PATH_STRINGS.projectsDashboard}
            img={cardBg}
            name={'Project Setup'}
          />
        </PermissionWrappedComponent>

        <PermissionWrappedComponent
          permission={[
            'nextera_sys_tables.create_user',
            'nextera_sys_tables.create_company',
            'project_tables.create_location',
            'project_tables.create_project_stair_climber_script'
          ]}
          useOrInPermissionCheck
        >
          <Card
            link={PATH_STRINGS.entities}
            img={cardBg2}
            name={'Entity Management'}
          />
        </PermissionWrappedComponent>
        <PermissionWrappedComponent permission="project_tables.create_project_floor_lidar_map">
          <Card
            link={PATH_STRINGS.lidarManagement}
            img={cardBg3}
            name={'Lidar Maps'}
          />
        </PermissionWrappedComponent>
        <Card
          link={PATH_STRINGS.sitewalk}
          img={cardBg}
          name={'Site Walk'}
        />
        <PermissionWrappedComponent
          permission="progress_tables.read_project_tracker"
        >
          <Card
            link={PATH_STRINGS.progress}
            img={cardBg2}
            name={'Progress Tracking'}
          />
        </PermissionWrappedComponent>
        <PermissionWrappedComponent
          permission="old_standard.create_instance_command_history"
        >
          <Card
            link={PATH_STRINGS.instances}
            img={cardBg}
            name={'Instance Management'}
          />
        </PermissionWrappedComponent>
      </Flex>
    </div>
  );
};
