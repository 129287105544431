import { ProjectFloorSection, createProjectFloorSection, updateProjectFloorSection } from "../../../../api/projectFloorSections";
import { useNotifications } from "../../../../contexts/notificationProvider";
import { useProjectContext } from "../../../../contexts/projectContext"
import { useFetchProjectLidarMapConfigurationsQuery, useProjectFloorSectionQuery } from "../../../../hooks/projectQueries"
import { useProjectNavigation } from "../../../../hooks/useNavigation";
import { FormCheckbox, FormContainer, FormHeader, SaveButtonContainer, StyledInput, StyledLabel } from "../../../common/FormControls";
import { Button } from "../../../common/Inputs/Button";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { useMemo, useState, useCallback, useRef, useEffect } from "react";
import { FullScreenTextEditor } from "../../../common/Modal/FullScreenTextEditor";
import { DEFAULT_CARTOGRAPHER_ROS_LUA, DEFAULT_SLAM_TOOLBOX_YAML, LidarMapConfigurationTextEditorType, ProjectLidarMapConfiguration } from "../../../../api/projectFloorLidarMaps";
import styled from "styled-components";
import { Select } from "../../../common/Inputs/Select";
import axios from "axios";

export const ManageProjectFloorSection = () => {
  const {
    updateState,
    updateSection,
    state: projectState,
  } = useProjectContext();

  const {
    addNotification,
  } = useNotifications();

  const {
    navigateToProjectSections
  } = useProjectNavigation();

  const {
    section,
    projectId,
    floorId,
    lidarMapConfigurations,
  }  = projectState;

  const sectionIsNew = !projectState.sectionId || projectState.sectionId === 'new';
  const usingProjectLevelConfig = section.use_project_config;
  
  const onUpdateLidarMapConfigurations = (updatedConfigurations: ProjectLidarMapConfiguration[]) => {
    updateState({ lidarMapConfigurations: updatedConfigurations });
  }

  const {
    isLoading: projectConfigurationsQuery
  } = useFetchProjectLidarMapConfigurationsQuery(projectState.projectId, onUpdateLidarMapConfigurations);
  
  const [cartographerRosConfigurationText, setCartographerRosConfigurationText] = useState<string>(DEFAULT_CARTOGRAPHER_ROS_LUA);
  const [slamToolboxConfigurationText, setSlamToolboxConfigurationText] = useState<string>(DEFAULT_SLAM_TOOLBOX_YAML);
  const [configurationTextEditorOpen, setConfigurationTextEditorOpen] = useState<boolean>(false);
  const [configurationTextEditorType, setConfigurationTextEditorType] = useState<LidarMapConfigurationTextEditorType>(LidarMapConfigurationTextEditorType.none);
  const [configurationFilesLoading, setConfigurationFilesLoading] = useState<boolean>(!sectionIsNew);
  const initialConfigValuesSet = useRef<boolean>(false);

  const onLoadProjectFloorSection = async (data: ProjectFloorSection) => {
    updateSection(data);

    const promiseArr: Promise<any>[] = [];

    if (data.cartographer_ros_configuration_url) {
      const cartographerRosConfigurationPromise = fetchConfigurationText(data.cartographer_ros_configuration_url).then(returnedText => {
        setCartographerRosConfigurationText(returnedText);
      });

      promiseArr.push(cartographerRosConfigurationPromise);
    }

    if (data.slam_toolbox_configuration_url) {
      const slamToolboxConfigurationPromise = fetchConfigurationText(data.slam_toolbox_configuration_url).then(returnedText => {
        setSlamToolboxConfigurationText(returnedText);
      });

      promiseArr.push(slamToolboxConfigurationPromise);
    }

    await Promise.all(promiseArr).then(() => {
      setConfigurationFilesLoading(false);
    });
  }

  const { isLoading: sectionLoading, isIdle: sectionIdle } = useProjectFloorSectionQuery(onLoadProjectFloorSection);

  const validForSubmit = useMemo(() => {
    const nameValid = !!section.name && !!section.name.trim();
    const useProjectConfigValid = !usingProjectLevelConfig || !!section.project_lidar_map_configuration_id;
    const runCartographerRosValid = !section.run_cartographer_ros || !!cartographerRosConfigurationText;
    const runSlamToolboxValid = !section.run_slam_toolbox || !!slamToolboxConfigurationText;

    return nameValid && useProjectConfigValid && runCartographerRosValid && runSlamToolboxValid;
  }, [section.name, usingProjectLevelConfig, section.project_lidar_map_configuration_id, section.run_cartographer_ros, section.run_slam_toolbox, cartographerRosConfigurationText, slamToolboxConfigurationText]);

  const fetchConfigurationText = useCallback(async (configurationFileUrl: string) => {
    const awsAxiosInstance = axios.create();
    delete awsAxiosInstance.defaults.headers.common['Authorization'];
    
    const response = await awsAxiosInstance.get(configurationFileUrl);

    return response.data as string;
  }, []);

  const handleSelectedProjectLidarMapConfig = useCallback(async (selectedConfig: ProjectLidarMapConfiguration) => {
    initialConfigValuesSet.current = true;

    if (selectedConfig.cartographer_ros_configuration_url) {
      const updatedCartographerRosConfigurationText = await fetchConfigurationText(selectedConfig.cartographer_ros_configuration_url);
      setCartographerRosConfigurationText(updatedCartographerRosConfigurationText);
    }

    if (selectedConfig.slam_toolbox_configuration_url) {
      const updatedSlamToolboxConfigurationText = await fetchConfigurationText(selectedConfig.slam_toolbox_configuration_url);
      setSlamToolboxConfigurationText(updatedSlamToolboxConfigurationText);
    }

    updateSection({
      project_lidar_map_configuration_id : selectedConfig.id,
      run_cartographer_ros: selectedConfig.run_cartographer_ros,
      run_slam_toolbox: selectedConfig.run_slam_toolbox,
    });
  }, [fetchConfigurationText, updateSection]);

  const onChangeSelectedProjectLidarMapConfig = async (selectedConfigName: string) => {
    if (lidarMapConfigurations) {
      const selectedConfig: ProjectLidarMapConfiguration = lidarMapConfigurations.find((config: ProjectLidarMapConfiguration) => config.name === selectedConfigName);

      if (selectedConfig) {
        handleSelectedProjectLidarMapConfig(selectedConfig);
      }
    }
  }

  console.log("SECTION: ", section);

  useEffect(() => {
    if (!initialConfigValuesSet.current && !!section && !!section.project_lidar_map_configuration_id && !!lidarMapConfigurations) {
      initialConfigValuesSet.current = true;

      const selectedConfig: ProjectLidarMapConfiguration = lidarMapConfigurations.find((config: ProjectLidarMapConfiguration) => config.id === section.project_lidar_map_configuration_id);

      if (selectedConfig) {
        handleSelectedProjectLidarMapConfig(selectedConfig);
      }
    }
  }, [lidarMapConfigurations, section, handleSelectedProjectLidarMapConfig]);

  const projectConfigOptions = useMemo(() => {
    if (lidarMapConfigurations) {
      return lidarMapConfigurations.map((config: ProjectLidarMapConfiguration) => config.name);
    }

    return [];
  }, [lidarMapConfigurations]);

  const selectedProjectLidarMapConfig = useMemo(() => {
    if (!!section && !!section.project_lidar_map_configuration_id && !!lidarMapConfigurations) {
      return lidarMapConfigurations.find((config: ProjectLidarMapConfiguration) => config.id === section.project_lidar_map_configuration_id)
    }

    return "";
  }, [lidarMapConfigurations, section]);

  const onChangeUseProjectConfig = (checked: boolean) => {
    const stateUpdate: Partial<ProjectFloorSection> = { use_project_config: checked };

    if (!checked) {
      stateUpdate.project_lidar_map_configuration_id = null;
    }

    updateSection(stateUpdate);
  }

  const onClickEditConfigButton = (configType: LidarMapConfigurationTextEditorType) => {
    setConfigurationTextEditorType(configType);
    setConfigurationTextEditorOpen(true);
  }

  const onCloseTextEditor = () => {
    setConfigurationTextEditorOpen(false);
    setConfigurationTextEditorType(LidarMapConfigurationTextEditorType.none);
  }

  if (sectionLoading || sectionIdle || projectConfigurationsQuery || configurationFilesLoading) {
    return <LoadingIndicator/>
  }

  const formatSection = () => {
    const postData = {...section};

    if (postData.run_cartographer_ros) {
      postData.cartographer_ros_configuration_text = cartographerRosConfigurationText;
    }

    if (postData.run_slam_toolbox) {
      postData.slam_toolbox_configuration_text = slamToolboxConfigurationText;
    }

    return postData;
  }

  const onSaveNewSection = async () => {
    try {
      const postData = formatSection();

      await createProjectFloorSection(projectId, floorId, postData);
      navigateToProjectSections();
      addNotification('Section Created Successfully', 'success');
    } catch (err) {
      console.log('onSaveNewSection==>>', err);
      addNotification('Error saving new section', 'error');
    }
  }

  const onUpdateExistingSection = async () => {
    try {
      const postData = formatSection();

      await updateProjectFloorSection(projectId, floorId, postData);
      navigateToProjectSections();
      addNotification('Section Updated Successfully', 'success');
    } catch (err) {
      console.log('onUpdateExistingSection==>>', err);
      addNotification('Error saving section', 'error');
    }
  }

  const onClickSave = () => {
    if (sectionIsNew) {
      onSaveNewSection();
    } else {
      onUpdateExistingSection();
    }
  }

  const saveButtonText: string = sectionIsNew ? 'Create' : 'Save';

  return (
    <FormContainer>
      <FormHeader>
        {sectionIsNew ? 'New Section' : 'Edit Section'}
      </FormHeader>

      <StyledLabel htmlFor='name'>Name:</StyledLabel>
      <StyledInput
        id='name'
        value={section.name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateSection({ name: e.target.value })}
      />

      <h2
        style={{
          margin: '20px 0px',
          fontSize: '18px'
        }}
      >
        Map Building Configuration
      </h2>

      <ConfigureRunContainer>
        <FormCheckbox
          label="Use Project Config"
          labelStyle={{
            width: '175px'
          }}
          checked={usingProjectLevelConfig}
          onChangeChecked={onChangeUseProjectConfig}
          checkboxContainerStyle={{
            width: 'auto',
            marginBottom: '0px'
          }}
        />
        <Select
          disabled={!usingProjectLevelConfig}
          options={projectConfigOptions}
          value={selectedProjectLidarMapConfig?.name ?? ''}
          onSelect={e => onChangeSelectedProjectLidarMapConfig(e.target.value)}
          selectStyles={{ 
            margin: '0 0 20px 0',
            width: 220
          }}
          useInitialEmptyOption
        />
      </ConfigureRunContainer>

      <ConfigureRunContainer>
        <FormCheckbox
          disabled={usingProjectLevelConfig}
          label="Run Cartographer Ros"
          labelStyle={{
            width: '175px'
          }}
          checked={section.run_cartographer_ros}
          onChangeChecked={(checked: boolean) => updateSection({ run_cartographer_ros: checked })}
          checkboxContainerStyle={{
            width: 'auto',
            marginBottom: '0px'
          }}
        />
        <Button
          primary
          disabled={!section.run_cartographer_ros || (usingProjectLevelConfig && !section.project_lidar_map_configuration_id)}
          text={`${usingProjectLevelConfig ? 'View' : 'Edit'} Cartographer Config`}
          onClick={() => onClickEditConfigButton(LidarMapConfigurationTextEditorType.cartographer_ros)}
          style={{ display: 'inline-block' }}
        />
      </ConfigureRunContainer>

      <ConfigureRunContainer>
        <FormCheckbox
          disabled={usingProjectLevelConfig}
          label="Run Slam Toolbox"
          labelStyle={{
            width: '175px'
          }}
          checked={section.run_slam_toolbox}
          onChangeChecked={(checked: boolean) => updateSection({ run_slam_toolbox: checked })}
          checkboxContainerStyle={{
            width: 'auto',
            marginBottom: '0px'
          }}
        />
        <Button
          primary
          disabled={!section.run_slam_toolbox || (usingProjectLevelConfig && !section.project_lidar_map_configuration_id)}
          text={`${usingProjectLevelConfig ? 'View' : 'Edit'} Slam Toolbox Config`}
          onClick={() => onClickEditConfigButton(LidarMapConfigurationTextEditorType.slam_toolbox)}
          style={{ display: 'inline-block' }}
        />
      </ConfigureRunContainer>

      {configurationTextEditorOpen &&
        <FullScreenTextEditor
          disabled={usingProjectLevelConfig}
          modalStyle={{zIndex: 12}}
          modalOpen={configurationTextEditorOpen}
          onClose={onCloseTextEditor}
          text={configurationTextEditorType === LidarMapConfigurationTextEditorType.cartographer_ros ? cartographerRosConfigurationText : slamToolboxConfigurationText}
          onChangeText={configurationTextEditorType === LidarMapConfigurationTextEditorType.cartographer_ros ? setCartographerRosConfigurationText : setSlamToolboxConfigurationText}
        />
      }

      <SaveButtonContainer>
        <Button
          primary={validForSubmit}
          disabled={!validForSubmit}
          text={saveButtonText}
          onClick={onClickSave}
          style={{ display: 'inline-block' }}
        />
      </SaveButtonContainer>
    </FormContainer>
  )
}

const ConfigureRunContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 25px;
`;