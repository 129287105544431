import React, { useEffect, useRef } from "react";
import styled from "styled-components"
import iconDown from '../../../assets/images/icons/icon_down_gray.svg';
import { Checkbox, ICheckboxProps, StyledInput } from "../../common/FormControls";

interface IDashboardSelect {
  title: string;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  searchPlaceholder?: string;
  containerStyle?: React.CSSProperties;
}

export const DashboardSelect = ({
  title,
  expanded,
  setExpanded,
  children,
  searchText,
  setSearchText,
  searchPlaceholder,
  containerStyle,
}: React.PropsWithChildren<IDashboardSelect>) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target as HTMLDivElement)) {
        setExpanded(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [setExpanded]);

  return (
    <DropdownContainer
      ref={dropdownRef}
      style={{
        ...containerStyle,
      }}
    >
      <TitleContainer
        onClick={() => setExpanded((prevState) => !prevState)}
      >
        <TitleTextContainer>{title}</TitleTextContainer>

        <DropdownArrow
          src={iconDown} 
          alt='expand dropdown'
          expanded={expanded}
        />
      </TitleContainer>

      { expanded &&
        <FilterCheckboxContainer>
          <FilterContainer>
            <StyledInput
              placeholder={searchPlaceholder ?? 'Search'}
              value={searchText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
              style={{width: '100%', marginBottom: '0px'}}
            />
          </FilterContainer>
          
          <OptionsContainer>
            {children}
          </OptionsContainer>
        </FilterCheckboxContainer>
      }
    </DropdownContainer>
  )
}

const DropdownContainer = styled.div`
  font-size: 14px;
  width: 400px;
  background-color: white;
`

const TitleContainer = styled.div`
  border: 1px solid #073c7a;
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleTextContainer = styled.div`
  flex: 1;
`

const DropdownArrow = styled.img<{expanded: boolean}>`
  transform: rotate(${props => props.expanded ? -180 : 0}deg);
  transition: transform 0.25s;
`

const OptionsContainer = styled.div`
  max-height: 250px;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

const FilterContainer = styled.div`
  padding: 14px 0;
  align-items: center;
  border-bottom: 1px solid #E2E8F0;
`;

const FilterCheckboxContainer = styled.div`
  border: 1px solid #073c7a;
  border-top: none;
  padding: 0 13px;
`;

interface IDashboardCheckboxProps extends ICheckboxProps {
  containerStyle?: React.CSSProperties;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const DashboardCheckbox = ({
  checked,
  onChangeChecked,
  label,
  containerStyle,
  checkmarkTop,
  checkmarkRight,
  checkmarkBottom,
  checkmarkLeft,
  onMouseEnter,
  onMouseLeave,
  children,
  checkmarkStyle,
}: React.PropsWithChildren<IDashboardCheckboxProps>) => {
  return (
    <CheckboxContainer
      style={containerStyle}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Checkbox
        checked={checked}
        onChangeChecked={onChangeChecked}
        label={label}
        checkmarkTop={checkmarkTop}
        checkmarkRight={checkmarkRight}
        checkmarkBottom={checkmarkBottom}
        checkmarkLeft={checkmarkLeft}
        checkmarkStyle={{
          marginRight: '10px',
          ...checkmarkStyle,
        }}
      />
      {children}
    </CheckboxContainer>
  )
}

const CheckboxContainer = styled.div`
  width: 400px;
  padding: 14px 0;
  position: relative;
  display: flex;
  align-items: center;
  &:not(&:last-child) {
    border-bottom: 1px solid #E2E8F0;
  }
`;