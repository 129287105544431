import React, {useEffect, useState} from "react";
import {useLocation} from 'react-router-dom';
import styled from "styled-components";

import {Annotoirous} from "../../third_party/AnnotoriousOS/Annotorious";
import {LabelingSidebar} from "./LabelingSidebar/LabelingSidebar";
import {ImagesSidebar} from "./ImagesSidebar/ImagesSidebar";
import {ImageSelectionModal} from "./ImageSelctionModal/ImageSelctionModal";
import {useEditorContext} from "../../../contexts/editorContext";
import {listProcessedImages, retrieveProcessedImage} from "../../../api/processedImages";


export const Editor = () => {
    const location = useLocation()
    const {state: EditorState, updateEditor} = useEditorContext()
    const [selectorOpen, setSelectorOpen] = useState(false)

    const checkQueryParams = () => {
        return new URLSearchParams(location.search).get("image_id")
    }

    const modalSubmit = (filters: any) => {
        updateEditor({filters: filters, refreshData: true})
        setSelectorOpen(false)
    }

    const refreshImage = async (imageId: number) => {
        let image = await retrieveProcessedImage(imageId)
        updateEditor({activeImage: image.data, refreshImage: false})
        let allImages = [...EditorState.images]
        let index = allImages.indexOf( (item: any) => item.id === imageId)
        if(index) {
            allImages[index] = image
            updateEditor({images: allImages})
        }
    }

    const fetchImages = async (offset=null) => {
        updateEditor({loading: true})
        let filters = {...EditorState.filters}
        if(offset)
            filters.offset = offset;
        let images = await listProcessedImages(filters)
        let activeImage = EditorState.activeImage || null;
        if(!offset)
            activeImage = EditorState.activeImage ? images.data.find( (image: any) => image.id === EditorState.activeImage.id) : images.data[0]
        let imgs = offset ? EditorState.images.concat(images.data) : images.data
        updateEditor({
             images: imgs, activeImage: activeImage, refreshData: false, loading: false, offset: images.meta.next ? images.meta.next_offset : null})
    }

    const fetchSingleImage = async (imageId: number) => {
        updateEditor({loading: true})
        retrieveProcessedImage(imageId)
            .then((data: any) => {
                let image = data.data
                updateEditor({images: [image], activeImage: image, refreshData: false, loading: false})
            })
            .catch( (e: Error) => console.log(e))
    }

    const paginate = async () => {
        if(EditorState.offset)
            fetchImages(EditorState.offset)
    }

    useEffect(() => {
        if(EditorState.refreshData) {
            fetchImages()
        }
    }, [EditorState.filters, EditorState.refreshData]);

    useEffect( () => {
        if(EditorState.refreshImage){
            refreshImage(EditorState.activeImage.id)
        }
    }, [EditorState.refreshImage])

    useEffect( () => {
        let q_check = checkQueryParams()
        if(q_check)
            fetchSingleImage(parseInt(q_check))
    }, [])

    return(
        <EditorContainer className="editor">
            <ImagesSidebar setImageSelector={setSelectorOpen} paginate={paginate}/>
            <Annotoirous />
            <LabelingSidebar />
            <ImageSelectionModal isOpen={selectorOpen} setIsOpen={setSelectorOpen} onSubmit={modalSubmit}/>
        </EditorContainer>
    )
}

const EditorContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flexDirection: row;
    background: #000;
`
