import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRetrieveMostRecentCommandExecutionsQuery } from "../../../hooks/projectQueries";
import { HeadCell, Table } from "../../common/Table";
import { InstanceCommandHistory, InstanceCommandType, executeInstanceCommand, instanceCommandTypes } from "../../../api/instanceCommands";
import { TableRow } from "../../common/TableRow";
import { Autocomplete, Button, TableCell, TextField } from "@mui/material";
import styled from "styled-components";
import { Refresh } from "@mui/icons-material";
import { useUserContext } from "../../../contexts/userContext";
import { useNotifications } from "../../../contexts/notificationProvider";

export const InstanceManagementDashboard = () => {
  const {state: userState} = useUserContext();
  const {addNotification} = useNotifications();

  const [commandExecutions, setCommandExecutions] = useState<InstanceCommandHistory[]>([]);
  const [commandExecutionInProgress, setCommandExecutionInProgress] = useState<boolean>(false);
  const [selectedInstanceCommandType, setSelectedInstanceCommandType] = useState<InstanceCommandType | null>(null);
  const [now, setNow] = useState<Date>(new Date());
  const nowInterval = useRef<NodeJS.Timeout>();

  const instanceCommandHistoryOnSuccess = useCallback((data: InstanceCommandHistory[]) => {
    setCommandExecutions(data);
  }, []);

  const {
    isLoading: commandExecutionsLoading,
    refetch: refetchCommandExecutions,
    isRefetching: isRefetchingCommandExecutions
  } = useRetrieveMostRecentCommandExecutionsQuery(instanceCommandHistoryOnSuccess);

  const dataLoaded = !commandExecutionsLoading && !isRefetchingCommandExecutions;

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 5000);

    nowInterval.current = interval;

    return () => {
      clearInterval(interval);
    }
  }, []);

  const onClickExecute = useCallback(async () => {
    if (selectedInstanceCommandType) {
      try {
        setCommandExecutionInProgress(true);

        const executedCommand = await executeInstanceCommand(selectedInstanceCommandType, userState.public_id);

        setCommandExecutions(prev => {
          return prev.filter(command => command.command_type !== executedCommand.command_type).concat(executedCommand);
        });
        setSelectedInstanceCommandType(null);
        
        addNotification('Command executed successfully', 'success');
      } catch {
        addNotification('Failed to execute command', 'error');
      } finally {
        setCommandExecutionInProgress(false);
      }
    }
  }, [addNotification, selectedInstanceCommandType, userState.public_id]);

  const columns: readonly HeadCell<any>[] = useMemo(() => [
    { id: 'command_type', label: 'Command Type' },
    { id: 'executed_on', label: 'Last Executed' },
    { id: 'last_edited_by', label: 'Last Executed By'}
  ], []);

  const rows = useMemo(() => {
    return commandExecutions ?? [];
  }, [commandExecutions]);

  const formatExecutedOn = useCallback((executedOn: string) => {
    const executedOnDate = new Date(executedOn);

    const diff = now.getTime() - executedOnDate.getTime();
    const diffSeconds = Math.floor(diff / 1000);
    const diffMinutes = Math.floor(diff / 1000 / 60);

    if (diffSeconds < 60) {
      return `< 1 minute ago`;
    } else if (diffMinutes < 60) {
      return `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`;
    } else {
      const dateString = executedOnDate.toLocaleDateString();
      const timeString = executedOnDate.toLocaleTimeString().replace(/:\d{2}\s/, ' ');

      return `${dateString} ${timeString}`;
    }
  }, [now]);

  const renderVisibleRows = useCallback((row: InstanceCommandHistory, index: number) => {
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={index}
      >
        <TableCell align="left">{row.command_type}</TableCell>
        <TableCell align="left">{formatExecutedOn(row.executed_on)}</TableCell>
        <TableCell align="left">{`${row.last_edited_by.first_name} ${row.last_edited_by.last_name}`}</TableCell>
      </TableRow>
    )
  }, [formatExecutedOn]);

  return (
    <InstanceManagementDashboardContainer>
      <InstanceManagementDashboardControlsContainer>
        <Button
          variant="contained"
          onClick={() => refetchCommandExecutions()}
        >
          <Refresh/>
        </Button>
        <InstanceCommandExecutionContainer>
          <Autocomplete
            value={selectedInstanceCommandType}
            options={instanceCommandTypes}
            onChange={(event, newValue) => {
              setSelectedInstanceCommandType(newValue);
            }}
            renderInput={(params) => {
              return (
                <TextField {...params} label="Command Type" required />
              )
            }}
            sx={{
              width: '275px'
            }}
          />
          <Button
            variant="contained"
            disabled={!selectedInstanceCommandType || commandExecutionInProgress}
            onClick={() => onClickExecute()}
            sx={{
              height: '56px'
            }}
          >
            Execute
          </Button>
        </InstanceCommandExecutionContainer>
      </InstanceManagementDashboardControlsContainer>
      <Table
        columns={columns}
        rows={rows}
        renderVisibleRows={renderVisibleRows}
        initialOrderedColumnName="executed_on"
        initialOrder="desc"
        loading={!dataLoaded || commandExecutionInProgress}
      />
    </InstanceManagementDashboardContainer>
  );
}

const InstanceManagementDashboardControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InstanceCommandExecutionContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

const InstanceManagementDashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 10px;
`