import { useCallback, useMemo } from "react";
import { EditableItem, useProgressItemsContext } from "../../../../../../contexts/progressItemsContext";

interface IProgressItemsEditorMapViewSVGItemProps {
  item: EditableItem;
}

export const ProgressItemsEditorMapViewSVGItem = ({
  item,
}: IProgressItemsEditorMapViewSVGItemProps) => {
  const {
    state: progressItemsState,
    dispatch: dispatchProgressItems,
    onUpdateTouched,
    onUpdateItem,
    setItems,
    setItemEdits,
  } = useProgressItemsContext();

  const {
    renderItemsAsPath,
  } = progressItemsState;

  const getItemCoordinates = useCallback((item: EditableItem) => {
    return item.position.map(point => [point.x, point.y]);
  }, []);

  const generatePolygonPoints = useCallback((item: EditableItem) => {
    const coordinates = getItemCoordinates(item);

    return coordinates.map(point => `${point[0]},${point[1]}`).join(' ');
  }, [getItemCoordinates]);

  const generatePathD = useCallback((item: EditableItem) => {
    const coordinates = getItemCoordinates(item);

    let pathString = `M${coordinates[0][0]},${coordinates[0][1]}`;
          
    for (let i = 1; i < coordinates.length; i++) {
      pathString += ` L${coordinates[i][0]},${coordinates[i][1]}`;
    }

    return pathString;
  }, [getItemCoordinates]);

  const onMouseDownItem = useCallback((e: React.MouseEvent<SVGLineElement | SVGPolygonElement, MouseEvent>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    dispatchProgressItems({
      type: 'UPDATE_STATE',
      payload: {
        currentlyEditingItemId: item.id,
        currentlyEditingCoodinateIndex: null,
        dragStartX: e.nativeEvent.offsetX,
        dragStartY: e.nativeEvent.offsetY,
      },
    });

    onUpdateTouched(item.id);
  }, [dispatchProgressItems, item.id, onUpdateTouched]);

  const onMouseUpItem = useCallback(() => {
    dispatchProgressItems({
      type: 'UPDATE_STATE',
      payload: {
        currentlyCreatingItemId: null,
        currentlyEditingItemId: null,
        currentlyEditingCoodinateIndex: null,
        dragStartX: null,
        dragStartY: null,
      },
    });
  }, [dispatchProgressItems]);

  const onContextMenuItem = useCallback((e: React.MouseEvent<SVGLineElement | SVGPolygonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if (item.id < 0) {
      setItems(items => items.filter(i => i.id !== item.id));
      setItemEdits(itemEdits => {
        delete itemEdits[item.id];
        return {...itemEdits};
      });
    } else {
      onUpdateItem({
        ...item,
        position: [],
      });
    }
  }, [item, onUpdateItem, setItemEdits, setItems]);

  const commonProps = useMemo(() => {
    return {
      key: item.id,
      cursor: "move",
      onMouseDown: onMouseDownItem,
      onMouseUp: onMouseUpItem,
      onContextMenu: onContextMenuItem,
    }
  }, [item.id, onContextMenuItem, onMouseDownItem, onMouseUpItem]);

  if (item.position.length === 0) {
    return <></>
  }

  if (renderItemsAsPath) {
    const d = generatePathD(item);

    return (
      <path
        {...commonProps} 
        d={d}
        stroke={item.color}
        strokeWidth="14"
        fill="transparent"
      />
    )
  } else {
    const points = generatePolygonPoints(item);

    return (
      <polygon
        {...commonProps}
        points={points}
        fill={item.color}
        opacity={0.7}
      />
    )
  }
}