import Flex from "@react-css/flex";
import { Card } from '../../common/Card/Card';

import cardBg from '../../../assets/images/card-bg-1.jpg';
import cardBg2 from '../../../assets/images/card-bg-2.jpg';
import cardBg3 from '../../../assets/images/card-bg-3.jpg';
import { PATH_STRINGS } from '../../../hooks/useGeneratedPaths';

export const LidarManagementDashboard = () => {
  return (
    <div>
      <Flex justifyCenter wrap>
        <Card
          link={PATH_STRINGS.mapsNeedingReview}
          img={cardBg}
          name={'Maps to Review'}
        />
        <Card
          link={PATH_STRINGS.mapsNeedingTransformation}
          img={cardBg2}
          name={'Maps to Transform'}
        />
        <Card
          link={PATH_STRINGS.mapEditingDashboard}
          img={cardBg3}
          name={'Map Editing'}
        />
      </Flex>
    </div>
  )
}