import axios from "axios";

export const getPresignedPostUrl = async (image: File, endPoint: string) => {
  const res = await axios.post(
      `https://api.nexterarobotics.com/api/${endPoint}`,
      {
          file_name: image.name
      },
  );
  const response = await res.data;
  return await response.data;
}

export const uploadToPresignedPost = async (file: File, presignedPost: PresignedPost) => {
    const key = presignedPost.fields.key.replace(`\${filename}`, file.name);

    const form = new FormData();

    Object.entries(presignedPost.fields).forEach(([field, value]) => {
        let fieldValue = value;

        if (field === 'key') {
            fieldValue = key;
        }

        form.append(field, fieldValue);
    });

    form.append('file', file);

    await axios.post(
        presignedPost.url,
        form,
        { 
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': ''
            }
        }
    );
  
    return key;
}

export const uploadToS3 = async (image: File, endPoint: string) => {
  try {
      const postUrlData: PresignedPost = await getPresignedPostUrl(image, endPoint);

      return uploadToPresignedPost(image, postUrlData);
  } catch (err: any) {
      return err;
  }
}

export interface PresignedPost {
  url: string;
  fields: {
      key: string;
  }
}