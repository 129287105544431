import axios from "axios";
import { API_BASE } from "./constants";

export interface ItemType {
  id: number;
  name: string;
}

export interface ItemTypeParams {
  tracker?: string;
}

export const fetchItemTypes = async (params?: ItemTypeParams): Promise<ItemType[]> => {
  const res = await axios.get(
    `${API_BASE}/item-types`,
    {
      params: params
    }
  )
  const response = await res.data;
  return response.data;
}

type ItemParamNames = "viewpoint" | "floor" | "type" | "limit" | "offset" | "fields";
export type ItemParams = Partial<Record<ItemParamNames, string | number | string[] | number[]>>;

export const fetchItems = async (projectId: string, trackerName: string, params?: ItemParams): Promise<Item[]> => {
  const res = await axios.get(
    `${API_BASE}/project/${projectId}/tracker/${trackerName}/items`,
    {
      params: {
        ...params,
        fields: (params?.fields as string[])?.join(',')
      }
    }
  );
  const response = await res.data;
  return response.data;
}

export interface ItemCreateData {
  type: number;
  project: string;
  tracker: string;
  viewpoint: number;
  region: number | null;
  stl_file_key: string | null;
  position: ItemPosition[];
  is_vertical: boolean;
}

export const createItem = async (itemCreateData: ItemCreateData): Promise<Item> => {
  const res = await axios.post(
    `${API_BASE}/project/${itemCreateData.project}/tracker/${itemCreateData.tracker}/item`,
    itemCreateData
  );
  const response = await res.data;
  return response.data;
}

export const updateItem = async (projectPublicId: string, trackerName: string, itemId: string | number, item: Partial<ItemCreateData>): Promise<Item> => {
  const res = await axios.patch(
    `${API_BASE}/project/${projectPublicId}/tracker/${trackerName}/item/${itemId}`,
    item
  );
  const response = await res.data;
  return response.data;
}

export const deleteItem = async (projectId: string, trackerName: string, itemId: string | number): Promise<void> => {
  const res = await axios.delete(
    `${API_BASE}/project/${projectId}/tracker/${trackerName}/item/${itemId}`
  );
  const response = await res.data;
  return response.data;
}

export const deleteItems = async (projectId: string, trackerName: string, itemIds: string[] | number[]): Promise<void> => {
  const res = await axios.delete(
    `${API_BASE}/project/${projectId}/tracker/${trackerName}/items`,
    {
      data: {
        item_ids: itemIds
      }
    }
  );
  const response = await res.data;
  return response.data;
}

export const uploadItemsJson = async (file: File): Promise<void> => {
  const formData = new FormData();
  formData.append('file', file);

  const res = await axios.post(
    `${API_BASE}/pt/v2/items/initialize`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );

  const response = await res.data;
  return response.data;
}

export interface ItemPosition {
  x: string;
  y: string;
  order: number;
}

export interface Item {
  id: number;
  type: ItemType;
  region: number | null;
  position: ItemPosition[];
  project_tracker: number;
  viewpoint: number;
  floor_code: string;
}