import React, { useCallback } from 'react';
import NotificationSystem from 'react-notification-system';

const ref: any = React.createRef();

const style = {
  NotificationItem: {
    // Override the notification item
    DefaultStyle: {
      // Applied to every notification, regardless of the notification level
      padding: '20px',
      fontSize: '1em',
    },
  },
};

export const NotificationsProvider = ({ children }: any) => {
  return (
    <>
      {children}
      <NotificationSystem ref={ref} style={style} />
    </>
  );
};

export const useNotifications = () => {
  const addNotification = useCallback((message: string, level: string) => {
    const notification = ref.current;
    notification.addNotification({
      message: message,
      level: level,
      position: 'br',
    });
  }, []);
  return { addNotification };
};
