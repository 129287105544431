export const getRandomHexColor = (min: number = 0, max: number = 255) => {
  const r = Math.floor(Math.random() * (max-min+1)) + min;
  const g = Math.floor(Math.random() * (max-min+1)) + min;
  const b = Math.floor(Math.random() * (max-min+1)) + min;

  const rHex = r.toString(16).padStart(2, '0');
  const gHex = g.toString(16).padStart(2, '0');
  const bHex = b.toString(16).padStart(2, '0');

  const hexColor = `#${rHex}${gHex}${bHex}`;

  return hexColor;
};