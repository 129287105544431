import Spinner from 'react-spinkit';
import React from 'react';
import styled from 'styled-components';

const IndicatorFlex = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
  position: relative;
  align-items: center;
  height: 100%;
`;

export const LoadingIndicator = ({color, indicatorFlexStyle}: any) => {
  return (
    <IndicatorFlex
      style={indicatorFlexStyle}
    >
      <div style={{ position: 'relative', zIndex: 10 }}>
        <Spinner name="ball-grid-pulse" color={color || "#073C7A"} fadeIn={'none'} />
      </div>
    </IndicatorFlex>
  );
};
