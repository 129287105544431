import axios from "axios";
import { SERVICES_API_BASE } from "./constants";

interface RunBuildWeightsResponse {
  success: number;
  failure: number;
}

export const runBuildWeights = async (id: number): Promise<RunBuildWeightsResponse> => {
  const res = await axios.post(
    `${SERVICES_API_BASE}/cartographer/build-weights/`,
    {
      lidar_map_ids: [id],
    },
  );

  return res.data;
}