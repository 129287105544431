import { ManageLidarMap } from '../../../api/projectFloorLidarMaps';
import { useProjectNavigation } from '../../../hooks/useNavigation';
import { LidarMapTable } from './LidarMapTable';

export const MapsNeedingTransformationDashboard = () => {
  const {
    navigateToLidarMapTransform,
  } = useProjectNavigation();
  
  const onClickRow = (row: ManageLidarMap) => {
    navigateToLidarMapTransform(row.project_public_id, row.floor_code, row.sub_id.toString());
  }
  
  return (
    <LidarMapTable
      title="Lidar Maps Needing Transformation"
      checkedHasTransformation={false}
      onClickRow={onClickRow}
      noRowsMessage="No Maps Needing Transformation"
      checkboxLabel="Needing Transformation"
      initialSortBy={[{id: 'id', desc: true}]}
    />
  )
}