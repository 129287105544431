import { ManageLidarMap } from "../../../api/projectFloorLidarMaps";
import { useProjectNavigation } from "../../../hooks/useNavigation";
import { LidarMapTable } from "./LidarMapTable";

export const MapsNeedingReviewDashboard = () => {
  const {
    navigateToLidarMapReview,
  } = useProjectNavigation();
  
  const onClickRow = (row: ManageLidarMap) => {
    navigateToLidarMapReview(row.project_public_id, row.floor_code, row.sub_id.toString());
  }
  
  return (
    <LidarMapTable
      checkedIsUsable={false}
      checkedIsLatest
      title="Lidar Maps Needing Review"
      onClickRow={onClickRow}
      noRowsMessage="No Maps Needing Review"
      checkboxLabel="Needing Review"
      initialSortBy={[{id: 'id', desc: true}]}
    />
  )
}