import { useReducer } from "react";
import { useRouteMatch } from "react-router-dom";
import { Company, createCompany, updateCompany } from "../../../api/companies";
import { useNotifications } from "../../../contexts/notificationProvider";
import { useCompanyQuery } from "../../../hooks/projectQueries";
import { PATH_STRINGS } from "../../../hooks/useGeneratedPaths";
import { useCompanyNavigation } from "../../../hooks/useNavigation";
import { FormContainer, FormHeader, SaveButtonContainer, StyledInput, StyledLabel } from "../../common/FormControls"
import { Button } from "../../common/Inputs/Button";
import { LoadingIndicator } from "../../common/LoadingIndicator";

const companyReducer = (state: Company, payload: Partial<Company>) => {
  return {
    ...state,
    ...payload,
  }
}

const initialCompany: Company = {
  public_id: '',
  name: '',
  website_url: '',
  is_verified: false
}

export const CompanyDetails = () => {
  const {
    addNotification,
  } = useNotifications();

  const {
    navigateToCompanies
  } = useCompanyNavigation();

  const [company, dispatchCompany] = useReducer(companyReducer, initialCompany);

  const companyIdMatch = useRouteMatch<{companyId: string}>(PATH_STRINGS.company);
  const companyId = companyIdMatch?.params.companyId || 'new';
  const companyIsNew = companyId === 'new';

  const { isLoading: companyLoading } = useCompanyQuery(companyId, dispatchCompany);

  if (companyLoading) {
    return <LoadingIndicator/>
  }

  const saveButtonText: string = companyIsNew ? 'Create' : 'Save';

  const validForSubmit = !!company.name && !!company.name.trim();

  const redirectToCompaniesDashboard = () => {
    navigateToCompanies();
  }

  const onClickCancel = () => {
    redirectToCompaniesDashboard();
  }

  const onCreateCompany = async () => {
    createCompany(company)
    .then(() => {
      redirectToCompaniesDashboard();
      addNotification('Company created successfully', 'success');
    })
    .catch(() => {
      addNotification('Error creating company', 'error');
    });
  }

  const onUpdateCompany = async () => {
    updateCompany(company)
    .then(() => {
      redirectToCompaniesDashboard();
      addNotification('Company saved successfully', 'success');
    })
    .catch(() => {
      addNotification('Error saving company', 'error');
    });
  }

  const onClickSave = () => {
    if (companyIsNew) {
      onCreateCompany();
    } else {
      onUpdateCompany();
    }
  }

  return (
    <FormContainer>
      { companyIsNew &&  
        <FormHeader>
          New Project
        </FormHeader>
      }

      <StyledLabel htmlFor='name'>Name:</StyledLabel>
      <StyledInput
        id='name'
        value={company.name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatchCompany({ name: e.target.value })}
      />

      <StyledLabel htmlFor='url'>Website URL:</StyledLabel>
      <StyledInput
        id='url'
        value={company.website_url}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatchCompany({ website_url: e.target.value })}
      />

      <SaveButtonContainer>
        <Button
          text='Cancel'
          onClick={onClickCancel}
          style={{ display: 'inline-block', marginRight: '10px' }}
        />
        <Button
          primary={validForSubmit}
          disabled={!validForSubmit}
          text={saveButtonText}
          onClick={onClickSave}
          style={{ display: 'inline-block' }}
        />
      </SaveButtonContainer>
    </FormContainer>
  )
}