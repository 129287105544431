import { createRef, useState } from "react";
import PanZoom from "../../common/PanZoom/PanZoom";
import { PanZoomContainer } from "../project-setup/project-floors/LidarPointPicker/MapPointPicker";
import { IconButton } from "../../common/IconButton";
import iconClose from '../../../assets/images/icons/icon_close.svg';
import styled from "styled-components";
import { Button } from "../../common/Inputs/Button";
import { LoadingIndicator } from "../../common/LoadingIndicator";

interface ISitewalkTransformationConfiramtionProps {
  imageUrl: string;
  onClose: () => void;
  onConfirmTransformation: () => any;
}

export const SitewalkTransformationConfirmation = ({
  imageUrl,
  onClose,
  onConfirmTransformation,
}: ISitewalkTransformationConfiramtionProps) => {
  const mapContainerRef = createRef<HTMLDivElement>();

  const [mapX, setMapX] = useState<number>(0);
  const [mapY, setMapY] = useState<number>(0);
  const [confirmationInProgress, setConfirmationInProgress] = useState<boolean>(false);

  const onClickConfirmaTransformation = async () => {
    setConfirmationInProgress(true);

    try {
      await onConfirmTransformation();
      onClose();
    } catch {
      console.log('Error confirming transformation');
    } finally {
      setConfirmationInProgress(false);
    }
  }

  return (
    <PanZoomContainer
      style={{
        border: 'none',
      }}
    >
      <CloseButtonContainer>
        <IconButton
          icon={iconClose}
          size={45}
          onClick={onClose}
          style={{position: 'static'}}
        />
      </CloseButtonContainer>
      <PanZoom
        mapContainerRef={mapContainerRef}
        minScale={0.1}
        maxScale={5}
        x={mapX}
        updateX={setMapX}
        y={mapY}
        updateY={setMapY}
      >
        <img
          src={imageUrl}
          alt="Site walk confirmation"
        />
      </PanZoom>
      <ButtonContainer>
        <Button
          text='Exit'
          onClick={onClose}
          style={{marginRight: '10px'}}
        />
        {confirmationInProgress &&
          <LoadingIndicator
            indicatorFlexStyle={{
              width: 'auto'
            }}
          />
        }
        {!confirmationInProgress &&
          <Button
            primary
            text='Confirm Transformation'
            onClick={onClickConfirmaTransformation}
          />
        }
      </ButtonContainer>
    </PanZoomContainer>
  )
}

const commonContainerProps = `
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px;
`;

const CloseButtonContainer = styled.div`
  ${commonContainerProps}
  justify-content: flex-end;
  padding: 0px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 70px;
  padding: 10px 30px;
`;