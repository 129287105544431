import axios from "axios";
import { API_BASE } from "./constants";

export interface JobType {
  id: number;
  name: string;
  slug: string;
  units: string;
  display_color: string;
  secondary_display_color: string | null;
  tertiary_display_color: string | null;
  display_shape: string;
  display_order: number;
  item_type: number;
}

export const listJobTypes = async (): Promise<JobType[]> => {
  const res = await axios.get(
    `${API_BASE}/job-types`
  )
  const response = await res.data;
  return response.data;
}

export interface ProjectJobType {
  job_type: JobType;
  project: number;
}

export const listProjectJobTypes = async (projectId: string): Promise<ProjectJobType[]> => {
  const res = await axios.get(
    `${API_BASE}/project/${projectId}/job-types`
  )
  const response = await res.data;
  return response.data;
}