import { useMemo } from "react";
import { useProgressItemsContext } from "../../../../../../contexts/progressItemsContext";
import { ProgressItemsEditorMapViewSVGItem } from "./ProgressItemsEditorMapViewItem";
import { ProgressItemsEditorMapViewSVGGhostPoints } from "./ProgressItemsEditorMapViewSVGGhostPoints";
import { ProgressItemsEditorMapViewSVGBoundaryPoints } from "./ProgressItemsEditorMapViewSVGBoundaryPoints";
import { useProgressContext } from "../../../../../../contexts/progressContext";

export const ProgressItemsEditorMapViewSVGItems = () => {
  const {
    state: progressState,
  } = useProgressContext();

  const {
    itemTypeId,
  } = progressState;

  const {
    itemsWithEdits,
    itemsLoading,
    touched,
  } = useProgressItemsContext();

  const sortedItemsWithEdits = useMemo(() => {
    if (itemsLoading) {
      return [];
    }

    return itemsWithEdits
    .filter(item => !itemTypeId || item.type.id === itemTypeId)
    .sort((a,b) => {
      const lastEditedA = touched[a.id] ?? -Infinity;
      const lastEditedB = touched[b.id] ?? -Infinity;

      return lastEditedA - lastEditedB;
    });
  }, [itemTypeId, itemsLoading, itemsWithEdits, touched]);

  if (itemsLoading) {
    return <></>
  }

  return (
    <>
      {sortedItemsWithEdits.map(item => {
        return (
          <>
            <ProgressItemsEditorMapViewSVGItem
              key={`item-${item.id}`}
              item={item}
            />
            <ProgressItemsEditorMapViewSVGGhostPoints
              key={`ghost-points-${item.id}`}
              item={item}
            />
            <ProgressItemsEditorMapViewSVGBoundaryPoints
              key={`boundary-points-${item.id}`}
              item={item}
            />
          </>
        )
      })}
    </>
  );
}