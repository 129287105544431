import { useCallback } from "react";
import { TransparentSVG } from "../../../../project-setup/project-floors/LidarPointPicker/MapPointPicker";
import { ProgressItemsEditorMapViewSVGItems } from "./ProgressItemsEditorMapViewSVGItems";
import { EditableItem, useProgressItemsContext } from "../../../../../../contexts/progressItemsContext";
import { useFetchViewpointsQuery } from "../../../../../../hooks/projectQueries";
import { useProgressContext } from "../../../../../../contexts/progressContext";
import { MapPointPickerPoint } from "../../../../project-setup/project-floors/LidarPointPicker/MapPointPickerPoint";
import { ViewpointsPoint } from "../../../../../../api/viewpoints";
import { getRandomHexColor } from "../../../../../../api/utils";

export const ProgressItemsEditorMapViewSVG = () => {
  const {
    state: progressState
  } = useProgressContext();

  const {
    project,
    floor,
    selectedItemType,
  } = progressState;

  const {
    state: progressItemsState,
    dispatch: dispatchProgressItems,
    itemsWithEdits,
    onUpdateItem,
    setItems,
  } = useProgressItemsContext();

  const {
    currentlyCreatingItemId,
    currentlyEditingItemId,
    currentlyEditingCoodinateIndex,
    dragStartX,
    dragStartY,
    selectedPoint,
    renderItemsAsPath
  } = progressItemsState;

  const {data: points, isLoading: pointsLoading} = useFetchViewpointsQuery(
    project?.public_id ?? '',
    floor?.floor_code ?? '',
    {
      activeOnly: true,
    }
  );

  const onMoveItemPoint = useCallback((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (currentlyEditingItemId !== null && currentlyEditingCoodinateIndex !== null) {
      let movedItem = itemsWithEdits.find(item => item.id === currentlyEditingItemId) as EditableItem;

      if (!!movedItem) {
        const itemToUpdate = {...movedItem};

        itemToUpdate.position = itemToUpdate.position.map((point, index) => {
          if (index === currentlyEditingCoodinateIndex) {
            return {
              ...point,
              x: event.nativeEvent.offsetX.toFixed(2),
              y: event.nativeEvent.offsetY.toFixed(2),
            };
          } else {
            return point;
          }
        });

        onUpdateItem(itemToUpdate);
      }
    }
  }, [currentlyEditingCoodinateIndex, currentlyEditingItemId, itemsWithEdits, onUpdateItem]);

  const onMoveItem = useCallback((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    const dragEndX = event.nativeEvent.offsetX;
    const dragEndY = event.nativeEvent.offsetY;

    const diffX = dragEndX - dragStartX!;
    const diffY = dragEndY - dragStartY!;

    if (currentlyEditingItemId) {
      const movedItem = itemsWithEdits.find(item => item.id === currentlyEditingItemId) as EditableItem;

      if (movedItem) {
        const itemToUpdate = {
          ...movedItem,
          position: movedItem.position.map(point => {
            return {
              ...point,
              x: (Math.round(parseFloat(point.x) + diffX)).toFixed(2),
              y: (Math.round(parseFloat(point.y) + diffY)).toFixed(2),
            };
          }),
        }

        onUpdateItem(itemToUpdate);

        dispatchProgressItems({
          type: 'UPDATE_STATE',
          payload: {
            dragStartX: dragEndX,
            dragStartY: dragEndY,
          },
        });
      }
    }
  }, [currentlyEditingItemId, dispatchProgressItems, dragStartX, dragStartY, itemsWithEdits, onUpdateItem]);

  const getRectangle = (startX: number, startY: number, endX: number, endY: number) => {
    const topLeft = {x: Math.min(startX, endX).toFixed(2), y: Math.min(startY, endY).toFixed(2), order: 0};
    const topRight = {x: Math.min(startX, endX).toFixed(2), y: Math.max(startY, endY).toFixed(2), order: 1};
    const bottomLeft = {x: Math.max(startX, endX).toFixed(2), y: Math.min(startY, endY).toFixed(2), order: 2};
    const bottomRight = {x: Math.max(startX, endX).toFixed(2), y: Math.max(startY, endY).toFixed(2), order: 3};

    return [topLeft, topRight, bottomRight, bottomLeft];
  }

  const onMoveCreatedItem = useCallback((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (renderItemsAsPath) {
      onMoveItemPoint(event);
    } else {
      const dragEndX = event.nativeEvent.offsetX;
      const dragEndY = event.nativeEvent.offsetY;

      const movedItem = itemsWithEdits.find(item => item.id === currentlyCreatingItemId) as EditableItem;

      if (movedItem) {
        const itemToUpdate = {
          ...movedItem,
          position: getRectangle(dragStartX!, dragStartY!, dragEndX, dragEndY),
        }

        onUpdateItem(itemToUpdate);
      }
    }
  }, [currentlyCreatingItemId, dragStartX, dragStartY, itemsWithEdits, onMoveItemPoint, onUpdateItem, renderItemsAsPath])

  const onMouseMoveSvg = useCallback((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (currentlyCreatingItemId !== null) {
      onMoveCreatedItem(event);
    } else if (currentlyEditingItemId !== null && currentlyEditingCoodinateIndex !== null) {
      onMoveItemPoint(event);
    } else if (currentlyEditingItemId !== null) {
      onMoveItem(event);
    }
  }, [currentlyCreatingItemId, currentlyEditingItemId, currentlyEditingCoodinateIndex, onMoveCreatedItem, onMoveItemPoint, onMoveItem]);

  const onDoubleClickSVG = useCallback((e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (!!selectedPoint && !!floor && !!selectedItemType) {
      const clickX = e.nativeEvent.offsetX;
      const clickY = e.nativeEvent.offsetY;
      const numInitialPoints = renderItemsAsPath ? 2 : 4;

      const coordinates = Array(numInitialPoints).fill(null).map((_, index) => {
        return {
          x: clickX.toFixed(2),
          y: clickY.toFixed(2),
          order: index,
        }
      });

      const newItem: EditableItem = {
        id: -Date.now(),
        position: coordinates,
        viewpoint: selectedPoint.id,
        region: selectedPoint.progress_regions ? selectedPoint.progress_regions[0].id : null,
        color: getRandomHexColor(),
        floor_code: floor.floor_code,
        type: selectedItemType,
        project_tracker: -1
      };

      setItems(prevItems => [...prevItems, newItem]);

      dispatchProgressItems({
        type: 'UPDATE_STATE',
        payload: {
          currentlyCreatingItemId: newItem.id,
          currentlyEditingItemId: newItem.id,
          currentlyEditingCoodinateIndex: 0,
          dragStartX: clickX,
          dragStartY: clickY,
        },
      });
    }    
  }, [dispatchProgressItems, floor, renderItemsAsPath, selectedItemType, selectedPoint, setItems]);

  const onClickMapPoint = useCallback((point: ViewpointsPoint) => {
    let newSelectedPoint: ViewpointsPoint | null = null;

    if (point.id !== selectedPoint?.id) {
      newSelectedPoint = point;
    }

    dispatchProgressItems({
      type: 'UPDATE_STATE',
      payload: {
        selectedPoint: newSelectedPoint,
      },
    });
  }, [dispatchProgressItems, selectedPoint]);

  return (
    <TransparentSVG
      width='100%'
      height='100%'
      onMouseMove={onMouseMoveSvg}
      onDoubleClick={onDoubleClickSVG}
      cursor={!!currentlyEditingItemId ? 'grabbing' : 'default'}
    >
      {(points && !pointsLoading) && (
        <>
          {points.map(point => {
            return (
              <MapPointPickerPoint
                key={point.id}
                id={point.id.toString()}
                x={point.x}
                y={point.y}
                pointSize={18}
                selected={point.id === selectedPoint?.id}
                onMouseDownPoint={() => onClickMapPoint(point)}
              />
            )
          })}
        </>
      )}
      <ProgressItemsEditorMapViewSVGItems/>
    </TransparentSVG>
  );
}