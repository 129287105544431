import axios from "axios";
import { API_BASE } from "./constants";

type ProgressRegionParamNames = "floor_code";
export type ProgressRegionParams = Partial<Record<ProgressRegionParamNames, string>>;

export const fetchProgressRegions = async (projectId: string, params?: ProgressRegionParams): Promise<ProgressRegion[]> => {
  const res = await axios.get(
    `${API_BASE}/project/${projectId}/regions`,
    {
      params: params
    }
  );
  const response = await res.data;
  return response.data;
}

export const createProgressRegion = async (projectId: string, region: ProgressRegionCreateData): Promise<ProgressRegion> => {
  const res = await axios.post(
    `${API_BASE}/project/${projectId}/region`,
    region
  );
  const response = await res.data;
  return response.data;
}

export const updateProgressRegion = async (projectId: string, regionId: number, data: Partial<ProgressRegionCreateData>): Promise<ProgressRegion> => {
  const res = await axios.patch(
    `${API_BASE}/project/${projectId}/region/${regionId}`,
    data
  );
  const response = await res.data;
  return response.data;
}

export const deleteProgressRegion = async (projectId: string, regionId: number): Promise<void> => {
  const res = await axios.delete(
    `${API_BASE}/project/${projectId}/region/${regionId}`
  );
  const response = await res.data;
  return response.data;
}

export const deleteProgressRegions = async (projectId: string, regionIds: string[] | number[]): Promise<void> => {
  const res = await axios.delete(
    `${API_BASE}/project/${projectId}/regions`,
    {
      data: {
        region_ids: regionIds
      }
    }
  );
  const response = await res.data;
  return response.data;
}

interface ProgressRegionPosition {
  x: number;
  y: number;
  order: number;
  id: number;
}

export interface ProgressRegion {
  id: number;
  position: ProgressRegionPosition[];
  viewpoint: number;
  viewpoint_sub_id: number;
}

interface ProgressRegionCreateData {
  viewpoint: number;
  position: ProgressRegionPosition[];
}