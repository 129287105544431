import {useMemo} from 'react';

interface MapPointPickerLineProps {
  points: [number, number][];
  stroke?: string;
  strokeWidth?: number;
}

export const MapPointPickerLine = ({
  points,
  stroke="black",
  strokeWidth,
}: MapPointPickerLineProps) => {
  const formattedCoordinates = useMemo(() => {
    const pointStrings = points.map(point => `${point[0]},${point[1]}`);

    return pointStrings.join(" ");
  }, [points]);

  return (
    <polyline
      points={formattedCoordinates}
      fill="none"
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  );
}