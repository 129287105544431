import React, { useMemo } from 'react';
import { Row } from 'react-table';
import { StyledInput } from '../../FormControls';

// @ts-ignore
export function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search records...`}
    />
  );
}

// @ts-ignore
export function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id]);
    });
    // @ts-ignore
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}>
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

interface IGlobalFilterTextboxProps {
  globalFilter: string;
  preGlobalFilteredRows: Row[];
  setGlobalFilter: (newFilter?: string) => void;
  gotoPage: (pageIndex: number) => void;
  style?: React.CSSProperties;
  placeholder?: string;
}

export function GlobalFilterTextbox({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  style,
  placeholder,
  gotoPage,
}: IGlobalFilterTextboxProps) {
  const onChangeGlobalFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalFilter(e.target.value || undefined);
    gotoPage(0);
  }

  return (
    <StyledInput
      type='search'
      value={globalFilter || ''}
      onChange={onChangeGlobalFilter}
      placeholder={placeholder ?? `Search records...`}
      style={style}
    />
  );
}
