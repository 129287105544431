import { useState, useMemo, useEffect } from 'react';
import { useFetchLidarMapsQuery, useProjectsQuery } from "../../../hooks/projectQueries";
import { SortBy, Table } from "../../common/Table/Table"
import styled from "styled-components";
import { LoadingIndicator } from '../../common/LoadingIndicator';
import { Select } from '../../common/Inputs/Select';
import { Project } from '../../../api/projects';
import { ManageLidarMap } from '../../../api/projectFloorLidarMaps';
import { DashboardCheckbox } from '../project-setup/DashboardFormControls';
import { Button } from '../../common/Inputs/Button';
import { useProjectNavigation } from '../../../hooks/useNavigation';

const allProjectsKey = 'All Projects';

interface ILidarMapTableProps {
  title?: string;
  onClickRow: (map: ManageLidarMap) => void;
  noRowsMessage: string;
  checkboxLabel: string;
  checkedIsUsable?: boolean;
  checkedIsLatest?: boolean;
  checkedHasTransformation?: boolean;
  initialSortBy?: SortBy[];
}

export const LidarMapTable = ({
  title,
  onClickRow,
  noRowsMessage,
  checkboxLabel,
  checkedIsUsable,
  checkedIsLatest,
  checkedHasTransformation,
  initialSortBy,
}: ILidarMapTableProps) => {
  const {
    navigateToLidarManagementDashboard,
  } = useProjectNavigation();

  const { data: projects, isLoading: projectsLoading } = useProjectsQuery();
  const [selectedProjectId, setSelectedProjectId] = useState<string>(allProjectsKey);
  const [tableCheckboxChecked, setTableCheckboxChecked] = useState<boolean>(true);
  const [hasTransformation, setHasTransformation] = useState<boolean | undefined>(checkedHasTransformation);
  const [isUsable, setIsUsable] = useState<boolean | undefined>(checkedIsUsable);
  const [isLatest, setIsLatest] = useState<boolean | undefined>(checkedIsLatest);
  
  const {data: lidarMaps, isLoading: lidarMapsLoading} = useFetchLidarMapsQuery(hasTransformation, isUsable, isLatest, false);

  useEffect(() => {
    if (tableCheckboxChecked) {
      setHasTransformation(checkedHasTransformation);
      setIsUsable(checkedIsUsable);
      setIsLatest(checkedIsLatest);
    } else {
      setHasTransformation(undefined);
      setIsUsable(undefined);
      setIsLatest(undefined);
    }
  }, [tableCheckboxChecked, checkedHasTransformation, checkedIsUsable, checkedIsLatest]);

  const columns = useMemo(() => [
    { Header: 'Id', accessor: 'id' },
    { Header: 'Project', accessor: 'project_name' },
    { Header: 'Floor', accessor: 'floor_code' },
    { Header: 'Section', accessor: 'floor_section.name' },
    { Header: 'Processed Via', accessor: 'processed_via' },
    { Header: 'Captured On', accessor: (originalRow: ManageLidarMap) => new Date(originalRow.captured_on).toDateString() }
  ], []);

  const data = useMemo(() => {
    if (!lidarMapsLoading && !!lidarMaps) {
      return lidarMaps.filter(map => {
        const matchesSelectedProject = map.project_public_id === selectedProjectId || selectedProjectId === allProjectsKey;
         
        return matchesSelectedProject;
      });
    }

    return lidarMaps;
  }, [lidarMaps, lidarMapsLoading, selectedProjectId]);

  const projectDropdownOptions = useMemo(() => {
    if (!projects || projectsLoading) {
      return [];
    }

    return [{name: allProjectsKey, public_id: allProjectsKey}, ...projects];
  }, [projectsLoading, projects]);
  
  return (
    <LidarMapTableContainer>
      <TitleContainer>
        <ReturnToDashboardContainer>
          <Button
            text="Return To Dashboard"
            onClick={navigateToLidarManagementDashboard}
          />
        </ReturnToDashboardContainer>
        <Title>
          {title ?? 'Lidar Maps'}
        </Title>
        <EmptyDiv/>
      </TitleContainer>
      <LidarMapControlsContainer>
        <Select
          disabled={projectsLoading || !projects}
          options={projectDropdownOptions}
          value={selectedProjectId}
          onSelect={e => setSelectedProjectId(e.target.value)}
          useInitialEmptyOption
          getOptionLabel={(option: Project) => option.name}
          getOptionValue={(option: Project) => option.public_id}
          selectContainerStyles={{
            width: '250px',
          }}
          selectStyles={{
            margin: 0,
          }}
        />
        <DashboardCheckbox
          checked={tableCheckboxChecked}
          onChangeChecked={() => setTableCheckboxChecked(prevValue => !prevValue)}
          label={checkboxLabel}
          containerStyle={{width: 'auto'}}
        />
      </LidarMapControlsContainer>

      {lidarMapsLoading &&
        <LoadingIndicator />
      }
      {!lidarMapsLoading &&
        <Table
          columns={columns}
          data={data}
          onClickRow={onClickRow}
          noRowsMessage={noRowsMessage}
          initialSortBy={initialSortBy}
        />
      }
    </LidarMapTableContainer>
  )
}

const LidarMapTableContainer = styled.div`
  margin-top: 1em;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
`;

const ReturnToDashboardContainer = styled.div`
  display: flex;
  flex: 1;
`;

const Title = styled.h1`
  font-size: 24px;
`;

const LidarMapControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px;
`;

const EmptyDiv = styled.div`
  flex: 1;
`;