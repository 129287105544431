import React, {useMemo, useState, useCallback} from 'react';
import axios from 'axios';
import { PageTitle, TopMenu } from '../../styles';
import Flex from '@react-css/flex';
import {Link, useHistory} from 'react-router-dom';

import { Icon } from '../../../../common/Icon';
import { Table } from '../../../../common/Table/Table';
import iconRadioChecked from '../../../../../assets/images/icons/icon_radio_checked.svg';
import iconImage from '../../../../../assets/images/icons/icon_image.svg';
import { Button } from '../../../../common/Inputs/Button';
import {LoadingIndicator} from "../../../../common/LoadingIndicator";
import {SelectColumnFilter} from "../../../../common/Table/components/TableFilters";
import {Select} from "../../../../common/Inputs/Select";
import {Checkbox} from "../../../../common/Inputs/Checkbox";
import {listProjects, Project} from "../../../../../api/projects";
import {listProcessedImages} from "../../../../../api/processedImages";


export const SafetyAITable = () => {
    const history = useHistory()
    const [images, setImages] = useState<any[]|null>();
    const [projectsList, setProjectsList] = useState<any[]>([])
    const [selectedRows, setSelectedRows] = useState([])
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const [bulkAction, setBulkAction] = useState('---')
    const [filters, setFilters] = useState<any>({
        limit: 100,
        verified: false,
        project: "Any"
    })

    const openImageViewer = useCallback((imageId: number) => {
        history.push({
            pathname: '/editor',
            search: `?image_id=${imageId}`
        })
    }, []);


    const itemHasLabels = (item: any) => {
        return item.length
    }

    const bulkUpdate = (action: any) => {
        if(!Object.keys(selectedRows).length)
            return

        let fields: any = {}
        switch (action) {
            case "Mark for Retain":
                fields = {retrain: true}
                break
            case "Mark Verified":
                fields = {verified: true}
                break
            case "Mark for Reprocess":
                fields = {reprocess: true}
                break;
            default:
                return;
        }
        if (images) {
            let imagesToUpdate = Object.keys(selectedRows).map((item: any) => ({...fields, id: images[item].id}))
            axios.patch('https://api.nexterarobotics.com/api/processed-images', imagesToUpdate)
                .then(() => {
                    setShouldUpdate(true)
                    setBulkAction('---')
                })
                .catch(() => alert('error updating!'))
        }
    }

    const projects = useMemo( () => (
            listProjects()
                .then( d => {
                    let p: string[] = d.data.map((item: Project) => item.name)
                    setProjectsList(["Any"].concat(p))
                })
                .catch( () => alert('error loading projects'))
        ), []
    )

    const data = useMemo(
        () => {
            setImages(null)
            let qFilters = {...filters} as any
            if(qFilters.project === "Any")
                qFilters.project = ""
            return listProcessedImages(qFilters)
                .then( d => {
                    setShouldUpdate(false)
                    setImages(d.data);
                })
                .catch( () => alert("error loading images!"))
        },
        [filters, shouldUpdate]
    );

    const columns = useMemo(
        () => [
            {
                Header: ' ',
                accessor: '',
                disableSortBy: true,
                disableFilters: true,
                Cell: (tableinfo: any) => <Icon icon={iconImage} size={14} style={{cursor: 'pointer'}} onClick={() => openImageViewer(tableinfo.row.values.id)}/>,
            },
            {
                Header: 'Unverified',
                accessor: 'verified',
                sortType: 'basic',
                disableFilters: true,
                Cell: (tableInfo: any) =>
                    !tableInfo.value ? <Icon icon={iconRadioChecked} size={12} /> : '',
            },
            {
                Header: 'Id',
                accessor: 'id',
            },
            {
                Header: 'Added',
                accessor: 'registered_on',
            },
            {
                Header: 'Project',
                accessor: 'viewpoints_image.project',
                Filter: SelectColumnFilter
            },
            {
                Header: 'Point #',
                accessor: 'viewpoints_image.sub_point_id',
            },
            {
                Header: 'Location',
                accessor: 'viewpoints_image.project_floor',
            },
            {
                Header: 'Has Labels',
                id: 'worker',
                Filter: SelectColumnFilter,
                filter: 'boolean',
                accessor: (originalRow: { labels: any; }) => itemHasLabels(originalRow.labels),
                Cell: (tableInfo: any) =>
                    tableInfo.value ? <Icon icon={iconRadioChecked} size={12} /> : '',
            },
            {
                Header: 'Reprocess',
                accessor: 'reprocess',
                sortType: 'basic',
                disableFilters: true,
                Cell: (tableInfo: any) =>
                    tableInfo.value ? <Icon icon={iconRadioChecked} size={12} /> : '',
            },
            {
                Header: 'Marked for Retraining',
                accessor: 'retrain',
                sortType: 'basic',
                disableFilters: true,
                Cell: (tableInfo: any) =>
                    tableInfo.value ? <Icon icon={iconRadioChecked} size={12} /> : '',
            },
        ],
        []
    );

    return (
          <div style={{width: '90%', margin: 'auto', background: "#fff", padding: '30px'}}>
            <TopMenu>
              <Flex justifySpaceBetween alignContentEnd style={{width: '100%', margin: 'auto'}}>
                  <Flex alignItemsEnd style={{width: '100%', margin: 'auto'}}>
                      <Select label={'Bulk Actions'} options={['---', 'Mark for Retain', "Mark Verified", "Mark for Reprocess"]} onSelect={(e) => setBulkAction(e.target.value)} value={bulkAction}/>
                      <Button primary={true} text={'Submit'} onClick={() => bulkUpdate(bulkAction)} />
                  </Flex>
                <Flex justifyCenter style={{width: '100%', margin: 'auto'}}>
                  <div style={{margin: '0 1em'}}>
                    <h2 style={{textDecoration: 'underline'}}>Images</h2>
                  </div>
                  <div style={{margin: '0 1em'}}>
                    <h2 style={{color: "#ddd"}}>Observations</h2>
                  </div>
                </Flex>
                <Flex justifyEnd alignItemsEnd style={{width: '100%', height: 'fit-content'}}>
                    <Checkbox label="Unverified" onSelect={() => setFilters({...filters, verified: !filters.verified})} value={!filters.verified}/>
                    {projectsList.length &&
                    <Select label={'Project'} options={projectsList} onSelect={(e) => setFilters({...filters, project: e.target.value})} value={filters.project}/>}
                    <Select label={'Results Limit'} options={['100', '200', '500', '1000']} onSelect={(e) => setFilters({...filters, limit: e.target.value})} value={filters.limit.toString()}/>
                </Flex>
              </Flex>
            </TopMenu>
            <Flex justifyCenter>
              {images ?
                  <Table
                      data={images}
                      columns={columns}
                      rowSelect={true}
                      rowSelectHeader=""
                      pageSize={15}
                      onRowSelected={(rows) => setSelectedRows(rows)}
                      filters={false}
                  /> : <LoadingIndicator />}
              </Flex>
          </div>
    );
};
