import {Sidebar} from "../../../common/Sidebar/Sidebar";
import styled from 'styled-components';
import { updateObservation } from '../../../../api/observations';
import { useNotifications } from '../../../../contexts/notificationProvider';

export const ObservationSidebar = ({observation, projectId}: any) => {
    const {addNotification} = useNotifications()

    const markObservationVerified = () => {
        updateObservation(projectId, observation.id, {verified: true})
          .then( () => addNotification('Marked as verified', 'success'))
          .catch( () => addNotification('Error verifying', 'error'))
    }

    return(
    <Sidebar minWidth={"300px"} maxWidth={"300px"} style={{color: "#fff", background: "#444", padding: "10px", paddingTop: "35px"}}>
        {observation && (
          <div>
              <ObservationTitle>Observation: <ObservationValue>{observation.id}</ObservationValue></ObservationTitle>
              <ObservationTitle>Type: <ObservationValue>{observation.type.code}</ObservationValue></ObservationTitle>
              <ObservationTitle style={{ margin: "0.5em 0"}}>Description:</ObservationTitle>
              <p style={{marginBottom: "1em"}}>{observation.type.description}</p>
              <ObservationTitle>Subcontractor: <ObservationValue>N/A</ObservationValue></ObservationTitle>
              <ObservationTitle style={{fontSize: "1.3em", margin: "0.5em 0"}}>Notes:</ObservationTitle>
              <textarea value={observation.notes} cols={35} rows={10} />
              {observation.verified ? <p>Observation Verified</p> :
              <VerifyButton onClick={markObservationVerified}>Verify Observation</VerifyButton>}
          </div>
        )}

    </Sidebar>)
}

const ObservationTitle = styled.h1`
  font-size: 1.3em;
  margin: 1em 0;
  font-weight: bold;
`;

const ObservationValue = styled.span`
    font-weight: normal;
`;

const VerifyButton = styled.div`
    cursor: pointer;
    padding: 5px 10px;
    margin: 1em 0;
    background: #222;
    color: #fff;
    text-align: center;
`
