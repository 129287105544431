import { useState, useEffect, useCallback } from 'react';
import styled from "styled-components";
import { DEFAULT_CARTOGRAPHER_ROS_LUA, DEFAULT_SLAM_TOOLBOX_YAML, LidarMap, reRunLidarMap, updateLidarMap } from "../../../api/projectFloorLidarMaps"
import { useNotifications } from "../../../contexts/notificationProvider";
import { useProjectContext } from "../../../contexts/projectContext";
import { useProjectNavigation } from "../../../hooks/useNavigation";
import { Button } from "../../common/Inputs/Button";
import { BlurableMainWindow } from "../../common/Modal/Modal";
import { LoadingIndicator } from '../../common/LoadingIndicator';
import axios from 'axios';
import { FullScreenTextEditor } from '../../common/Modal/FullScreenTextEditor';

export const LidarMapReview = () => {
  const {
    addNotification
  } = useNotifications();

  const {
    navigateToLidarMap,
    navigateToLidarMapTransform,
    navigateToMapsNeedingReview,
  } = useProjectNavigation();

  const [reprocessModalOpen, setReprocessModalOpen] = useState<boolean>(false);
  const [configurationText, setConfigurationText] = useState<string>('');
  const [configurationTextLoading, setConfigurationTextLoading] = useState<boolean>(true);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

  const {
    state: projectState,
  } = useProjectContext();

  const {
    projectId,
    floorId,
    lidarMapId,
    lidarMap,
  } = projectState as {projectId: string; project: any, floorId: string, lidarMapId: string, lidarMap: LidarMap | null};

  const fetchConfigurationText = useCallback(async (configurationFileUrl: string) => {
    setConfigurationTextLoading(true);

    try {
      const awsAxiosInstance = axios.create();
      delete awsAxiosInstance.defaults.headers.common['Authorization'];
      
      const res = await awsAxiosInstance.get(configurationFileUrl);

      setConfigurationText(res.data);
    } catch {
      addNotification('Error loading lidar map configuration file', 'error');
    } finally {
      setConfigurationTextLoading(false);
    }
  }, [addNotification]);

  useEffect(() => {
    if (lidarMap) {
      if (lidarMap.configuration_file_url) {
        fetchConfigurationText(lidarMap.configuration_file_url);
      } else {
        setConfigurationTextLoading(false);
      }
    }
  }, [lidarMap, fetchConfigurationText]);

  const onClickRejectMap = async () => {
    setSaveInProgress(true);

    try {
      await updateLidarMap(projectId, floorId, lidarMapId, {is_rejected: true});

      addNotification('Lidar map rejected successfully', 'success');

      setTimeout(() => {
        navigateToMapsNeedingReview();
      }, 2000);
    } catch {
      addNotification('Error rejecting lidar map', 'error');
    } finally {
      setSaveInProgress(false);
    }
  }

  const onClickGoToManualUpload = () => {
    if (lidarMap) {
      navigateToLidarMap(lidarMapId, {section: lidarMap.floor_section});
    }
  }

  const onClickConfigureReRun = () => {
    if (!configurationText && !!lidarMap?.processed_via) {
      const defaultText = lidarMap?.processed_via === 'slam_toolbox' ? DEFAULT_SLAM_TOOLBOX_YAML : DEFAULT_CARTOGRAPHER_ROS_LUA;

      setConfigurationText(defaultText);
    }

    setReprocessModalOpen(true)
  }

  const onConfirmIsUsable = async () => {
    setSaveInProgress(true);

    try {
      await updateLidarMap(projectId, floorId, lidarMapId, {is_latest: true, is_usable: true});

      addNotification('Lidar map confirmed successfully', 'success');

      setTimeout(() => {
        navigateToLidarMapTransform(projectId, floorId, lidarMapId);
      }, 2000);
    } catch (err) {
      addNotification('Error confirming lidar map', 'error');
      console.log('Error confirming lidar map: ', err);
    } finally {
      setSaveInProgress(false);
    }
  }

  const onSubmitForReprocessing = async () => {
    setSaveInProgress(true);

    try {
      const extension = lidarMap?.processed_via === 'slam_toolbox' ? 'yaml' : 'lua';
      const fileName = `${new Date().toISOString()}_${projectId}_${floorId}.${extension}`;
      const file = new File([configurationText], fileName, {type: 'text/plain'});
      
      await reRunLidarMap(projectId, floorId, lidarMapId, file);
      
      setReprocessModalOpen(false);
      addNotification('Bag will be re-processed during the next run of cartographer', 'success');
    } catch {
      addNotification('Error submiting for re-processing', 'error');
    } finally {
      setSaveInProgress(false);
    }
  }

  if (!lidarMap || configurationTextLoading) {
    return (
      <BlurableMainWindow>
        <LoadingIndicator />
      </BlurableMainWindow>
    )
  }

  return (
    <>
      <BlurableMainWindow
        top={134}
        blur={reprocessModalOpen}
      >
        <LidarMapContainer>
          <img
            src={lidarMap.web_image_url ?? undefined}
            alt='Map to Review'
            style={{maxHeight: '100%'}}
          />
        </LidarMapContainer>
        <ConfirmationContainer>
          {saveInProgress &&
            <LoadingIndicator/>
          }
          {(!lidarMap.is_rejected && !saveInProgress) &&
            <>
              <Button
                text="Reject"
                style={{
                  color: '#FF5252'
                }}
                onClick={onClickRejectMap}
              />
              <Button
                text="Go To Manual Upload"
                onClick={onClickGoToManualUpload}
              />
              {lidarMap.mission_bag &&
                <Button
                  text="Configure Re-Run"
                  onClick={onClickConfigureReRun}
                />
              }
              <Button
                primary
                text='Confirm Is Usable'
                onClick={onConfirmIsUsable}
              />
            </>
          }
        </ConfirmationContainer>
      </BlurableMainWindow>
      {lidarMap.mission_bag &&
        <FullScreenTextEditor
          modalStyle={{zIndex: 12}}
          modalOpen={reprocessModalOpen}
          onClose={() => setReprocessModalOpen(false)}
          text={configurationText}
          onChangeText={setConfigurationText}
          onSubmit={onSubmitForReprocessing}
        />
      }
    </>
  )
}

const LidarMapContainer = styled.div`
  display: flex;
  height: 90%;
  justify-content: center;
`;

const ConfirmationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;
  gap: 10px;
`;