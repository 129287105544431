import { useState, useMemo } from 'react';
import { useProjectContext } from "../../../../../contexts/projectContext";
import { MapPointPicker } from './MapPointPicker';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { useFetchLidarMapQuery, useFetchProjectFloorLidarMapsQuery } from '../../../../../hooks/projectQueries';
import { LidarMap, updateLidarMap } from '../../../../../api/projectFloorLidarMaps';
import { Button } from '../../../../common/Inputs/Button';
import { PanZoomProvider } from '../../../../common/PanZoom/PanZoomContext';
import { LidarTransformationConfirmation } from './LidarTransformationConfirmation';
import { useNotifications } from '../../../../../contexts/notificationProvider';
import { BlurableMainWindow, Modal } from '../../../../common/Modal/Modal';
import { useProjectNavigation } from '../../../../../hooks/useNavigation';
import { processLidarMapTransformation, Transformation } from '../../../../../api/projectFloors';

export enum TranslationPointType {
  lidar,
  floorPlan,
  siteWalk,
}

export interface TranslationPoint {
  id: string;
  x: number;
  y: number;
  type: TranslationPointType;
}

export const ProjectFloorLidarPointPicker = () => {
  const {
    addNotification,
  } = useNotifications();

  const {
    navigateToLidarMapTransform,
  } = useProjectNavigation();
  
  const {
    state: projectState,
    updateFloor,
  } = useProjectContext();

  const onFetchLidarMapsSuccess = (loadedMaps: LidarMap[]) => {
    updateFloor({
      lidarMaps: loadedMaps,
    });
  }

  useFetchProjectFloorLidarMapsQuery(onFetchLidarMapsSuccess, false);

  const {
    floor,
    projectId,
    floorId,
  } = projectState;

  const lidarMaps = floor.lidarMaps;

  const [points, setPoints] = useState<TranslationPoint[]>([]);
  const [selectedLidarMap, setSelectedLidarMap] = useState<LidarMap | undefined>();
  const [reviewMode, setReviewMode] = useState<boolean>(false);
  const [transformationFromAbs, setTransformationFromAbs] = useState<Transformation>();
  const [transformationToAbs, setTransformationToAbs] = useState<Transformation>();
  const [confirmationInProgress, setConfirmationInProgress] = useState<boolean>(false);

  const displayProcessTransformationButton = !!selectedLidarMap && !selectedLidarMap.is_rejected;

  useFetchLidarMapQuery(setSelectedLidarMap);

  const showConfirmationInterface = reviewMode && !!transformationFromAbs && !!transformationToAbs && !!selectedLidarMap;

  const lidarPoints = useMemo(() => {
    return points.filter(point => point.type === TranslationPointType.lidar);
  }, [points]);

  const floorPoints = useMemo(() => {
    return points.filter(point => point.type === TranslationPointType.floorPlan);
  }, [points]);

  const processTransformationDisabled = !selectedLidarMap || points.length < 6 || lidarPoints.length !== floorPoints.length || reviewMode;

  const onDoubleClickMap = (e: React.MouseEvent, type: TranslationPointType, x: number, y: number) => {
    const newPoint = {id: uuidv4(), x, y, type};

    setPoints(prevPoints => [...prevPoints, newPoint]);
  }

  const onMouseMoveMap = (e: React.MouseEvent, currentDraggingPointId: string | null, x: number, y: number) => {
    if (currentDraggingPointId !== null) {
      const updatedPoints = points.map(point => {
        if (point.id !== currentDraggingPointId) {
          return point;
        } else {
          return {...point, x, y};
        }
      });

      setPoints(updatedPoints);
    }
  }

  const onChangeSelectedLidarMap = (newMapId?: string) => {
    if (newMapId && lidarMaps) {
      const newLidarMap = lidarMaps.find((map: LidarMap) => map.id.toString() === newMapId);
      
      navigateToLidarMapTransform(projectId, floorId, newLidarMap.sub_id);
    }

    setPoints(prevPoints => prevPoints.filter(point => point.type !== TranslationPointType.lidar));
  }

  const onClearSelectedPoints = (selectedPoints: Set<string>) => {
    setPoints(prevPoints => prevPoints.filter(point => !selectedPoints.has(point.id)));
  }

  const onClickProcessTransformation = () => {
    processLidarMapTransformation(floorPoints, lidarPoints)
      .then(res => {
        setTransformationFromAbs(JSON.parse(res.primary_to_secondary));
        setTransformationToAbs(JSON.parse(res.secondary_to_primary));
        setReviewMode(true);
      })
      .catch(err => {
        console.log('Process Transformation Error: ', err);
        addNotification('Error Processing Transformation: ', 'error');
      });
  }

  const onConfirmTransformation = () => {
    if (selectedLidarMap) {
      const transformations = {
        transformation_from_abs: transformationFromAbs,
        transformation_to_abs: transformationToAbs,
      }

      setConfirmationInProgress(true);

      updateLidarMap(projectId, floorId, selectedLidarMap.sub_id, transformations)
        .then(updatedLidarMap => {
          setReviewMode(false);
          addNotification('Transformation saved successfully', 'success');
        })
        .catch(err => {
          console.log('Transformation Save Error: ', err);
          addNotification('Error saving transformation', 'error');
        })
        .finally(() => {
          setConfirmationInProgress(false);
        })
    }
  }

  return (
    <div>
      <BlurableMainWindow
        blur={showConfirmationInterface}
        top={133}
        right={25}
        left={25}
      >
        <MapPointPickerContainer
          float='left'
        >
          <PanZoomProvider initialScale={0.7}>
            <MapPointPicker
              minScale={0.1}
              maxScale={10}
              imageUrl={selectedLidarMap?.web_image_url}
              onDoubleClickMap={onDoubleClickMap}
              onMouseMoveMap={onMouseMoveMap}
              points={lidarPoints}
              pointType={TranslationPointType.lidar}
              selectedLidarMap={selectedLidarMap}
              setSelectedLidarMap={onChangeSelectedLidarMap}
              onClearSelectedPoints={onClearSelectedPoints}
              lidarMaps={projectState.floor.lidarMaps}
            />
          </PanZoomProvider>
        </MapPointPickerContainer>
        <MapPointPickerContainer
          float='right'
        >
          <PanZoomProvider initialScale={0.3}>
            <MapPointPicker
              minScale={0.1}
              maxScale={3}
              imageUrl={floor.latest_floor_plan?.web_image_url}
              onDoubleClickMap={onDoubleClickMap}
              onMouseMoveMap={onMouseMoveMap}
              points={floorPoints}
              pointType={TranslationPointType.floorPlan}
              onClearSelectedPoints={onClearSelectedPoints}
            />
          </PanZoomProvider>
        </MapPointPickerContainer>
        <div style={{clear: 'both'}} />
        <ButtonContainer>
          {displayProcessTransformationButton && 
            <Button
              disabled={processTransformationDisabled}
              primary
              text="Process Transformation"
              onClick={onClickProcessTransformation}
            />
          }
        </ButtonContainer>
      </BlurableMainWindow>

      <Modal
        onClose={() => setReviewMode(false)}
        open={reviewMode}
      >
        {(!!transformationFromAbs && !!transformationToAbs && !!selectedLidarMap) &&
          <PanZoomProvider initialScale={0.5}>
            <LidarTransformationConfirmation
              onReturnToPointSelection={() => setReviewMode(false)}
              onConfirmTransformation={onConfirmTransformation}
              selectedLidarMap={selectedLidarMap}
              transformationFromAbs={transformationFromAbs}
              transformationToAbs={transformationToAbs}
              confirmationInProgress={confirmationInProgress}
            />
          </PanZoomProvider>
        }
      </Modal>
    </div>
  )
}

export const MapPointPickerContainer = styled.div<{float: 'left' | 'right'}>`
  width: 50%;
  height: calc(100% - 75px);
  padding: 0 30px 15px 30px;
  overflow: hidden;
  float: ${props => props.float}
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 50px;
  padding: 0px 30px;
`;