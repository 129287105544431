import { useRef, useState } from "react";
import { useNotifications } from "../../../../contexts/notificationProvider";
import { FormContainer } from "../../../common/FormControls"
import { Button } from "../../../common/Inputs/Button"
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { StyledFileInput } from "./StyledFileInput";

interface IFileUploadProps {
  imageSourceUrl?: string;
  imageAlt?: string;
  onSaveFile: (image: File) => Promise<any>;
  hideLoadingIndicator?: boolean;
  successNotificationText?: string;
}

export const FileUpload = ({
  imageSourceUrl,
  imageAlt,
  onSaveFile,
  hideLoadingIndicator,
  successNotificationText,
}: IFileUploadProps) => {
  const {
    addNotification,
  } = useNotifications();

  const [projectImage, setProjectImage] = useState<File | null>(null);
  const [imageUploading, setImageUploading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const showUploadButton = !!fileInputRef.current?.value && !imageUploading;
  const showLoadingIndicator = imageUploading && !hideLoadingIndicator;

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setProjectImage(e.target.files[0]);
    }
  }

  const onClickUpload = async () => {
    if (projectImage) {
      setImageUploading(true);

      try {
        await onSaveFile(projectImage);
        setProjectImage(null);

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }

        const successMessage = successNotificationText ?? 'File uploaded successfully';
        addNotification(successMessage, 'success');
      } catch (err) {
        console.log('onClickUpload==>>', err);
        addNotification('Error uploading file', 'error');
      } finally {
        setImageUploading(false);
      }
    }
  }

  return (
    <FormContainer>
      { showLoadingIndicator &&
        <LoadingIndicator/>
      }

      { (!imageUploading && !!imageSourceUrl) &&
        <img
          src={imageSourceUrl}
          alt={imageAlt}
          style={{width: '500px', height: 'auto'}}
        />
      }

      <StyledFileInput
        ref={fileInputRef}
        onChange={onSelectFile}
      />

      { showUploadButton &&
        <Button
          primary
          text='Upload'
          onClick={onClickUpload}
          style={{marginBottom: '20px'}}
        />
      }
    </FormContainer>
  )
}