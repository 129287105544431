import { createRef, useCallback, useMemo, useState, useEffect } from "react";
import { drawLidarMapLines, LidarMap } from "../../../../api/projectFloorLidarMaps";
import { BlurableMainWindow } from "../../../common/Modal/Modal";
import PanZoom from "../../../common/PanZoom/PanZoom";
import { PanZoomProvider } from "../../../common/PanZoom/PanZoomContext";
import { PanZoomContainer, TransparentSVG } from "../../project-setup/project-floors/LidarPointPicker/MapPointPicker";
import { v4 as uuidv4 } from 'uuid';
import { LidarMapEditingViewSelector } from "./LidarMapEditingViewSelector";
import { MapLine } from "./MapLine";
import { useProjectContext } from "../../../../contexts/projectContext";
import { useFetchLidarMapLinesQuery, useFetchLidarMapQuery } from "../../../../hooks/projectQueries";
import { useNotifications } from "../../../../contexts/notificationProvider";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import styled from "styled-components";
import { NoGoZone } from "../../../../api/projectFloors";

interface ILidarMapReviewProps {
  initialSelectedLidarMap?: LidarMap;
}

interface Coordinates {
  x: number;
  y: number;
}

enum LidarMapLineSource {
  manual,
  noGoZone,
}

interface BaseLine {
  id: string;
  x1: number;
  y1: number;
  x2?: number;
  y2?: number;
  stroke: string;
  stroke_width: number;
  enabled: boolean;
  source: LidarMapLineSource;
}

export interface LineInProgress extends BaseLine {
  x2?: number;
  y2?: number;
}

export interface CompletedLine extends BaseLine {
  x2: number;
  y2: number;
}

export type LidarMapLine = LineInProgress | CompletedLine;

export type HandleType = 1 | 2;

export const LidarMapEditing = ({
  initialSelectedLidarMap,
}: ILidarMapReviewProps) => {
  const {
    state: projectState
  } = useProjectContext();

  const {
    projectId,
    floorId,
    lidarMapId,
  } = projectState;

  const noGoZones: NoGoZone[] = projectState.floor.no_go_zones;

  const { addNotification } = useNotifications();

  const mapContainerRef = createRef<HTMLDivElement>();
  const mapRef = createRef<HTMLImageElement>();
  const [lidarMap, setLidarMap] = useState<LidarMap | undefined>(initialSelectedLidarMap);
  const [mapImageLoaded, setMapImageLoaded] = useState<boolean>(false);
  const [mapX, setMapX] = useState<number>(0);
  const [mapY, setMapY] = useState<number>(0);
  const [lines, setLines] = useState<LidarMapLine[]>([]);
  const [lineInCreation, setLineInCreation] = useState<LineInProgress | null>(null);
  const [lineBeingDragged, setLineBeingDragged] = useState<CompletedLine | null>(null);
  const [startCoordinates, setStartCoordinates] = useState<Coordinates | null>(null);
  const [handleType, setHandleType] = useState<HandleType | null>(null);
  const [defaultStrokeWidth, setDefaultStrokeWidth] = useState<number>(5);
  const [defaultStroke, setDefaultStroke] = useState<string>('#000000');
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [mapWidth, setMapWidth] = useState<number | undefined>();
  const [mapHeight, setMapHeight] = useState<number | undefined>();
  const [showFloorOverlay, setShowFloorOverlay] = useState<boolean>(false);
  const [noGoZonesDrawn, setNoGoZonesDrawn] = useState<boolean>(false);

  const onLidarMapLinesLoaded = (loadedLines: LidarMapLine[]) => {
    setLines(prevLines => [...prevLines, ...loadedLines.map(line => ({...line, source: LidarMapLineSource.manual}))]);
  }

  const {isLoading: lidarMapLoading} = useFetchLidarMapQuery(setLidarMap);
  const {isLoading: lidarMapLinesLoading} = useFetchLidarMapLinesQuery(projectId, floorId, lidarMapId, onLidarMapLinesLoaded);

  const dataLoaded = !!lidarMap && !lidarMapLoading && !lidarMapLinesLoading;
  const displayFloorPlan = showFloorOverlay && projectState.floor.latest_floor_plan && projectState.floor.latest_floor_plan.web_image_url;

  const manuallyDrawnLines = useMemo(() => {
    return lines.filter(line => line.source === LidarMapLineSource.manual);
  }, [lines]);

  const getTransformationFromAbs = useCallback((x: number, y: number) => {
    if (!!lidarMap?.transformation_from_abs) {
      const translation = lidarMap.transformation_from_abs.translation;
      const linearTransformation = lidarMap.transformation_from_abs.linear_transformation;
      const [[a,b], [c,d]] = linearTransformation;

      const transformedX = a*x + b*y + translation[0];
      const transformedY = c*x + d*y + translation[1];

      return {
        x: transformedX,
        y: transformedY,
      }
    }

    return {
      x: null,
      y: null,
    }
  }, [lidarMap?.transformation_from_abs]);

  useEffect(() => {
    if (mapImageLoaded && !noGoZonesDrawn) {
      const activeNoGoZones = noGoZones.filter(zone => zone.is_active);

      activeNoGoZones.forEach(zone => {
        const zonePoints = zone.points.split(' ');

        const noGoZoneLines: LidarMapLine[] = [];

        for (let i=0; i<zonePoints.length; i++) {
          const startingPoint = zonePoints[i];
          const endingPoint = i+1 < zonePoints.length ? zonePoints[i+1] : zonePoints[0];

          const startingPointComponents = startingPoint.split(',');
          const endingPointComponents = endingPoint.split(',');

          const {x: x1, y: y1} = getTransformationFromAbs(parseInt(startingPointComponents[0]), parseInt(startingPointComponents[1]));
          const {x: x2, y: y2} = getTransformationFromAbs(parseInt(endingPointComponents[0]), parseInt(endingPointComponents[1]));

          if (x1 !== null && y1 !== null && x2 !== null && y2 !== null) {
            noGoZoneLines.push({
              id: uuidv4(),
              x1: x1,
              y1: y1,
              x2: x2,
              y2: y2,
              stroke: '#ff0000',
              stroke_width: 5,
              enabled: true,
              source: LidarMapLineSource.noGoZone,
            });
          }
        }

        if (noGoZoneLines.length > 0) {
          setLines(prevLines => [...prevLines, ...noGoZoneLines]);
          setNoGoZonesDrawn(true);
        }
      });
    }
  }, [
    noGoZones,
    defaultStroke,
    defaultStrokeWidth,
    mapImageLoaded,
    getTransformationFromAbs,
    noGoZonesDrawn
  ]);

  const getClickCoordinates = (event: React.MouseEvent) => {
    return {
      x: event.nativeEvent.offsetX,
      y: event.nativeEvent.offsetY,
    };
  }

  const onDoubleClickMap = (e: React.MouseEvent<SVGSVGElement>) => {
    const newLineId = uuidv4();

    const {x, y} = getClickCoordinates(e);

    const newLine = {
      id: newLineId,
      x1: x,
      y1: y,
      enabled: true,
      stroke: defaultStroke,
      stroke_width: defaultStrokeWidth,
      source: LidarMapLineSource.manual,
    }

    setLineInCreation(newLine);
    setLines(prevLines => [...prevLines, newLine]);
  }

  const updateLine = (lineToUpdate: LidarMapLine) => {
    setLines(prevLines => {
      return prevLines.map(line => {
        if (line.id === lineToUpdate.id) {
          return lineToUpdate
        } else {
          return line;
        }
      });
    });
  }

  const onDeleteLine = (lineToDelete: LidarMapLine) => {
    setLines(prevLines => {
      return prevLines.filter(line => line.id !== lineToDelete.id);
    })
  }

  const onMouseMoveMap = (e: React.MouseEvent<SVGSVGElement>) => {
    const {x, y} = getClickCoordinates(e);

    if (lineInCreation) {
      const lineFromList = lines.find(line => line.id === lineInCreation.id);

      if (lineFromList) {
        const lineToUpdate = {...lineFromList};
        lineToUpdate.x2 = x;
        lineToUpdate.y2 = y;

        updateLine(lineToUpdate);
      }
    } else if (lineBeingDragged) {
      if (startCoordinates) {        
        const lineFromList = lines.find(line => line.id === lineBeingDragged.id) as CompletedLine;

        if (lineFromList) {
          const lineToUpdate = {...lineFromList};
          
          if (handleType !== null) {
            if (handleType === 1) {
              lineToUpdate.x1 = x;
              lineToUpdate.y1 = y;
            } else if (handleType === 2) {
              lineToUpdate.x2 = x;
              lineToUpdate.y2 = y;
            }
          } else {
            const deltaX = x - startCoordinates.x;
            const deltaY = y - startCoordinates.y;
            
            lineToUpdate.x1 += deltaX;
            lineToUpdate.y1 += deltaY;
            lineToUpdate.x2 += deltaX;
            lineToUpdate.y2 += deltaY;
          }

          updateLine(lineToUpdate);
          setStartCoordinates({x, y});
        }
      }
    }
  }

  const onMouseDownMap = (e: React.MouseEvent<SVGSVGElement>) => {
    if (lineInCreation) {
      setLineInCreation(null);
    }
  }

  const onMouseUpMap = () => {
    setLineBeingDragged(null);
    setStartCoordinates(null);
    setLineInCreation(null);
    setHandleType(null);
  }

  const onMouseDownLine = useCallback((e: React.MouseEvent<SVGLineElement>, line: CompletedLine) => {
    const {x, y} = getClickCoordinates(e);

    if (!lineInCreation) {
      e.preventDefault();
      e.stopPropagation();

      setLineBeingDragged(line);
      setStartCoordinates({x, y});
    }
  }, [lineInCreation]);

  const onMouseUpLine = (e: React.MouseEvent<SVGLineElement>) => {
    setLineBeingDragged(null);
    setStartCoordinates(null);
  }

  const onMouseDownHandle = useCallback((e: React.MouseEvent<SVGLineElement>, line: CompletedLine, handleType: 1 | 2) => {
    const {x, y} = getClickCoordinates(e);

    if (!lineInCreation) {
      e.preventDefault();
      e.stopPropagation();

      setLineBeingDragged(line);
      setStartCoordinates({x, y});
      setHandleType(handleType);
    }
  }, [lineInCreation]);

  const onMouseUpHandle = () => {
    setLineBeingDragged(null);
    setStartCoordinates(null);
    setHandleType(null);
  }

  const svgLines = useMemo(() => {
    const eligibleLines = lines.filter(line => line.x2 !== undefined && line.y2 !== undefined && line.enabled) as CompletedLine[];

    return eligibleLines.map(line => {
      if (line.source === LidarMapLineSource.manual) {
        return (
          <MapLine
            key={line.id}
            line={line}
            lineInCreation={lineInCreation}
            lineBeingDragged={lineBeingDragged}
            handleType={handleType}
            onMouseUpHandle={onMouseUpHandle}
            onMouseDownHandle={onMouseDownHandle}
            onMouseUpLine={onMouseUpLine}
            onMouseDownLine={onMouseDownLine}
          />
        )
      } else {
        return (
          <MapLine
            key={line.id}
            line={line}
          />
        )
      }
    });
  }, [
    lines,
    lineInCreation,
    onMouseDownLine,
    onMouseDownHandle,
    handleType,
    lineBeingDragged,
  ]);

  const mapLineForSubmit = (line: CompletedLine) => {
    return `<line id="${line.id}" x1="${line.x1}" y1="${line.y1}" x2="${line.x2}" y2="${line.y2}" stroke="${line.stroke}" stroke-width="${line.stroke_width}" enabled="${line.enabled}"></line>`;
  }

  const onSubmitLinesForDrawing = async () => {
    if (!!lidarMap) {
      setSaveInProgress(true);

      const completedLines = manuallyDrawnLines.filter(line => line.x2 !== undefined && line.y2 !== undefined) as CompletedLine[];
      
      const stringifiedLines = completedLines.map(mapLineForSubmit);
      const stringifiedSVG = `<svg width="100%" height="100%">${stringifiedLines}</svg>`;

      const blob = new Blob([stringifiedSVG], { type: "image/svg+xml" });
      
      try {
        await drawLidarMapLines(projectId, floorId, lidarMap.sub_id, blob);

        addNotification('Lines saved successfully', 'success');
      } catch {
        addNotification('Error saving lines', 'error');
      } finally {
        setSaveInProgress(false);
      }
    }
  }

  const floorTransformation = useMemo(() => {
    if (lidarMap && lidarMap.transformation_from_abs) {
      const transformation = lidarMap.transformation_from_abs;
      const linearTransformation = transformation.linear_transformation;
      const translation = transformation.translation;
    
      return `matrix(${linearTransformation[0][0]}, ${linearTransformation[1][0]}, ${linearTransformation[0][1]}, ${linearTransformation[1][1]}, ${translation[0]}, ${translation[1]})`
    }

    return '';
  }, [lidarMap]);

  useEffect(() => {
    if (mapRef.current) {
      setMapHeight(mapRef.current.height);
      setMapWidth(mapRef.current.width)
    }
  }, [mapRef]);

  return (
    <BlurableMainWindow
      top={134}
    >
      {!dataLoaded &&
        <LoadingIndicator/>
      }

      {dataLoaded &&
        <>
          <div
            style={{
              position: 'absolute',
              right: 20,
              zIndex: 100,
            }}
          >
            <LidarMapEditingViewSelector
              lines={manuallyDrawnLines}
              updateLine={updateLine}
              onDeleteLine={onDeleteLine}
              onChangeDefaultStrokeWidth={setDefaultStrokeWidth}
              onChangeDefaultStroke={setDefaultStroke}
              onClickSubmit={onSubmitLinesForDrawing}
              saveInProgress={saveInProgress}
              lidarMap={lidarMap}
              showFloorOverlay={showFloorOverlay}
              setShowFloorOverlay={setShowFloorOverlay}
            />
          </div>

          <LidarMapEditingContainer>
            <PanZoomProvider initialScale={0.3}>
              <PanZoomContainer
                style={{
                  border: 'none'
                }}
              >
                <PanZoom
                  mapContainerRef={mapContainerRef}
                  minScale={0.1}
                  maxScale={5}
                  x={mapX}
                  updateX={setMapX}
                  y={mapY}
                  updateY={setMapY}
                >
                  {(lidarMap && lidarMap.original_web_image_url) &&
                    <>
                      <img
                        ref={mapRef}
                        alt="Lidar Map"
                        src={lidarMap.original_web_image_url}
                        style={{
                          position: 'absolute'
                        }}
                        onLoad={() => setMapImageLoaded(true)}
                      />
                      {displayFloorPlan &&
                        <img 
                          alt="floor plan"
                          src={projectState.floor.latest_floor_plan.web_image_url}
                          style={{
                            position: 'absolute',
                            transformOrigin: 'left top',
                            transform: floorTransformation,
                            opacity: 0.8
                          }}
                        />
                      }
                    </>
                  }
                  <SVGContainer
                    style={{
                      width: mapWidth,
                      height: mapHeight
                    }}
                  >
                    <TransparentSVG
                      width='100%'
                      height='100%'
                      onDoubleClick={onDoubleClickMap}
                      onMouseMove={onMouseMoveMap}
                      onMouseDown={onMouseDownMap}
                      onMouseUp={onMouseUpMap}
                      cursor={handleType !== null ? 'grabbing' : 'default'}
                    >
                      {svgLines}
                    </TransparentSVG>
                  </SVGContainer>
                </PanZoom>
              </PanZoomContainer>
            </PanZoomProvider>
          </LidarMapEditingContainer>
        </>
      }
    </BlurableMainWindow>
  )
}

const LidarMapEditingContainer = styled.div`
  width: 50%;
  height: calc(100% - 20px);
  margin: auto;
  border: 1px solid #000;
`;

const SVGContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;