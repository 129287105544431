import axios from "axios";
import { API_BASE, SERVICES_API_BASE } from "./constants";
import { PCDPoint } from "../components/views/sitewalk/ProcessSitewalkTransformation";
import { TranslationPoint } from "../components/views/project-setup/project-floors/LidarPointPicker/ProjectFloorLidarPointPicker";

export type SiteWalkStatus =
  | 'New'
  | 'Uploaded'
  | 'Incomplete'
  | 'Not Uploaded'
  | 'Awaiting Transformation'
  | 'Upload Later';

export type SiteWalkType = 'picture' | 'video';

export interface SiteWalkStartPoint {
  id: number;
  sub_id: number;
  x: string;
  y: string;
}

export interface Sitewalk {
  id: number;
  status: SiteWalkStatus;
  user: {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
  };
  duration: number;
  sub_group_id: number;
  group_name: string;
  project_floor: {
    id: number;
    floor_code: string;
  };
  project: {
    id: number;
    public_id: string;
    name: string;
  };
  video: {
    id: number;
    sub_id: number;
    number_of_parts: number;
  }
  last_modified_on: string;
  start_point: SiteWalkStartPoint | null;
}

export const fetchSitewalks = async (type?: SiteWalkType, status?: SiteWalkStatus): Promise<Sitewalk[]> => {
  const res = await axios.get(
    `${API_BASE}/site-walks`,
    {
      params: {
        type: type,
        status: status,
      }
    }
  );
  const response = await res.data;
  return response.data;
};

export const fetchSitewalk = async (sitewalkId: string | number): Promise<Sitewalk> => {
  const res = await axios.get(
    `${API_BASE}/site-walk/${sitewalkId}`,
  );
  const response = await res.data;
  return response.data;
};

export const updatePointCloud = async (sitewalkId: string | number, pointCloudSubId: string | number, data: Partial<SiteWalkPointCloud>): Promise<SiteWalkPointCloud> => {
  const res = await axios.patch(
    `${API_BASE}/site-walk/${sitewalkId}/point-cloud/${pointCloudSubId}`,
    data
  );
  const response = await res.data;
  return response.data;
}

export const mapSiteWalkPoints = <T extends {x: number; y: number;}>(point: T) => {
  return [point.x, point.y];
}

export const processSiteWalkTransformation = async (pcdPoints: PCDPoint[], floorPoints: TranslationPoint[], siteWalkId: number, pointCloudSubId: number) => {
  const res = await axios.post(
    'https://services.nexterarobotics.com/transformations/point-cloud/',
    {
      pcd_points: pcdPoints.map(mapSiteWalkPoints),
      floor_points: floorPoints.map(mapSiteWalkPoints),
      site_walk_id: siteWalkId,
      pcd_id: pointCloudSubId,
    },
    {
      responseType: 'blob',
    }
  );

  return res.data;
}

export interface SiteWalkPointCloud {
  id: number;
  sub_id: number;
  site_walk_id: number;
  point_cloud_s3_key: string;
  point_cloud_url: string;
  transformation_json: string | null;
  // part_number: number;
  part_numbers: number[];
  is_rejected: boolean;
}

export const fetchSiteWalkPointClouds = async (siteWalkId: string | number, isRejected?: boolean): Promise<SiteWalkPointCloud[]> => {
  const res = await axios.get(
    `${API_BASE}/site-walk/${siteWalkId}/point-clouds`,
    {
      params: {
        is_rejected: isRejected,
      }
    }
  );
  const response = await res.data;
  return response.data;
}

export const confirmSiteWalkTransformation = async (siteWalkId: string | number, pointCloudSubId: string | number, pcdPoints: PCDPoint[], floorPoints: TranslationPoint[]): Promise<SiteWalkPointCloud> => {
  const res = await axios.post(
    `${API_BASE}/site-walk/${siteWalkId}/point-cloud/${pointCloudSubId}/confirm-transformation`,
    {
      pcd_points: pcdPoints.map(mapSiteWalkPoints),
      floor_points: floorPoints.map(mapSiteWalkPoints),
    }
  );
  const response = await res.data;
  return response.data;
}

export interface SiteWalkTrajectory {
  pcd_sub_id: number;
  trajectories: [number, number][];
}

export const fetchSiteWalkTrajectories = async (siteWalkId: string | number): Promise<SiteWalkTrajectory[]> => {
  const res = await axios.get(
    `${SERVICES_API_BASE}/transformations/trajectories/${siteWalkId}/`
  );
  const response = await res.data;
  return response;
}