import { AutocompleteRenderOptionState, Box, Checkbox, TableCell, TextField } from "@mui/material";
import { useProjectTrackersQuery } from "../../../../hooks/progressTrackingQueries";
import { TableRow } from "../../../common/TableRow";
import { ProjectTracker, TrackerType, createProjectTracker, deleteProjectTracker } from "../../../../api/trackers";
import { HeadCell } from "../../../common/Table";
import { useCallback, useMemo, useState } from "react";
import { DropdownManagedTable, IDropdownManagedTableProps } from "../DropdownManagedTable";
import { useNotifications } from "../../../../contexts/notificationProvider";

interface IProjectTrackersTableProps {
  projectId: string;
  trackerTypes: TrackerType[];
  trackerTypesLoading: boolean;
  refetchTrackerTypes: () => void;
}

export const ProjectTrackersTable = ({
  projectId,
  trackerTypes,
  trackerTypesLoading,
  refetchTrackerTypes
}: IProjectTrackersTableProps) => {
  const { addNotification } = useNotifications();

  const [projectTrackers, setProjectTrackers] = useState<ProjectTracker[]>([]);

  const onSuccessProjectTrackers = useCallback((data: ProjectTracker[]) => {
    setProjectTrackers(data);
  }, []);

  const {isLoading: projectTrackersLoading} = useProjectTrackersQuery(projectId, onSuccessProjectTrackers);

  const columns: readonly HeadCell<any>[] = useMemo(() => [
    { id: 'id', label: 'ID' },
    { id: 'type.name', label: 'Name' },
  ], []);

  const renderVisibleRows = useCallback((row: ProjectTracker, index: number) => {
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={index}
      >
        <TableCell align="left">{row.id}</TableCell>
        <TableCell align="left">{row.type.name}</TableCell>
      </TableRow>
    );
  }, []);

  const renderAutocompleteOption = useCallback((
    props: React.HTMLAttributes<HTMLLIElement>,
    option: TrackerType,
    { selected }: AutocompleteRenderOptionState
  ) => {
    return (
      <li {...props}>
        <Box
          display="flex"
          alignItems="center"
        >
          <Checkbox checked={selected}/>
          {option.name}
        </Box>
      </li>
    );
  }, []);

  const selectedOptions = useMemo(() => {
    if (projectTrackers && trackerTypes) {
      const currentlySelectedTrackerNames = new Set(projectTrackers.map(pt => pt.type.name));

      return trackerTypes.filter(tt => currentlySelectedTrackerNames.has(tt.name));
    }

    return [];
  }, [projectTrackers, trackerTypes]);

  const autocompleteOnChange = useCallback(async (e: React.SyntheticEvent, value: TrackerType[]) => {
    const newlySelectedIds = value.map(v => v.id);
    const previouslySelectedIds = selectedOptions.map(tt => tt.id);

    const trackerTypeIdsToAdd = newlySelectedIds.filter(id => !previouslySelectedIds.includes(id));
    const trackerTypeIdsToRemove = previouslySelectedIds.filter(id => !newlySelectedIds.includes(id));

    let createdProjectTrackers: ProjectTracker[] = [];

    try {
      const createPromises = trackerTypeIdsToAdd.map(id => {
        return createProjectTracker(projectId, id);
      });

      createdProjectTrackers = await Promise.all(createPromises);

      console.log("CREATED PROJECT TRACKERS", createdProjectTrackers);

      if (createdProjectTrackers.length > 0) {
        addNotification('Project tracker created successfully', 'success');
      }
    } catch {
      addNotification('Error creating project tracker', 'error');
    }

    try {
      const deletePromises = trackerTypeIdsToRemove.map(id => {
        const tracker = projectTrackers.find(pt => pt.type.id === id) as ProjectTracker;
  
        return deleteProjectTracker(projectId, tracker.type.name);
      });
  
      await Promise.all(deletePromises);

      if (deletePromises.length > 0) {
        addNotification('Project tracker deleted successfully', 'success');
      }
    } catch {
      addNotification('Error deleting project tracker', 'error');
    }
    
    setProjectTrackers(prevTrackers => {
      return (
        prevTrackers
          .filter(pt => !trackerTypeIdsToRemove.includes(pt.type.id))
          .concat(createdProjectTrackers)
      );
    });
  }, [addNotification, projectId, projectTrackers, selectedOptions]);

  const autocompleteProps: IDropdownManagedTableProps<TrackerType>['autocompleteProps'] = useMemo(() => {
    return {
      multiple: true,
      limitTags: 1,
      value: selectedOptions,
      options: trackerTypes ?? [],
      onChange: autocompleteOnChange,
      getOptionLabel: (option) => option.name,
      renderInput: (params) => <TextField {...params} label="Trackers" />,
      renderOption: renderAutocompleteOption,
      sx: { width: 300 },
      onOpen: () => refetchTrackerTypes(),
      disableCloseOnSelect: true,
    };
  }, [autocompleteOnChange, refetchTrackerTypes, renderAutocompleteOption, selectedOptions, trackerTypes]);

  return (
    <DropdownManagedTable
      columns={columns}
      rows={projectTrackers ?? []}
      initialOrderedColumnName='id'
      renderVisibleRows={renderVisibleRows}
      loading={projectTrackersLoading || trackerTypesLoading}
      autocompleteProps={{...autocompleteProps}}
    />
  );
}