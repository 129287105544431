import styled from 'styled-components';

export const TopMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1em;
`;

export const PageTitle = styled.div`
  font-size: 24px;
  line-height: 28px;
  text-transform: capitalize;
  color: #202020;
  margin-top: 40px;
  margin-bottom: 32px;
`;

export const Select = styled.select`
  background: #ffffff;
  opacity: 0.5;
  border: 1px solid #a7aabd;
  border-radius: 2px;
  font-size: 12px;
  padding: 0 4px 0 4px;
  min-width: 112px;
  height: 30px;
`;
