import styled from 'styled-components';

interface IModalProps {
  open: boolean;
  onClose: () => void;
  width?: number;
  y?: number;
  modalStyle?: React.CSSProperties;
}

export const Modal = ({
  open,
  onClose,
  width,
  y,
  modalStyle,
  children,
}: React.PropsWithChildren<IModalProps>) => {
  return (
    <>
      <ModalBlur
        onClick={onClose}
        open={open}
      />
      {open &&
        <ModalContainer
          style={modalStyle}
          width={width}
          y={y}
        >
          <Window>
            {children}
          </Window>
        </ModalContainer>
      }
    </>
  )
}

const ModalBlur = styled.div<{open: boolean}>`
  position: absolute;
  background-color: black;
  opacity: ${props => props.open ? 0.25 : 0};
  height: ${props => props.open ? 'auto' : 0};
  transition: opacity 0.5s ease-out;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ModalContainer = styled.div<{width?: number; y?: number}>`
  position: absolute;
  top: ${props => props.y ?? 25}px;
  margin: auto;
  left: 0;
  right: 0;
  bottom: ${props => props.y ?? 25}px;
  width: ${props => props.width ?? 75}%;
  z-index: 10000;
`;

interface IBlurableMainWindowProps {
  top?: number;
  blur?: boolean;
  right?: number;
  left?: number;
  bottom?: number;
}

export const BlurableMainWindow = styled.div<IBlurableMainWindowProps>`
  position: absolute;
  top: 0px;
  padding-top: ${props => props.top ?? 47}px;
  bottom: ${props => props.bottom ?? 0}px;
  right: ${props => props.right ?? 0}px;
  left: ${props => props.left ?? 0}px;
  z-index: 0;
  ${props => props.blur ? 'filter: blur(2px)' : ''}
`;

const Window = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 20px;
`;