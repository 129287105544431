import React, { useEffect, useMemo, useReducer, useState } from "react"
import { useRouteMatch } from "react-router-dom"
import { createLocation, fetchStates, Location, Place, updateLocation } from "../../../api/locations"
import { useNotifications } from "../../../contexts/notificationProvider"
import { useCountriesQuery, useLocationQuery } from "../../../hooks/projectQueries"
import { PATH_STRINGS } from "../../../hooks/useGeneratedPaths"
import { useLocationNavigation } from "../../../hooks/useNavigation"
import { FormContainer, FormHeader, SaveButtonContainer, StyledInput, StyledLabel } from "../../common/FormControls"
import { Button } from "../../common/Inputs/Button"
import { Select } from "../../common/Inputs/Select"
import { LoadingIndicator } from "../../common/LoadingIndicator"

const locationReducer = (state: Location, payload: Partial<Location>) => {
  return {
    ...state,
    ...payload,
  }
}

const initialLocation: Location = {
  id: 0,
  city: '',
  state_code: '',
  state_name: '',
  state_id: 0,
  country_code: '',
  country_name: '',
  country_id: 0,
}

const selectStyles = { 
  margin: '0 0 20px 0',
  width: 400
}

export const LocationDetails = () => {
  const {
    addNotification,
  } = useNotifications();

  const {
    navigateToLocations
  } = useLocationNavigation();

  const [location, dispatchLocation] = useReducer(locationReducer, initialLocation);
  const { data: countries, isLoading: countriesLoading } = useCountriesQuery();

  const [states, setStates] = useState<Place[]>([]);
  const [statesLoading, setStatesLoading] = useState<boolean>(false);

  useEffect(() => {
    if (location.country_id) {
      setStatesLoading(true);

      fetchStates(location.country_id).then((returnedStates: Place[]) => {
        setStates(returnedStates);
        setStatesLoading(false);
      });
    } else {
      setStatesLoading(false);
    }
  }, [location.country_id]);

  const locationIdMatch = useRouteMatch<{locationId: string}>(PATH_STRINGS.location);
  const locationId = locationIdMatch?.params.locationId || 'new';
  const locationIsNew = locationId === 'new';

  const { isLoading: locationLoading } = useLocationQuery(locationId, dispatchLocation);

  const countryOptions = useMemo(() => {
    if (countriesLoading) {
      return [];
    }

    return countries.map((country: Place) => country.name);
  }, [countries, countriesLoading]);

  const stateOptions = useMemo(() => {
    if (statesLoading) {
      return [];
    }

    return states.map((state: Place) => state.name);
  }, [states, statesLoading]);

  if (locationLoading || countriesLoading) {
    return <LoadingIndicator/>
  }

  const saveButtonText: string = locationIsNew ? 'Create' : 'Save';

  const validForSubmit = !!location.city && !!location.city.trim();

  const onChangeCountry = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const country_name = e.target.value;
    const selectedCountry = countries.filter((country: Place) => country.name === country_name)[0];

    dispatchLocation({
      country_code: selectedCountry.code,
      country_name: selectedCountry.name,
      country_id: selectedCountry.id
    });
  }

  const onChangeState = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const state_name = e.target.value;
    const selectedState = states.filter((state: Place) => state.name === state_name)[0];

    dispatchLocation({
      state_code: selectedState.code,
      state_name: selectedState.name,
      state_id: selectedState.id
    });
  }

  const redirectToLocationsDashboard = () => {
    navigateToLocations();
  }

  const onClickCancel = () => {
    redirectToLocationsDashboard();
  }

  const onCreateNewLocation = () => {
    createLocation(location)
    .then(() => {
      redirectToLocationsDashboard();
      addNotification('Location created successfully', 'success');
    })
    .catch(() => {
      addNotification('Error creating location', 'error');
    });
  }

  const onUpdateLocation = () => {
    updateLocation(location)
    .then(() => {
      redirectToLocationsDashboard();
      addNotification('Location saved successfully', 'success');
    })
    .catch(() => {
      addNotification('Error saving location', 'error');
    });
  }

  const onClickSave = () => {
    if (locationIsNew) {
      onCreateNewLocation();
    } else {
      onUpdateLocation();
    }
  }

  return (
    <FormContainer>
      { locationIsNew &&  
        <FormHeader>
          New Location
        </FormHeader>
      }

      <StyledLabel htmlFor='name'>Name:</StyledLabel>
      <StyledInput
        id='name'
        value={location.city}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatchLocation({ city: e.target.value })}
      />

      <StyledLabel>Country:</StyledLabel>
      <Select
        options={countryOptions}
        value={location.country_name}
        onSelect={onChangeCountry}
        selectStyles={selectStyles}
        useInitialEmptyOption
      />

      <StyledLabel>State:</StyledLabel>
      <Select
        disabled={statesLoading || states.length === 0}
        options={stateOptions}
        value={location.state_name}
        onSelect={onChangeState}
        selectStyles={selectStyles}
        useInitialEmptyOption
      />

      <SaveButtonContainer>
        <Button
          text='Cancel'
          onClick={onClickCancel}
          style={{ display: 'inline-block', marginRight: '10px' }}
        />
        <Button
          primary={validForSubmit}
          disabled={!validForSubmit}
          text={saveButtonText}
          onClick={onClickSave}
          style={{ display: 'inline-block' }}
        />
      </SaveButtonContainer>
    </FormContainer>
  )
}