import {Flex} from "@react-css/flex";
import React, {useState} from "react";
import iconBack from "../../../assets/images/icons/icon_back.svg"
import iconForward from "../../../assets/images/icons/icon_forward.svg"

import {Icon} from "../Icon";

interface ISidebarProps {
    minWidth: string;
    maxWidth: string;
    children: any;
    style?: any;
    collapsible?: boolean
}
export const Sidebar = ({minWidth, maxWidth, style, children, collapsible=false}: ISidebarProps) => {
    const [collapsed, setCollapsed] = useState(false)

    return(
        <>
            {collapsed && <div style={{background: "#444", margin: "auto", float: "initial", cursor: "pointer", height: "100%"}} onClick={() => setCollapsed(false)}><Icon icon={iconBack} size={20} style={{position: "relative", top: "50%", transform: "translateY(-50%)"}}/></div>}
            <Flex flexDirection="column" style={{...style, maxWidth: maxWidth, minWidth: minWidth, display: collapsed ? "none" : "flex"}}>
                {collapsible && <div onClick={() => setCollapsed(true)} style={{cursor: "pointer", marginBottom: "5px"}}><Icon icon={iconForward} size={20}/></div>}
                {children}
            </Flex>
        </>
    )
}
