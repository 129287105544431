import axios from "axios";
import { API_BASE } from "./constants";
import { PresignedPost } from "./s3ImageUpload";

export interface STLFloorPlan {
  id: number;
  stl_floor_plan_s3_key: string;
  stl_floor_plan_s3_url: string;
  project_tracker: {
    id: number;
    project: number;
    type: {
      id: number;
      name: string;
    }
  };
  item_type: {
    id: number;
    name: string;
  };
}

export const fetchSTLFloorPlanPresignedPost = async (projectPublicId: string, floorCode: string): Promise<PresignedPost> => {
  const res = await axios.get(
    `${API_BASE}/project/${projectPublicId}/floor/${floorCode}/stl-floor-plans/presigned-post`
  );

  const response = await res.data;
  return response.data;
}

export const fetchSTLFloorPlans = async (projectPublicId: string, floorCode: string): Promise<STLFloorPlan[]> => {
  const res = await axios.get(
    `${API_BASE}/project/${projectPublicId}/floor/${floorCode}/stl-floor-plans`
  );

  const response = await res.data;
  return response.data;
}

interface STLFloorPlanCreateData {
  tracker_type: string;
  item_type: string | null | undefined;
  stl_floor_plan_s3_key: string;
}

export const createSTLFloorPlan = async (projectPublicId: string, floorCode: string, data: STLFloorPlanCreateData): Promise<STLFloorPlan> => {
  const res = await axios.post(
    `${API_BASE}/project/${projectPublicId}/floor/${floorCode}/stl-floor-plan`,
    data,
  );

  const response = await res.data;
  return response.data;
}