import { useCallback } from 'react';
import { generatePath } from 'react-router';

const projectSetupBase = '/project-setup';
const projectDetailsBase = `${projectSetupBase}/project/:projectId`;
const floorDetailsBase = `${projectDetailsBase}/floor/:floorId`;
const lidarMapDetailsBase = `${floorDetailsBase}/lidar-map/:lidarMapSubId`;
const lidarManagementBase = `/lidar`;
const progressBase = '/progress';

export const PATH_STRINGS = {
  projectsDashboard: projectSetupBase,
  project: projectDetailsBase,
  projectImage: `${projectDetailsBase}/image`,
  projectClientLogo: `${projectDetailsBase}/clientLogo`,
  projectRendering: `${projectDetailsBase}/rendering`,
  projectWhiteRendering: `${projectDetailsBase}/white-rendering`,
  projectRenderingVectors: `${projectDetailsBase}/rendering-vectors`,
  projectForgeModel: `${projectDetailsBase}/forge-model`,
  projectForgeTransformation: `${projectDetailsBase}/forge-transformation`,
  projectFloors: `${projectDetailsBase}/floors`,
  projectUsers: `${projectDetailsBase}/users`,
  floor: `${floorDetailsBase}`,
  projectFloorPlan: `${floorDetailsBase}/plan`,
  projectFloorSections: `${floorDetailsBase}/sections`,
  section: `${floorDetailsBase}/section/:sectionId`,
  projectFloorLidarMapEditing: `${lidarMapDetailsBase}/edit`,
  projectFloorLidarMaps: `${floorDetailsBase}/lidar-maps`,
  projectFloorLidarMap: `${lidarMapDetailsBase}`,
  projectSubcontractors: `${projectDetailsBase}/subcontractors`,
  projectLidarMapConfigurations: `${projectDetailsBase}/lidar-map-configurations`,
  projectLidarMapConfiguration: `${projectDetailsBase}/lidar-map-configuration/:lidarMapConfigId`,
  lidarTransformation: `${lidarMapDetailsBase}/transformation`,

  entities: '/entities',

  companies: '/companies',
  company: '/companies/company/:companyId',

  locations: '/locations',
  location: '/locations/location/:locationId',

  lidarManagement: lidarManagementBase,
  mapsNeedingTransformation: `${lidarManagementBase}/transformation`,
  mapsNeedingReview: `${lidarManagementBase}/review`,
  mapEditingDashboard: `${lidarManagementBase}/editing`,
  lidarReview: `${lidarMapDetailsBase}/review`,

  sitewalk: '/sitewalk',
  sitewalkTranslation: '/sitewalk/:sitewalkId/translation',

  stairClimberScripts: `/stairs`,
  stairClimberScript: `/stairs/script/:scriptId`,

  userManagementDashboard: `/user-management`,
  manageUser: `/user-management/user/:userId`,

  progress: progressBase,
  progressTrackers: `${progressBase}/trackers`,
  progressItems: `${progressBase}/items`,
  progressJobs: `${progressBase}/jobs`,
  progressRegions: `${progressBase}/regions`,

  instances: '/instances',
};

/**
 * Generates path strings following a defined pattern with passed in args
 */
export const useGeneratedPaths = () => {
  const generateProjectPath = useCallback((projectId: string) =>
    generatePath(PATH_STRINGS.project, {
      projectId: projectId,
    }), []);

  const generateProjectImagePath = useCallback((projectId: string) =>
    generatePath(PATH_STRINGS.projectImage, {
      projectId: projectId,
    }), []);

  const generateProjectClientLogoPath = useCallback((projectId: string) =>
    generatePath(PATH_STRINGS.projectClientLogo, {
      projectId: projectId,
    }), []);

  const generateProjectRenderingPath = useCallback((projectId: string) =>
    generatePath(PATH_STRINGS.projectRendering, {
      projectId: projectId,
    }), []);

  const generateProjectWhiteRenderingPath = useCallback((projectId: string) =>
    generatePath(PATH_STRINGS.projectWhiteRendering, {
      projectId: projectId,
    }), []);

  const generateProjectRenderingVectorsPath = useCallback((projectId: string) =>
    generatePath(PATH_STRINGS.projectRenderingVectors, {
      projectId: projectId,
    }), []);

  const generateProjectForgeModelPath = useCallback((projectId: string) =>
    generatePath(PATH_STRINGS.projectForgeModel, {
      projectId: projectId,
    }), []);

  const generateProjectForgeTransformationPath = useCallback((projectId: string) =>
    generatePath(PATH_STRINGS.projectForgeTransformation, {
      projectId: projectId,
    }), []);

  const generateProjectFloorsPath = useCallback((projectId: string) =>
    generatePath(PATH_STRINGS.projectFloors, {
      projectId: projectId,
    }), []);
  
  const generateProjectUsersPath = useCallback((projectId: string) =>
    generatePath(PATH_STRINGS.projectUsers, {
      projectId: projectId,
    }), []);
  
  const generateFloorPath = useCallback((projectId: string, floorId: string) =>
    generatePath(PATH_STRINGS.floor, {
      projectId: projectId,
      floorId: floorId,
    }), []);
  
  const generateProjectFloorPlanPath = useCallback((projectId: string, floorId: string) =>
    generatePath(PATH_STRINGS.projectFloorPlan, {
      projectId: projectId,
      floorId: floorId,
    }), []);

  const generateProjectFloorSectionsPath = useCallback((projectId: string, floorId: string) =>
    generatePath(PATH_STRINGS.projectFloorSections, {
      projectId: projectId,
      floorId: floorId,
    }), []);
  
  const generateProjectFloorSectionPath = useCallback((projectId: string, floorId: string, sectionId: string) =>
    generatePath(PATH_STRINGS.section, {
      projectId: projectId,
      floorId: floorId,
      sectionId: sectionId
    }), []);

  const generateProjectFloorLidarMapsPath = useCallback((projectId: string, floorId: string) =>
    generatePath(PATH_STRINGS.projectFloorLidarMaps, {
      projectId: projectId,
      floorId: floorId,
    }), []);

  const generateProjectFloorLidarMapUploadPath = useCallback((projectId: string, floorId: string, lidarMapSubId: string | number) =>
    generatePath(PATH_STRINGS.projectFloorLidarMap, {
      projectId: projectId,
      floorId: floorId,
      lidarMapSubId: lidarMapSubId,
    }), []);

  const generateProjectSubcontractorsPath = useCallback((projectId: string) =>
    generatePath(PATH_STRINGS.projectSubcontractors, {
      projectId: projectId,
    }), []);

  const generateCompanyPath = useCallback((companyId: string) =>
    generatePath(PATH_STRINGS.company, {
      companyId: companyId,
    }), []);

  const generateLocationPath = useCallback((locationId: string) =>
    generatePath(PATH_STRINGS.location, {
      locationId: locationId,
    }), []);

  const generateLidarTransformationPath = useCallback((projectId: string, floorId: string, lidarMapSubId: string | number) =>
    generatePath(PATH_STRINGS.lidarTransformation, {
      projectId: projectId,
      floorId: floorId,
      lidarMapSubId: lidarMapSubId,
    }), []);

  const generateLidarReviewPath = useCallback((projectId: string, floorId: string, lidarMapSubId: string) =>
    generatePath(PATH_STRINGS.lidarReview, {
      projectId: projectId,
      floorId: floorId,
      lidarMapSubId: lidarMapSubId,
    }), []);
  
  const generateLidarEditingPath = useCallback((projectId: string, floorId: string, lidarMapSubId: string | number) => 
    generatePath(PATH_STRINGS.projectFloorLidarMapEditing, {
      projectId: projectId,
      floorId: floorId,
      lidarMapSubId: lidarMapSubId,
    }), []);

  const generateProcessSitewalkTransformationPath = useCallback((sitewalkId: string | number) =>
    generatePath(PATH_STRINGS.sitewalkTranslation, {
      sitewalkId: sitewalkId,
    }), []);

  const generateStairClimberScriptPath = useCallback((scriptId: string | number) =>
    generatePath(PATH_STRINGS.stairClimberScript, {
      scriptId: scriptId.toString(),
    }), []);

  const generateProjectLidarMapConfigurationsPath = useCallback((projectId: string) =>
    generatePath(PATH_STRINGS.projectLidarMapConfigurations, {
      projectId: projectId,
    }), []);

  const generateProjectLidarMapConfigurationPath = useCallback((projectId: string, lidarMapConfigId: string | number) =>
    generatePath(PATH_STRINGS.projectLidarMapConfiguration, {
      projectId: projectId,
      lidarMapConfigId: lidarMapConfigId
    }), []);

  const generateProgressTrackingTrackersPath = useCallback((projectId?: string | null) => {
    const params = new URLSearchParams({
      ...(projectId && { project: projectId }),
    });

    return `${PATH_STRINGS.progressTrackers}${params ? `?${params.toString()}` : ''}`;
  }, []);

  const generateProgressTrackingJobsPath = useCallback((projectId?: string) => {
    const params = new URLSearchParams({
      ...(projectId && { project: projectId }),
    });

    return `${PATH_STRINGS.progressJobs}${params ? `?${params.toString()}` : ''}`;
  }, []);

  const generateProgressTrackingItemsPath = useCallback((projectId?: string | null, floorCode?: string | null, trackerName?: string | null, itemTypeId?: string | number | null, search?: string | null) => {
    const params = new URLSearchParams({
      ...(projectId && { project: projectId }),
      ...(floorCode && { floor: floorCode }),
      ...(trackerName && { tracker: trackerName }),
      ...(itemTypeId && { itemType: itemTypeId.toString() }),
      ...(search && { search: search }),
    });

    return `${PATH_STRINGS.progressItems}${params ? `?${params.toString()}` : ''}`;
  }, []);

  const generateProgressTrackingRegionsPath = useCallback((projectId?: string | null, floorCode?: string | null) => {
    const params = new URLSearchParams({
      ...(projectId && { project: projectId }),
      ...(floorCode && { floor: floorCode }),
    });

    return `${PATH_STRINGS.progressRegions}${params ? `?${params.toString()}` : ''}`;
  }, []);
  
  return {
    generateProjectPath,
    generateProjectImagePath,
    generateProjectClientLogoPath,
    generateProjectRenderingPath,
    generateProjectWhiteRenderingPath,
    generateProjectRenderingVectorsPath,
    generateProjectForgeModelPath,
    generateProjectForgeTransformationPath,
    generateProjectFloorsPath,
    generateProjectUsersPath,
    generateFloorPath,
    generateProjectFloorPlanPath,
    generateProjectFloorSectionsPath,
    generateProjectFloorSectionPath,
    generateProjectSubcontractorsPath,
    generateProjectFloorLidarMapsPath,
    generateProjectFloorLidarMapUploadPath,
    generateCompanyPath,
    generateLocationPath,
    generateLidarTransformationPath,
    generateLidarReviewPath,
    generateLidarEditingPath,
    generateProcessSitewalkTransformationPath,
    generateStairClimberScriptPath,
    generateProjectLidarMapConfigurationsPath,
    generateProjectLidarMapConfigurationPath,
    generateProgressTrackingTrackersPath,
    generateProgressTrackingJobsPath,
    generateProgressTrackingItemsPath,
    generateProgressTrackingRegionsPath,
  };
};
