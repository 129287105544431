import { useMemo } from "react";
import { Column } from "react-table";
import { useCompaniesQuery } from "../../../hooks/projectQueries";
import { Table } from "../../common/Table/Table";
import styled from "styled-components"
import { Button } from "../../common/Inputs/Button";
import { Company } from "../../../api/companies";
import { LoadingIndicator } from "../../common/LoadingIndicator";
import { useCompanyNavigation } from "../../../hooks/useNavigation";

export const ManageCompanies = () => {
  const {
    navigateToCompany,
  } = useCompanyNavigation();

  const { data: companies, isLoading: companiesLoading } = useCompaniesQuery();

  const data = useMemo(() => {
    if (companiesLoading) {
      return [];
    }

    return companies;
  }, [companiesLoading, companies]);

  const columns = useMemo((): Column[] => {
    return [
      { Header: 'Name', accessor: 'name' }
    ]
  }, []);

  if (companiesLoading) {
    return <LoadingIndicator/>
  }

  const onClickAddNewCompany = () => {
    navigateToCompany('new');
  }

  const onClickRow = (row: Company) => {
    navigateToCompany(row.public_id);
  }

  return (
    <CompanyDashboardContainer>
      <AddNewCompanyButtonContainer>
        <Button
          primary
          text='Add New Company'
          onClick={onClickAddNewCompany}
        />
      </AddNewCompanyButtonContainer>
      <Table
        data={data}
        columns={columns}
        onClickRow={onClickRow}
      />
    </CompanyDashboardContainer>
  )
}

const CompanyDashboardContainer = styled.div`
  margin-top: 1em;
`;

const AddNewCompanyButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 20px 20px 0;
`;