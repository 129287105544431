import axios from "axios";

export const fetchLocations = async () => {
  const res = await axios.get(
    `https://api.nexterarobotics.com/api/locations`
  )
  const response = await res.data;
  return response.data;
}

export const fetchLocation = async (locationId: string) => {
  const res = await axios.get(
    `https://api.nexterarobotics.com/api/location/${locationId}`
  )
  const response = await res.data;
  return response.data;
}

export const createLocation = async (locationData: Location) => {
  const res = await axios.post(
    `https://api.nexterarobotics.com/api/location`,
    locationData
  );
  const response = await res.data;
  return response.data;
}

export const updateLocation = async (locationData: Location) => {
  const res = await axios.patch(
    `https://api.nexterarobotics.com/api/location/${locationData.id}`,
    {
      city: locationData.city,
      state_id: locationData.state_id,
      country_id: locationData.country_id,
    }
  );
  const response = await res.data;
  return response.data;
}

export const fetchCountries = async () => {
  const res = await axios.get(
    `https://api.nexterarobotics.com/api/countries`
  )
  const response = await res.data;
  return response.data;
}

export const fetchStates = async (countryId: number) => {
  const res = await axios.get(
    `https://api.nexterarobotics.com/api/country/${countryId}/states`
  )
  const response = await res.data;
  return response.data;
}

export interface Place {
  id: number;
  name: string;
  code: string;
}

export interface Location {
  id: number;
  city: string;
  state_code: string;
  state_name: string;
  state_id: number;
  country_code: string;
  country_name: string;
  country_id: number;
}