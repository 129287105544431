import { Box, Button, CircularProgress } from "@mui/material";
import { ProjectFloorsAutoComplete, ProjectsAutocomplete } from "../../../../../common/Dropdowns";
import { ProjectFloor } from "../../../../../../api/projectFloors";
import { useCallback, useMemo, useState } from "react";
import { useProgressTrackingNavigation } from "../../../../../../hooks/useNavigation";
import { useProgressContext } from "../../../../../../contexts/progressContext";
import { EditableProgressRegion, useProgressRegionsContext } from "../../../../../../contexts/progressRegionsContext";
import { useNotifications } from "../../../../../../contexts/notificationProvider";
import { getRandomHexColor } from "../../../../../../api/utils";
import { ProgressRegion } from "../../../../../../api/viewpoints";
import { createProgressRegion, deleteProgressRegions, updateProgressRegion } from "../../../../../../api/progressRegions";

export const ProgressRegionsEditorHeader = () => {
  const {
    addNotification,
  } = useNotifications();

  const {
    navigateToProgressTrackingRegions
  } = useProgressTrackingNavigation();

  const {
    state: progressState,
  } = useProgressContext();

  const {
    projectId,
    floor,
  } = progressState;

  const {
    regionEdits,
    setRegions,
    setRegionEdits,
  } = useProgressRegionsContext();

  const [projectsLoading, setProjectsLoading] = useState<boolean>(false);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

  const regionEditingInProgress = useMemo(() => {
    return !!regionEdits && Object.getOwnPropertyNames(regionEdits).length > 0;
  }, [regionEdits]);

  const onChangeProjectFloor = useCallback((incomingFloor: ProjectFloor | null) => {
    navigateToProgressTrackingRegions(projectId, incomingFloor?.floor_code);
  }, [navigateToProgressTrackingRegions, projectId]);

  const onClickRevert = useCallback(() => {
    setRegionEdits({});
    setRegions(prevRegions => prevRegions.filter(region => region.id > 0));
  }, [setRegionEdits, setRegions]);

  const handleRegionsToUpdate = useCallback(async (regions: EditableProgressRegion[]) => {
    if (!!projectId) {
      const regionSavePromises: Promise<ProgressRegion>[] = regions.map(region => {
        return updateProgressRegion(projectId, region.id, {position: region.position});
      });

      return await Promise.all(regionSavePromises);
    }

    return [];
  }, [projectId]);

  const handleRegionsToCreate = useCallback(async (regions: EditableProgressRegion[]) => {
    if (!!projectId) {
      const regionSavePromises: Promise<ProgressRegion>[] = regions.map(region => {
        let regionData = {
          viewpoint: region.viewpoint,
          position: region.position,
        };

        return createProgressRegion(projectId, regionData);
      });

      return await Promise.all(regionSavePromises);
    }

    return [];
  }, [projectId]);

  const onClickSave = useCallback(async () => {
    if (!!projectId) {
      try {
        setSaveInProgress(true);
        const regionsToUpdate = Object.values(regionEdits);

        const regionsToCreate = regionsToUpdate.filter(region => region.id < 0);
        const regionsToSave = regionsToUpdate.filter(region => region.id > 0 && region.position.length > 0);
        const regionsToDelete = regionsToUpdate.filter(region => region.position.length === 0);

        const createdRegions = await handleRegionsToCreate(regionsToCreate);

        const updatedRegions = await handleRegionsToUpdate(regionsToSave);
        const updatedRegionsMap = new Map<number, ProgressRegion>();

        updatedRegions.forEach(region => {
          updatedRegionsMap.set(region.id, region);
        });

        const regionIdsToDelete = regionsToDelete.map(region => region.id);

        if (regionIdsToDelete.length > 0) {
          await deleteProgressRegions(projectId, regionIdsToDelete);
        }
        
        const deletedRegionIds = new Set(regionIdsToDelete);

        setRegions(prevRegions => {
          let updatedRegions = [...prevRegions];
          updatedRegions = updatedRegions.filter(region => region.id > 0);
          updatedRegions = updatedRegions.filter(region => !deletedRegionIds.has(region.id));
          updatedRegions = updatedRegions.concat(createdRegions.map(region => ({...region, color: getRandomHexColor()})));

          return updatedRegions.map(region => {
            const saveRegion = updatedRegionsMap.get(region.id);

            if (saveRegion) {
              return {
                ...saveRegion,
                color: getRandomHexColor(),
              };
            }

            return region;
          });
        });

        setRegionEdits({});

        addNotification('Regions Updated Successfully', 'success');
      } catch {
        addNotification('Error updating regions', 'error');
      } finally {
        setSaveInProgress(false);
      }
    }
  }, [addNotification, handleRegionsToCreate, handleRegionsToUpdate, projectId, regionEdits, setRegionEdits, setRegions]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        alignItems="center"
        gap="10px"
      >
        <ProjectsAutocomplete
          label="Project"
          disabled={regionEditingInProgress}
          selectedProjectId={projectId}
          setSelectedProjectId={(newValue: string | null) => navigateToProgressTrackingRegions(newValue)}
          setProjectsLoading={setProjectsLoading}
          width={300}
        />
        <ProjectFloorsAutoComplete
          projectId={projectId}
          disabled={regionEditingInProgress}
          selectedProjectFloor={floor}
          setSelectedProjectFloor={onChangeProjectFloor}
          loading={projectsLoading}
          width={200}
        />
      </Box>
      {Object.getOwnPropertyNames(regionEdits).length > 0 && (
        <>
          {!saveInProgress &&
            <Box
              display="flex"
              alignItems="center"
              gap="10px"
            >
              <Button
                variant="outlined"
                onClick={onClickRevert}
              >
                Revert
              </Button>
              <Button
                variant="contained"
                onClick={onClickSave}
              >
                Save
              </Button>
            </Box>
          }
          {saveInProgress &&
            <CircularProgress/>
          }
        </>
      )}
    </Box>
  );
}