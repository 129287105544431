import { useReducer, createContext, useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { useProgressContext } from './progressContext';
import { Item } from '../api/items';
import { useFetchSTLFloorPlansQuery, useItemsQuery } from '../hooks/progressTrackingQueries';
import { ViewpointsPoint } from '../api/viewpoints';
import { getRandomHexColor } from '../api/utils';
import { STLFloorPlan } from '../api/stlFloorPlans';

const oldProjectIds = ["myFE3eLIhA", "xVrtPeVag", "Cdfrk7G", "nNkkv7gmQ", "rVHF8Zfxz", "2MEQPeZxFv", "3FD6QNa"];

export interface EditableItem extends Item {
  color: string;
}

interface ProgressItemsState {
  renderItemsAsPath: boolean;
  currentlyCreatingItemId: number | null;
  currentlyEditingItemId: number | null;
  currentlyEditingCoodinateIndex: number | null;
  dragStartX: number | null;
  dragStartY: number | null;
  selectedPoint: ViewpointsPoint | null;
  showSTLFloorPlan: boolean;
}

const initialState: ProgressItemsState = {
  renderItemsAsPath: false,
  currentlyCreatingItemId: null,
  currentlyEditingItemId: null,
  currentlyEditingCoodinateIndex: null,
  dragStartX: null,
  dragStartY: null,
  selectedPoint: null,
  showSTLFloorPlan: false,
};

interface IProgressItemsContext {
  state: ProgressItemsState,
  dispatch: React.Dispatch<ProgressAction>;
  setItems: React.Dispatch<React.SetStateAction<EditableItem[]>>;
  itemsLoading: boolean;
  itemEdits: Record<number, EditableItem>;
  setItemEdits: React.Dispatch<React.SetStateAction<Record<number, EditableItem>>>;
  touched: Record<number, number>;
  itemsWithEdits: EditableItem[];
  onUpdateItem: (updatedItem: EditableItem) => void;
  onUpdateTouched: (touchedItemId: number) => void;
  stlFloorPlans: STLFloorPlan[];
  setSTLFloorPlans: React.Dispatch<React.SetStateAction<STLFloorPlan[]>>;
  selectedStlFloorPlan: STLFloorPlan | undefined;
}

const initialProgressItemsContext: IProgressItemsContext = {
  state: {...initialState},
  dispatch: () => {},
  setItems: () => {},
  itemsLoading: false,
  itemEdits: {},
  setItemEdits: () => {},
  touched: {},
  itemsWithEdits: [],
  onUpdateItem: () => {},
  onUpdateTouched: () => {},
  stlFloorPlans: [],
  setSTLFloorPlans: () => {},
  selectedStlFloorPlan: undefined,
}

const UPDATE_STATE = 'UPDATE_STATE';

type ProgressAction = {
  type: typeof UPDATE_STATE,
  payload: Partial<ProgressItemsState>,
}

const ProgressItemsContext = createContext<IProgressItemsContext>({...initialProgressItemsContext});

const progressReducer = (state: ProgressItemsState, action: ProgressAction) => {
  switch (action.type) {
    case UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      }
  }
}

export const ProgressItemsProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(progressReducer, {...initialState});

  const {
    state: progressState
  } = useProgressContext();

  const {
    projectId,
    floorId,
    trackerName,
    itemTypeId,
  } = progressState;

  const [items, setItems] = useState<EditableItem[]>([]);
  const [itemEdits, setItemEdits] = useState<Record<number, EditableItem>>({});
  const [stlFloorPlans, setSTLFloorPlans] = useState<STLFloorPlan[]>([]);
  const [touched, setTouched] = useState<Record<number, number>>({});

  const itemsOnSuccess = (data: Item[]) => {
    const itemsWithColors = data.map(item => ({
      ...item,
      color: getRandomHexColor(),
    }));

    setItems(itemsWithColors);
  }

  const {isLoading: itemsLoading} = useItemsQuery(projectId, trackerName, {floor: floorId ?? ''}, itemsOnSuccess);

  const stlFloorPlansOnSuccess = (data: STLFloorPlan[]) => {
    setSTLFloorPlans(data);
  }

  useFetchSTLFloorPlansQuery(projectId ?? '', floorId ?? '', stlFloorPlansOnSuccess);

  useEffect(() => {
    dispatch({
      type: UPDATE_STATE,
      payload: {
        currentlyCreatingItemId: null,
        currentlyEditingItemId: null,
        currentlyEditingCoodinateIndex: null,
        dragStartX: null,
        dragStartY: null,
        selectedPoint: null,
      }
    })
  }, [projectId, floorId]);

  useEffect(() => {
    let renderAsPath = trackerName?.toLowerCase() === 'walls' || trackerName?.toLowerCase() === 'guardrails' || (trackerName?.toLowerCase() === 'mechanical' && oldProjectIds.includes(projectId ?? ''));

    dispatch({
      type: UPDATE_STATE,
      payload: {
        renderItemsAsPath: renderAsPath,
      }
    });
  }, [projectId, trackerName]);

  const onUpdateItem = useCallback((updatedItem: EditableItem) => {
    setItemEdits(prevItemEdits => {
      return {
        ...prevItemEdits,
        [updatedItem.id]: updatedItem,
      };
    });
  }, []);

  const onUpdateTouched = useCallback((itemId: number) => {
    setTouched(prevTouched => {
      return {
        ...prevTouched,
        [itemId]: Date.now(),
      }
    })
  }, []);

  const itemsWithEdits = useMemo(() => {
    return items.map(item => {
      if (itemEdits[item.id]) {
        return itemEdits[item.id];
      } else {
        return item;
      }
    });
  }, [items, itemEdits]);

  const selectedStlFloorPlan = useMemo(() => {
    return stlFloorPlans.find(plan => plan.project_tracker.type.name === trackerName && plan.item_type.id === itemTypeId)
  }, [itemTypeId, stlFloorPlans, trackerName]);

  return (
    <ProgressItemsContext.Provider
      value={{
        state,
        dispatch,
        setItems,
        itemsLoading,
        itemEdits,
        setItemEdits,
        itemsWithEdits,
        touched,
        onUpdateItem,
        onUpdateTouched,
        stlFloorPlans,
        setSTLFloorPlans,
        selectedStlFloorPlan,
      }}
    >
      {children}
    </ProgressItemsContext.Provider>
  )
}

export const useProgressItemsContext = () => {
  return useContext(ProgressItemsContext);
};