import { useCallback, useMemo, useState } from "react";
import { JobType } from "../../../../api/jobs";
import { CreateEditTable, TrackerTableEditingMode } from "../CreateEditTable";
import { HeadCell } from "../../../common/Table";
import { TableRow } from "../../../common/TableRow";
import { TableCell, TextField, Typography } from "@mui/material";

const initialNewJobType: JobType = {
  id: -1,
  name: '',
  slug: '',
  units: '',
  display_color: '',
  secondary_display_color: null,
  tertiary_display_color: null,
  display_order: 0,
  display_shape: '',
  item_type: 0,
}

interface IJobTypesTableProps {
  jobTypes: JobType[];
  jobTypesLoading: boolean;
}

export const JobTypesTable = ({
  jobTypes,
  jobTypesLoading,
}: IJobTypesTableProps) => {
  const [editingMode, setEditingMode] = useState<TrackerTableEditingMode>(TrackerTableEditingMode.none);
  const [edits, setEdits] = useState<Record<number, Partial<JobType>>>({});
  const [colorChangeTimeout, setColorChangeTimeout] = useState<NodeJS.Timeout>();

  const inCreateMode = editingMode === TrackerTableEditingMode.create;
  const inUpdateMode = editingMode === TrackerTableEditingMode.update;
  const readOnly = editingMode === TrackerTableEditingMode.none;

  const columns: readonly HeadCell<any>[] = useMemo(() => [
    { id: 'id', label: 'ID' },
    { id: 'name', label: 'Name' },
    ...readOnly ? [{ id: 'slug', label: 'Slug' }] : [],
    { id: 'units', label: 'Units' },
    { id: 'display_color', label: 'Display Color' },
    { id: 'secondary_display_color', label: 'Secondary Display Color' },
    { id: 'tertiary_display_color', label: 'Tertiary Display Color' },
    { id: 'display_order', label: 'Display Order' },
    { id: 'display_shape', label: 'Display Shape' },
  ], [readOnly]);

  const onChangeColorField = useCallback((rowId: number, field: 'display_color' | 'secondary_display_color' | 'tertiary_display_color', value: string) => {
    if (colorChangeTimeout) {
      clearTimeout(colorChangeTimeout);
    }

    setColorChangeTimeout(
      setTimeout(() => {
        setEdits(prevEdits => ({...prevEdits, [rowId]: {...prevEdits[rowId], [field]: value}}));
      }, 300)
    );
  }, [colorChangeTimeout]);

  const renderVisibleRows = useCallback((row: JobType, index: number) => {
    const isNewItem = row.id === -1;
    const fieldCanBeEdited = (isNewItem && inCreateMode) || (!isNewItem && inUpdateMode);
    const itemToDisplay = {...row, ...edits[row.id]};
    const {
      id,
      name,
      slug,
      units,
      display_color,
      secondary_display_color,
      tertiary_display_color,
      display_order,
      display_shape,
    } = itemToDisplay;

    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={index}
      >
        <TableCell align="left">
          {(!readOnly && isNewItem) &&
            <Typography fontWeight={700}>New</Typography>
          }
          {(readOnly || !isNewItem) &&
            <Typography>{id}</Typography>
          }
        </TableCell>

        <TableCell>
          {fieldCanBeEdited &&
            <TextField
              value={name}
              onChange={(e) => {setEdits(prevEdits => ({...prevEdits, [row.id]: {name: e.target.value}}))}}
              sx={{
                width: '100%'
              }}
            />
          }
          {!fieldCanBeEdited &&
            <>{name}</>
          }
        </TableCell>
        {readOnly &&
          <TableCell>{slug}</TableCell>
        }
       
        <TableCell>
          {fieldCanBeEdited &&
            <TextField
              value={units}
              onChange={(e) => {setEdits(prevEdits => ({...prevEdits, [row.id]: {units: e.target.value}}))}}
              sx={{
                width: '100%'
              }}
            />
          }
          {!fieldCanBeEdited &&
            <>{units}</>
          }
        </TableCell>
        <TableCell>
          {fieldCanBeEdited &&
            <TextField
              type="color"
              value={display_color}
              onChange={(e) => {onChangeColorField(row.id, 'display_color', e.target.value)}}
              sx={{
                width: '100%'
              }}
            />
          }
          {!fieldCanBeEdited &&
            <>{display_color}</>
          }
        </TableCell>
        <TableCell>
          {fieldCanBeEdited &&
            <TextField
              type="color"
              value={secondary_display_color ?? '#ffffff'}
              onChange={(e) => {onChangeColorField(row.id, 'secondary_display_color', e.target.value)}}
              sx={{
                width: '100%'
              }}
            />
          }
          {!fieldCanBeEdited &&
            <>{secondary_display_color}</>
          }
        </TableCell>
        <TableCell>
          {fieldCanBeEdited &&
            <TextField
              type="color"
              value={tertiary_display_color ?? '#ffffff'}
              onChange={(e) => {onChangeColorField(row.id, 'tertiary_display_color', e.target.value)}}
              sx={{
                width: '100%'
              }}
            />
          }
          {!fieldCanBeEdited &&
            <>{tertiary_display_color}</>
          }
        </TableCell>
        <TableCell>
          {fieldCanBeEdited &&
            <TextField
              type="number"
              value={display_order}
              onChange={(e) => {setEdits(prevEdits => ({...prevEdits, [row.id]: {display_order: parseInt(e.target.value)}}))}}
              sx={{
                width: '100%'
              }}
            />
          }
          {!fieldCanBeEdited &&
            <>{display_order}</>
          }
        </TableCell>
        <TableCell>
          {fieldCanBeEdited &&
            <TextField
              value={display_shape}
              onChange={(e) => {setEdits(prevEdits => ({...prevEdits, [row.id]: {display_shape: e.target.value}}))}}
              sx={{
                width: '100%'
              }}
            />
          }
          {!fieldCanBeEdited &&
            <>{display_shape}</>
          }
        </TableCell>
      </TableRow>
    );
  }, [edits, inCreateMode, inUpdateMode, onChangeColorField, readOnly]);

  const clearEdits = useCallback(() => {
    setEdits({});
  }, []);

  const onCreateItem = useCallback(async (createdItem: JobType) => {
    //return await createTrackerType(createdItem);

    return {...createdItem}
  }, []);

  const onEditItem = useCallback(async (id: number, editedItem: Partial<JobType>) => {
    //return await updateTrackerType(id, editedItem);

    return {...editedItem} as JobType;
  }, []);

  const isValidForSubmit = useCallback((tableData: JobType[]) => {
    const fieldsValid = tableData.every(row => {
      return (
        !!row.name
      )
    });

    const displayOrderSet = new Set(tableData.filter(row => row.display_order >= 0).map(row => row.display_order));
    const nameSet = new Set(tableData.map(row => row.name));

    const displayOrdersUnique = displayOrderSet.size === tableData.length;
    const namesUnique = nameSet.size === tableData.length;

    return fieldsValid && displayOrdersUnique && namesUnique;
  }, []);

  return (
    <CreateEditTable
      columns={columns}
      rows={jobTypes ?? []}
      initialOrderedColumnName='id'
      renderVisibleRows={renderVisibleRows}
      loading={jobTypesLoading}
      onCreateItem={onCreateItem}
      onEditItem={onEditItem}
      initialNewItem={{...initialNewJobType}}
      isValidForSubmit={isValidForSubmit}
      editingMode={editingMode}
      setEditingMode={setEditingMode}
      edits={edits}
      clearEdits={clearEdits}
      // customComparator={trackerTypesComparator}
      readModeCreateButtonText="Create Job Type"
      createModeCreateButtonText="Save Job Type"
      readModeEditButtonText="Edit Job Types"
      editModeEditButtonText="Save Job Types"
    />
  );
}