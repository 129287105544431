import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { PATH_STRINGS, useGeneratedPaths } from './useGeneratedPaths';
import { useProjectContext } from '../contexts/projectContext';

export const useProjectNavigation = () => {
  const {
    generateProjectPath,
    generateProjectFloorsPath,
    generateFloorPath,
    generateProjectFloorSectionPath,
    generateProjectFloorSectionsPath,
    generateProjectFloorLidarMapsPath,
    generateProjectFloorLidarMapUploadPath,
    generateLidarTransformationPath,
    generateLidarReviewPath,
    generateLidarEditingPath,
    generateProjectLidarMapConfigurationPath,
  } = useGeneratedPaths();

  const {
    state: projectState,
  } = useProjectContext();

  const {
    projectId,
    floorId,
  } = projectState;

  const history = useHistory();

  const navigateToProjectsDashboard = useCallback(
    () => history.push(PATH_STRINGS.projectsDashboard),
    [history]
  );

  const navigateToProject = useCallback(
    (projectId: string) => history.push(generateProjectPath(projectId)),
    [history, generateProjectPath]
  );

  const navigateToProjectFloors = useCallback(
    () => history.push(generateProjectFloorsPath(projectId)),
    [history, projectId, generateProjectFloorsPath]
  );

  const navigateToFloor = useCallback(
    (floorId: string) => history.push(generateFloorPath(projectId, floorId)),
    [history, projectId, generateFloorPath]
  );

  const navigateToProjectSections = useCallback(
    () => history.push(generateProjectFloorSectionsPath(projectId, floorId)),
    [history, projectId, floorId, generateProjectFloorSectionsPath]
  );

  const navigateToSection = useCallback(
    (sectionId: string) => history.push(generateProjectFloorSectionPath(projectId, floorId, sectionId)),
    [history, projectId, floorId, generateProjectFloorSectionPath]
  );

  const navigateToLidarMap = useCallback(
    (lidarMapSubId: string | number, state?: any) => {
      return history.push(
        generateProjectFloorLidarMapUploadPath(projectId, floorId, lidarMapSubId),
        state
      );
    },
    [history, projectId, floorId, generateProjectFloorLidarMapUploadPath]
  );

  const navigateToLidarMaps = useCallback(
    (projectPublicId: string = projectId, floorCode: string = floorId) => history.push(generateProjectFloorLidarMapsPath(projectPublicId, floorCode)),
    [history, projectId, floorId, generateProjectFloorLidarMapsPath]
  );

  const navigateToLidarMapTransform = useCallback(
    (projectId: string, floorId: string, lidarMapSubId: string | number) => {
      return history.push(generateLidarTransformationPath(projectId, floorId, lidarMapSubId));
    },
    [history, generateLidarTransformationPath]
  );

  const navigateToLidarMapReview = useCallback(
    (projectId: string, floorId: string, lidarMapSubId: string) => history.push(generateLidarReviewPath(projectId, floorId, lidarMapSubId)),
    [history, generateLidarReviewPath]
  );

  const navigateToMapsNeedingReview = useCallback(
    () => history.push(PATH_STRINGS.mapsNeedingReview),
    [history]
  );

  const navigateToLidarManagementDashboard = useCallback(
    () => history.push(PATH_STRINGS.lidarManagement),
    [history]
  );

  const navigateToLidarEditing = useCallback(
    (projectId: string, floorId: string, lidarMapSubId: string | number) => history.push(generateLidarEditingPath(projectId, floorId, lidarMapSubId)),
    [history, generateLidarEditingPath]
  );

  const navigateToProjectLidarMapConfiguration = useCallback(
    (projectId: string, lidarMapConfigId: string | number) => history.push(generateProjectLidarMapConfigurationPath(projectId, lidarMapConfigId)),
    [generateProjectLidarMapConfigurationPath, history]);

  return {
    navigateToProjectsDashboard,
    navigateToProject,
    navigateToProjectFloors,
    navigateToFloor,
    navigateToProjectSections,
    navigateToSection,
    navigateToLidarMap,
    navigateToLidarMaps,
    navigateToLidarMapTransform,
    navigateToLidarMapReview,
    navigateToMapsNeedingReview,
    navigateToLidarManagementDashboard,
    navigateToLidarEditing,
    navigateToProjectLidarMapConfiguration,
  };
};

export const useStairsNavigation = () => {
  const {
    generateStairClimberScriptPath,
  } = useGeneratedPaths();

  const history = useHistory();

  const navigateToStairClimberScript = useCallback(
    (scriptId: string | number) => history.push(generateStairClimberScriptPath(scriptId)),
    [generateStairClimberScriptPath, history]
  );

  const navigateToStairClimberScripts = useCallback(
    () => history.push(PATH_STRINGS.stairClimberScripts),
    [history]
  );

  return {
    navigateToStairClimberScript,
    navigateToStairClimberScripts,
  };
}


export const useCompanyNavigation = () => {
  const {
    generateCompanyPath,
  } = useGeneratedPaths();

  const history = useHistory();

  const navigateToCompany = useCallback(
    (companyId: string) => history.push(generateCompanyPath(companyId)),
    [generateCompanyPath, history]
  );

  const navigateToCompanies = useCallback(
    () => history.push(PATH_STRINGS.companies),
    [history]
  );

  return {
    navigateToCompany,
    navigateToCompanies,
  };
}

export const useLocationNavigation = () => {
  const {
    generateLocationPath,
  } = useGeneratedPaths();

  const history = useHistory();

  const navigateToLocation = useCallback(
    (companyId: string) => history.push(generateLocationPath(companyId)),
    [generateLocationPath, history]
  );

  const navigateToLocations = useCallback(
    () => history.push(PATH_STRINGS.locations),
    [history]
  );

  return {
    navigateToLocation,
    navigateToLocations,
  };
}

export const useSitewalkNavigation = () => {
  const {
    generateProcessSitewalkTransformationPath,
  } = useGeneratedPaths();

  const history = useHistory();

  const navigateToSitewalkTransformation = useCallback(
    (sitewalkId: string | number) => history.push(generateProcessSitewalkTransformationPath(sitewalkId)),
    [generateProcessSitewalkTransformationPath, history]
  );

  return {
    navigateToSitewalkTransformation,
  };
}

export const useProgressTrackingNavigation = () => {
  const history = useHistory();
  const {
    generateProgressTrackingTrackersPath,
    generateProgressTrackingJobsPath,
    generateProgressTrackingItemsPath,
    generateProgressTrackingRegionsPath
  } = useGeneratedPaths();

  const navigateToProgressTrackingTrackers = useCallback(
    (projectId?: string | null) => history.replace(generateProgressTrackingTrackersPath(projectId)),
    [generateProgressTrackingTrackersPath, history]
  );

  const navigateToProgressTrackingJobs = useCallback(
    (projectId?: string) => history.replace(generateProgressTrackingJobsPath(projectId)),
    [generateProgressTrackingJobsPath, history]
  );

  const navigateToProgressTrackingItems = useCallback(
    (projectId?: string | null, floorCode?: string | null, trackerName?: string | null, itemTypeId?: string | number | null, search?: string | null) => history.replace(generateProgressTrackingItemsPath(projectId, floorCode, trackerName, itemTypeId, search)),
    [generateProgressTrackingItemsPath, history]
  );

  const navigateToProgressTrackingRegions = useCallback(
    (projectId?: string | null, floorCode?: string | null) => history.replace(generateProgressTrackingRegionsPath(projectId, floorCode)),
    [generateProgressTrackingRegionsPath, history]
  );

  return {
    navigateToProgressTrackingTrackers,
    navigateToProgressTrackingJobs,
    navigateToProgressTrackingItems,
    navigateToProgressTrackingRegions,
  };
}
