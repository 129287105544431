import { ChangeEvent, useEffect, useMemo, useReducer, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom"
import { useNotifications } from "../../../contexts/notificationProvider";
import { Button } from "../../common/Inputs/Button";
import { Select } from "../../common/Inputs/Select";
import { LoadingIndicator } from "../../common/LoadingIndicator";
import { StyledLabel, StyledInput, FormContainer, FormHeader, SaveButtonContainer, FormCheckbox } from '../../common/FormControls';
import { createUser, fetchUser, IEmployer, IManageUser, updateUser } from "../../../api/users";
import { fetchCompanies } from "../../../api/companies";
import styled from "styled-components";

const initialEmployer: IEmployer = {
  is_verified: true,
  name: '',
  public_id: '',
  website_url: '',
}

const initialUser: IManageUser = {
  date_of_birth: null,
  email: '',
  employer: {...initialEmployer},
  account_owner: {...initialEmployer},
  first_name: '',
  gender: '',
  is_verified: true,
  joined_on: new Date().toISOString(),
  last_name: '',
  public_id: '',
  user_type: '',
  is_demo_account: false,
}

const inputWidth: string = '250px';

const selectStyles = { 
  margin: '0 0 20px 0',
  width: inputWidth
}

const userReducer = (state: IManageUser, payload: Partial<IManageUser>) => {
  return {
    ...state,
    ...payload,
  }
}

const userTypeOptions = ['standard', 'staff', 'super', 'anonymous'];
const genderOptions = ['male', 'female', 'other'];

export const ManageUser = () => {
  const [user, dispatchUser] = useReducer(userReducer, {...initialUser});
  const [userLoaded, setUserLoaded] = useState<boolean>(false);

  const [companies, setCompanies] = useState<IEmployer[]>([]);
  const [companyLoaded, setCompanyLoaded] = useState<boolean>(false);
  
  const userIdMatch = useRouteMatch<{userId: string}>('/user-management/user/:userId');
  const userId = userIdMatch?.params.userId || 'new';
  const userIsNew = userId === 'new';

  const history = useHistory();
  const { addNotification } = useNotifications();

  const companyOptions = useMemo(() => {
    return companies.map((company: IEmployer) => company.name);
  }, [companies]);

  useEffect(() => {
    fetchCompanies().then((fetchedCompanies: IEmployer[]) => {
      setCompanies(fetchedCompanies);
      setCompanyLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (!userIsNew) {
      fetchUser(userId).then((fetchedUser: IManageUser) => {
        dispatchUser(fetchedUser);
        setUserLoaded(true);
      });
    } else {
      dispatchUser(initialUser);
      setUserLoaded(true);
    }
  }, [userIsNew, userId]);

  const dataLoaded = userLoaded && companyLoaded;

  if (!dataLoaded || user === null) {
    return <LoadingIndicator/>
  }

  const onChangeEmployer = (e: ChangeEvent<HTMLSelectElement>) => {
    const newEmployer = companies.filter((company: IEmployer) => company.name === e.target.value)[0];

    dispatchUser({ employer: newEmployer });
  }

  const onChangeAccountOwner = (e: ChangeEvent<HTMLSelectElement>) => {
    const newEmployer = companies.filter((company: IEmployer) => company.name === e.target.value)[0];

    dispatchUser({ account_owner: newEmployer });
  }

  const onCreateNewUser = async (dataToSave: any) => {
    try {
      const createdUser = await createUser(dataToSave);
      delete user["password"];
      history.push(`/user-management/user/${createdUser.public_id}`);
      addNotification('User created successfully', 'success');
    } catch (err) {
      console.log('onCreateNewUser==>>', err);
      addNotification('Error creating new user', 'error');
    } finally {
      setUserLoaded(true);
    }
  }

  const onSaveExistingUser = async (dataToSave: any) => {
    try {
      const updatedUser = await updateUser(dataToSave);
      dispatchUser(updatedUser);
      setUserLoaded(true);
      addNotification('User updated successfully', 'success');
    } catch (err) {
      console.log('onSaveExistingUser==>>', err);
      addNotification('Error updating user', 'error');
    } finally {
      setUserLoaded(true);
    }
  }

  const onClickSave = () => {
    setUserLoaded(false);
    
    delete user["confirm_password"];
    const dataToSave: any = {...user, employer: user.employer.public_id, account_owner: user.account_owner.public_id}

    if (userIsNew) {
      onCreateNewUser(dataToSave);
    } else {
      onSaveExistingUser(dataToSave);
    }
  }

  const onClickCancel = () => {
    history.push('/user-management')
  }

  const saveButtonText: string = userIsNew ? 'Create' : 'Save';

  const validForSubmit = (
    !!user.first_name.trim() &&
    !!user.last_name.trim() &&
    !!user.email &&
    (!userIsNew || !!user.password?.trim()) &&
    (!userIsNew || !!user.confirm_password?.trim()) &&
    (!userIsNew || (user.password === user.confirm_password)) &&
    !!user.employer.public_id &&
    !!user.account_owner.public_id &&
    !!user.user_type &&
    !!user.gender);

  return (
    <FormContainer>
        <FormHeader>
          {userIsNew ? 'New User' : 'Editing User'}
        </FormHeader>

      <ManageUserFormRow>
        <div>
          <StyledLabel
            htmlFor='first_name'
            width={250}
          >
            First Name:
          </StyledLabel>
          <StyledInput
            id='first_name'
            value={user.first_name}
            onChange={(e: ChangeEvent<HTMLInputElement>) => dispatchUser({ first_name: e.target.value })}
            width={250}
          />
        </div>
        
        <div>
          <StyledLabel
            htmlFor='last_name'
            width={250}
          >
            Last Name:
          </StyledLabel>
          <StyledInput
            id='last_name'
            value={user.last_name}
            onChange={(e: ChangeEvent<HTMLInputElement>) => dispatchUser({ last_name: e.target.value })}
            width={250}
          />
        </div>
      </ManageUserFormRow>

      <StyledLabel
        htmlFor='new-email'
        width={520}
      >
        Email:
      </StyledLabel>
      <StyledInput
        width={520}
        autoComplete="new-email"
        id='new-email'
        value={user.email}
        onChange={(e: ChangeEvent<HTMLInputElement>) => dispatchUser({ email: e.target.value })}
      />

      { userIsNew &&
        <ManageUserFormRow>
          <div>
            <StyledLabel
              htmlFor='new-password'
              width={250}
            >
              Password:
            </StyledLabel>
            <StyledInput
              width={250}
              autoComplete="new-password"
              type='password'
              id='new-password'
              value={user.password}
              onChange={(e: ChangeEvent<HTMLInputElement>) => dispatchUser({ password: e.target.value })}
            />
          </div>
          <div>
            <StyledLabel
              htmlFor='confirm-password'
              width={250}
            >
              Confirm Password:
            </StyledLabel>
            <StyledInput
              width={250}
              autoComplete="confirm-password"
              type='password'
              id='confirm-password'
              value={user.confirm_password}
              onChange={(e: ChangeEvent<HTMLInputElement>) => dispatchUser({ confirm_password: e.target.value })}
            />
          </div>
        </ManageUserFormRow>
      }

      <ManageUserFormRow>
        <div>
          <StyledLabel
            htmlFor='employer'
            width={250}
          >
            Employer:
          </StyledLabel>
          <Select
            options={companyOptions}
            value={user.employer.name}
            onSelect={onChangeEmployer}
            selectStyles={selectStyles}
            useInitialEmptyOption
          />
        </div>
        <div>
          <StyledLabel
            htmlFor='account-owner'
            width={250}
          >
            Account Owner:
          </StyledLabel>
          <Select
            options={companyOptions}
            value={user.account_owner.name}
            onSelect={onChangeAccountOwner}
            selectStyles={selectStyles}
            useInitialEmptyOption
          />
        </div>
      </ManageUserFormRow>

      <ManageUserFormRow>
        <div>
          <StyledLabel
            htmlFor='user_type'
            width={250}
          >
            User Type:
          </StyledLabel>
          <Select
            options={userTypeOptions}
            value={user.user_type}
            onSelect={(e: ChangeEvent<HTMLSelectElement>) => dispatchUser({ user_type: e.target.value })}
            selectStyles={selectStyles}
            useInitialEmptyOption
          />
        </div>
        
        <div>
          <StyledLabel
            htmlFor='gender'
            width={250}
          >
            Gender:
          </StyledLabel>
          <Select
            options={genderOptions}
            value={user.gender}
            onSelect={(e: ChangeEvent<HTMLSelectElement>) => dispatchUser({ gender: e.target.value })}
            selectStyles={selectStyles}
            useInitialEmptyOption
          />
        </div>
      </ManageUserFormRow>

      <FormCheckbox
        label="Demo Account?"
        checked={user.is_demo_account}
        onChangeChecked={checked => dispatchUser({is_demo_account: checked})}
        labelStyle={{
          width: 520,
        }}
        checkboxContainerStyle={{
          width: 520,
        }}
      />
        
      <SaveButtonContainer>
        <Button
          text='Cancel'
          onClick={onClickCancel}
          style={{ display: 'inline-block', marginRight: validForSubmit ? '10px' : '0px' }}
        />

        { validForSubmit && 
          <Button
            primary
            text={saveButtonText}
            onClick={onClickSave}
            style={{ display: 'inline-block' }}
          />
        }
      </SaveButtonContainer>
    </FormContainer>
  )
}

const ManageUserFormRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;