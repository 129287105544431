import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { TrackerTypesTable } from "./TrackerTypesTable";
import { ProjectTrackersTable } from "./ProjectTrackersTable";
import { useTrackerTypesQuery } from "../../../../hooks/progressTrackingQueries";
import { useEffect } from "react";

export const ProgressTrackersPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const projectParam = params.get('project');

  const {data: trackerTypes, isLoading: trackerTypesLoading, refetch: refetchTrackerTypes} = useTrackerTypesQuery();

  useEffect(() => {
    refetchTrackerTypes();
  }, [refetchTrackerTypes, projectParam]);

  return (
    <Box>
      {!!projectParam &&
        <ProjectTrackersTable
          projectId={projectParam}
          trackerTypes={trackerTypes ?? []}
          trackerTypesLoading={trackerTypesLoading}
          refetchTrackerTypes={refetchTrackerTypes}
        />
      }
      {!projectParam &&
        <TrackerTypesTable
          trackerTypes={trackerTypes ?? []}
          trackerTypesLoading={trackerTypesLoading}
        />
      }
    </Box>
  );
}