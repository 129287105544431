import { useCallback, useMemo } from "react";
import { EditableItem, useProgressItemsContext } from "../../../../../../contexts/progressItemsContext";

interface IProgressItemsEditorMapViewSVGBoundaryPointsProps {
  item: EditableItem;
}

export const ProgressItemsEditorMapViewSVGBoundaryPoints = ({
  item,
}: IProgressItemsEditorMapViewSVGBoundaryPointsProps) => {
  const {
    state: progressItemsState,
    dispatch: dispatchProgressItems,
    onUpdateItem,
    onUpdateTouched,
  } = useProgressItemsContext();

  const {
    renderItemsAsPath,
    currentlyEditingCoodinateIndex,
  } = progressItemsState;

  const onMouseDownBoundaryPoint = useCallback((e: React.MouseEvent<SVGCircleElement, MouseEvent>, itemId: number, index: number) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    dispatchProgressItems({
      type: 'UPDATE_STATE',
      payload: {
        currentlyEditingItemId: itemId,
        currentlyEditingCoodinateIndex: index,
        dragStartX: e.nativeEvent.offsetX,
        dragStartY: e.nativeEvent.offsetY
      },
    });

    onUpdateTouched(itemId);
  }, [dispatchProgressItems, onUpdateTouched]);

  const onMouseUpBoundaryPoint = useCallback(() => {
    dispatchProgressItems({
      type: 'UPDATE_STATE',
      payload: {
        currentlyEditingItemId: null,
        currentlyEditingCoodinateIndex: null,
        currentlyCreatingItemId: null,
        dragStartX: null,
        dragStartY: null,
      },
    });
  }, [dispatchProgressItems]);

  const onContextMenuBoundaryPoint = useCallback((e: React.MouseEvent, item: EditableItem, index: number) => {
    e.preventDefault();
    
    const itemToUpdate = {...item};
    let newCoordinates = [...item.position];
    
    if (newCoordinates.length > 2) {
      newCoordinates.splice(index, 1);
    } else {
      newCoordinates = [];
    }

    newCoordinates.forEach((point, i) => point.order = i-1);

    itemToUpdate.position = newCoordinates;

    onUpdateItem(itemToUpdate);
    onUpdateTouched(itemToUpdate.id);
  }, [onUpdateItem, onUpdateTouched]);

  const coordinates = useMemo(() => {
    return item.position.map(point => [point.x, point.y]);
  }, [item.position]);

  if (coordinates.length === 0) {
    return (
      <></>
    )
  }

  return (
    <>
      {coordinates.map((point, index) => (
        <circle
          key={`${item.id}-${index}-boundary-point-circle`}
          cursor={!!currentlyEditingCoodinateIndex ? 'grabbing' : 'grab'}
          cx={point[0]}
          cy={point[1]}
          r={renderItemsAsPath ? 2 : 5}
          fill="white"
          stroke="black"
          onMouseDown={e => onMouseDownBoundaryPoint(e, item.id, index)}
          onMouseUp={onMouseUpBoundaryPoint}
          onContextMenu={e => onContextMenuBoundaryPoint(e, item, index)}
        />
      ))};
    </>
  );
}