import { User } from './api/types';

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const getUserInitials = (user: {first_name: string; last_name: string; email: string;}) => {
  if (user.first_name && user.last_name) {
    return (
      user.first_name.substring(0, 1).toUpperCase() + user.last_name.substring(0, 1).toUpperCase()
    );
  } else {
    return user.email.substring(0, 2);
  }
};

export const getFullName = (user: User) => {
  return user.firstName + ' ' + user.lastName;
};

export const truncateMessage = (str: string, num: number) => {
  if (str.length > num) {
    return str.slice(0, num) + '...';
  } else {
    return str;
  }
};
