import React, { useCallback, useEffect, useMemo, useState } from "react";
import { addProjectUserMapping, deleteProjectUserMapping, ProjectUser, updateProjectUser } from "../../../../api/projectUsers";
import { fetchUsers, IManageUser } from "../../../../api/users";
import { useNotifications } from "../../../../contexts/notificationProvider";
import { useProjectContext } from "../../../../contexts/projectContext"
import { useProjectUsersQuery } from "../../../../hooks/projectQueries";
import { Checkbox, FormContainer, FormHeader } from "../../../common/FormControls";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { Table } from "../../../common/Table/Table";
import { DashboardCheckbox, DashboardSelect } from "../DashboardFormControls";

export const ProjectUsers = () => {
  const {
    updateProject,
    state: projectState,
  } = useProjectContext();

  const { project } = projectState;

  const projectUsers: ProjectUser[] = project.users;

  const [users, setUsers] = useState<IManageUser[]>([]);
  const [usersLoaded, setUsersLoaded] = useState<boolean>(false);
  const [usersExpanded, setUsersExpanded] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  const updateProjectUsers = (returnedUsers: ProjectUser[]) => {
    updateProject({
      users: returnedUsers,
    });
  }

  const { isLoading: projectUsersLoading } = useProjectUsersQuery(updateProjectUsers);

  const {
    addNotification,
  } = useNotifications();

  useEffect(() => {
    fetchUsers().then((fetchedUsers: IManageUser[]) => {
      setUsers(fetchedUsers);
      setUsersLoaded(true);
    });
  }, []);

  const dataLoaded = usersLoaded && !projectUsersLoading;

  const onAddUser = useCallback(async (userId: string) => {
    try {
      const returnedProjectWorker = await addProjectUserMapping(project.public_id, userId);

      const updatedProjectUsers = [...projectUsers];
      updatedProjectUsers.push(returnedProjectWorker);

      updateProject({
        users: updatedProjectUsers,
      });
      addNotification('User Added Successfully', 'success');
    } catch (err) {
      console.log('addProjectUserMapping==>>', err);
      addNotification('Error Adding User', 'error');
    }
  }, [addNotification, project.public_id, projectUsers, updateProject]);

  const onRemoveUser = useCallback(async (userId: string) => {
    try {
      await deleteProjectUserMapping(project.public_id, userId);

      const updatedProjectUsers = projectUsers.filter((user: ProjectUser) => {
        return user.user.public_id !== userId;
      });

      updateProject({
        users: updatedProjectUsers,
      });
      addNotification('User Removed Successfully', 'success');
    } catch (err) {
      console.log('deleteProjectUserMapping==>>', err);
      addNotification('Error Deleting User', 'error');
    }
  }, [project.public_id, projectUsers, addNotification, updateProject]);

  const onUserChecked = useCallback(async (checked: boolean, userId: string) => {
    if (checked) {
      onAddUser(userId);
    } else {
      onRemoveUser(userId);
    }
  }, [onAddUser, onRemoveUser]);

  const data = useMemo(() => {
    if (!dataLoaded) {
      return [];
    }

    return projectUsers;
  }, [dataLoaded, projectUsers]);

  const filteredUsers = useMemo(() => {
    if (!usersLoaded) {
      return [];
    }

    return users.filter((user: IManageUser) => {
      const firstNameMatches: boolean = user.first_name.toLowerCase().includes(searchText.toLowerCase());
      const lastNameMatches = user.last_name.toLowerCase().includes(searchText.toLowerCase());

      return firstNameMatches || lastNameMatches;
    });
  }, [usersLoaded, users, searchText]);

  const onCheckFloorTransitionCheckbox = useCallback(async (checked: boolean, projectUser: ProjectUser) => {
    try {
      const updatedProjectUser = await updateProjectUser(project.public_id, projectUser.user.public_id, checked);

      const updatedProjectUsers = projectUsers.map((user: ProjectUser) => {
        if (user.user.public_id === updatedProjectUser.user.public_id) {
          return updatedProjectUser;
        }

        return user;
      });

      updateProject({
        users: updatedProjectUsers,
      });

      addNotification("Project user updated successfully", "success");
    } catch (err) {
      console.log('updateProjectUser==>>', err);
      addNotification('Error updating project user', 'error');
    }
  }, [project.public_id, projectUsers, updateProject, addNotification]);

  const generateFloorTransitionColumn = useCallback((projectUser: ProjectUser) => {
    return (
      <Checkbox
        checked={projectUser.handles_floor_transitions}
        onChangeChecked={checked => onCheckFloorTransitionCheckbox(checked, projectUser)}
        checkmarkLeft={0}
        labelStyle={{
          display: 'block',
        }}
      />
    )
  }, [onCheckFloorTransitionCheckbox]);

  const columns = useMemo(() => [
    {
      Header: 'Name',
      accessor: (originalRow: ProjectUser) => `${originalRow.user.first_name} ${originalRow.user.last_name}`,
    },
    {
      Header: 'Email',
      accessor: 'user.email',
    },
    ...project.has_floor_transitions ?
    [{
      Header: 'Handles Floor Transitions?',
      accessor: generateFloorTransitionColumn
    }] : []
  ], [generateFloorTransitionColumn, project.has_floor_transitions]);

  if (!dataLoaded) {
    return <LoadingIndicator/>
  }

  return (
    <>    
      <FormContainer>
        <FormHeader>{project.name}</FormHeader>
      </FormContainer>
      
      <DashboardSelect
        title='Manage Users'
        expanded={usersExpanded}
        setExpanded={setUsersExpanded}
        searchText={searchText}
        setSearchText={setSearchText}
        searchPlaceholder='Filter Users'
        containerStyle={{
          position: 'absolute',
          left: '20px',
        }}
      >
        { filteredUsers.map((user: IManageUser) => {
          const checked = !!projectUsers.find((projectUser: ProjectUser) => projectUser.user.public_id === user.public_id);

          return (
            <DashboardCheckbox
              key={user.public_id}
              checked={checked}
              onChangeChecked={(newValue: boolean) => onUserChecked(newValue, user.public_id)}
              label={`${user.first_name} ${user.last_name}`}
              checkmarkTop={2}
            />
          );
        })}
      </DashboardSelect>

      <Table
        data={data}
        columns={columns}
        preventAutoResetPage
        preventAutoResetGlobalFilter
        useGlobalFilterTextBox
        globalFilterPlaceholder="Search Users"
        hideColumnFilters
      />
    </>
  )
}