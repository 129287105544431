import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { fetchLidarMap, initialLidarMap } from '../api/projectFloorLidarMaps';

const initialState = {
  projectId: '',
  floorId: '',
  sectionId: '',
  lidarMapId: '',
  lidarMapConfigId: '',
  project: {
    name: '',
    company: {},
    location: {},
    time_zone: 'America/New_York',
    has_floor_transitions: false,
  },
  floor: {
    lidarMaps: [],
  },
  section: {},
  lidarMap: null,
  lidarMapConfigurations: [],
  lidarMapConfig: {
    run_cartographer_ros: true,
    run_slam_toolbox: true,
  },
}

const ProjectContext = createContext<any>(null);

const UPDATE_STATE = 'UPDATE_STATE';
const UPDATE_PROJECT = 'UPDATE_PROJECT';
const UPDATE_FLOOR = 'UPDATE_FLOOR';
const UPDATE_SECTION = 'UPDATE_SECTION';
const UPDATE_LIDAR_MAP = 'UPDATE_LIDAR_MAP';
const UPDATE_LIDAR_MAP_CONFIG = 'UPDATE_LIDAR_MAP_CONFIG'; 

const projectReducer = (state: any, action: any) => {
  switch (action.type) {
    case UPDATE_STATE:
      return {
        ...state,
        ...action.payload.item,
      };
    case UPDATE_PROJECT:
      return {
        ...state,
        project: {
          ...state.project,
          ...action.payload.item,
        },
    };
    case UPDATE_FLOOR:
      return {
        ...state,
        floor: {
          ...state.floor,
          ...action.payload.item,
        },
      };
    case UPDATE_SECTION:
      return {
        ...state,
        section: {
          ...state.section,
          ...action.payload.item,
        },
      };
    case UPDATE_LIDAR_MAP:
      return {
        ...state,
        lidarMap: {
          ...state.lidarMap,
          ...action.payload
        }
      };
    case UPDATE_LIDAR_MAP_CONFIG:
      return {
        ...state,
        lidarMapConfig: {
          ...state.lidarMapConfig,
          ...action.payload
        }
      }
  }
}

export const ProjectProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(projectReducer, initialState);

  const resetLidarMap = useCallback(() => {
    dispatch({
      type: UPDATE_STATE,
      payload: {
        item: {
          lidarMap: null
        }
      },
    });
  }, []);

  const manageLidarMap = useCallback(async () => {
    if (state.projectId && state.floorId && state.lidarMapId) {
      try {
        let lidarMapToUpdate = {...initialLidarMap};

        if (state.lidarMapId !== 'new') {
          lidarMapToUpdate = await fetchLidarMap(state.projectId, state.floorId, state.lidarMapId);
        }

        dispatch({
          type: UPDATE_LIDAR_MAP,
          payload: lidarMapToUpdate,
        });
      } catch {
        resetLidarMap();
      }
    } else {
      resetLidarMap();
    }
  }, [state.projectId, state.floorId, state.lidarMapId, resetLidarMap]);

  useEffect(() => {
    manageLidarMap();
  }, [manageLidarMap]);

  return <ProjectContext.Provider value={[state, dispatch]}>{children}</ProjectContext.Provider>;
};

export const useProjectContext = () => {
  const [state, dispatch] = useContext(ProjectContext);

  const updateState = useCallback((item: any) => {
    dispatch({
      type: UPDATE_STATE,
      payload: {
        item,
      },
    });
  }, [dispatch]);

  const updateFloor = useCallback((item: any) => {
    dispatch({
      type: UPDATE_FLOOR,
      payload: {
        item,
      },
    });
  }, [dispatch]);

  const updateProject = (item: any) => {
    dispatch({
      type: UPDATE_PROJECT,
      payload: {
        item,
      },
    });
  };

  const updateSection = (item: any) => {
    dispatch({
      type: UPDATE_SECTION,
      payload: {
        item,
      },
    });
  };

  const updateLidarMap = (item: any) => {
    dispatch({
      type: UPDATE_LIDAR_MAP,
      payload: item,
    });
  }

  const updateLidarMapConfig = (item: any) => {
    dispatch({
      type: UPDATE_LIDAR_MAP_CONFIG,
      payload: item,
    });
  }

  return {
    updateState,
    updateProject,
    updateFloor,
    updateSection,
    updateLidarMap,
    updateLidarMapConfig,
    state,
  };
};
