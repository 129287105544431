import { Route, Switch } from "react-router-dom"
import { PATH_STRINGS } from "../../../hooks/useGeneratedPaths"
import { ManageProjectAssets } from "./project-assets/ManageProjectAssets"
import { ProjectDetails } from "./project/ProjectDetails"
import { ProjectMenu } from "./project/ProjectMenu"
import { ManageFloors } from "./project-floors/ManageFloors"
import { ProjectSetupDashboard } from "./project/ProjectSetupDashboard"
import { ProjectUsers } from "./project-users/ProjectUsers"
import { useRouteData } from "../../../hooks/useRouteData"
import { useProjectQuery } from "../../../hooks/projectQueries"
import { useProjectContext } from "../../../contexts/projectContext"
import { LoadingIndicator } from "../../common/LoadingIndicator"
import { ProjectSubcontractors } from "./project-subcontractors/ProjectSubcontractors"
import { ProjectForgeTransformation } from "./project/ProjectForgeTransformation"
import { ManageLidarMapConfigurations } from "./project-lidar-map-configurations/ManageLidarMapConfigurations"

export const ManageProject = () => {
  useRouteData();

  const {
    updateProject,
    state: projectState
  } = useProjectContext();

  const {
    projectId
  } = projectState;

  const { isFetching: projectFetching } = useProjectQuery(projectId, updateProject);

  if (projectFetching) {
    return <LoadingIndicator/>
  }
  
  return (
    <>
      <Switch>
        <Route path={PATH_STRINGS.project}>
          { !!projectId && <ProjectMenu/> }
        </Route>
      </Switch>

      <Switch>
        <Route exact path={PATH_STRINGS.projectsDashboard}>
          <ProjectSetupDashboard/>
        </Route>
        { !!projectId &&
          <>
            <Route exact path={PATH_STRINGS.project}>
              <ProjectDetails/>
            </Route>
            <Route exact path={[PATH_STRINGS.projectImage, PATH_STRINGS.projectClientLogo, PATH_STRINGS.projectRendering, PATH_STRINGS.projectWhiteRendering, PATH_STRINGS.projectRenderingVectors, PATH_STRINGS.projectForgeModel]}>
              <ManageProjectAssets/>
            </Route>
            <Route path={[PATH_STRINGS.projectFloors, PATH_STRINGS.floor]}>
              <ManageFloors/>
            </Route>
            <Route path={PATH_STRINGS.projectUsers}>
              <ProjectUsers/>
            </Route>
            <Route path={PATH_STRINGS.projectSubcontractors}>
              <ProjectSubcontractors/>
            </Route>
            <Route path={PATH_STRINGS.projectForgeTransformation}>
              <ProjectForgeTransformation/>
            </Route>
            <Route path={[PATH_STRINGS.projectLidarMapConfigurations, PATH_STRINGS.projectLidarMapConfiguration]}>
              <ManageLidarMapConfigurations/>
            </Route>
          </>
        }
      </Switch>
    </>
  )
}