import { CompletedLine, HandleType, LineInProgress } from "./LidarMapEditing";

interface ILidarMapLineProps {
  line: CompletedLine;
  lineInCreation?: LineInProgress | null;
  lineBeingDragged?: CompletedLine | null;
  handleType?: HandleType | null;
  onMouseUpHandle?: (e: React.MouseEvent<SVGLineElement>) => void;
  onMouseDownHandle?: (e: React.MouseEvent<SVGLineElement>, line: CompletedLine, handleType: HandleType) => void;
  onMouseUpLine?: (e: React.MouseEvent<SVGLineElement>) => void;
  onMouseDownLine?: (e: React.MouseEvent<SVGLineElement>, line: CompletedLine) => void;
}

export const MapLine = ({
  line,
  lineInCreation,
  lineBeingDragged,
  handleType,
  onMouseUpHandle,
  onMouseDownHandle,
  onMouseUpLine,
  onMouseDownLine,
}: ILidarMapLineProps) => {
  const {id, x1, y1, x2, y2, stroke, stroke_width} = line;
  const interactive = !!onMouseUpLine || !!onMouseDownLine;

  let x1Handle = x1;
  let y1Handle = y1;
  let x2Handle = x2;
  let y2Handle = y2;  

  if (x1 !== x2) {
    const slope = (y2 - y1) / (x2 - x1);
    
    const intercept = y1 - (slope * x1);
    const handleDiff = Math.min((x2 - x1) / 8, 30);

    x1Handle = x1 + handleDiff;
    y1Handle = slope * x1Handle + intercept;

    x2Handle = x2 - handleDiff;
    y2Handle = slope * x2Handle + intercept;
  } else {
    const yDiff = Math.min((y2 - y1) / 8, 30);
    
    y1Handle = y1 + (y1 > y2 ? -yDiff : yDiff);
    y2Handle = y2 + (y2 > y1 ? -yDiff : yDiff);
  }

  const handleCursor = (lineBeingDragged && handleType) ? 'grabbing' : 'grab';

  return (
    <>
      <line
        cursor={interactive ? 'pointer' : 'default'}
        key={id}
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke={stroke}
        strokeWidth={stroke_width}
        onMouseDown={e => {
          if (onMouseDownLine) {
            onMouseDownLine(e, line as CompletedLine)
          }
        }}
        onMouseUp={onMouseUpLine}
      />
      {(!lineInCreation && interactive) &&
        <>
          <line
            cursor={handleCursor}
            x1={x1}
            y1={y1}
            x2={x1Handle}
            y2={y1Handle}
            stroke="transparent"
            strokeWidth={stroke_width}
            onMouseDown={e => {
              if (onMouseDownHandle) {
                onMouseDownHandle(e, line, 1)
              }
            }}
            onMouseUp={onMouseUpHandle}
          />
          <line
            cursor={handleCursor}
            x1={x2}
            y1={y2}
            x2={x2Handle}
            y2={y2Handle}
            stroke="transparent"
            strokeWidth={stroke_width}
            onMouseDown={e => {
              if (onMouseDownHandle) {
                onMouseDownHandle(e, line, 2)
              }
            }}
            onMouseUp={onMouseUpHandle}
          />
        </>
      }
    </>
  )
}