import React from 'react';
import { Link } from 'react-router-dom';

import { CardBase, CardDesc, CardDescItem, CardImage, CardTitle, CardTitleInner } from './styles';

/**
 * Temporary component to wrap cards. Allows for only specified project to be clicked.
 *
 * @param projectId
 * @param children
 */
const BetaProofLink = ({ link, children }: any) => {
  if(link.includes('http'))
    return (<a href={link} target="_blank" rel="noopener noreferrer">{children}</a>)
  return <Link to={link}>{children}</Link>
};

export const Card = (props: {
  link: string;
  name: string;
  description?: string;
  image_id?: string;
  img?: string;
}) => (
  <BetaProofLink link={props.link}>
    <CardBase>
      <CardImage src={props.img} />
      <CardTitle>
        <CardTitleInner>{props.name}</CardTitleInner>
      </CardTitle>
      <CardDesc>
        <CardDescItem>
          <div>{props.description}</div>
        </CardDescItem>
      </CardDesc>
    </CardBase>
  </BetaProofLink>
);
