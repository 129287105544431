import { useState } from "react";
import styled from "styled-components";
import { IconButton } from "../../../common/IconButton";
import { ViewSelector } from "../../../common/ViewSelector/ViewSelector";
import { DashboardCheckbox } from "../../project-setup/DashboardFormControls";
import { LidarMapLine } from "./LidarMapEditing";
import iconDelete from "../../../../assets/images/icons/icon_delete_blue.svg";
import { Button } from "../../../common/Inputs/Button";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { LidarMap } from "../../../../api/projectFloorLidarMaps";

interface ILidarMapEditingViewSelectorProps {
  lines: LidarMapLine[];
  updateLine: (line: LidarMapLine) => void;
  onDeleteLine: (line: LidarMapLine) => void;
  onChangeDefaultStrokeWidth: (newWidth: number) => void;
  onChangeDefaultStroke: (newStroke: string) => void;
  onClickSubmit: () => void;
  saveInProgress: boolean;
  lidarMap: LidarMap;
  showFloorOverlay: boolean;
  setShowFloorOverlay: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LidarMapEditingViewSelector = ({
  lines,
  updateLine,
  onDeleteLine,
  onChangeDefaultStrokeWidth,
  onChangeDefaultStroke,
  onClickSubmit,
  saveInProgress,
  lidarMap,
  showFloorOverlay,
  setShowFloorOverlay,
}: ILidarMapEditingViewSelectorProps) => {
  const [viewingExpanded, setViewingExpanded] = useState<boolean>(true);

  const onChangeChecked = (checked: boolean, line: LidarMapLine) => {
    const lineToUpdate = {...line, enabled: checked};

    updateLine(lineToUpdate);
  }

  const onChangeStroke = (e: React.ChangeEvent<HTMLInputElement>, line: LidarMapLine) => {
    const newStroke = e.target.value;
    const lineToUpdate = {...line, stroke: newStroke};

    updateLine(lineToUpdate);
    onChangeDefaultStroke(newStroke);
  }

  const onChangeStrokeWidth = (e: React.ChangeEvent<HTMLInputElement>, line: LidarMapLine) => {
    const newStrokeWidth = parseInt(e.target.value);
    const lineToUpdate = {...line, stroke_width: newStrokeWidth};

    updateLine(lineToUpdate);
    onChangeDefaultStrokeWidth(newStrokeWidth);
  }

  return (
    <ViewSelector
      initialViewSelectorExpanded
      title='Lines'
      viewingExpanded={viewingExpanded}
      setViewingExpanded={setViewingExpanded}
      width={300}
      maxHeight={500}
    >
      <>
        {lines.map((line, i) => {
          return (
            <DashboardCheckbox
              key={line.id}
              checked={line.enabled}
              onChangeChecked={checked => onChangeChecked(checked, line)}
              label={`Line ${i+1}`}
              containerStyle={{
                width: 'auto',
                gap: '10px',
              }}
            >
              <input
                disabled={!line.enabled}
                type="color"
                value={line.stroke}
                onChange={e => onChangeStroke(e, line)}
              />
              <div>
                <StrokeWidthInput
                  disabled={!line.enabled}
                  value={line.stroke_width}
                  onChange={e => onChangeStrokeWidth(e, line)}
                />
                <span>px</span>
              </div>
              <IconButton
                icon={iconDelete}
                size={12}
                onClick={() => onDeleteLine(line)}
                style={{
                  right: '0px',
                  bottom: 'auto',
                  boxShadow: 'none',
                }}
              />
            </DashboardCheckbox>
          )
        })}
      </>
      <SubmitButtonContainer>
        {saveInProgress &&
          <LoadingIndicator/>
        }
        {(!saveInProgress && !!lidarMap.transformation_from_abs) &&
          <Button
            text={showFloorOverlay ? "Hide Floor Plan" : "Show Floor Plan"}
            onClick={() => setShowFloorOverlay(prevValue => !prevValue)}
          />
        }
        {(!saveInProgress && !lidarMap.is_rejected) &&
          <Button
            primary
            text="Save"
            onClick={onClickSubmit}
          />
        }
      </SubmitButtonContainer>
    </ViewSelector>
  )
}

const StrokeWidthInput = styled.input.attrs({type: 'number', min: 1})`
  font-size: 16px;
  width: 55px;
  height: 27px;
  margin-right: 3px;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;
`;