import {LabelType} from "../../../../api/labels";
import {useEffect, useState} from "react";
import {useEditorContext} from "../../../../contexts/editorContext";
import Tree, { TreeNode } from 'rc-tree';
import "rc-tree/assets/index.css";
import {Icon} from "../../../common/Icon";
import iconDanger from "../../../../assets/images/icons/icon_hazard.svg"
import ReactTooltip from "react-tooltip";


export const LabelTree = () => {
    const {state: EditorState, updateEditor} = useEditorContext()
    const [groupedLabels, setGroupedLabels] = useState<any>()

    const groupLabels = () => {
        return EditorState.labels.reduce((groups: any, item: LabelType) => {
            const group = (groups[item.category.name] || []);
            group.push(item);
            groups[item.category.name] = group;
            return groups;
        }, {});
    }

    const handleCheck = (checkedKeys: any, info: any) => {
        updateEditor({activeLabels: checkedKeys})
    }

    const onMouseEnter = (info:any) => {
        ReactTooltip.rebuild()
        ReactTooltip.show(info.event.target)
    }

    useEffect( () => {
        setGroupedLabels(groupLabels())
        if(EditorState.labels)
            updateEditor({activeLabels: EditorState.labels.map((type: LabelType) => type.name)})
    }, [EditorState.labels])

    if(!groupedLabels || !EditorState.labels.length)
        return <></>

    return(
        <div style={{height: "100%", overflowY: "auto"}}>
            <Tree checkable showIcon={false} onCheck={handleCheck} checkedKeys={EditorState.activeLabels} onMouseEnter={onMouseEnter} onExpand={() => ReactTooltip.rebuild()}>
                {Object.keys(groupedLabels).map( (category: string) =>
                    <TreeNode key={"category-"+category} title={category} selectable={false}>
                        {groupedLabels[category].map( (type: LabelType) => <TreeNode title={() =>NodeTitle(type)} style={{color: type.color, fontSize: "0.8em", overflow: "hidden"}} key={type.name} isLeaf={true} checkable/>)}
                    </TreeNode>
                )}
            </Tree>
            <ReactTooltip id="labels"/>
        </div>
    )
}

const NodeTitle = (label: LabelType) => {
    return(
        <span style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}} data-tip={label.name} data-for={"labels"} data-type={label.is_hazard ? "warning" :"info"}>
            <span style={{paddingRight: "2px"}}>{label.name}</span>
            {label.is_hazard && <Icon icon={iconDanger} size={16}/>}
        </span>
    )
}
