import styled from "styled-components";
import { useState } from "react"
import { IconButton } from "../IconButton";
import { Modal } from "./Modal";
import Spinner from 'react-spinkit';
import { Button } from "../Inputs/Button";
import iconClose from "../../../assets/images/icons/icon_close.svg";

interface FullScreenTextEditorProps {
  disabled?: boolean;
  modalStyle?: React.CSSProperties;
  modalOpen: boolean;
  onClose: () => void;
  text: string;
  onChangeText: (newValue: string) => void;
  onSubmit?: (() => void) | (() => Promise<void>);
}

export const FullScreenTextEditor = ({
  disabled,
  modalStyle,
  modalOpen,
  onClose,
  text,
  onChangeText,
  onSubmit,
}: FullScreenTextEditorProps) => {
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

  const onClickSubmitButton = async () => {
    if (onSubmit) {
      setSaveInProgress(true);

      await onSubmit();

      setSaveInProgress(false);
    }
  }

  return (
    <Modal
      modalStyle={modalStyle}
      open={modalOpen}
      onClose={onClose}
    >
      <ModalContentContainer>
        <ModalButtonContainer>
          <IconButton
            icon={iconClose}
            size={45}
            onClick={onClose}
            style={{position: 'static'}}
          />
        </ModalButtonContainer>
        <TextEditorContainer>
          <TextEditor
            disabled={disabled}
            spellCheck={false}
            value={text}
            onChange={e => onChangeText(e.target.value)}
          />
        </TextEditorContainer>
        {!!onSubmit &&
          <ModalButtonContainer>
            {!saveInProgress &&
              <Button
                primary
                text="Submit"
                onClick={onClickSubmitButton}
              />
            }
            {saveInProgress &&
              <Spinner
                name="ball-grid-pulse"
                color="#073C7A"
                fadeIn='none'
              />
            }
          </ModalButtonContainer>
        }
      </ModalContentContainer>
    </Modal>
  );
}

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 5px 10px;
  gap: 5px;
`;

const TextEditorContainer = styled.div`
  flex: 1;
`;

const TextEditor = styled.textarea`
  width: 100%;
  height: 100%;
  font-family: monospace;
  font-size: 14px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;