import { LidarMap } from "../../../../../api/projectFloorLidarMaps";
import { useProjectContext } from "../../../../../contexts/projectContext";
import { usePanZoomContext } from "../../../../common/PanZoom/PanZoomContext";
import { PanZoomContainer } from "./MapPointPicker";
import styled from 'styled-components';
import { createRef, useState } from "react";
import PanZoom from "../../../../common/PanZoom/PanZoom";
import { Button } from "../../../../common/Inputs/Button";
import iconClose from '../../../../../assets/images/icons/icon_close.svg';
import iconPlus from '../../../../../assets/images/icons/icon_plus.svg';
import iconMinus from '../../../../../assets/images/icons/icon_minus.svg';
import { IconButton } from "../../../../common/IconButton";
import { StyledInput } from "../../../../common/FormControls";
import { Transformation } from "../../../../../api/projectFloors";
import { LoadingIndicator } from "../../../../common/LoadingIndicator";

enum TransformationType {
  from_abs,
  to_abs,
}

interface ILidarTransformationConfirmationProps {
  selectedLidarMap: LidarMap;
  transformationFromAbs: Transformation;
  transformationToAbs: Transformation;
  onReturnToPointSelection: () => void;
  onConfirmTransformation: () => void;
  confirmationInProgress: boolean;
}

export const LidarTransformationConfirmation = ({
  selectedLidarMap,
  transformationFromAbs,
  transformationToAbs,
  onReturnToPointSelection,
  onConfirmTransformation,
  confirmationInProgress,
}: ILidarTransformationConfirmationProps) => {
  const {
    state: projectState,
  } = useProjectContext();

  const {
    floor,
  } = projectState;

  const {
    setScale,
  } = usePanZoomContext();

  const mapContainerRef = createRef<HTMLDivElement>();

  const [mapX, setMapX] = useState<number>(0);
  const [mapY, setMapY] = useState<number>(0);
  const [selectedTransformationType, setSelectedTransformationType] = useState<TransformationType>(TransformationType.from_abs);
  const [opacity, setOpacity] = useState<number>(0.5);

  const transformingToAbs = selectedTransformationType === TransformationType.to_abs;
  const transformingFromAbs = selectedTransformationType === TransformationType.from_abs;

  const onChangeRadioButton = (transformationType: TransformationType) => {
    setSelectedTransformationType(transformationType);
    setScale(0.5);
    setOpacity(0.5);
    setMapX(0);
    setMapY(0);
  }

  const onChangeOpacity = (step: number) => {
    let newOpacity = opacity + step;
    newOpacity = Math.round(newOpacity * 10) / 10

    if (newOpacity >= 0 && newOpacity <= 1) {
      setOpacity(newOpacity);
    }
  }

  const lidarMapImage = (
    <TransformationImage
      transformation={transformationToAbs}
      src={selectedLidarMap.web_image_url}
      alt="Lidar Map"
      opacity={opacity}
      undergoingTransformation={transformingToAbs}
    />
  );

  const floorPlanImage = (
    <TransformationImage
      transformation={transformationFromAbs}
      src={floor.latest_floor_plan?.web_image_url}
      alt="Floor Plan"
      opacity={opacity}
      undergoingTransformation={transformingFromAbs}
    />
  );

  return (
    <PanZoomContainer
      style={{
        border: 'none',
      }}
    >
      <CloseButtonContainer>
        <IconButton
          icon={iconClose}
          size={45}
          onClick={onReturnToPointSelection}
          style={{position: 'static'}}
        />
      </CloseButtonContainer>
      <PanZoom
        mapContainerRef={mapContainerRef}
        minScale={0.1}
        maxScale={5}
        x={mapX}
        updateX={setMapX}
        y={mapY}
        updateY={setMapY}
      >
        <div>
          {lidarMapImage}
          {floorPlanImage}
        </div>
      </PanZoom>
      <ControlsContainer>
        <div>
          <TransformationRadioButton
            id='from-absolute'
            label='From Absolute'
            checked={transformingFromAbs}
            onChange={() => onChangeRadioButton(TransformationType.from_abs)}
          />
          <TransformationRadioButton
            id='to-absolute'
            label='To Absolute'
            checked={transformingToAbs}
            onChange={() => onChangeRadioButton(TransformationType.to_abs)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <label htmlFor="opacity-selector">Opacity</label>
          <IconButton
            disabled={opacity === 0}
            icon={iconMinus}
            size={35}
            onClick={() => onChangeOpacity(-0.1)}
            style={{position: 'static'}}
          />
          <StyledInput
            id="opacity-selector"
            type="number"
            min={0}
            max={1}
            step={0.1}
            value={opacity}
            onChange={(e: any) => setOpacity(parseFloat(e.target.value))}
            style={{
              margin: '0px',
              width: '75px'
            }}
          />
          <IconButton
            disabled={opacity === 1}
            icon={iconPlus}
            size={35}
            onClick={() => onChangeOpacity(0.1)}
            style={{position: 'static'}}
          />
        </div>
      </ControlsContainer>
      <ButtonContainer>
        <Button
          text='Return to Point Selection'
          onClick={onReturnToPointSelection}
          style={{marginRight: '10px'}}
        />
        {confirmationInProgress &&
          <LoadingIndicator
            indicatorFlexStyle={{width: 'auto'}}
          />
        }
        {!confirmationInProgress &&
          <Button
            primary
            text='Confirm Transformation'
            onClick={onConfirmTransformation}
          />
        }
      </ButtonContainer>
    </PanZoomContainer>
  )
}

interface ITransformationImageProps {
  transformation: Transformation;
  src: string | null;
  alt: string;
  opacity: number;
  undergoingTransformation: boolean;
}

const TransformationImage = ({
  transformation,
  src,
  alt,
  opacity,
  undergoingTransformation
}: ITransformationImageProps) => {
  const linearTransformation = transformation.linear_transformation;
  const translation = transformation.translation;

  const matrixTransformation = `matrix(${linearTransformation[0][0]}, ${linearTransformation[1][0]}, ${linearTransformation[0][1]}, ${linearTransformation[1][1]}, ${translation[0]}, ${translation[1]})`

  return (
    <img
      src={src ?? undefined}
      alt={alt}
      style={{
        position: 'absolute',
        transformOrigin: undergoingTransformation ? 'left top' : undefined,
        transform: undergoingTransformation ? matrixTransformation : undefined,
        opacity: undergoingTransformation ? opacity : 1,
        zIndex: undergoingTransformation ? 1 : 0,
      }}
    />
  )
}

interface ITransformationRadioButtonProps {
  id: string;
  checked: boolean;
  label: string;
  onChange: () => void;
}

const TransformationRadioButton = ({
  id,
  checked,
  label,
  onChange,
}: ITransformationRadioButtonProps) => {
  return (
    <>
      <input type='radio' name='transformation' id={id} checked={checked} onChange={onChange}/>
      <label htmlFor={id} style={{marginRight: '10px'}}>{label}</label>
    </>
  )
}

const commonContainerProps = `
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px;
`;

const CloseButtonContainer = styled.div`
  ${commonContainerProps}
  justify-content: flex-end;
  padding: 0px;
`;

const ControlsContainer = styled.div`
  justify-content: space-between;
  height: 75px;
  ${commonContainerProps}
`;

const ButtonContainer = styled.div`
  height: 75px;
  ${commonContainerProps}
`;