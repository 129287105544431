import { Route, Switch } from "react-router-dom";
import { PATH_STRINGS } from "../../../../hooks/useGeneratedPaths";
import { LidarMapConfigurationsDashboard } from "./LidarMapConfigurationsDashboard";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { useProjectContext } from "../../../../contexts/projectContext";
import { ManageLidarMapConfiguration } from "./ManageLidarMapConfiguration";

export const ManageLidarMapConfigurations = () => {
  const {
    state: projectState,
  } = useProjectContext();

  if (!projectState.projectId) {
    return <LoadingIndicator/>;
  }

  return (
    <Switch>
      <Route path={PATH_STRINGS.projectLidarMapConfigurations}>
        <LidarMapConfigurationsDashboard/>
      </Route>
      <Route exact path={PATH_STRINGS.projectLidarMapConfiguration}>
        <ManageLidarMapConfiguration/>
      </Route>
    </Switch>
  )
}