import axios, {AxiosResponse} from "axios";

export const retrieveObservation = async (projectId: string, observationId: number) => {
    return await axios.get(`https://api.nexterarobotics.com/api/project/${projectId}/observation/${observationId}`)
        .then( (r: AxiosResponse) => r.data)
        .catch( e => console.log(e))
}

export const updateObservation = async (projectId: string, observationId: number, data: any) => {
    return await axios.patch(`https://api.nexterarobotics.com/api/project/${projectId}/observation/${observationId}`, data)
      .then( (r: AxiosResponse) => r.data)
      .catch( e => console.log(e))
}

export const listObservations = async (projectId: string) => {
    return await axios.get(`https://api.nexterarobotics.com/api/project/${projectId}/observations`)
      .then((r: AxiosResponse) => r.data)
      .catch(e => console.log(e))
}
