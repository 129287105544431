import { useEffect } from "react";
import { ItemType } from "../../../../../../api/items";
import { useItemTypesQuery } from "../../../../../../hooks/progressTrackingQueries";
import { Autocomplete, TextField } from "@mui/material";

interface IItempTypesAutocompleteProps {
  selectedItemType: ItemType | null;
  setSelectedItemType: (newValue: ItemType | null) => void;
  selectedTrackerName: string;
  loading?: boolean;
  disabled?: boolean;
}

export const ItemTypesAutocomplete = ({
  selectedItemType,
  setSelectedItemType,
  selectedTrackerName,
  loading=false,
  disabled=false,
}: IItempTypesAutocompleteProps) => {
  const {data: itemTypes, isLoading: itemTypesLoading} = useItemTypesQuery({tracker: selectedTrackerName});

  useEffect(() => {
    if (itemTypes && itemTypes.length > 0) {
      setSelectedItemType(itemTypes[0]);
    }
  }, [itemTypes, setSelectedItemType]);

  return (
    <Autocomplete
      disabled={disabled}
      options={itemTypes ?? []}
      value={selectedItemType}
      onChange={(e, value) => setSelectedItemType(value)}
      getOptionLabel={option => option.name}
      renderInput={(params) => {
        return (
          <TextField {...params} label="Item Type" />
        )
      }}
      sx={{
        width: 200,
      }}
      loading={loading || itemTypesLoading}
    />
  )
}