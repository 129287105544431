import styled from 'styled-components';

const ButtonBase = styled.div`
  padding: 1em;
  border-radius: 2px;
  cursor: pointer;
  max-height: 50px;
  background: #E8E8E8;
  color: #073C7A;
  
  &.primary {
    background: #073C7A;
    color: #E8E8E8;
  }
  
  &.disabled { 
    background: #E8E8E8!important;
    color: #ccc!important;
    cursor: default;
  }
`;

interface IButtonProps {
  primary?: boolean;
  text: string | JSX.Element;
  onClick: any;
  style?: React.CSSProperties;
  disabled?: boolean;
}
export const Button = ({ primary, text, onClick, style, disabled=false }: IButtonProps) => (
  <ButtonBase
    style={{...style}}
    className={`${disabled ? 'disabled' :''} ${primary ? 'primary' : ''}`}
    onClick={disabled ? null:onClick}>
    {text}
  </ButtonBase>
);

