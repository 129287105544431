/**
 * Create custom selection widget for annotations
 * Passed in a list a labels and return back the generated select element.
 *
 * @param args {widget: LabelSelectWidget, labels: []} include labels in the annotorious init
 * @return HTMLDivElement the widget container
 */
import {LabelType} from "../../../../api/labels";


export const LabelSelectWidget = function(args: any) {
    var currentLabel = args.annotation ?
        args.annotation.bodies.find(function(b: any) {
            return b.purpose == 'labeling';
        }) : null;
    var addTag = function(evt: any) {
        let color = evt.target.options[evt.target.options.selectedIndex].getAttribute('data-color')
        if (currentLabel) {
            args.onUpdateBody(currentLabel, {
                type: 'TextualBody',
                purpose: 'labeling',
                value: evt.target.value,
                color: color
            });
        } else {
            args.onAppendBody({
                type: 'TextualBody',
                purpose: 'labeling',
                value: evt.target.value,
                color: color
            });
        }
        return true;
    }
    var container = document.createElement('div');
    container.className = 'colorselector-widget';
    var select = document.createElement('select');
    container.appendChild(select);
    let labels = args.labels
    const groupLabels = labels.reduce((groups: any, item: LabelType) => {
            const group = (groups[item.category.name] || []);
            group.push(item);
            groups[item.category.name] = group;
            return groups;
        }, {});
    Object.keys(groupLabels).sort().forEach( (group: any) => {
        var optgroup = document.createElement("optgroup");
        optgroup.label = group
        groupLabels[group].forEach( (type: LabelType) => {
            var option = document.createElement("option");
            option.value = type.id?.toString() || type.name;
            option.text = type.name;
            option.id = type.id?.toString()|| type.name;
            option.setAttribute("data-color", type.color)
            optgroup.appendChild(option);
        })
        select.appendChild(optgroup)
    })
    if(currentLabel){
        let lt = labels.find( (label: LabelType) => label.name === currentLabel.value)
        if(lt)
            select.value = lt.id
        else
            select.value = currentLabel.value
    }
    select.addEventListener('change', addTag, true);
    return container;
}
