import axios from "axios";
import { Company } from "./companies";

export interface ProjectSubcontractor {
  project: number;
  subcontractor: Company;
  id: number;
}

export const fetchProjectSubcontractors = async (projectId: string) => {
  const res = await axios.get(
    `https://api.nexterarobotics.com/api/project/${projectId}/subcontractors`
  );
  const response = await res.data;
  return response.data;
}

export const createProjectSubcontractor = async (projectId: string, data: Company) => {
  const res = await axios.post(
    `https://api.nexterarobotics.com/api/project/${projectId}/subcontractor`,
    {
      subcontractor: {
        public_id: data.public_id
      }
    }
  );
  const response = await res.data;
  return response.data;
}

export const deleteProjectSubcontractorMapping = async (projectId: string, companyId: string) => {
  const res = await axios.delete(
    `https://api.nexterarobotics.com/api/project/${projectId}/subcontractor/${companyId}`
  );
  const response = await res.data;
  return response.data;
}