import { useMemo, useEffect } from 'react';
import { useProjectContext } from "../../../../contexts/projectContext"
import styled from "styled-components";
import { PanZoomProvider } from "../../../common/PanZoom/PanZoomContext";
import { useState } from "react";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { useProjectFloorsQuery } from "../../../../hooks/projectQueries";
import { Select } from "../../../common/Inputs/Select";
import { processForgeViewerTransformation, ProjectFloor } from '../../../../api/projectFloors';
import { IconButton } from '../../../common/IconButton';
import iconClose from '../../../../assets/images/icons/icon_close.svg';
import { useProjectNavigation } from '../../../../hooks/useNavigation';
import { MapPointPicker, PanZoomContainer } from '../project-floors/LidarPointPicker/MapPointPicker';
import { TranslationPoint, TranslationPointType } from '../project-floors/LidarPointPicker/ProjectFloorLidarPointPicker';
import { v4 as uuidv4 } from 'uuid';
import { ForgeViewerPointPicker } from './ForgeViewerPointPicker';
import { Button } from '../../../common/Inputs/Button';
import { Checkbox } from '../../../common/Inputs/Checkbox';

import { ProjectForgeTransformationKey } from './ProjectForgeTransformationKey';
import { updateProject } from '../../../../api/projects';
import { useNotifications } from '../../../../contexts/notificationProvider';

export interface FloorPoint extends TranslationPoint {
  floor_code: string;
}

export interface ForgePoint {
  vector: THREE.Vector3;
  floor_code: string;
  selectingFloorHeight: boolean;
}

export const ProjectForgeTransformation = () => {
  const {
    addNotification,
  } = useNotifications();

  const {
    navigateToProject,
  } = useProjectNavigation();

  const {
    state: projectState,
    updateProject: dispatchProject,
  } = useProjectContext();

  const project = projectState.project;
  const floors = project.floors;

  const onUpdateProjectFloors = (floors: ProjectFloor[]) => {
    dispatchProject({floors: floors});
    setFloorPlanSelectedFloor(floors[0]);
    setForgeViewerSelectedFloor(floors[0]);
  }

  const { isLoading: floorsLoading } = useProjectFloorsQuery(projectState.projectId, onUpdateProjectFloors);
  const floorsLoaded = !!floors && !floorsLoading;

  const [floorPlanSelectedFloor, setFloorPlanSelectedFloor] = useState<ProjectFloor>();
  const [forgeViewerSelectedFloor, setForgeViewerSelectedFloor] = useState<ProjectFloor>();
  const [floorPoints, setFloorPoints] = useState<FloorPoint[]>([]);
  const [forgePoints, setForgePoints] = useState<ForgePoint[]>([]);
  const [selectingFloorHeight, setSelectingFloorHeight] = useState<boolean>(false);

  const processTransformationDisabled = useMemo(() => {
    const forgePointCountsMap = new Map<string, number>();
    const forgeFloorHeights = new Map<string, number>();
    const floorPointCountsMap = new Map<string, number>();
    let disabled = false;

    if (floorsLoaded) {
      forgePoints.forEach(point => {
        if (!point.selectingFloorHeight) {
          const currentCount = forgePointCountsMap.get(point.floor_code) ?? 0;

          forgePointCountsMap.set(point.floor_code, currentCount + 1);
        } else {
          forgeFloorHeights.set(point.floor_code, point.vector.z);
        }
      });

      floorPoints.forEach(point => {
        const currentCount = floorPointCountsMap.get(point.floor_code) ?? 0;

        floorPointCountsMap.set(point.floor_code, currentCount + 1);
      });

      floors.forEach((floor: ProjectFloor) => {
        const forgeFloorCount = forgePointCountsMap.get(floor.floor_code) ?? 0;
        const floorFloorCount = floorPointCountsMap.get(floor.floor_code) ?? 0;
        const floorHeight = forgeFloorHeights.get(floor.floor_code);

        if (forgeFloorCount < 3 || forgeFloorCount !== floorFloorCount || floorHeight === undefined) {
          disabled = true;
        }
      });

      return disabled;
    } else {
      return true;
    }
  }, [floorPoints, forgePoints, floors, floorsLoaded]);

  const floorSelectOptions = useMemo(() => {
    if (floorsLoaded) {
      return floors.map((floor: ProjectFloor) => floor.floor_code);
    }

    return [];
  }, [floors, floorsLoaded]);

  const currentlySelectedFloorPoints = useMemo(() => {
    const selectedFloorCode = floorPlanSelectedFloor?.floor_code;

    if (selectedFloorCode) {
      return floorPoints.filter(point => point.floor_code === selectedFloorCode);
    }

    return []
  }, [floorPoints, floorPlanSelectedFloor?.floor_code]);

  const getFloorFromFloorCode = (floorCode: string) => {
    const floor = floors.filter((floor: ProjectFloor) => floor.floor_code === floorCode)[0];

    return {...floor};
  }

  const onChangeSelectedFloor = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const updatedFloor = getFloorFromFloorCode(e.target.value);
    
    setFloorPlanSelectedFloor(updatedFloor);
    setForgeViewerSelectedFloor(updatedFloor);
  }

  const onDoubleClickMap = (e: React.MouseEvent, type: TranslationPointType, x: number, y: number) => {
    const newPoint = {id: uuidv4(), x, y, type, floor_code: floorPlanSelectedFloor?.floor_code ?? ''};

    setFloorPoints(prevPoints => [...prevPoints, newPoint]);
  }

  const onMouseMoveMap = (e: React.MouseEvent, currentDraggingPointId: string | null, x: number, y: number) => {
    if (currentDraggingPointId !== null) {
      const updatedPoints = floorPoints.map(point => {
        if (point.id !== currentDraggingPointId) {
          return point;
        } else {
          return {...point, x, y};
        }
      });

      setFloorPoints(updatedPoints);
    }
  }

  const onClearSelectedFloorPoints = (selectedPoints: Set<string>) => {
    setFloorPoints(prevPoints => prevPoints.filter(point => !selectedPoints.has(point.id)));
  }

  const onClickProcessTransformation = async () => {
    const translation_json = await processForgeViewerTransformation(floorPoints, forgePoints);
    
    try {
      await updateProject(projectState.projectId, { forge_translations: JSON.stringify(translation_json) });
      addNotification('Translation saved successfully', 'success');
    } catch (err) {
      console.log('UPDATE FORGE TRANSLATION ERROR: ', err);
      addNotification('Error saving translation', 'error');
    }
  }

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        setSelectingFloorHeight(true);
      }
    }

    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        setSelectingFloorHeight(false);
      }
    }

    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);

    // return () => {
    //   document.removeEventListener('keydown', onKeyDown);
    //   document.removeEventListener('keyup', onKeyUp);
    // }
  }, []);

  return (
    <ForgeTransformationContainer>
      <FloatContainer float='left'>
        <PanZoomContainer style={{borderRight: 'none'}}>
          <ForgeViewerPointPicker
            forgePoints={forgePoints}
            setForgePoints={setForgePoints}
            fileUrn={project.forge_model_urn}
            selectingFloorHeight={selectingFloorHeight}
            setSelectingFloorHeight={setSelectingFloorHeight}
            selectedFloorCode={forgeViewerSelectedFloor?.floor_code ?? ''}
          />
        </PanZoomContainer>
        <MapOverlayContainer>
          <Select
            useInitialEmptyOption
            disabled={!floorsLoaded}
            options={floorSelectOptions}
            value={forgeViewerSelectedFloor?.floor_code ?? ''}
            onSelect={onChangeSelectedFloor}
            selectContainerStyles={{minWidth: '150px'}}
          />
          <Checkbox
            label="Selecting Floor"
            onSelect={() => setSelectingFloorHeight(prevValue => !prevValue)}
            value={selectingFloorHeight}
            labelStyles={{color: 'white'}}
          />
        </MapOverlayContainer>
      </FloatContainer>
      <FloatContainer float='right'>
        {floorsLoading && (
          <LoadingIndicatorContainer>
            <LoadingIndicator />
          </LoadingIndicatorContainer>
        )}
        <PanZoomProvider initialScale={0.3}>
          <MapPointPicker
            minScale={0.1}
            maxScale={3}
            imageUrl={floorPlanSelectedFloor?.latest_floor_plan?.web_image_url}
            onDoubleClickMap={onDoubleClickMap}
            onMouseMoveMap={onMouseMoveMap}
            points={currentlySelectedFloorPoints}
            pointType={TranslationPointType.floorPlan}
            onClearSelectedPoints={onClearSelectedFloorPoints}
          />
        </PanZoomProvider>
        <MapOverlayContainer>
          <Select
            useInitialEmptyOption
            disabled={!floorsLoaded}
            options={floorSelectOptions}
            value={floorPlanSelectedFloor?.floor_code ?? ''}
            onSelect={onChangeSelectedFloor}
            selectContainerStyles={{minWidth: '150px'}}
          />
          <IconButton
            icon={iconClose}
            size={45}
            onClick={() => navigateToProject(project.public_id)}
            style={{position: 'static'}}
          />
        </MapOverlayContainer>
      </FloatContainer>
      <div style={{clear: 'both'}}></div>
      <ButtonContainer>
        <Button
          primary
          disabled={processTransformationDisabled}
          text="Process Transformation"
          onClick={onClickProcessTransformation}
        />
      </ButtonContainer>
      <ProjectForgeTransformationKey/>
    </ForgeTransformationContainer>
  )
}

const LoadingIndicatorContainer = styled.div`
  background: #f8f8f8;
  height: 100%;
  width: 100%;
`

const ForgeTransformationContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const FloatContainer = styled.div<{float: 'left' | 'right', width?: number}>`
  width: ${props => props.width ?? 50}%;
  height: 92%;
  background-color: #f8f8f8;
  float: ${props => props.float};
  position: relative;
`;

const MapOverlayContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
`;

const ButtonContainer = styled.div`
  height: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
`;