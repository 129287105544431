import axios from "axios";

// const BASE_URL = 'http://localhost:8000'
const BASE_URL = 'https://api.nexterarobotics.com'

export const listLabelTypes = async (params?: any) => {
    return await axios.get(`${BASE_URL}/api/label-types`, {params: params})
        .then(r => r.data)
        .catch(e => console.log)
}

export const createLabel = async (label: Label) => {
    return await axios.post(`${BASE_URL}/api/label`, label)
        .then(r => r.data)
        .catch(e => console.log)
}

export const updateLabel = async (label: Label) => {
    return await axios.put(`${BASE_URL}/api/label/${label.id}`, label)
        .then(r => r.data)
        .catch(e => console.log)
}

export const deleteLabel = async (label: Label) => {
    return await axios.delete(`${BASE_URL}/api/label/${label.id}`)
        .then(r => console.log(r))
        .catch(e => console.log)
}

export interface LabelCategory {
    name: string;
    type: string;
}

export interface LabelType {
    id?:number;
    category: LabelCategory;
    name: string;
    description?: string | null;
    internal_code?: string | null;
    is_hazard: boolean;
    color: string;
}

export interface Label {
    id?: number | null;
    processed_image: number;
    coordinates: string;
    confidence: number;
    type?: LabelType | number;
    type_id?: number;
    shape: string;
    is_manual: boolean;
}
