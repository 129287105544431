import styled from "styled-components";

interface ISidebarCardProps {
    heading: string;
    style?: any;
    children?: any;
}
export const SidebarCard = ({heading, style, children}: ISidebarCardProps) => {
    return(
    <SidebarCardContainer style={style} className="sidebar-card">
        <SidebarCardHeader>
            <h2>
                {heading}
            </h2>
        </SidebarCardHeader>
        <SidebarCardBody>
            {children}
        </SidebarCardBody>
    </SidebarCardContainer>
    )
}

const SidebarCardContainer = styled.div`
    background: #222;
    border: 1px solid #ccc;
    margin: 10px;
`;
const SidebarCardHeader = styled.div`
    background: #ccc;
    color: #222;
    text-align: center;
    padding: 5px 10px;
`;

const SidebarCardBody = styled.div`
    padding: 10px;
    height: 90%;
`;
