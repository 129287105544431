import { Autocomplete, TextField } from "@mui/material";
import { useMemo } from "react";
import { useProjectTrackersQuery } from "../../../../../../hooks/progressTrackingQueries";

interface IProjectTrackersAutoCompleteProps {
  projectId: string | null;
  selectedTrackerName: string | null;
  setSelectedTrackerName: (newValue: string | null | undefined) => void;
  loading?: boolean;
  disabled?: boolean;
}

export const ProjectTrackersAutoComplete = ({
  projectId,
  selectedTrackerName,
  setSelectedTrackerName,
  loading = false,
  disabled=false,
}: IProjectTrackersAutoCompleteProps) => {
  const {data: trackers, isLoading: trackersLoading} = useProjectTrackersQuery(projectId);

  const selectedProjectTracker = useMemo(() => {
    if (trackers) {
      return trackers.find(tracker => tracker.type.name === selectedTrackerName);
    }

    return null;
  }, [selectedTrackerName, trackers]);

  return (
    <Autocomplete
      options={trackers ?? []}
      value={selectedProjectTracker}
      loading={loading || trackersLoading}
      disabled={!projectId || disabled}
      onChange={(e, value) => setSelectedTrackerName(value?.type.name)}
      getOptionLabel={option => option.type.name}
      isOptionEqualToValue={(option, value) => option.type.name === value?.type.name}
      renderInput={(params) => {
        return (
          <TextField {...params} label="Tracker" />
        )
      }}
      sx={{
        width: 200,
      }}
    />
  );
}