import styled from 'styled-components';
import { Flex } from '@react-css/flex';

const SelectBase = styled.select`
  padding: 1em;
  border: 1px solid #073c7a;
  color: #073c7a;
  margin: 0 5px;
  height: fit-content;
`;

const SelectLabel = styled.label`
  color: ${props => props.theme === 'dark' ? '#073c7a' : '#fff'};
  font-size: 1em;
  text-align: center;
  margin-bottom: 5px;
`;

interface IProps {
  disabled?: boolean;
  label?: string;
  labelStyles?: React.CSSProperties;
  options: any;
  value: string | number | readonly string[] | undefined;
  onSelect: (value: any) => void;
  theme?: string;
  selectStyles?: React.CSSProperties;
  selectContainerStyles?: React.CSSProperties;
  useInitialEmptyOption?: boolean;
  getOptionValue?: (option: any) => any;
  getOptionLabel?: (option: any) => string;
  helperText?: string;
  helperTextStyle?: React.CSSProperties;
}
export const Select = ({ 
  disabled,
  label,
  labelStyles,
  options,
  value,
  onSelect,
  theme='dark',
  selectStyles,
  selectContainerStyles,
  useInitialEmptyOption,
  getOptionValue,
  getOptionLabel,
  helperText,
  helperTextStyle,
 }: IProps) => {
  // const onChangeSelect = (value: any) => {
  //   if (!disabled) {
  //     onSelect(value);
  //   }
  // }

  return (
    <Flex flexDirection="column" alignContentEnd={true} justifyCenter={true} style={selectContainerStyles}>
      { !!label && <SelectLabel theme={theme} style={labelStyles}>{label}</SelectLabel> }
      <SelectBase
        disabled={disabled}
        value={value}
        onChange={onSelect}
        style={{ backgroundColor: disabled ? 'lightgray' : 'white', ...selectStyles }}
      >
        { useInitialEmptyOption &&
          <option key='empty' selected disabled hidden></option>
        }

        {options.map((option: any, index: number) => {
          const optionValue = getOptionValue ? getOptionValue(option) : option;
          const optionLabel = getOptionLabel ? getOptionLabel(option) : option;
          let optionStyle = {};

          if (typeof option === 'object' && option.style) {
            optionStyle = option.style;
          }

          return (
            <option
              key={option + index}
              value={optionValue}
              style={optionStyle}
            >
              {optionLabel}
            </option>
          );
        })}
      </SelectBase>
      {helperText &&
        <HelperText
          style={helperTextStyle}
        >
          {helperText}
        </HelperText>
      }
    </Flex>
  );
};

const HelperText = styled.span`
  font-size: 12px;
  margin: 2px 5px 0px;
`;
