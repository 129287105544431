import { createRef, useMemo, useState } from "react";
import PanZoom from "../../../../../common/PanZoom/PanZoom";
import { PanZoomProvider } from "../../../../../common/PanZoom/PanZoomContext"
import { useProgressContext } from "../../../../../../contexts/progressContext";
import { Box } from "@mui/material";
import { ProgressItemsEditorMapViewSVG } from "./ProgressItemsEditorMapViewSVG";
import { useProgressItemsContext } from "../../../../../../contexts/progressItemsContext";

export const ProgressItemsEditorMapView = () => {
  const {
    state: progressState,
  } = useProgressContext();

  const {
    floor
  } = progressState;
  
  const {
    state: progressItemsState,
    selectedStlFloorPlan,
  } = useProgressItemsContext();

  const {
    showSTLFloorPlan,
  } = progressItemsState;

  const [mapX, setMapX] = useState<number>(0);
  const [mapY, setMapY] = useState<number>(0);
  const [mapWidth, setMapWidth] = useState<number | undefined>();
  const [mapHeight, setMapHeight] = useState<number | undefined>();

  const mapContainerRef = createRef<HTMLDivElement>();

  const onLoadImage = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const target = e.target as HTMLImageElement;

    if (target) {
      setMapWidth(target.width);
      setMapHeight(target.height);
    }
  }

  const floorPlanImageSrc = useMemo(() => {
    if (showSTLFloorPlan && selectedStlFloorPlan) {
      return selectedStlFloorPlan.stl_floor_plan_s3_url;
    } else if (!!floor && !!floor.latest_floor_plan && !!floor.latest_floor_plan.web_image_url) {
      return floor.latest_floor_plan.web_image_url;
    }

    return null;
  }, [floor, selectedStlFloorPlan, showSTLFloorPlan]);

  return (
    <Box
      height="100%"
    >
      <PanZoomProvider initialScale={0.3}>
        <PanZoom
          mapContainerRef={mapContainerRef}
          minScale={0.1}
          maxScale={20}
          x={mapX}
          updateX={setMapX}
          y={mapY}
          updateY={setMapY}
        >
          {!!floorPlanImageSrc && (
            <>
              <img
                alt="floor plan"
                src={floorPlanImageSrc}
                style={{
                  position: 'absolute',
                }}
                onLoad={e => onLoadImage(e)}
              />
              <Box
                position="absolute"
                height={mapHeight}
                width={mapWidth}
              >
                <ProgressItemsEditorMapViewSVG/>
              </Box>
            </>
          )}
        </PanZoom>
      </PanZoomProvider>
    </Box>
  );
}