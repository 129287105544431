/**
 * Function to format Annotorious labels.
 * Displays box ID or type and shades the fill for each type.
 *
 * @param {object} annotation the label object to be formatted
 * @returns {element, styles}
 */
export const LabelColorFormatter = function(annotation: any) {
    var highlightBody = annotation.bodies.find(function(b: any) {
        return b.purpose == 'labeling';
    });

    if (highlightBody) {
        const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');

        foreignObject.setAttribute('width', '100%');
        foreignObject.setAttribute('height', '50px');

        foreignObject.innerHTML = `
          <div xmlns="http://www.w3.org/1999/xhtml" class="a9s-shape-label-wrapper">
            <div class="a9s-shape-label" style="margin-left: 10px">
              ${annotation.id}
            </div>
          </div>`;

        return {
            element: foreignObject,
            style: `stroke: ${highlightBody.color || "#fff"}; fill: ${highlightBody.color || "#fff"}; fill-opacity: 0.3; stroke-width: 2;`
        };
    }

}
