import React from 'react';
import { StyledInput } from "../../../common/FormControls"

interface IStyledInputProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
}

export const StyledFileInput = React.forwardRef<HTMLInputElement, IStyledInputProps>(({onChange, style}, ref) => {
  return (
    <StyledInput
      ref={ref}
      type='file'
      onChange={onChange}
      style={{ backgroundColor: 'white', marginTop: '30px', ...style }}
    />
  )
})