import { useMemo } from "react";
import { Column } from "react-table";
import { useLocationsQuery } from "../../../hooks/projectQueries";
import { Table } from "../../common/Table/Table";
import styled from "styled-components"
import { Button } from "../../common/Inputs/Button";
import { LoadingIndicator } from "../../common/LoadingIndicator";
import { useLocationNavigation } from "../../../hooks/useNavigation";
import { Location } from "../../../api/locations";

export const ManageLocations = () => {
  const {
    navigateToLocation,
  } = useLocationNavigation();

  const { data: locations, isLoading: locationsLoading } = useLocationsQuery();

  const data = useMemo(() => {
    if (locationsLoading) {
      return [];
    }

    return locations;
  }, [locationsLoading, locations]);

  const columns = useMemo((): Column[] => {
    return [
      { Header: 'City', accessor: 'city' },
      { Header: 'State', accessor: 'state_code' },
      { Header: 'Country', accessor: 'country_code' },
    ]
  }, []);

  if (locationsLoading) {
    return <LoadingIndicator/>
  }

  const onClickAddLocation = () => {
    navigateToLocation('new');
  }

  const onClickRow = (row: Location) => {
    navigateToLocation(row.id.toString());
  }

  return (
    <LocationDashboardContainer>
      <AddNewLocationButtonContainer>
        <Button
          primary
          text='Add New Location'
          onClick={onClickAddLocation}
        />
      </AddNewLocationButtonContainer>
      <Table
        data={data}
        columns={columns}
        onClickRow={onClickRow}
      />
    </LocationDashboardContainer>
  )
}

const LocationDashboardContainer = styled.div`
  margin-top: 1em;
`;

const AddNewLocationButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 20px 20px 0;
`;