import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react"
import { createNewProject, updateProject } from "../../../../api/projects";
import { useNotifications } from "../../../../contexts/notificationProvider";
import { FormHeader, StyledInput, StyledLabel, SaveButtonContainer, FormContainer, FormCheckbox } from "../../../common/FormControls";
import { Button } from "../../../common/Inputs/Button";
import { Select } from "../../../common/Inputs/Select";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { useProjectNavigation } from "../../../../hooks/useNavigation";
import { useProjectContext } from "../../../../contexts/projectContext";
import { useCompaniesQuery, useLocationsQuery } from "../../../../hooks/projectQueries";
import { Company } from "../../../../api/companies";
import { Location } from "../../../../api/locations";

const selectStyles = { 
  margin: '0 0 20px 0',
  width: 400
}

export const ProjectDetails = () => {
  const {
    state: projectState,
    updateProject: dispatchProject,
  } = useProjectContext();

  const { project } = projectState;

  const {
    navigateToProject,
  } = useProjectNavigation();

  const { data: companies, isLoading: companiesLoading } = useCompaniesQuery();
  const { data: locations, isLoading: locationsLoading } = useLocationsQuery();

  const [tzTime, setTzTime] = useState<string | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  
  const projectIsNew = projectState.projectId === 'new';

  useEffect(() => {
    if (project.time_zone) {
      intervalRef.current = setInterval(() => {
        setTzTime(new Date().toLocaleString('en-US', {timeZone: project.time_zone}));
      }, 1000);
      
      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      }
    } else {
      setTzTime(null);
      
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }
    
  }, [project.time_zone]);

  const companyOptions = useMemo(() => {
    if (companiesLoading) {
      return [];
    }

    return companies.map((company: Company) => company.name);
  }, [companiesLoading, companies]);

  const locationOptions = useMemo(() => {
    if (locationsLoading) {
      return [];
    }

    return locations.map((location: Location) => location.city);
  }, [locationsLoading, locations]);

  const { addNotification } = useNotifications();

  const validForSubmit = !!project.name && !!project.name.trim() && !!project.company.public_id && !!project.time_zone && !!project.location.city;

  if (companiesLoading || locationsLoading) {
    return <LoadingIndicator/>
  }

  const onChangeCompany = (e: ChangeEvent<HTMLSelectElement>) => {
    const companyName = e.target.value;
    const selectedCompany = companies.filter((company: Company) => company.name === companyName)[0];

    dispatchProject({ company: selectedCompany });
  }

  const onChangeLocation = (e: ChangeEvent<HTMLSelectElement>) => {
    const locationCity = e.target.value;
    const selectedLocation = locations.filter((location: Location) => location.city === locationCity)[0];

    dispatchProject({ location: selectedLocation });
  }

  const onChangeStatus = (e: ChangeEvent<HTMLSelectElement>) => {
    const status = e.target.value;

    dispatchProject({status: status});
  }

  const onCreateNewProject = async () => {
    try {
      const createdProject = await createNewProject(project);
      navigateToProject(createdProject.public_id);
      addNotification('Project created successfully', 'success');
    } catch (err) {
      console.log('onCreateNewProject==>>', err);
      addNotification('Error creating new project', 'error');
    }
  }

  const onSaveExistingProject = async () => {
    try {
      const updatedProjectInformation = {
        name: project.name,
        company: project.company,
        location: project.location,
        time_zone: project.time_zone,
        status: project.status,
        has_floor_transitions: project.has_floor_transitions,
      };

      const updatedProject = await updateProject(projectState.projectId, updatedProjectInformation);
      dispatchProject(updatedProject);
      addNotification('Project updated successfully', 'success');
    } catch (err) {
      console.log('onSaveExistingProject==>>', err);
      addNotification('Error updating project', 'error');
    }
  }

  const onClickSave = () => {
    if (validForSubmit) {
      if (projectIsNew) {
        onCreateNewProject();
      } else {
        onSaveExistingProject();
      }
    }
  }

  const saveButtonText: string = projectIsNew ? 'Create' : 'Save';
  
  return (
    <FormContainer>      
      { projectIsNew &&
        <FormHeader>
          New Project
        </FormHeader>
      }

      <StyledLabel htmlFor='name'>Project Name:</StyledLabel>
      <StyledInput
        id='name'
        value={project.name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatchProject({ name: e.target.value })}
      />

      <StyledLabel>Company:</StyledLabel>
      <Select
        options={companyOptions}
        value={project.company.name}
        onSelect={onChangeCompany}
        selectStyles={selectStyles}
        useInitialEmptyOption
      />

      <StyledLabel>Location:</StyledLabel>
      <Select
        options={locationOptions}
        value={project.location.city}
        onSelect={onChangeLocation}
        selectStyles={selectStyles}
        useInitialEmptyOption
      />
      <StyledLabel>Time Zone:</StyledLabel>
      <Select
        options={(Intl as any).supportedValuesOf('timeZone')}
        value={project.time_zone}
        onSelect={e => dispatchProject({time_zone: e.target.value})}
        selectStyles={{...selectStyles, ...tzTime ? {marginBottom: 0} : {}}}
        useInitialEmptyOption
        helperText={tzTime ? `Current Time: ${tzTime}` : undefined}
        helperTextStyle={{
          margin: '2px 0 20px 0'
        }}
      />

      <FormCheckbox
        label="Has Floor Transitions?"
        checked={project.has_floor_transitions}
        onChangeChecked={checked => dispatchProject({has_floor_transitions: checked})}
      />

      {!projectIsNew &&
        <>
          <StyledLabel>Status:</StyledLabel>
          <Select
            options={['active', 'hold', 'done']}
            value={project.status}
            onSelect={onChangeStatus}
            selectStyles={selectStyles}
            useInitialEmptyOption
          />
        </>
      }

      <SaveButtonContainer>
        <Button
          primary={validForSubmit}
          disabled={!validForSubmit}
          text={saveButtonText}
          onClick={onClickSave}
          style={{ display: 'inline-block' }}
        />
      </SaveButtonContainer>
    </FormContainer>
  )
}