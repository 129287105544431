import { useState, createRef, useEffect } from 'react';
import PanZoom from "../../../../common/PanZoom/PanZoom";
import styled from 'styled-components';
import { TranslationPoint, TranslationPointType } from "./ProjectFloorLidarPointPicker";
import { Select } from "../../../../common/Inputs/Select"
import { LidarMap } from '../../../../../api/projectFloorLidarMaps';
import { Button } from '../../../../common/Inputs/Button';
import { usePanZoomContext } from '../../../../common/PanZoom/PanZoomContext';
import { MapPointPickerPoint } from './MapPointPickerPoint';
import { MapPointPickerLine } from './MapPointPickerLine';
import { MapPointPickerLineLegend } from './MapPointPickerLineLegend';

const getClickCoordinates = (event: React.MouseEvent) => {
  return {
    x: event.nativeEvent.offsetX,
    y: event.nativeEvent.offsetY,
  };
}

const coordinateColors = ['#990000', '#FFCC00', 'DarkBlue', 'ForestGreen', 'Purple'];

export interface MapLine {
  name?: string;
  color?: string;
  points: [number, number][];
}

interface IBasePointPickerProps {
  minScale: number;
  maxScale: number;
  imageUrl: string | null | undefined;
  onDoubleClickMap: (e: React.MouseEvent<Element, MouseEvent>, type: TranslationPointType, x: number, y: number) => void;
  onMouseMoveMap: (e: React.MouseEvent<SVGElement>, id: string | null, x: number, y: number) => void;
  points: TranslationPoint[];
  pointType: TranslationPointType;
  selectedLidarMap?: LidarMap | undefined;
  setSelectedLidarMap?: (newMapId: string | undefined) => void;
  onClearSelectedPoints: (points: Set<string>) => void;
  lidarMaps?: LidarMap[];
  startPoint?: {x: string | number, y: string | number} | null;
  defaultShowLines?: boolean;
  lines?: MapLine[];
  linesLoaded?: boolean;
  showLinesButtonText?: string;
  hideLinesButtonText?: string;
}

interface ILidarPointPickerProps extends IBasePointPickerProps {
  pointType: TranslationPointType.lidar;
  selectedLidarMap: LidarMap | undefined;
  setSelectedLidarMap: (newMapId: string | undefined) => void;
  lidarMaps: LidarMap[];
}

interface IFloorPlanPointPickerProps extends IBasePointPickerProps {
  pointType: TranslationPointType.floorPlan;
}

type IMapPointPickerProps = ILidarPointPickerProps | IFloorPlanPointPickerProps;

export const MapPointPicker = ({
  minScale,
  maxScale,
  imageUrl,
  onDoubleClickMap,
  onMouseMoveMap,
  points,
  pointType,
  selectedLidarMap,
  setSelectedLidarMap,
  onClearSelectedPoints,
  lidarMaps,
  startPoint,
  lines,
  linesLoaded=true,
  defaultShowLines=false,
  showLinesButtonText = "Show Lines",
  hideLinesButtonText = "Hide Lines"
}: IMapPointPickerProps) => {
  const {
    scale,
    setScale,
  } = usePanZoomContext();

  const imageRef = createRef<HTMLImageElement>();
  const mapContainerRef = createRef<HTMLDivElement>();
  const [minImageDimension, setMinImageDimension] = useState<number>(0);
  const [selectedPoints, setSelectedPoints] = useState<Set<string>>(new Set());
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [currentDraggingPointId, setCurrentDraggingPointId] = useState<string | null>(null);
  const [showLines, setShowLines] = useState<boolean>(defaultShowLines);

  const [mapX, setMapX] = useState<number>(0);
  const [mapY, setMapY] = useState<number>(0);

  const isLidar = pointType === TranslationPointType.lidar;
  const pointSize = minImageDimension / 200;

  const renderLines = !!lines && lines.length > 0 && showLines && linesLoaded;

  useEffect(() => {
    if (imageRef.current && mapContainerRef.current) {
      setMinImageDimension(Math.min(imageRef.current.width, imageRef.current.height));
    }
  }, [imageRef, mapContainerRef]);

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const target = (e.target) as HTMLImageElement;
    setMinImageDimension(Math.min(target.clientHeight, target.clientWidth));
  }

  const stopEventPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  const onDoubleClickPoint = (e: React.MouseEvent) => {
    stopEventPropagation(e);
  }

  const onMouseDownPoint = (e: React.MouseEvent, id: string) => {
    stopEventPropagation(e);
    setCurrentDraggingPointId(id);
  }

  const onMouseUpPoint = (e: React.MouseEvent, id: string) => {
    const updatedSelectedPoints = new Set(selectedPoints);

    if (!isDragging) {
      if (updatedSelectedPoints.has(id)) {
        updatedSelectedPoints.delete(id);
      } else {
        updatedSelectedPoints.add(id);
      }
    }

    setCurrentDraggingPointId(null);
    setIsDragging(false);
    setSelectedPoints(updatedSelectedPoints);
  }

  const onMouseMovePoint = (e: React.MouseEvent) => {
    if (currentDraggingPointId) {
      setIsDragging(true);
    }
  }

  const clearSelectedPoints = () => {
    onClearSelectedPoints(selectedPoints);
    setSelectedPoints(new Set());
  }

  const moveMap = (newX: number, newY: number, newScale: number = scale) => {
    if (mapContainerRef.current) {
      const panzoomParent = mapContainerRef.current;

      const offsetX = panzoomParent.clientWidth/2;
      const offsetY = panzoomParent.clientHeight/2;

      setMapX(-newX * newScale + offsetX);
      setMapY(-newY * newScale + offsetY);
      setScale(newScale);
    }
  }

  const onClickCoordinates = (point: TranslationPoint) => {
    moveMap(point.x, point.y, maxScale / 2);
  }

  const handleDoubleClick = (e: React.MouseEvent, pointType: TranslationPointType) => {
    const { x, y } = getClickCoordinates(e);

    return onDoubleClickMap(e, pointType, x, y);
  }

  const handleMouseMoveMap = (e: React.MouseEvent<SVGSVGElement>, id: string | null) => {
    const { x, y } = getClickCoordinates(e);

    return onMouseMoveMap(e, id, x, y);
  }

  const onClickCenterOnStartPoint = () => {
    if (startPoint) {
      moveMap(parseInt(startPoint.x.toString()), parseInt(startPoint.y.toString()), maxScale / 3);
    }
  }

  const onClickLineLegendLabel = (line: MapLine) => {
    const numPoints = line.points.length;
    let totalX = 0;
    let totalY = 0;

    line.points.forEach(point => {
      totalX += point[0];
      totalY += point[1];
    });

    moveMap(totalX / numPoints, totalY / numPoints, maxScale / 4);
  }

  return (
    <PanZoomContainer
      style={{
        position: 'relative'
      }}
    >
      <PanZoom
        mapContainerRef={mapContainerRef}
        minScale={minScale}
        maxScale={maxScale}
        x={mapX}
        updateX={setMapX}
        y={mapY}
        updateY={setMapY}
      >
        <img
          ref={imageRef}
          src={imageUrl ?? undefined}
          alt=""
          onLoad={handleImageLoad}
        />
        <TransparentSVG
          width='100%'
          height='100%'
          onDoubleClick={e => handleDoubleClick(e, pointType)}
          onMouseMove={e => handleMouseMoveMap(e, currentDraggingPointId)}
        >
          {!!startPoint &&
            <MapPointPickerPoint
              x={startPoint.x}
              y={startPoint.y}
              id="start-point"
              fill='#00d25b'
              pointSize={pointSize}
              fontSize={pointSize / 1.75}
              label="Start"
            />
          }
          {points.map((point, i) => {
            if (!minImageDimension) {
              return <></>;
            }

            return (
              <MapPointPickerPoint
                key={i+1}
                x={point.x}
                y={point.y}
                id={point.id}
                selected={selectedPoints.has(point.id)}
                pointSize={pointSize}
                label={i+1}
                onDoubleClickPoint={onDoubleClickPoint}
                onMouseDownPoint={onMouseDownPoint}
                onMouseUpPoint={onMouseUpPoint}
                onMouseMovePoint={onMouseMovePoint}
              />
            )}
          )}
          {renderLines &&
            <>
              {lines.map((line, i) => {
                return (
                  <MapPointPickerLine
                    key={i}
                    points={line.points}
                    strokeWidth={pointSize / 5}
                    stroke={line.color}
                  />
                )
              })}
            </>
          }
        </TransparentSVG>
      </PanZoom>
      
      {renderLines &&
        <MapPointPickerLineLegendContainer>
          <MapPointPickerLineLegend
            lines={lines}
            onClickLabel={onClickLineLegendLabel}
          />
        </MapPointPickerLineLegendContainer>
      }
      
      <PointControlsContainer>
        <PointControlsButtonsContainer>
          <Button 
            disabled={selectedPoints.size === 0}
            text="Clear Selected Points"
            onClick={clearSelectedPoints}
          />
          {!!startPoint &&
            <Button
              text="Center on Start Point"
              onClick={onClickCenterOnStartPoint}
            />
          }
          {(!!lines && lines.length > 0) &&
            <Button
              text={!showLines ? showLinesButtonText : hideLinesButtonText}
              onClick={() => setShowLines(prevValue => !prevValue)}
            />
          }
        </PointControlsButtonsContainer>
        {isLidar &&
          <Select 
            disabled={!lidarMaps || lidarMaps.length === 0}
            useInitialEmptyOption
            options={lidarMaps}
            value={selectedLidarMap?.id.toString() ?? ''}
            onSelect={e => {
              if (setSelectedLidarMap) {
                setSelectedLidarMap(e.target.value)
              }
            }}
            getOptionValue={(option: LidarMap) => option.id}
            getOptionLabel={(option: LidarMap) => new Date(option.captured_on).toDateString()}
            selectStyles={{width: '175px'}}
          />
        }
      </PointControlsContainer>
      <CoordinatesContainer>
        Coordinates:
        <div>
          {points.map((point, i, arr) => (
            <span
              style={{cursor: 'pointer', color: coordinateColors[i % coordinateColors.length]}}
              onClick={() => onClickCoordinates(point)}
            >
              {`${i+1}: (${point.x}, ${point.y})${i < arr.length - 1 ? ', ' : ''}`}
            </span>
          ))}
        </div>
      </CoordinatesContainer>
    </PanZoomContainer>
  )
}

export const TransparentSVG = styled.svg`
  position: absolute;
  left: 0;
  right: 0;
  background-color:transparent;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &::selection 
  {
    background-color:transparent;
  } 
  
  &::-moz-selection
  {
    background-color:transparent;
  }
`;

export const PanZoomContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  border: 1px solid black;
`

export const PointControlsContainer = styled.div`
  padding: 10px;
  border-top: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PointControlsButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const CoordinatesContainer = styled.div`
  padding: 10px;
  border-top: 1px solid black;
`;

const MapPointPickerLineLegendContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 33%;
  width: 250px;
  overflow-y scroll;
  background-color: white;
`;