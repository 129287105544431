import axios from "axios";
import { API_BASE } from "./constants";
import { ProjectJobType } from "./jobs";

interface ProjectTrackerTrackerType {
  id: number;
  name: string;
  is_sequential: boolean;
  display_order: number;
}

export interface ProjectTracker {
  id: number;
  type: ProjectTrackerTrackerType;
  project: number;
  types?: ProjectJobType[];
}

export const listProjectTrackers = async (projectId: string): Promise<ProjectTracker[]> => {
  const res = await axios.get(
    `${API_BASE}/project/${projectId}/trackers`
  )
  const response = await res.data;
  return response.data;
};

export const createProjectTracker = async (projectId: string, trackerTypeId: number): Promise<ProjectTracker> => {
  const res = await axios.post(
    `${API_BASE}/project/${projectId}/tracker`,
    {
      type_id: trackerTypeId
    }
  )
  const response = await res.data;
  return response.data;
}

export const deleteProjectTracker = async (projectId: string, trackerName: string): Promise<ProjectTracker> => {
  const res = await axios.delete(
    `${API_BASE}/project/${projectId}/tracker/${trackerName}`
  )
  const response = await res.data;
  return response.data;
}

export type TrackerTypeCategory = 'safety' | 'pt';

export interface TrackerType {
  id: number;
  name: string;
  is_sequential: boolean;
  display_order: number;
  category: TrackerTypeCategory;
}

export const listTrackerTypes = async (): Promise<TrackerType[]> => {
  const res = await axios.get(
    `${API_BASE}/tracker-types`
  )
  const response = await res.data;
  return response.data;
}

export const createTrackerType = async (newTrackerType: TrackerType): Promise<TrackerType> => {
  const res = await axios.post(
    `${API_BASE}/tracker-type`,
    newTrackerType
  )
  const response = await res.data;
  return response.data;
}

export const updateTrackerType = async (id: number, trackerTypeToUpdate: Partial<TrackerType>): Promise<TrackerType> => {
  const res = await axios.patch(
    `${API_BASE}/tracker-type/${id}`,
    trackerTypeToUpdate
  )
  const response = await res.data;
  return response.data;
}