import { fetchProject, listProjects } from "../api/projects";
import { useQuery } from "react-query";
import { useProjectContext } from "../contexts/projectContext";
import { fetchProjectFloor, fetchProjectFloors, initialProjectFloor } from "../api/projectFloors";
import { fetchProjectUsers } from "../api/projectUsers";
import { fetchProjectFloorSection, fetchProjectFloorSections, initialProjectFloorSection } from "../api/projectFloorSections";
import { fetchProjectSubcontractors } from "../api/projectSubcontractors";
import { fetchCompanies, fetchCompany } from "../api/companies";
import { fetchCountries, fetchLocation, fetchLocations } from "../api/locations";
import axios from "axios";
import { fetchLidarMap, fetchLidarMapLines, fetchLidarMaps, fetchProjectFloorLidarMaps, fetchProjectLidarMapConfiguration, fetchProjectLidarMapConfigurations, LidarMap, initialProjectLidarMapConfiguration } from "../api/projectFloorLidarMaps";
import { SiteWalkStatus, SiteWalkType, fetchSitewalk, fetchSitewalks } from "../api/sitewalks";
import { fetchProjectStaircases, fetchStairClimberScript, fetchStairClimberScripts, initialStairClimberScript, StairClimberScript } from "../api/stairClimberScripts";
import { ViewpointsPointsParams, fetchPoints } from "../api/viewpoints";
import { InstanceCommandHistory, retrieveMostRecentCommandExecutions } from "../api/instanceCommands";

export const useProjectsQuery = (fields?: string[]) => {
  return useQuery (
    ['projects'],
    async () => {
      const projectData = await listProjects(fields);

      return await projectData.data;
    }
  );
}

export const useCompaniesQuery = () => {
  return useQuery (
    ['companies'],
    async () => {
      return await fetchCompanies();
    }
  );
}

export const useCompanyQuery = (companyId: string, onSuccess: (data: any) => void) => {
  return useQuery(
    ['company', companyId],
    async () => {
      if (companyId !== 'new') {
        return await fetchCompany(companyId);
      } else {
        return {};
      }
    },
    { enabled: !!companyId, onSuccess: data => onSuccess(data) }
  );
};

export const useLocationsQuery = () => {
  return useQuery (
    ['locations'],
    async () => {
      return await fetchLocations();
    }
  );
}

export const useLocationQuery = (locationId: string, onSuccess: (data: any) => void) => {
  return useQuery(
    ['location', locationId],
    async () => {
      if (locationId !== 'new') {
        return await fetchLocation(locationId);
      } else {
        return {};
      }
    },
    { enabled: !!locationId, onSuccess: data => onSuccess(data) }
  );
};

export const useCountriesQuery = () => {
  return useQuery (
    ['countries'],
    async () => {
      return await fetchCountries();
    }
  );
}

export const useProjectQuery = (projectId: string | null | undefined, onSuccess?: (data: any) => void) => {
  return useQuery(
    ['project', projectId],
    async () => {
      if (projectId !== 'new') {
        return await fetchProject(projectId ?? '');
      } else {
        return {
          name: '',
          company: {},
          location: {},
          has_floor_transitions: false,
        };
      }
    },
    { enabled: !!projectId, onSuccess: onSuccess }
  );
};

export const useProjectFloorsQuery = (projectId: string | undefined | null, onSuccess?: (data: any) => void) => {
  return useQuery(
    ['floors', projectId],
    async () => {
      if (projectId) {
        return await fetchProjectFloors(projectId);
      }
    },
    { enabled: !!projectId, onSuccess: data => {
      if (onSuccess) {
        onSuccess(data);
      }
    }}
  );
}

export const useProjectFloorQuery = (projectId: string | undefined | null, floorId: string | undefined | null, onSuccess?: (data: any) => void) => {
  return useQuery(
    ['floor', projectId, floorId],
    async () => {
      if (floorId !== 'new') {
        return await fetchProjectFloor(projectId ?? '', floorId ?? '');
      } else {
        return { ...initialProjectFloor }
      }
    },
    {
      enabled: !!projectId && !!floorId,
      onSuccess: data => {
        if (onSuccess) {
          return onSuccess(data);
        }
      }
    }
  );
}

export const useProjectUsersQuery = (onSuccess: (data: any) => void) => {
  const projectState = useProjectContext().state;
  const projectId = projectState.projectId;

  return useQuery(
    ['users', projectId],
    async () => {
      return await fetchProjectUsers(projectId);
    },
    { enabled: !!projectId, onSuccess: data => onSuccess(data) }
  );
}

export const useProjectFloorSectionsQuery = (onSuccess?: (data: any) => void) => {
  const projectState = useProjectContext().state;
  const {
    projectId,
    floorId,
  } = projectState;

  return useQuery(
    ['sections', projectId, floorId],
    async () => {
      return await fetchProjectFloorSections(projectId, floorId);
    },
    { 
      enabled: !!projectId && !!floorId, 
      onSuccess: data => {
        if (onSuccess) {
          return onSuccess({ sections: data });
        }
      }
    }
  );
}

export const useProjectFloorSectionQuery = (onSuccess: (data: any) => void) => {
  const projectState = useProjectContext().state;
  const {
    projectId,
    floorId,
    sectionId,
  } = projectState;

  return useQuery(
    ['section', projectId, floorId, sectionId],
    async () => {
      if (sectionId !== 'new') {
        return await fetchProjectFloorSection(projectId, floorId, sectionId);
      } else {
        return {...initialProjectFloorSection}
      }
    },
    { enabled: !!projectId && !!floorId && !!sectionId, onSuccess: data => onSuccess(data) }
  );
}

export const useProjectSubcontractorsQuery = (onSuccess: (data: any) => void) => {
  const projectState = useProjectContext().state;
  const {
    projectId,
  } = projectState;

  return useQuery(
    ['subcontractors', projectId],
    async () => {
      return await fetchProjectSubcontractors(projectId);
    },
    { enabled: !!projectId, onSuccess: data => onSuccess(data)}
  );
}

export const useFetchProjectFloorLidarMapsQuery = (onSuccess: (data: LidarMap[]) => void, is_rejected?: boolean) => {
  const projectState = useProjectContext().state;
  const {
    projectId,
    floorId,
  } = projectState;

  return useQuery(
    ['lidar-maps', projectId, floorId],
    async () => {
      return await fetchProjectFloorLidarMaps(projectId, floorId, is_rejected);
    },
    { enabled: !!projectId && !!floorId, onSuccess: data => onSuccess(data)}
  );
}

export const useFetchLidarMapQuery = (onSuccess?: (data: any) => void) => {
  const projectState = useProjectContext().state;
  const {
    projectId,
    floorId,
    lidarMapId,
  } = projectState;

  return useQuery(
    ['lidar-map', projectId, floorId, lidarMapId],
    async () => {
      if (lidarMapId !== 'new') {
        return await fetchLidarMap(projectId, floorId, lidarMapId);
      } else {
        return {
          id: 0,
          sub_id: 0,
          floor_section: {name: ''},
          graymap_image_url: null,
          graymap_yaml_url: null,
          web_image_url: null,
          graymap_image_s3_key: null,
          graymap_yaml_s3_key: null,
          web_image_s3_key: null,
          original_web_image_s3_key: null,
          original_web_image_url: null,
          is_latest: false,
          is_usable: false,
          transformation_from_abs: null,
          transformation_to_abs: null,
          captured_on: new Date().toISOString(),
          mission_bag: null,
          mission_bag_url: null,
        };
      }
    },
    { enabled: !!projectId && !!floorId && !!lidarMapId,
      onSuccess: data => {
        if (onSuccess) {
          onSuccess(data);
        }
      }
    }
  );
}

export const useFetchLidarMapsQuery = (hasTransformation?: boolean, isUsable?: boolean, isLatest?: boolean, isRejected?: boolean) => {
  return useQuery(
    ['lidar-maps', hasTransformation, isUsable],
    async ({signal}) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by React Query')
      });

      return await fetchLidarMaps(source, hasTransformation, isUsable, isLatest, isRejected);
    }
  );
}

export const useFetchLidarMapLinesQuery = (projectPublicId: string, floorCode: string, lidarMapSubId: string | number, onSuccess: (data: any) => void) => {
  return useQuery(
    ['lidar-map-lines', projectPublicId, floorCode, lidarMapSubId],
    async () => {
      return await fetchLidarMapLines(projectPublicId, floorCode, lidarMapSubId);
    },
    { enabled: !!projectPublicId && !!floorCode && !!lidarMapSubId, onSuccess: data => onSuccess(data) }
  );
}

export const useFetchSitewalksQuery = (type?: SiteWalkType, status?: SiteWalkStatus) => {
  return useQuery(
    ['sitewalks', type, status],
    async () => {
      return await fetchSitewalks(type, status);
    }
  );
}

export const useFetchSitewalkQuery = (sitewalkId: string | number | undefined) => {
  return useQuery(
    ['sitewalk', sitewalkId],
    async () => {
      if (!!sitewalkId) {
        return await fetchSitewalk(sitewalkId);
      }
    },
    { enabled: !!sitewalkId }
  );
}

export const useFetchStairClimberScriptsQuery = () => {
  return useQuery(
    ['stair-climber-scripts'],
    async () => {
      return await fetchStairClimberScripts();
    }
  )
}

export const useFetchStairClimberScriptQuery = (scriptId: string | number | undefined, onSuccess: (data: StairClimberScript) => any) => {
  return useQuery(
    ['stair-climber-script', scriptId],
    async () => {
      if (scriptId) {
        if (scriptId !== 'new') {
          return await fetchStairClimberScript(scriptId);
        } else {
          return {...initialStairClimberScript}
        }
      }
    },
    { enabled: !!scriptId, onSuccess: onSuccess }
  )
}

export const useFetchProjectStaircasesQuery = (projectId: string | undefined) => {
  return useQuery(
    ['project-staircases', projectId],
    async () => {
      if (projectId) {
        return await fetchProjectStaircases(projectId);
      }      
    },
    { enabled: !!projectId }
  )
}

export const useFetchProjectLidarMapConfigurationsQuery = (projectId: string, onSuccess?: (data: any) => void) => {
  return useQuery(
    ['lidar-map-configurations', projectId],
    async () => {
      return await fetchProjectLidarMapConfigurations(projectId);
    },
    {
      enabled: !!projectId,
      onSuccess: onSuccess
    }
  );
}

export const useFetchProjectLidarMapConfigurationQuery = (projectId: string, lidarMapConfigId: string | number, onSuccess?: (data: any) => void) => {
  return useQuery(
    ['lidar-map-configuration', projectId, lidarMapConfigId],
    async () => {
      if (lidarMapConfigId && lidarMapConfigId !== 'new') {
        return await fetchProjectLidarMapConfiguration(projectId, lidarMapConfigId);
      } else {
        return {...initialProjectLidarMapConfiguration}
      }
    },
    {
      enabled: !!projectId && !!lidarMapConfigId,
      onSuccess: onSuccess
    }
  );
}

export const useFetchViewpointsQuery = (projectId: string, floorId: string, params?: ViewpointsPointsParams) => {
  return useQuery(
    ['viewpoints', projectId, floorId, params?.activeOnly, params?.fields],
    async () => {
      return await fetchPoints(projectId, floorId, params);
    },
    { enabled: !!projectId && !!floorId }
  );
}

export const useRetrieveMostRecentCommandExecutionsQuery = (onSuccess?: (data: InstanceCommandHistory[]) => void) => {
  return useQuery(
    ['instance-command-history'],
    async () => {
      return await retrieveMostRecentCommandExecutions();
    },
    { onSuccess: onSuccess }
  );
}