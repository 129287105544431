import styled from 'styled-components';
import { Flex } from '@react-css/flex';

const CheckboxBase = styled.input`
  border: 1px solid #073c7a;
  color: #073c7a;
  margin: 5px auto;
  height: fit-content;
`;

const CheckboxLabel = styled.label`
  color: #073c7a;
  font-size: 1em;
  text-align: center;
  margin-bottom: 5px;
  max-width: fit-content;
`;

interface IProps {
  label: string;
  value: boolean;
  onSelect: () => void;
  labelStyles?: React.CSSProperties;
}
export const Checkbox = ({ label, value, onSelect, labelStyles }: IProps) => {
  return (
    <Flex
      flexDirection="column"
      alignContentStart={true}
      justifyCenter={true}
      style={{ margin: '0 1em' }}>
      <CheckboxBase checked={value} type="checkbox" onClick={onSelect} />
      <CheckboxLabel style={labelStyles}>{label}</CheckboxLabel>
    </Flex>
  );
};
