import { useCallback, useMemo, useState } from "react";
import { Company } from "../../../../api/companies";
import { createProjectSubcontractor, deleteProjectSubcontractorMapping, ProjectSubcontractor } from "../../../../api/projectSubcontractors";
import { useNotifications } from "../../../../contexts/notificationProvider";
import { useProjectContext } from "../../../../contexts/projectContext";
import { useCompaniesQuery, useProjectSubcontractorsQuery } from "../../../../hooks/projectQueries";
import { FormContainer, FormHeader } from "../../../common/FormControls"
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { Table } from "../../../common/Table/Table"
import { DashboardCheckbox, DashboardSelect } from "../DashboardFormControls";

export const ProjectSubcontractors = () => {
  const {
    updateProject,
    state: projectState,
  } = useProjectContext();

  const [subcontractorsExpanded, setSubcontractorsExpanded] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  const {
    projectId,
    project,
  } = projectState;

  const {
    subcontractorIds,
    subcontractors
  } = project;

  const {
    addNotification
  } = useNotifications();

  const updateProjectSubcontractors = (returnedSubcontractors: ProjectSubcontractor[]) => {
    const subcontractorIds = new Set<string>();

    returnedSubcontractors.forEach((projectSubcontractor: ProjectSubcontractor) => {
      subcontractorIds.add(projectSubcontractor.subcontractor.public_id);
    });

    updateProject({
      subcontractors: returnedSubcontractors,
      subcontractorIds,
    });
  }

  const { isLoading: subcontractorsLoading } = useProjectSubcontractorsQuery(updateProjectSubcontractors);
  const { data: companies, isLoading: companiesLoading } = useCompaniesQuery();

  const columns = useMemo(() => [
    { 
      Header: 'Name', 
      accessor: 'subcontractor.name' 
    }
  ], []);

  const onAddSubcontractor = useCallback(async (company: Company) => {
    try {
      const newProjectSubcontractor: ProjectSubcontractor = await createProjectSubcontractor(projectId, company);
      
      const updatedProjectSubcontractorIds = new Set(subcontractorIds);
      updatedProjectSubcontractorIds.add(newProjectSubcontractor.subcontractor.public_id);

      const updatedProjectSubcontractors = [...subcontractors]
      updatedProjectSubcontractors.push(newProjectSubcontractor);
      
      updateProject({
        subcontractorIds: updatedProjectSubcontractorIds,
        subcontractors: updatedProjectSubcontractors,
      });
      addNotification('Subcontractor added successfully', 'success');
    } catch (err) {
      console.log('onAddSubcontractor==>>', err);
      addNotification('Error adding subcontractor', 'error');
    }
  }, [subcontractorIds, subcontractors, updateProject, projectId, addNotification]);

  const onRemoveSubcontractor = useCallback(async (company: Company) => {
    try {
      await deleteProjectSubcontractorMapping(projectId, company.public_id);
      
      const updatedProjectSubcontractorIds = new Set(subcontractorIds);
      updatedProjectSubcontractorIds.delete(company.public_id);

      const updatedProjectSubcontractors = subcontractors.filter((subcontractor: ProjectSubcontractor) => {
        return subcontractor.subcontractor.public_id !== company.public_id;
      })
      
      updateProject({ 
        subcontractorIds: updatedProjectSubcontractorIds,
        subcontractors: updatedProjectSubcontractors,
      });
      addNotification('Subcontractor removed successfully', 'success');
    } catch (err) {
      console.log('onRemoveSubcontractor==>>', err);
      addNotification('Error removing subcontractor', 'error');
    }
  }, [addNotification, projectId, subcontractorIds, subcontractors, updateProject]);

  const onSubcontractorChecked = useCallback((checked: boolean, company: Company) => {
    if (checked) {
      onAddSubcontractor(company);
    } else {
      onRemoveSubcontractor(company);
    }
  }, [onAddSubcontractor, onRemoveSubcontractor]);

  const dataLoaded = !subcontractorsLoading && !companiesLoading;

  const data = useMemo(() => {
    if (!dataLoaded) {
      return [];
    }

    return subcontractors;
  }, [dataLoaded, subcontractors]);

  const filteredCompanies = useMemo(() => {
    if (companiesLoading) {
      return [];
    }

    return companies.filter((company: Company) => company.name.toLowerCase().includes(searchText.toLowerCase()));
  }, [companiesLoading, companies, searchText]);

  if (!dataLoaded) {
    return <LoadingIndicator/>
  }

  return (
    <>    
      <FormContainer>
        <FormHeader>{`${project.name}`}</FormHeader>
      </FormContainer>

      <DashboardSelect
        title='Manage Subcontractors'
        expanded={subcontractorsExpanded}
        setExpanded={setSubcontractorsExpanded}
        searchText={searchText}
        setSearchText={setSearchText}
        searchPlaceholder='Filter Subcontractors'
        containerStyle={{
          position: 'absolute',
          left: '20px'
        }}
      >
        { filteredCompanies.map((company: Company) => {
          const checked = subcontractorIds.has(company.public_id);

          return (
            <DashboardCheckbox
              key={company.public_id}
              checked={checked}
              onChangeChecked={(newValue: boolean) => onSubcontractorChecked(newValue, company)}
              label={company.name}
              checkmarkTop={2}
            />
          )
        })}
      </DashboardSelect>

      <Table
        data={data}
        columns={columns}
        preventAutoResetPage
        preventAutoResetGlobalFilter
        useGlobalFilterTextBox
        globalFilterPlaceholder="Search Users"
        hideColumnFilters
      />
    </>
  )
}