import axios, { CancelTokenSource } from "axios";
import { CompletedLine } from "../components/views/lidar-management/LidarMapEditing/LidarMapEditing";
import { API_BASE } from "./constants";
import { MissionBag } from "./missions";
import { Transformation } from "./projectFloors";
import { ProjectFloorSection } from "./projectFloorSections";
import { PresignedPost } from "./s3ImageUpload";

type LidarMapProcessor = 'cartographer_ros' | 'slam_toolbox';

export interface LidarMap {
  floor_section: {
    name: string;
  };
  graymap_image_url: string | null;
  graymap_image_s3_key: string | null;
  graymap_yaml_url: string | null;
  graymap_yaml_s3_key: string | null;
  id: number;
  is_latest: boolean;
  is_usable: boolean;
  is_rejected: boolean;
  sub_id: number;
  web_image_url: string | null;
  web_image_s3_key: string | null;
  original_web_image_s3_key: string | null;
  original_web_image_url: string | null;
  captured_on: string;
  transformation_from_abs: Transformation | null;
  transformation_to_abs: Transformation | null;
  mission_bag: number | null;
  mission_bag_url: string | null;
  processed_via: LidarMapProcessor;
  configuration_file_url: string | null;
  weights_s3_key: string | null;
  lines_svg_s3_key: string | null;
}

export const initialLidarMap: LidarMap = {
  id: 0,
  sub_id: 0,
  floor_section: {name: ''},
  graymap_image_url: null,
  graymap_yaml_url: null,
  web_image_url: null,
  graymap_image_s3_key: null,
  graymap_yaml_s3_key: null,
  web_image_s3_key: null,
  original_web_image_s3_key: null,
  original_web_image_url: null,
  is_latest: false,
  is_usable: false,
  is_rejected: false,
  transformation_from_abs: null,
  transformation_to_abs: null,
  captured_on: new Date().toISOString(),
  mission_bag: null,
  mission_bag_url: null,
  processed_via: 'cartographer_ros',
  configuration_file_url: null,
  weights_s3_key: null,
  lines_svg_s3_key: null,
}
export interface ManageLidarMap {
  floor_code: string;
  floor_section: {
    name: string;
  };
  id: number;
  project_name: string;
  project_public_id: string;
  sub_id: number;
  captured_on: string;
  transformation_from_abs: Transformation | null;
  transformation_to_abs: Transformation | null;
  is_usable: boolean;
}

interface LidarMapToCreate {
  floor_section: ProjectFloorSection;
}

interface LidarMapCreateReturn {
  lidar_map: LidarMap;
  map_assets_presigned_url: PresignedPost;
}

export const fetchLidarMaps = async (cancelTokenSource: CancelTokenSource, has_transformation?: boolean, is_usable?: boolean, is_latest?: boolean, is_rejected?: boolean): Promise<ManageLidarMap[]> => {
  const res = await axios.get(
    `${API_BASE}/lidar-maps`,
    {
      cancelToken: cancelTokenSource.token,
      params: {
        has_transformation: has_transformation,
        is_usable: is_usable,
        is_latest: is_latest,
        is_rejected: is_rejected
      },
    }
  );
  const response = await res.data;
  return response.data;
}

export const fetchProjectFloorLidarMaps = async (projectId: string, floorCode: string, is_rejected?: boolean): Promise<LidarMap[]> => {
  const res = await axios.get(
    `${API_BASE}/project/${projectId}/floor/${floorCode}/maps/lidar-maps`,
    {
      params: {
        is_rejected: is_rejected,
      }
    }
  );
  const response = await res.data;
  return response.data;
}
export const fetchLidarMap = async (projectId: string, floorCode: string, lidarMapSubId: string): Promise<LidarMap> => {
  const res = await axios.get(
    `${API_BASE}/project/${projectId}/floor/${floorCode}/maps/lidar-map/${lidarMapSubId}`
  );
  const response = await res.data;
  return response.data;
}

export const createLidarMap = async (projectId: string, floorCode: string, lidarMap: LidarMapToCreate): Promise<LidarMapCreateReturn> => {
  const res = await axios.post(
    `${API_BASE}/project/${projectId}/floor/${floorCode}/maps/lidar-map`,
    lidarMap,
  );
  const response = await res.data;
  return response.data;
}

export const updateLidarMap = async (projectId: string, floorCode: string, lidarMapSubId: string | number, mapToUpdate: Partial<LidarMap>): Promise<LidarMap> => {
  const res = await axios.patch(
    `${API_BASE}/project/${projectId}/floor/${floorCode}/maps/lidar-map/${lidarMapSubId}`,
    mapToUpdate
  );
  const response = await res.data;
  return response.data;
}

export const getMapAssetPresignedPost = async (projectPublicId: string, floorCode: string, lidarMapSubId: string | number): Promise<PresignedPost> => {
  const res = await axios.get(
    `${API_BASE}/project/${projectPublicId}/floor/${floorCode}/maps/lidar-map/${lidarMapSubId}/map_asset_presigned_post`
  );
  const response = await res.data;
  return response.data;
}

export const fetchLidarMapLines = async (projectPublicId: string, floorCode: string, lidarMapSubId: string | number): Promise<CompletedLine[]> => {
  const res = await axios.get(
    `${API_BASE}/project/${projectPublicId}/floor/${floorCode}/maps/lidar-map/${lidarMapSubId}/lines`
  );
  const response = await res.data;

  return response.data;
}

export const drawLidarMapLines = async (projectPublicId: string, floorCode: string, lidarMapSubId: string | number, svg: Blob): Promise<CompletedLine[]> => {
  const form = new FormData();
  form.append('file', svg);

  const res = await axios.post(
    `${API_BASE}/project/${projectPublicId}/floor/${floorCode}/maps/lidar-map/${lidarMapSubId}/draw`,
    form
  );
  const response = await res.data;
  return response.data;
}

export const reRunLidarMap = async (projectPublicId: string, floorCode: string, lidarMapSubId: string | number, luaFile: File): Promise<MissionBag> => {
  const form = new FormData();
  form.append('file', luaFile);

  const res = await axios.post(
    `${API_BASE}/project/${projectPublicId}/floor/${floorCode}/maps/lidar-map/${lidarMapSubId}/re-run`,
    form
  );
  const response = await res.data;
  return response.data;
}

export interface ProjectLidarMapConfiguration {
  id: number;
  name: string;
  run_cartographer_ros: boolean;
  cartographer_ros_configuration_s3_key: string | null;
  cartographer_ros_configuration_url: string | null;
  run_slam_toolbox: boolean;
  slam_toolbox_configuration_s3_key: string | null;
  slam_toolbox_configuration_url: string | null;
}

export const initialProjectLidarMapConfiguration: ProjectLidarMapConfiguration = {
  id: 0,
  name: '',
  run_cartographer_ros: true,
  cartographer_ros_configuration_s3_key: null,
  cartographer_ros_configuration_url: null,
  run_slam_toolbox: true,
  slam_toolbox_configuration_s3_key: null,
  slam_toolbox_configuration_url: null
}

type LidarMapConfigurationReadOnlyKeys = 'id' & 'cartographer_ros_configuration_s3_key' & 'slam_toolbox_configuration_s3_key' & 'cartographer_ros_configuration_url' & 'slam_toolbox_configuration_url';

interface CreateLidarMapConfiguration extends Omit<ProjectLidarMapConfiguration, LidarMapConfigurationReadOnlyKeys> {
  cartographer_ros_configuration_text?: string;
  slam_toolbox_configuration_text?: string;
}

export enum LidarMapConfigurationTextEditorType {
  none,
  cartographer_ros,
  slam_toolbox
}

export const fetchProjectLidarMapConfigurations = async (projectPublicId: string): Promise<ProjectLidarMapConfiguration[]> => {
  const res = await axios.get(
    `${API_BASE}/project/${projectPublicId}/lidar-map-configurations`
  );
  const response = await res.data;
  return response.data;
}

export const fetchProjectLidarMapConfiguration = async (projectPublicId: string, lidarMapConfigId: string | number): Promise<ProjectLidarMapConfiguration[]> => {
  const res = await axios.get(
    `${API_BASE}/project/${projectPublicId}/lidar-map-configuration/${lidarMapConfigId}`
  );
  const response = await res.data;
  return response.data;
}

export const createProjectLidarMapConfiguration = async (projectPublicId: string, data: CreateLidarMapConfiguration): Promise<ProjectLidarMapConfiguration> => {
  const res = await axios.post(
    `${API_BASE}/project/${projectPublicId}/lidar-map-configuration`,
    data,
  );
  const response = await res.data;
  return response.data;
}

export const updateProjectLidarMapConfiguration = async (projectPublicId: string, lidarMapConfigId: string | number, data: CreateLidarMapConfiguration): Promise<ProjectLidarMapConfiguration> => {
  const res = await axios.put(
    `${API_BASE}/project/${projectPublicId}/lidar-map-configuration/${lidarMapConfigId}`,
    data,
  );
  const response = await res.data;
  return response.data;
}

export const DEFAULT_CARTOGRAPHER_ROS_LUA = `-- Copyright 2016 The Cartographer Authors
--
-- Licensed under the Apache License, Version 2.0 (the "License");
-- you may not use this file except in compliance with the License.
-- You may obtain a copy of the License at
--
--      http://www.apache.org/licenses/LICENSE-2.0
--
-- Unless required by applicable law or agreed to in writing, software
-- distributed under the License is distributed on an "AS IS" BASIS,
-- WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
-- See the License for the specific language governing permissions and
-- limitations under the License.

include "map_builder.lua"
include "trajectory_builder.lua"

options = {
  map_builder = MAP_BUILDER,
  trajectory_builder = TRAJECTORY_BUILDER,
  map_frame = "map",
  tracking_frame = "lidar_link",
  published_frame = "lidar_link",
  odom_frame = "odom",
  provide_odom_frame = true,
  publish_frame_projected_to_2d = true,
  use_pose_extrapolator = true,
  use_odometry = false,
  use_nav_sat = false,
  use_landmarks = false,
  num_laser_scans = 1,
  num_multi_echo_laser_scans = 0,
  num_subdivisions_per_laser_scan = 1,
  num_point_clouds = 0,
  lookup_transform_timeout_sec = 0.2,
  submap_publish_period_sec = 0.3,
  pose_publish_period_sec = 5e-3,
  trajectory_publish_period_sec = 30e-3,
  rangefinder_sampling_ratio = 1.,
  odometry_sampling_ratio = 1.,
  fixed_frame_pose_sampling_ratio = 1.,
  imu_sampling_ratio = 1.,
  landmarks_sampling_ratio = 1.,
}

MAP_BUILDER.use_trajectory_builder_2d = true
MAP_BUILDER.num_background_threads = 5
TRAJECTORY_BUILDER_2D.min_range = 0.1
TRAJECTORY_BUILDER_2D.max_range = 35
TRAJECTORY_BUILDER_2D.missing_data_ray_length = 35.5
TRAJECTORY_BUILDER_2D.use_imu_data = false

TRAJECTORY_BUILDER_2D.use_online_correlative_scan_matching = true
TRAJECTORY_BUILDER_2D.real_time_correlative_scan_matcher.linear_search_window = 0.3
TRAJECTORY_BUILDER_2D.real_time_correlative_scan_matcher.angular_search_window = 0.3
TRAJECTORY_BUILDER_2D.motion_filter.max_distance_meters = 0.1
TRAJECTORY_BUILDER_2D.motion_filter.max_angle_radians = math.rad(0.1)
TRAJECTORY_BUILDER_2D.real_time_correlative_scan_matcher.translation_delta_cost_weight = 10.
TRAJECTORY_BUILDER_2D.real_time_correlative_scan_matcher.rotation_delta_cost_weight = 1e-1
TRAJECTORY_BUILDER_2D.ceres_scan_matcher.ceres_solver_options.use_nonmonotonic_steps = true
TRAJECTORY_BUILDER_2D.ceres_scan_matcher.ceres_solver_options.max_num_iterations = 100
TRAJECTORY_BUILDER_2D.ceres_scan_matcher.ceres_solver_options.num_threads = 5
-- for current lidar only 1 is good value
TRAJECTORY_BUILDER_2D.num_accumulated_range_data = 1
POSE_GRAPH.constraint_builder.min_score = 0.55
POSE_GRAPH.constraint_builder.max_constraint_distance = 30
POSE_GRAPH.constraint_builder.global_localization_min_score = 0.55
POSE_GRAPH.optimization_problem.huber_scale = 1e2
POSE_GRAPH.optimize_every_n_nodes = 300
-- POSE_GRAPH.global_sampling_ratio = 0.00001
POSE_GRAPH.global_constraint_search_after_n_seconds = 1
POSE_GRAPH.optimization_problem.ceres_solver_options.use_nonmonotonic_steps = true
POSE_GRAPH.optimization_problem.ceres_solver_options.max_num_iterations = 100
POSE_GRAPH.optimization_problem.ceres_solver_options.num_threads = 10
POSE_GRAPH.max_num_final_iterations = 200

MAP_BUILDER.num_background_threads = 32
POSE_GRAPH.constraint_builder.ceres_scan_matcher.ceres_solver_options.num_threads = 32

return options`;

export const DEFAULT_SLAM_TOOLBOX_YAML = `###############################################
###### LASER SCAN MATCHER NODE PARAMS #########
###############################################
laser_scan_matcher_node/use_odom: false
laser_scan_matcher_node/use_imu: false
laser_scan_matcher_node/use_vel: false
laser_scan_matcher_node/fixed_frame: odom
laser_scan_matcher_node/publish_tf: true
laser_scan_matcher_node/publish_pose_stamped: false
laser_scan_matcher_node/kf_dist_linear: 0.01
laser_scan_matcher_node/kf_dist_angular: 0.02
laser_scan_matcher_node/max_correspondence_dist: 1.0

###############################################
###### ROBOT LOCALIZATION NODE PARAMS #########
###############################################
ekf_odom_node/frequency: 10
ekf_odom_node/sensor_timeout: 0.1
ekf_odom_node/two_d_mode: true

ekf_odom_node/print_diagnostics: false
ekf_odom_node/debug: false
ekf_odom_node/debug_out_file: /path/to/debug/file.txt
ekf_odom_node/publish_tf: true
ekf_odom_node/publish_acceleration: false

ekf_odom_node/odom0: /odom
ekf_odom_node/odom0_config: [false, false, false,
                             false, false, false,
                              true, false, false,
                             false, false,  true,
                             false, false, false]
ekf_odom_node/odom0_queue_size: 6
ekf_odom_node/odom0_nodelay: false
ekf_odom_node/odom0_differential: false
ekf_odom_node/odom0_relative: false
ekf_odom_node/odom0_pose_rejection_threshold: 5
ekf_odom_node/odom0_twist_rejection_threshold: 1

ekf_odom_node/pose0: /pose0
ekf_odom_node/pose0_config: [ true,  true, false,
                             false, false,  true,
                             false, false, false,
                             false, false, false,
                             false, false, false]
ekf_odom_node/pose0_differential: false
ekf_odom_node/pose0_relative: false
ekf_odom_node/pose0_queue_size: 2
ekf_odom_node/pose0_rejection_threshold: 2  
ekf_odom_node/pose0_nodelay: false

ekf_odom_node/imu0: /imu/data
ekf_odom_node/imu0_config: [false, false, false,
                            false, false, false,
                            false, false, false,
                             true,  true,  true,
                            false, false, false]
ekf_odom_node/imu0_nodelay: false
ekf_odom_node/imu0_differential: false
ekf_odom_node/imu0_relative: false
ekf_odom_node/imu0_queue_size: 40
ekf_odom_node/imu0_pose_rejection_threshold: 0.8            
ekf_odom_node/imu0_twist_rejection_threshold: 0.8
ekf_odom_node/imu0_linear_acceleration_rejection_threshold: 0.8
ekf_odom_node/imu0_remove_gravitational_acceleration: true

ekf_odom_node/use_control: true
ekf_odom_node/stamped_control: false
ekf_odom_node/control_timeout: 0.2
ekf_odom_node/control_config: [ true, false, false, 
                               false, false,  true]
ekf_odom_node/acceleration_limits: [2.5, 0.0, 0.0,
                                    0.0, 0.0, 3.2]
ekf_odom_node/deceleration_limits: [2.5, 0.0, 0.0,
                                    0.0, 0.0, 3.2]
ekf_odom_node/acceleration_gains: [0.8, 0.0, 0.0,
                                   0.0, 0.0, 0.9]
ekf_odom_node/deceleration_gains: [0.8, 0.0, 0.0,
                                   0.0, 0.0, 0.9]

ekf_odom_node/process_noise_covariance: [0.05, 0,    0,    0,    0,    0,    0,     0,     0,    0,    0,    0,    0,    0,    0,
0,    0.05, 0,    0,    0,    0,    0,     0,     0,    0,    0,    0,    0,    0,    0,
0,    0,    0.06, 0,    0,    0,    0,     0,     0,    0,    0,    0,    0,    0,    0,
0,    0,    0,    0.03, 0,    0,    0,     0,     0,    0,    0,    0,    0,    0,    0,
0,    0,    0,    0,    0.03, 0,    0,     0,     0,    0,    0,    0,    0,    0,    0,
0,    0,    0,    0,    0,    0.06, 0,     0,     0,    0,    0,    0,    0,    0,    0,
0,    0,    0,    0,    0,    0,    0.025, 0,     0,    0,    0,    0,    0,    0,    0,
0,    0,    0,    0,    0,    0,    0,     0.025, 0,    0,    0,    0,    0,    0,    0,
0,    0,    0,    0,    0,    0,    0,     0,     0.04, 0,    0,    0,    0,    0,    0,
0,    0,    0,    0,    0,    0,    0,     0,     0,    0.01, 0,    0,    0,    0,    0,
0,    0,    0,    0,    0,    0,    0,     0,     0,    0,    0.01, 0,    0,    0,    0,
0,    0,    0,    0,    0,    0,    0,     0,     0,    0,    0,    0.02, 0,    0,    0,
0,    0,    0,    0,    0,    0,    0,     0,     0,    0,    0,    0,    0.01, 0,    0,
0,    0,    0,    0,    0,    0,    0,     0,     0,    0,    0,    0,    0,    0.01, 0,
0,    0,    0,    0,    0,    0,    0,     0,     0,    0,    0,    0,    0,    0,    0.015]

ekf_odom_node/initial_estimate_covariance: [1e-9, 0,    0,    0,    0,    0,    0,    0,    0,    0,     0,     0,     0,    0,    0,
0,    1e-9, 0,    0,    0,    0,    0,    0,    0,    0,     0,     0,     0,    0,    0,
0,    0,    1e-9, 0,    0,    0,    0,    0,    0,    0,     0,     0,     0,    0,    0,
0,    0,    0,    1e-9, 0,    0,    0,    0,    0,    0,     0,     0,     0,    0,    0,
0,    0,    0,    0,    1e-9, 0,    0,    0,    0,    0,     0,     0,     0,    0,    0,
0,    0,    0,    0,    0,    1e-9, 0,    0,    0,    0,     0,     0,     0,    0,    0,
0,    0,    0,    0,    0,    0,    1e-9, 0,    0,    0,     0,     0,     0,    0,    0,
0,    0,    0,    0,    0,    0,    0,    1e-9, 0,    0,     0,     0,     0,    0,    0,
0,    0,    0,    0,    0,    0,    0,    0,    1e-9, 0,     0,     0,     0,    0,    0,
0,    0,    0,    0,    0,    0,    0,    0,    0,    1e-9,  0,     0,     0,    0,    0,
0,    0,    0,    0,    0,    0,    0,    0,    0,    0,     1e-9,  0,     0,    0,    0,
0,    0,    0,    0,    0,    0,    0,    0,    0,    0,     0,     1e-9,  0,    0,    0,
0,    0,    0,    0,    0,    0,    0,    0,    0,    0,     0,     0,     1e-9, 0,    0,
0,    0,    0,    0,    0,    0,    0,    0,    0,    0,     0,     0,     0,    1e-9, 0,
0,    0,    0,    0,    0,    0,    0,    0,    0,    0,     0,     0,     0,    0,    1e-9]`;