import React, { useMemo, useState, useRef } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { createLidarMap, getMapAssetPresignedPost, LidarMap, updateLidarMap } from "../../../../../api/projectFloorLidarMaps";
import { ProjectFloorSection } from "../../../../../api/projectFloorSections";
import { uploadToPresignedPost } from "../../../../../api/s3ImageUpload";
import { useNotifications } from "../../../../../contexts/notificationProvider";
import { useProjectContext } from "../../../../../contexts/projectContext";
import { useFetchLidarMapQuery, useProjectFloorSectionsQuery } from "../../../../../hooks/projectQueries";
import { useProjectNavigation } from "../../../../../hooks/useNavigation";
import { FormHeader } from "../../../../common/FormControls"
import { Button } from "../../../../common/Inputs/Button";
import { Select } from "../../../../common/Inputs/Select";
import { LoadingIndicator } from "../../../../common/LoadingIndicator";
import { StyledFileInput } from "../../project-assets/StyledFileInput";

interface ILidarMapPathState {
  section: ProjectFloorSection;
}

export const ManageLidarMap = () => {
  const locationState = useLocation().state as ILidarMapPathState | undefined;

  const {
    navigateToLidarMapTransform,
    navigateToLidarMap,
  } = useProjectNavigation();

  const {
    addNotification,
  } = useNotifications();

  const {
    updateFloor,
    state: projectState
  } = useProjectContext();

  const {
    project,
    projectId,
    floor,
    floorId,
    lidarMapId,
  } = projectState;

  const lidarMapIsNew = lidarMapId === 'new';

  const [lidarMap, setLidarMap] = useState<any>();
  
  useFetchLidarMapQuery(data => {
    const incomingLidarMap = {...data};

    if (lidarMapIsNew && locationState?.section) {
      incomingLidarMap.floor_section = locationState.section;
    }

    setLidarMap(incomingLidarMap);
  });

  const webImageRef = useRef<HTMLInputElement>(null);
  const [webImage, setWebImage] = useState<File | null>(null);
  const graymapImageRef = useRef<HTMLInputElement>(null);
  const [graymapImage, setGraymapImage] = useState<File | null>(null);
  const graymapYamlRef = useRef<HTMLInputElement>(null);
  const [graymapYaml, setGraymapYaml] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);

  const displaySaveButton = !uploading && !!lidarMap && !lidarMap.is_rejected;

  const validForSubmit = useMemo(() => {
    if (lidarMap) {
      return (
        !!lidarMap.floor_section?.name &&
        (!!lidarMap.web_image_s3_key || !!webImage) &&
        (!!lidarMap.graymap_image_s3_key || !!graymapImage) &&
        (!!lidarMap.graymap_yaml_s3_key || !!graymapYaml)
      );
    }

    return false;
  }, [lidarMap, webImage, graymapImage, graymapYaml]);

  const {
    isLoading: sectionsLoading,
    isIdle: sectionsIdle
  } = useProjectFloorSectionsQuery(updateFloor);

  const floorSectionOptions = useMemo(() => {
    if (floor && floor.sections) {
      return floor.sections.map((section: ProjectFloorSection) => section.name)
    }
    
    return [];
  }, [floor]);

  const onChangeFloorSection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSection = floor.sections.filter((section: ProjectFloorSection) => {
      return section.name === e.target.value;
    })[0];

    const updatedLidarMap = {...lidarMap}
    updatedLidarMap.floor_section = selectedSection;

    setLidarMap(updatedLidarMap);
  }

  const resetFileUploadControl = (fileInputRef: React.RefObject<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.files = null;
    }

    setFile(null);
  }

  const resetFileUploadControls = () => {
    resetFileUploadControl(webImageRef, setWebImage);
    resetFileUploadControl(graymapImageRef, setGraymapImage);
    resetFileUploadControl(graymapYamlRef, setGraymapYaml);
  }

  const prepareForUploadOfAssets = async () => {
    if (lidarMapIsNew) {
      const createLidarMapResponse = await createLidarMap(projectId, floorId, {floor_section: lidarMap.floor_section});
      const createdLidarMap = createLidarMapResponse.lidar_map;
      const presignedPost = createLidarMapResponse.map_assets_presigned_url;

      return {
        presignedPost: presignedPost,
        subId: createdLidarMap.sub_id
      }
    } else {
      const presignedPost = await getMapAssetPresignedPost(projectId, floorId, lidarMapId);

      return {
        presignedPost: presignedPost,
        subId: lidarMapId,
      }
    }
  }

  const onClickSubmit = async () => {
    if (validForSubmit) {
      setUploading(true);

      const {
        presignedPost,
        subId,
      } = await prepareForUploadOfAssets();

      const lidarMapToUpdate: Partial<LidarMap> = {
        is_usable: true,
        is_latest: true,
      };

      if (!!webImage) {
        lidarMapToUpdate.web_image_s3_key = await uploadToPresignedPost(webImage, presignedPost);
      }

      if (!!graymapImage) {
        lidarMapToUpdate.graymap_image_s3_key = await uploadToPresignedPost(graymapImage, presignedPost);
      }

      if (!!graymapYaml) {
        lidarMapToUpdate.graymap_yaml_s3_key = await uploadToPresignedPost(graymapYaml, presignedPost);
      }

      const updatedLidarMap = await updateLidarMap(projectId, floorId, subId, lidarMapToUpdate);

      setUploading(false);
      addNotification('Lidar map uploaded successfully', 'success');
      resetFileUploadControls();

      if (lidarMapIsNew) {
        navigateToLidarMap(subId);
      } else {
        setLidarMap(updatedLidarMap);
      }
    }
  }

  if (!lidarMap || sectionsLoading || sectionsIdle) {
    return <LoadingIndicator/>
  }

  return (
    <ManageLidarMapContainer>
      <HeaderContainer>
        <EmptyDiv/>

        <FormHeader
          style={{marginBottom: '0px'}}
        >
          {`${project.name} - ${floor.name}`}
        </FormHeader>

        {!lidarMapIsNew &&
          <TransformationButtonContainer>
            <Button
              primary
              text="Process Transformation"
              onClick={() => navigateToLidarMapTransform(projectId, floorId, lidarMapId)}
            />
          </TransformationButtonContainer>
        }
        {lidarMapIsNew &&
          <EmptyDiv/>
        }
      </HeaderContainer>

      {!lidarMapIsNew &&
        <LidarAssetsContainer>
          <div>
            {!!lidarMap.web_image_url &&
              <>
                <div
                  style={{marginBottom: '5px'}}
                >
                  Web Image:
                </div>
                <img
                  src={lidarMap.original_web_image_url}
                  alt={`Lidar map: ${project.name} ${floor.name} ${lidarMap.sub_id}`}
                  style={{height: '350px', width: 'auto'}}
                />
              </>
            }
            {!lidarMap.web_image_url &&
              <>Web Image - Not Uploaded</>
            }
          </div>
          
          <div>
            {!!lidarMap.graymap_image_url &&
              <a href={lidarMap.graymap_image_url}>
                Graymap image
              </a>
            }

            {!lidarMap.graymap_image_url &&
              <>Graymap image - Not Uploaded</>
            }
          </div>
          
          <div>
            {!!lidarMap.graymap_yaml_url &&
              <a href={lidarMap.graymap_yaml_url}>
                Graymap YAML
              </a>
            }

            {!lidarMap.graymap_yaml_url &&
              <>Graymap YAML - Not Uploaded</>
            }
          </div>
        </LidarAssetsContainer>
      }

      <FileInputContainer>
        <FileInputWithLabel
          ref={webImageRef}
          label={`${!lidarMapIsNew ? 'Replace' : ''} Web Image:`}
          onChange={setWebImage}
        />
        <FileInputWithLabel
          ref={graymapImageRef}
          label={`${!lidarMapIsNew ? 'Replace' : ''} Graymap Image:`}
          onChange={setGraymapImage}
        />
        <FileInputWithLabel
          ref={graymapYamlRef}
          label={`${!lidarMapIsNew ? 'Replace' : ''} Graymap YAML:`}
          onChange={setGraymapYaml}
        />
      </FileInputContainer>

      <SelectContainer>
        <Select
          disabled={!lidarMapIsNew}
          label="Floor Section:"
          labelStyles={{color: 'black'}}
          options={floorSectionOptions}
          value={lidarMap.floor_section?.name}
          onSelect={onChangeFloorSection}
          useInitialEmptyOption
        />
      </SelectContainer>

      <SubmitContainer>
        {displaySaveButton &&
          <Button
            primary
            disabled={!validForSubmit}
            text={lidarMapIsNew ? 'Create' : 'Save'}
            onClick={onClickSubmit}
          />
        }
        {uploading &&
          <LoadingIndicator />
        }
      </SubmitContainer>
    </ManageLidarMapContainer>
  )
}

interface IFileInputWithLabelProps {
  label: string;
  onChange?: (file: File) => void;
}

export const FileInputWithLabel = React.forwardRef<HTMLInputElement, IFileInputWithLabelProps>(({label, onChange}, ref) => {
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      if (onChange) {
        onChange(e.target.files[0]);
      }
    }
  }

  return (
    <div>
      <div>
        {label}
      </div>
      <StyledFileInput
        ref={ref}
        style={{width: '250px', marginTop: '10px', marginBottom: '0px'}}
        onChange={onSelectFile}
      />
    </div>
  )
});

const ManageLidarMapContainer = styled.div`
  padding: 0px 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TransformationButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const EmptyDiv = styled.div`
  flex: 1;
`;

const LidarAssetsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;

const CenterFlexContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const FileInputContainer = styled(CenterFlexContainer)`
  gap: 25px;
`;

const SelectContainer = styled(CenterFlexContainer)``;

const SubmitContainer = styled(CenterFlexContainer)``;