import { useCallback, useRef, useState } from 'react';
import { customSceneKey, ForgeViewer } from "../../../common/ForgeViewer/ForgeViewer";
import { Button } from "../../../common/Inputs/Button"
import { CoordinatesContainer, PointControlsContainer } from "../project-floors/LidarPointPicker/MapPointPicker"
import * as THREE from 'three';
import { useEffect } from 'react';
import { ForgePoint } from './ProjectForgeTransformation';

interface IForgeViewerPointPickerProps {
  fileUrn: string;
  forgePoints: ForgePoint[];
  setForgePoints: React.Dispatch<React.SetStateAction<ForgePoint[]>>;
  selectingFloorHeight: boolean;
  setSelectingFloorHeight: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFloorCode: string;
}

export const ForgeViewerPointPicker = ({
  fileUrn,
  forgePoints,
  setForgePoints,
  selectingFloorHeight,
  setSelectingFloorHeight,
  selectedFloorCode,
}: IForgeViewerPointPickerProps) => {
  const viewer = useRef<any>();
  const selectingFloorHeightRef = useRef(selectingFloorHeight);
  const selectedFloorCodeRef = useRef<string>(selectedFloorCode);
  const forgePointsRef = useRef<ForgePoint[]>(forgePoints);
  const sphereMeshesRef = useRef<any[]>([]);
  const geomsRef = useRef<any[]>([]);
  const materialsRef = useRef<any[]>([]);

  const [selectedForgePoints, setSelectedForgePoints] = useState<Set<number>>(new Set());

  const onClickForgeCoordinates = useCallback((e: React.MouseEvent<HTMLSpanElement>, index: number) => {
    const point = forgePoints[index];
    const material = materialsRef.current[index];
    const updatedSelectedPoints = new Set(selectedForgePoints);

    if (e.ctrlKey) {
      const position = point.vector.clone();
      position.setX(position.x + 10);
      position.setY(position.y + 10);
      const target = point.vector.clone();
      viewer.current.navigation.setView(position, target);
    } else {
      if (updatedSelectedPoints.has(index)) {
        updatedSelectedPoints.delete(index);
        material.color = new (window as any).THREE.Color(0x073c7a);
      } else {
        updatedSelectedPoints.add(index);
        material.color = new (window as any).THREE.Color(0xFB7185);
      }

      setSelectedForgePoints(updatedSelectedPoints);
    }
  }, [forgePoints, selectedForgePoints]);

  const mapPointListToSpan = (point: {point: ForgePoint, originalIndex: number}, i: number, arr: {point: ForgePoint, originalIndex: number}[]) => {
    let spanText = '';
    const forgePoint = point.point;

    if (!forgePoint.selectingFloorHeight) {
      spanText = `(${forgePoint.vector.x.toFixed(3)}, ${forgePoint.vector.y.toFixed(3)}, ${forgePoint.vector.z.toFixed(3)})`;
    } else {
      spanText = `${forgePoint.vector.z.toFixed(3)}`;
    }

    spanText += i < arr.length - 1 ? ', ' : '';

    return (
      <span
        onClick={e => onClickForgeCoordinates(e, point.originalIndex)}
        style={{cursor: 'pointer', fontWeight: selectedForgePoints.has(point.originalIndex) ? 'bold' : 'normal'}}
      >
        {spanText}
      </span>
    );
  };

  const getPointListForDisplay = (selectingFloorHeight: boolean) => {
    const coordinatesWithOriginalIndex = 
      forgePoints
        .map((point, i) => ({point: point, originalIndex: i}))
        .filter(point => point.point.selectingFloorHeight === selectingFloorHeight && point.point.floor_code === selectedFloorCode);

      return coordinatesWithOriginalIndex.map(mapPointListToSpan);
  };

  const forgeCoordinatesList = getPointListForDisplay(false);
  const floorHeightsList = getPointListForDisplay(true);

  const updateCustomGeometryState = (newForgePoint: ForgePoint, geom: any, material: any, sphereMesh: any) => {
    setForgePoints(prevPoints => [...prevPoints, newForgePoint]);
    sphereMeshesRef.current = [...sphereMeshesRef.current, sphereMesh];
    geomsRef.current = [...geomsRef.current, geom];
    materialsRef.current =[...materialsRef.current, material];
  }

  const replaceCurrentSelectedFloorHeight = (newForgePoint: ForgePoint, geom: any, material: any, sphereMesh: any) => {
    let selectedFloorHeightIndex = -1;

    for (let i=0; i<forgePointsRef.current.length; i++) {
      const currrentPoint = forgePointsRef.current[i];

      if (currrentPoint.selectingFloorHeight && currrentPoint.floor_code === newForgePoint.floor_code) {
        selectedFloorHeightIndex = i;
        break;
      }
    }

    if (selectedFloorHeightIndex >= 0) {
      viewer.current.overlays.removeMesh(sphereMeshesRef.current[selectedFloorHeightIndex], customSceneKey);
      materialsRef.current[selectedFloorHeightIndex].dispose();
      geomsRef.current[selectedFloorHeightIndex].dispose();

      setForgePoints(prevPoints => [...prevPoints.slice(0, selectedFloorHeightIndex), ...prevPoints.slice(selectedFloorHeightIndex+1), newForgePoint]);
      sphereMeshesRef.current = [...sphereMeshesRef.current.slice(0, selectedFloorHeightIndex), ...sphereMeshesRef.current.slice(selectedFloorHeightIndex+1), sphereMesh];
      geomsRef.current = [...geomsRef.current.slice(0, selectedFloorHeightIndex), ...geomsRef.current.slice(selectedFloorHeightIndex+1), geom];
      materialsRef.current = [...materialsRef.current.slice(0, selectedFloorHeightIndex), ...materialsRef.current.slice(selectedFloorHeightIndex+1), material];
    } else {
      updateCustomGeometryState(newForgePoint, geom, material, sphereMesh);
    }
  }

  const onPlaceForgePoint = (currentPosition: THREE.Vector3) => {
    const geom = new (window as any).THREE.SphereGeometry(2, 15, 15);
    const material = new (window as any).THREE.MeshBasicMaterial({ color: 0x073c7a });
    const sphereMesh = new (window as any).THREE.Mesh(geom, material);

    sphereMesh.position.set(currentPosition.x, currentPosition.y, currentPosition.z);
    const meshAdded = viewer.current.overlays.addMesh(sphereMesh, customSceneKey);

    if (meshAdded) {
      const newForgePoint: ForgePoint = {
        vector: currentPosition,
        floor_code: selectedFloorCodeRef.current,
        selectingFloorHeight: selectingFloorHeightRef.current,
      };
      
      if (newForgePoint.selectingFloorHeight) {
        replaceCurrentSelectedFloorHeight(newForgePoint, geom, material, sphereMesh);
        setSelectingFloorHeight(false);
      } else {
        updateCustomGeometryState(newForgePoint, geom, material, sphereMesh);
      }
    } else {
      viewer.current.overlays.removeMesh(sphereMesh, customSceneKey);
      material.dispose();
      geom.dispose();
    }
  }

  const onDestroyCustomGeomtry = () => {
    sphereMeshesRef.current.forEach(sphereMesh => {
      viewer.current.overlays.removeMesh(sphereMesh, customSceneKey);
    });

    viewer.current.overlays.removeScene(customSceneKey);
    
    materialsRef.current.forEach(material => {
      material.dispose();
    });

    geomsRef.current.forEach(geom => {
      geom.dispose();
    })
  }

  const onClearSelectedForgePoints = () => {
    sphereMeshesRef.current.forEach((sphereMesh, i) => {
      if (selectedForgePoints.has(i)) {
        viewer.current.overlays.removeMesh(sphereMesh, customSceneKey);
      }
    });

    materialsRef.current.forEach((material, i) => {
      if (selectedForgePoints.has(i)) {
        material.dispose();
      }
    });

    geomsRef.current.forEach((geom, i) => {
      if (selectedForgePoints.has(i)) {
        geom.dispose();
      }
    })

    setForgePoints(prevPoints => prevPoints.filter((point, i) => !selectedForgePoints.has(i)));
    sphereMeshesRef.current = sphereMeshesRef.current.filter((mesh, i) => !selectedForgePoints.has(i));
    materialsRef.current = materialsRef.current.filter((material, i) => !selectedForgePoints.has(i));
    geomsRef.current = geomsRef.current.filter((geom, i) => !selectedForgePoints.has(i));
    setSelectedForgePoints(new Set());
  }

  useEffect(() => {
    selectingFloorHeightRef.current = selectingFloorHeight;
  }, [selectingFloorHeight]);

  useEffect(() => {
    selectedFloorCodeRef.current = selectedFloorCode;
  }, [selectedFloorCode]);

  useEffect(() => {
    forgePointsRef.current = forgePoints;
  }, [forgePoints]);

  return (
    <>
      <ForgeViewer
        fileUrn={fileUrn}
        onPlacePoint={onPlaceForgePoint}
        viewer={viewer}
        onDestroyCustomGeometry={onDestroyCustomGeomtry}
      />
      <PointControlsContainer>
        <Button
          disabled={selectedForgePoints.size === 0}
          text="Clear Selected Points"
          onClick={onClearSelectedForgePoints}
        />
      </PointControlsContainer>
      <CoordinatesContainer>
        Coordinates:&nbsp;
        <>{forgeCoordinatesList}</>
      </CoordinatesContainer>
      <CoordinatesContainer>
        Floor Height:&nbsp;
        <>{floorHeightsList}</>
      </CoordinatesContainer>
    </>
  )
}