import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useFetchStairClimberScriptsQuery } from "../../../hooks/projectQueries";
import { Table } from "../../common/Table/Table";
import { StairClimberScript } from "../../../api/stairClimberScripts";
import { LoadingIndicator } from "../../common/LoadingIndicator";
import { Button } from "../../common/Inputs/Button";
import { useStairsNavigation } from "../../../hooks/useNavigation";

export const StairClimberScripts = () => {
  const {
    navigateToStairClimberScript
  } = useStairsNavigation();

  const {data: scripts, isLoading: scriptsLoading} = useFetchStairClimberScriptsQuery();

  const dataLoaded = !!scripts && !scriptsLoading;

  const generateFloorsList = useCallback((item: StairClimberScript) => {
    return item.floors.map(floor => floor.project_floor.floor_code).join(', ');
  }, []);

  const columns = useMemo(() => [
    { Header: 'Id', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Site', accessor: 'staircase.project.name' },
    { Header: 'Staircase', accessor: 'staircase.name' },
    { Header: 'Starting Floor', accessor: generateFloorsList},
  ], [generateFloorsList]);

  const data = useMemo(() => {
    if (dataLoaded) {
      return scripts;
    }

    return [];
  }, [dataLoaded, scripts]);

  const onClickTableRow = (originalRow: StairClimberScript) => {
    navigateToStairClimberScript(originalRow.id);
  }

  return (
    <StairClimberScriptsContainer>
      <HeaderContainer>
        <EmptyDiv/>
        <TitleContainer>Stair Climber Scripts</TitleContainer>
        <ButtonContainer>
          <Button
            primary
            text="Create New Stair Climber Script"
            onClick={() => navigateToStairClimberScript('new')}
          />
        </ButtonContainer>
      </HeaderContainer>
      {!dataLoaded &&
        <LoadingIndicator/>
      }
      {dataLoaded &&
        <Table
          columns={columns}
          data={data}
          onClickRow={onClickTableRow}
          initialSortBy={[{id: 'id', desc: false}]}
        />
      }
    </StairClimberScriptsContainer>
  )
}

const StairClimberScriptsContainer = styled.div`
  padding: 10px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 40px;
`;

const TitleContainer = styled.div`
  font-size: 30px;
`;

const EmptyDiv = styled.div`
  flex: 1;
`

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;