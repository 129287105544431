import {SidebarCard} from "../../../common/SidebarCard/SidebarCard";
import {Flex} from "@react-css/flex";
import {Icon} from "../../../common/Icon";
import iconRefresh from "../../../../assets/images/icons/icon_refresh.svg";
import iconImageStack from "../../../../assets/images/icons/icon_image_stack.svg";
import iconCheck from "../../../../assets/images/icons/icon_check.svg";
import {useEditorContext} from "../../../../contexts/editorContext";
import styled from "styled-components";
import Spinner from 'react-spinkit';
import React from 'react';


interface IImageListCardProps {
    setImageSelector: (val: boolean) => void;
    paginate: () => void;
}
export const ImageListCard = ({setImageSelector, paginate}:IImageListCardProps) => {
    const {state: EditorState, updateEditor} = useEditorContext()

    const setActiveImage = (image: any) => {
        if(EditorState.activeImage.id !== image.id)
            updateEditor({activeImage: image, loading: true})
    }

    const renderImages = () => {
        return EditorState.images.map( (image: any) => (
            <ImageTile key={image.id} onClick={() => setActiveImage(image)} className={EditorState.activeImage?.id === image.id ? 'active-image' : ''}>
                {image.verified && <Icon icon={iconCheck} size={16} style={{position: "absolute", top: 2, left: 2}}/>}
                    <img src={image.processed_image_url} alt={""} style={{maxWidth: "100%"}}/>
            </ImageTile>

        ))
    }

    return(
        <SidebarCard heading="Images" style={{height: "60%"}}>
            <Flex justifyEnd>
                <div style={{height: '5px'}}>
                  {EditorState.loading && <Spinner name="wave" color="#ccc" fadeIn={'none'} style={{height: "10px"}}/>}
                </div>
                <div onClick={() => null} style={{cursor: "pointer", margin: "0 5px"}}>
                    <Icon icon={iconRefresh} size={20} onClick={ () => updateEditor({refreshData: true})}/>
                </div>
                <div onClick={() => setImageSelector(true)} style={{cursor: "pointer", margin: "0 5px"}}>
                    <Icon icon={iconImageStack} size={20} />
                </div>
            </Flex>
            <Flex flexDirection="row" flexWrap="wrap" style={{paddingTop: "10px", overflowY: "scroll", maxHeight: "100%"}}>
                {EditorState.images?.length ? renderImages() : <p>No Images! Open the selector to add images.</p>}
                {EditorState.offset && <button onClick={paginate}>Load More</button>}
            </Flex>
        </SidebarCard>
    )
}

const ImageTile = styled.div`
    position: relative;
    width: 45%;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px auto;
    cursor: pointer;

    &.active-image{
        border: 1px solid #0f0;
    }
`;
