import Flex from "@react-css/flex";
import { PermissionWrappedComponent } from "../../common/PermissionWrapper/PermissionWrapper";
import { Card } from "../../common/Card/Card";
import { PATH_STRINGS } from "../../../hooks/useGeneratedPaths";
import cardBg from '../../../assets/images/card-bg-1.jpg';
import cardBg2 from '../../../assets/images/card-bg-2.jpg';
import cardBg3 from '../../../assets/images/card-bg-3.jpg';

export const EntitiesDashboard = () => {
  return (
    <div>
      <Flex
        justifyCenter
        wrap
      >
        <PermissionWrappedComponent permission="nextera_sys_tables.create_user">
          <Card
            link={PATH_STRINGS.userManagementDashboard}
            img={cardBg}
            name={'User Management'}
          />
        </PermissionWrappedComponent>
        <PermissionWrappedComponent permission="nextera_sys_tables.create_company">
          <Card
            link={PATH_STRINGS.companies}
            img={cardBg2}
            name={'Company Management'}
          />
        </PermissionWrappedComponent>
        <PermissionWrappedComponent permission="project_tables.create_location">
          <Card
            link={PATH_STRINGS.locations}
            img={cardBg3}
            name={'Location Management'}
          />
        </PermissionWrappedComponent>
        <PermissionWrappedComponent permission="project_tables.create_project_stair_climber_script">
          <Card
            link={PATH_STRINGS.stairClimberScripts}
            img={cardBg}
            name='Stair Management'
          />
        </PermissionWrappedComponent>
      </Flex>
    </div>
  );
}