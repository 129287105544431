import { useReducer, createContext, useContext, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Project } from '../api/projects';
import { useProjectFloorQuery, useProjectQuery } from '../hooks/projectQueries';
import { ProjectFloor } from '../api/projectFloors';
import { ItemType } from '../api/items';

interface ProgressState {
  projectId: string | null;
  project: Project | null;
  floorId: string | null;
  floor: ProjectFloor | null;
  trackerName: string | null;
  itemTypeId: number | null;
  selectedItemType: ItemType | null;
}

const initialState: ProgressState = {
  projectId: null,
  project: null,
  floorId: null,
  floor: null,
  trackerName: null,
  itemTypeId: null,
  selectedItemType: null,
};

interface IProgressContext {
  state: ProgressState,
  dispatch: React.Dispatch<ProgressAction>;
}

const initialProgressContext: IProgressContext = {
  state: {...initialState},
  dispatch: () => {},
}

const UPDATE_STATE = 'UPDATE_STATE';
const UPDATE_PROJECT = 'UPDATE_PROJECT';

type ProgressAction = {
  type: typeof UPDATE_STATE | typeof UPDATE_PROJECT,
  payload: Partial<ProgressState>,
}

const ProgressContext = createContext<IProgressContext>({...initialProgressContext});

const progressReducer = (state: ProgressState, action: ProgressAction) => {
  switch (action.type) {
    case UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case UPDATE_PROJECT:
    default:
      return {
        ...state
      };
  }
}

export const ProgressProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(progressReducer, {...initialState});

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const projectParam = params.get('project');
  const floorParam = params.get('floor');
  const trackerParam = params.get('tracker');
  const itemTypeParam = params.get('itemType');

  const projectQueryOnSuccess = useCallback((incomingProject: Project) => {
    dispatch({
      type: UPDATE_STATE,
      payload: {
        project: incomingProject,
      }
    });
  }, []);

  const projectFloorQueryOnSuccess = useCallback((incomingFloor: ProjectFloor) => {
    dispatch({
      type: UPDATE_STATE,
      payload: {
        floor: incomingFloor,
      }
    });
  }, []);

  useProjectQuery(projectParam, projectQueryOnSuccess);
  useProjectFloorQuery(projectParam, floorParam, projectFloorQueryOnSuccess);

  useEffect(() => {
    let stateToUpdate: Partial<ProgressState> = {
      projectId: projectParam,
      floorId: floorParam,
      trackerName: trackerParam,
      itemTypeId: itemTypeParam ? parseInt(itemTypeParam) : null,
    };

    if (!projectParam) {
      stateToUpdate.project = null;
    }

    if (!floorParam) {
      stateToUpdate.floor = null;
    }

    if (!itemTypeParam) {
      stateToUpdate.selectedItemType = null;
    }

    dispatch({
      type: UPDATE_STATE,
      payload: stateToUpdate
    });
  }, [floorParam, itemTypeParam, projectParam, trackerParam]);

  return (
    <ProgressContext.Provider value={{state, dispatch}}>
      {children}
    </ProgressContext.Provider>
  )
}

export const useProgressContext = () => {
  return useContext(ProgressContext);
};
