import {Flex} from "@react-css/flex";
import styled from "styled-components";
import {useEffect, useState} from "react";

import {Sidebar} from "../../../common/Sidebar/Sidebar";
import {SidebarCard} from "../../../common/SidebarCard/SidebarCard";
import {useEditorContext} from "../../../../contexts/editorContext";
import {Icon} from "../../../common/Icon";
import {deleteLabel, Label, listLabelTypes} from "../../../../api/labels";

import iconDelete from "../../../../assets/images/icons/icon_delete.svg"
import {LabelTree} from "./LabelTree";
import {useNotifications} from "../../../../contexts/notificationProvider";



export const LabelingSidebar = () => {
    const {state: EditorState, updateEditor} = useEditorContext()
    const {addNotification} = useNotifications()
    const [activeMode, setActiveMode] = useState("Safety")

    const getLabels = async () => {
        let labelsTypes = await listLabelTypes({category: activeMode.toUpperCase()})
        updateEditor({labels: labelsTypes.data})
    }

    const _deleteLabel = async (label: Label) => {
        let _label = await deleteLabel(label);
        updateEditor({refreshImage: true})
        addNotification('Label Removed', 'success')
    }

    useEffect( () => {
        updateEditor({labelingMode: activeMode})
        getLabels()
    }, [activeMode])

    return (
        <Sidebar minWidth={"350px"} maxWidth={"350px"} style={{color: "#fff", background: "#444", padding: "10px", paddingTop: "35px"}} collapsible>
            <Flex flexDirection="row" style={{background: "#222", borderRadius: "5px", margin: "0 10px"}} justifyCenter>
                <ModeButton onClick={() => setActiveMode("Safety")}
                            style={{background: activeMode === "Safety" ? "#ccc" : "#222", color: activeMode === "Safety" ? "#222" : "#ccc"}}>
                    Safety
                </ModeButton>
                <ModeButton onClick={() => setActiveMode("Progress")}
                            style={{background: activeMode === "Progress" ? "#ccc" : "#222", color: activeMode === "Progress" ? "#222" : "#ccc"}}>
                    Progress
                </ModeButton>
            </Flex>
            <SidebarCard heading="Labels" style={{height: "55%"}}>
                <LabelTree />
            </SidebarCard>
            <SidebarCard heading="Labels in Image" style={{height: "30%"}} >
                <div style={{height: "100%", overflowY: "auto"}}>
                {EditorState.activeImage?.labels?.map( (label: any) => (
                    <div key={label.id}>
                        <Flex flexDirection="row" justifySpaceBetween alignItemsCenter>
                            <p style={{color: label.type.color}}>{label.id}: {label.type.name}</p>
                            <Icon icon={iconDelete} size={24} style={{cursor: "pointer"}} onClick={() => _deleteLabel(label)}/>
                        </Flex>
                    <p style={{fontSize: "0.7em"}}> {label.is_manual ? "manual" : "auto"} - {Math.round(label.confidence*100)}%</p>
                    </div>
                ))}
                </div>
            </SidebarCard>
        </Sidebar>
    )
}

const ModeButton = styled.div`
    background: #ccc;
    color: #000;
    width: 50%;
    height: 100%;
    text-align: center;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
`
