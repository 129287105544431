import axios from "axios";
import { API_BASE } from "./constants";

export interface ProgressRegionPoint {
  id: number;
  order: number;
  x: number;
  y: number;
}

export interface ProgressRegion {
  id: number;
  position: ProgressRegionPoint[];
  viewpoint: number;
  viewpoint_sub_id: number;
}

export interface ViewpointsPoint {  
  has_image: boolean;
  id: number;
  is_active: boolean;
  point_id: number;
  project_floor_section: number;
  x: string;
  y: string;
  progress_regions: ProgressRegion[];
}

export interface ViewpointsPointsParams {
  activeOnly?: boolean;
  fields?: string[];
}

export const fetchPoints = async (projectId: string, floorId: string, params?: ViewpointsPointsParams): Promise<ViewpointsPoint[]> => {
  const res = await axios.get(
    `${API_BASE}/project/${projectId}/floor/${floorId}/viewpoints/points`,
    {
      params: {
        activeOnly: params?.activeOnly,
        fields: params?.fields?.join(','),
      },
    }
  );
  const response: any = await res.data;
  return response.data;
};