import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useProjectContext } from "../../../../contexts/projectContext";
import { PATH_STRINGS, useGeneratedPaths } from "../../../../hooks/useGeneratedPaths";
import { FormContainer } from "../../../common/FormControls";
import { ViewMenu } from "../../../common/ViewMenu/ViewMenu"

export const ProjectMenu = () => {
  const {
    state: projectState
  } = useProjectContext();

  const {
    projectId,
    floorId,
    sectionId,
    lidarMapId,
    lidarMapConfigId,
  } = projectState;

  const projectIsNew = projectId === 'new';

  const {
    generateProjectPath,
    generateProjectImagePath,
    generateProjectFloorsPath,
    generateFloorPath,
    generateProjectFloorPlanPath,
    generateProjectFloorSectionsPath,
    generateLidarTransformationPath,
    generateProjectFloorLidarMapsPath,
    generateProjectFloorLidarMapUploadPath,
    generateProjectUsersPath,
    generateProjectFloorSectionPath,
    // generateProjectSubcontractorsPath,
    generateProjectForgeTransformationPath,
    generateLidarEditingPath,
    generateLidarReviewPath,
    generateProjectLidarMapConfigurationsPath,
    generateProjectLidarMapConfigurationPath,
  } = useGeneratedPaths();

  const viewMenuItems = useMemo(() => [
    { route: PATH_STRINGS.projectsDashboard, label: 'Projects list' },
    { route: generateProjectPath(projectId), label: 'Project Details' },
    ...!projectIsNew ? [{ route: generateProjectImagePath(projectId), label: 'Assets' }] : [],
    ...!projectIsNew ? [{ route: generateProjectFloorsPath(projectId), label: 'Floors' }] : [],
    ...!projectIsNew ? [{ route: generateProjectUsersPath(projectId), label: 'Users' }] : [],
    ...!projectIsNew ? [{ route: generateProjectLidarMapConfigurationsPath(projectId), label: 'Map Configurations' }] : [],
    // ...!projectIsNew ? [{ route: generateProjectSubcontractorsPath(projectId), label: 'Subcontractors' }] : [],
    ...!projectIsNew ? [{ route: generateProjectForgeTransformationPath(projectId), label: 'Forge Transformation' }] : [],
  ], 
  [
    generateProjectPath,
    generateProjectImagePath,
    generateProjectFloorsPath,
    generateProjectUsersPath,
    generateProjectLidarMapConfigurationsPath,
    // generateProjectSubcontractorsPath,
    projectId,
    projectIsNew,
    generateProjectForgeTransformationPath
  ]);

  const history = useHistory();
  const [viewMode, setViewMode] = useState(viewMenuItems[0]);

  useEffect( () => {
    const pathName = history.location.pathname;

    let matchesFloorPath = pathName === generateProjectFloorsPath(projectId);
    let matchesLidarMapConfigPath = pathName === generateProjectLidarMapConfigurationsPath(projectId);

    if (floorId) {
      matchesFloorPath = pathName === generateFloorPath(projectId, floorId) ||
                         pathName === generateProjectFloorPlanPath(projectId, floorId) ||
                         pathName === generateProjectFloorSectionsPath(projectId, floorId) ||
                         pathName === generateProjectFloorLidarMapsPath(projectId, floorId);
      if (sectionId) {
        matchesFloorPath = pathName === generateProjectFloorSectionPath(projectId, floorId, sectionId);
      } else if (lidarMapId) {
        matchesFloorPath = pathName === generateProjectFloorLidarMapUploadPath(projectId, floorId, lidarMapId) ||
                           pathName === generateLidarEditingPath(projectId, floorId, lidarMapId) ||
                           pathName === generateLidarTransformationPath(projectId, floorId, lidarMapId) ||
                           pathName === generateLidarReviewPath(projectId, floorId, lidarMapId);
      }
    } 
    
    if (lidarMapConfigId) {
      matchesLidarMapConfigPath = pathName === generateProjectLidarMapConfigurationPath(projectId, lidarMapConfigId);
    } 
    
    if (pathName === generateProjectPath(projectId)) {
      setViewMode(viewMenuItems[1]);
    } else if (pathName === PATH_STRINGS.projectsDashboard) {
      setViewMode(viewMenuItems[0]);
    } else if (pathName === generateProjectUsersPath(projectId)) {
      setViewMode(viewMenuItems[4]);
    } else if (matchesLidarMapConfigPath) {
      setViewMode(viewMenuItems[5]);
    } else if (history.location.pathname === generateProjectForgeTransformationPath(projectId)) {
      setViewMode(viewMenuItems[6]);
    } else if (matchesFloorPath) {
      setViewMode(viewMenuItems[3]);
    } else {
      setViewMode(viewMenuItems[2]);
    }
  }, 
  [
    history.location.pathname,
    viewMenuItems,
    generateProjectPath,
    generateProjectFloorsPath,
    projectId,
    generateFloorPath,
    floorId,
    generateProjectFloorPlanPath,
    generateProjectFloorSectionsPath,
    generateProjectUsersPath,
    sectionId,
    generateProjectFloorSectionPath,
    generateProjectLidarMapConfigurationPath,
    lidarMapConfigId,
    generateProjectLidarMapConfigurationsPath,
    generateLidarTransformationPath,
    generateProjectFloorLidarMapsPath,
    generateProjectFloorLidarMapUploadPath,
    generateProjectForgeTransformationPath,
    generateLidarEditingPath,
    generateLidarReviewPath,
    lidarMapId,
  ]);

  return (
    <FormContainer
      style={{
        position: 'relative',
        zIndex: 2,
      }}
    >
      <ViewMenu
        items={viewMenuItems}
        activeItem={viewMode}
        containerStyle={{ marginBottom: '20px' }}
      />
    </FormContainer>
  )
}