import { createRef, useState } from "react";
import PanZoom from "../../../../../common/PanZoom/PanZoom";
import { PanZoomProvider } from "../../../../../common/PanZoom/PanZoomContext"
import { useProgressContext } from "../../../../../../contexts/progressContext";
import { Box } from "@mui/material";
import { ProgressRegionsEditorMapViewSVG } from "./ProgressRegionsEditorMapViewSVG";

export const ProgressRegionsEditorMapView = () => {
  const {
    state: progressState,
  } = useProgressContext();

  const {
    floor
  } = progressState;

  const [mapX, setMapX] = useState<number>(0);
  const [mapY, setMapY] = useState<number>(0);
  const [mapWidth, setMapWidth] = useState<number | undefined>();
  const [mapHeight, setMapHeight] = useState<number | undefined>();

  const mapContainerRef = createRef<HTMLDivElement>();

  const onLoadImage = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const target = e.target as HTMLImageElement;

    if (target) {
      setMapWidth(target.width);
      setMapHeight(target.height);
    }
  }

  return (
    <Box
      height="100%"
    >
      <PanZoomProvider initialScale={0.3}>
        <PanZoom
          mapContainerRef={mapContainerRef}
          minScale={0.1}
          maxScale={20}
          x={mapX}
          updateX={setMapX}
          y={mapY}
          updateY={setMapY}
        >
          {!!floor?.latest_floor_plan?.web_image_url && (
            <>
              <img
                alt="floor plan"
                src={floor.latest_floor_plan.web_image_url}
                style={{
                  position: 'absolute',
                }}
                onLoad={e => onLoadImage(e)}
              />
              <Box
                position="absolute"
                height={mapHeight}
                width={mapWidth}
              >
                <ProgressRegionsEditorMapViewSVG/>
              </Box>
            </>
          )}
        </PanZoom>
      </PanZoomProvider>
    </Box>
  );
}