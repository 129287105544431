import React, {useEffect, useState} from 'react';
import { Flex } from '@react-css/flex';
import {Route, Switch, useHistory, useLocation} from 'react-router-dom';

import { ViewMenu } from '../../common/ViewMenu/ViewMenu';
import { SafetyDashboard } from './SafetyDashboard/SafetyDashboard';

const ViewModeMapping: any = [
  {
    label: "Safety",
    route: "/hoop/safety"
  },
  {
    label: "Progress",
    route: "/hoop/progress"
  }
];

export const Hoop = () => {
  const history = useHistory();
  const location = useLocation();
  const [viewMode, setViewMode] = useState(ViewModeMapping[0]);

  useEffect( () => {
    setViewMode(ViewModeMapping.find((item: any) => item.route === history.location.pathname))
  }, [location])

  return (
    <div style={{ marginTop: '1em' }}>
      <Flex justifyCenter>
        <ViewMenu
          items={ViewModeMapping}
          activeItem={viewMode}
        />
      </Flex>
      <Switch>
        <Route path="/hoop/safety" exact>
          <SafetyDashboard />
        </Route>
      </Switch>
    </div>
  );
};
