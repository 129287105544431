import { useEffect, useRef, useState } from 'react';
// @ts-ignore
import * as Annotorious from '@recogito/annotorious-openseadragon';
import '@recogito/annotorious-openseadragon/dist/annotorious.min.css';
import {AnnotoriousToolbar} from "./AnnotoriousToolbar";
import {useEditorContext} from "../../../contexts/editorContext";
import './widgets/LabelSelectWidget'
import './formatters/LabelColorFormatter'
import {LabelSelectWidget} from "./widgets/LabelSelectWidget";
import {LabelColorFormatter} from "./formatters/LabelColorFormatter";
import {createLabel, deleteLabel, Label, updateLabel} from "../../../api/labels";
import {useNotifications} from "../../../contexts/notificationProvider";
import OpenSeaDragon from 'openseadragon';
import { LoadingIndicator } from '../../common/LoadingIndicator';


export const Annotoirous = () => {
    const annoRef = useRef<any>()
    const idRef = useRef<any>()
    const [viewer, setViewer] = useState<any>( null);
    const {state: EditorState, updateEditor} = useEditorContext()
    const {addNotification} = useNotifications()

    const extractPolyPoints = (svg: string) => (
       svg.split("\"")[1]
    )

    const annotationToLabel = (annotation: Annotation): Label => ({
        id: typeof annotation.id === "number" ? annotation.id : null,
        confidence: 1,
        coordinates: annotation.target.selector.type === "SvgSelector" ? extractPolyPoints(annotation.target.selector.value) : annotation.target.selector.value.split('xywh=pixel:')[1],
        is_manual: true,
        processed_image: idRef.current.getAttribute('data-id'),
        shape: annotation.target.selector.type === "SvgSelector" ? "POLYGON" : "RECTANGLE",
        type: parseInt(annotation.body[0].value)
    })

    const _createLabel = async (annotation: Annotation) => {
        let _label = await createLabel(annotationToLabel(annotation));
        updateEditor({refreshImage: true})
        addNotification("Label created", "success")
    }
    const _updateLabel = async (annotation: Annotation) => {
        let _label = await updateLabel(annotationToLabel(annotation));
        updateEditor({refreshImage: true})
        addNotification("Label updated", "success")
    }
    const _deleteLabel = async (annotation: Annotation) => {
        let _label = await deleteLabel(annotationToLabel(annotation));
        updateEditor({refreshImage: true})
        addNotification("Label removed", "success")
    }

    const addImageLabels = () => {
        EditorState.activeImage?.labels.forEach( (label: any) => {
            if(EditorState.activeLabels.includes(label.type.name)) {
                let target;
                if (label.shape === "RECTANGLE") {
                    target = {
                        "selector": {
                            "type": "FragmentSelector",
                            "conformsTo": "http://www.w3.org/TR/media-frags/",
                            "value": "xywh=pixel:" + label.coordinates
                        }
                    }
                } else if (label.shape === "POLYGON") {
                    target = {
                        "selector": {
                            "type": "SvgSelector",
                            "value": "<svg><polygon points='" + label.coordinates + "'></polygon></svg>"
                        }
                    }
                }

                let annotation = {
                    "@context": "http://www.w3.org/ns/anno.jsonld",
                    "id": label.id,
                    "type": "Annotation",
                    "body": [{
                        "type": "TextualBody",
                        "purpose": "labeling",
                        "value": label.type.name,
                        "color": label.type.color
                    }],
                    "target": target
                }
                annoRef.current.addAnnotation(annotation)
            }
        })
    }

    useEffect(() => {
        if(viewer){
            viewer.close()
            viewer.open({type: 'image',
                url: EditorState.showBlur ? EditorState.activeImage?.processed_image_url : EditorState.activeImage?.base_image_url || ""})
        }
    }, [EditorState.activeImage])

    /* if we have labels, render them */
    useEffect( () => {
        if(annoRef.current && EditorState.activeImage?.labels?.length){
            annoRef.current.clearAnnotations();
            addImageLabels()
        }
    }, [EditorState.activeImage, EditorState.activeLabels, annoRef.current ])

    /* init annotorious */
    useEffect(() => {
        if(!EditorState.activeImage)
            return

        if(annoRef.current || viewer){
            return
        }

        let vwr = OpenSeaDragon({
            id: "openSeaDragon",
            animationTime: 0.5,
            blendTime: 0.1,
            constrainDuringPan: true,
            maxZoomPixelRatio: 2,
            minZoomLevel: 1,
            visibilityRatio: 1,
            zoomPerScroll: 1,
            showNavigationControl: false,
            gestureSettingsMouse: {
                clickToZoom: false
            }
        })
        let annotorious = Annotorious(vwr, {
            widgets: [
                {widget: LabelSelectWidget, labels: EditorState.labels, force: 'PlainJS'}
            ],
            formatter: LabelColorFormatter
        });
        annotorious.on('createAnnotation', (annotation: Annotation) => {
            _createLabel(annotation)
        });

        annotorious.on('updateAnnotation', (annotation: Annotation, previous: Annotation) => {
            _updateLabel(annotation)
        });

        annotorious.on('deleteAnnotation', (annotation: Annotation) => {
            _deleteLabel(annotation)
        });

        vwr.addHandler('tile-loaded', function (viewer: any) {
            updateEditor({loading: false})
        })

        vwr.open({type: 'image',
            url: EditorState.showBlur ? EditorState.activeImage?.processed_image_url : EditorState.activeImage?.base_image_url || ""})
        annoRef.current = annotorious;
        setViewer(vwr)
    }, [EditorState.labels, EditorState.activeImage]);

    return (
      <div style={{width: "100%"}}>
          {EditorState.loading && <LoadingIndicator color="#ccc"/>}
          <AnnotoriousToolbar anno={annoRef} viewer={viewer}/>
          <span ref={idRef} data-id={EditorState.activeImage?.id || ""} style={{display: "none"}}/>
          <div id="openSeaDragon"  style={{width: "100%", height: "100%", maxHeight: "90%"}} />
      </div>
    );
}

interface Annotation {
    "@context": string;
    id: string|number;
    type: string;
    body: AnnotationBody[],
    target: any | any[]
}
interface AnnotationBody {
    type: string;
    purpose: string;
    value: string;
    color: string;
}
