import { useCallback, useMemo } from "react";
import { EditableProgressRegion, useProgressRegionsContext } from "../../../../../../contexts/progressRegionsContext";

interface IProgressRegionsEditorMapViewSVGRegionProps {
  region: EditableProgressRegion;
}

export const ProgressRegionsEditorMapViewSVGRegion = ({
  region,
}: IProgressRegionsEditorMapViewSVGRegionProps) => {
  const {
    dispatch: dispatchProgressRegions,
    onUpdateTouched,
    onUpdateRegion,
    setRegions,
    setRegionEdits,
  } = useProgressRegionsContext();

  const getRegionCoordinates = useCallback((region: EditableProgressRegion) => {
    return region.position.map(point => [point.x, point.y]);
  }, []);

  const generatePolygonPoints = useCallback((region: EditableProgressRegion) => {
    const coordinates = getRegionCoordinates(region);

    return coordinates.map(point => `${point[0]},${point[1]}`).join(' ');
  }, [getRegionCoordinates]);

  const onMouseDownRegion = useCallback((e: React.MouseEvent<SVGLineElement | SVGPolygonElement, MouseEvent>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    dispatchProgressRegions({
      type: 'UPDATE_STATE',
      payload: {
        currentlyEditingRegionId: region.id,
        currentlyEditingCoodinateIndex: null,
        dragStartX: e.nativeEvent.offsetX,
        dragStartY: e.nativeEvent.offsetY,
      },
    });

    onUpdateTouched(region.id);
  }, [dispatchProgressRegions, region.id, onUpdateTouched]);

  const onMouseUpRegion = useCallback(() => {
    dispatchProgressRegions({
      type: 'UPDATE_STATE',
      payload: {
        currentlyCreatingRegionId: null,
        currentlyEditingRegionId: null,
        currentlyEditingCoodinateIndex: null,
        dragStartX: null,
        dragStartY: null,
      },
    });
  }, [dispatchProgressRegions]);

  const onContextMenuRegion = useCallback((e: React.MouseEvent<SVGLineElement | SVGPolygonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if (region.id < 0) {
      setRegions(regions => regions.filter(i => i.id !== region.id));
      setRegionEdits(regionEdits => {
        delete regionEdits[region.id];
        return {...regionEdits};
      });
    } else {
      onUpdateRegion({
        ...region,
        position: [],
      });
    }
  }, [region, onUpdateRegion, setRegionEdits, setRegions]);

  const commonProps = useMemo(() => {
    return {
      key: region.id,
      cursor: "move",
      onMouseDown: onMouseDownRegion,
      onMouseUp: onMouseUpRegion,
      onContextMenu: onContextMenuRegion,
    }
  }, [region.id, onContextMenuRegion, onMouseDownRegion, onMouseUpRegion]);

  if (region.position.length === 0) {
    return <></>
  }

  const points = generatePolygonPoints(region);

  return (
    <polygon
      {...commonProps}
      points={points}
      fill={region.color}
      opacity={0.7}
    />
  );
}