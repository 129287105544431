import { useCallback } from "react";
import { TransparentSVG } from "../../../../project-setup/project-floors/LidarPointPicker/MapPointPicker";
import { ProgressRegionsEditorMapViewSVGRegions } from "./ProgressRegionsEditorMapViewSVGRegions";
import { EditableProgressRegion, useProgressRegionsContext } from "../../../../../../contexts/progressRegionsContext";
import { useFetchViewpointsQuery } from "../../../../../../hooks/projectQueries";
import { useProgressContext } from "../../../../../../contexts/progressContext";
import { MapPointPickerPoint } from "../../../../project-setup/project-floors/LidarPointPicker/MapPointPickerPoint";
import { ViewpointsPoint } from "../../../../../../api/viewpoints";
import { getRandomHexColor } from "../../../../../../api/utils";

export const ProgressRegionsEditorMapViewSVG = () => {
  const {
    state: progressState
  } = useProgressContext();

  const {
    project,
    floor,
  } = progressState;

  const {
    state: progressRegionsState,
    dispatch: dispatchProgressRegions,
    regionsWithEdits,
    onUpdateRegion,
    setRegions,
  } = useProgressRegionsContext();

  const {
    currentlyCreatingRegionId,
    currentlyEditingRegionId,
    currentlyEditingCoodinateIndex,
    dragStartX,
    dragStartY,
    selectedPoint,
  } = progressRegionsState;

  const {data: points, isLoading: pointsLoading} = useFetchViewpointsQuery(
    project?.public_id ?? '',
    floor?.floor_code ?? '',
    {
      activeOnly: true,
    }
  );

  const onMoveRegionPoint = useCallback((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (currentlyEditingRegionId !== null && currentlyEditingCoodinateIndex !== null) {
      let movedRegion = regionsWithEdits.find(region => region.id === currentlyEditingRegionId) as EditableProgressRegion;

      if (!!movedRegion) {
        const regionToUpdate = {...movedRegion};

        regionToUpdate.position = regionToUpdate.position.map((point, index) => {
          if (index === currentlyEditingCoodinateIndex) {
            return {
              ...point,
              x: event.nativeEvent.offsetX,
              y: event.nativeEvent.offsetY,
            };
          } else {
            return point;
          }
        });

        onUpdateRegion(regionToUpdate);
      }
    }
  }, [currentlyEditingCoodinateIndex, currentlyEditingRegionId, onUpdateRegion, regionsWithEdits]);

  const onMoveRegion = useCallback((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    const dragEndX = event.nativeEvent.offsetX;
    const dragEndY = event.nativeEvent.offsetY;

    const diffX = dragEndX - dragStartX!;
    const diffY = dragEndY - dragStartY!;

    if (currentlyEditingRegionId) {
      const movedRegion = regionsWithEdits.find(region => region.id === currentlyEditingRegionId) as EditableProgressRegion;

      if (movedRegion) {
        const regionToUpdate = {
          ...movedRegion,
          position: movedRegion.position.map(point => {
            return {
              ...point,
              x: Math.round(point.x + diffX),
              y: Math.round(point.y + diffY),
            };
          }),
        }

        onUpdateRegion(regionToUpdate);

        dispatchProgressRegions({
          type: 'UPDATE_STATE',
          payload: {
            dragStartX: dragEndX,
            dragStartY: dragEndY,
          },
        });
      }
    }
  }, [currentlyEditingRegionId, dispatchProgressRegions, dragStartX, dragStartY, onUpdateRegion, regionsWithEdits]);

  const getRectangle = (startX: number, startY: number, endX: number, endY: number) => {
    const topLeft = {id: -1, x: Math.min(startX, endX), y: Math.min(startY, endY), order: 0};
    const topRight = {id: -2, x: Math.min(startX, endX), y: Math.max(startY, endY), order: 1};
    const bottomLeft = {id: -3, x: Math.max(startX, endX), y: Math.min(startY, endY), order: 2};
    const bottomRight = {id: -4, x: Math.max(startX, endX), y: Math.max(startY, endY), order: 3};

    return [topLeft, topRight, bottomRight, bottomLeft];
  }

  const onMoveCreatedRegion = useCallback((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    const dragEndX = event.nativeEvent.offsetX;
    const dragEndY = event.nativeEvent.offsetY;

    const movedRegion = regionsWithEdits.find(region => region.id === currentlyCreatingRegionId) as EditableProgressRegion;

    if (movedRegion) {
      const regionToUpdate = {
        ...movedRegion,
        position: getRectangle(dragStartX!, dragStartY!, dragEndX, dragEndY),
      }

      onUpdateRegion(regionToUpdate);
    }
  }, [currentlyCreatingRegionId, dragStartX, dragStartY, onUpdateRegion, regionsWithEdits])

  const onMouseMoveSvg = useCallback((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (currentlyCreatingRegionId !== null) {
      onMoveCreatedRegion(event);
    } else if (currentlyEditingRegionId !== null && currentlyEditingCoodinateIndex !== null) {
      onMoveRegionPoint(event);
    } else if (currentlyEditingRegionId !== null) {
      onMoveRegion(event);
    }
  }, [currentlyCreatingRegionId, currentlyEditingCoodinateIndex, currentlyEditingRegionId, onMoveCreatedRegion, onMoveRegion, onMoveRegionPoint]);

  const onDoubleClickSVG = useCallback((e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (!!selectedPoint && !!floor) {
      const clickX = e.nativeEvent.offsetX;
      const clickY = e.nativeEvent.offsetY;

      const coordinates = Array(4).fill(null).map((_, index) => {
        return {
          id: -index - 1,
          x: clickX,
          y: clickY,
          order: index,
        }
      });

      const newRegion: EditableProgressRegion = {
        id: -Date.now(),
        position: coordinates,
        viewpoint: selectedPoint.id,
        viewpoint_sub_id: selectedPoint.point_id,
        color: getRandomHexColor(),
      };

      setRegions(prevRegions => [...prevRegions, newRegion]);

      dispatchProgressRegions({
        type: 'UPDATE_STATE',
        payload: {
          currentlyCreatingRegionId: newRegion.id,
          currentlyEditingRegionId: newRegion.id,
          currentlyEditingCoodinateIndex: 0,
          dragStartX: clickX,
          dragStartY: clickY,
        },
      });
    }    
  }, [dispatchProgressRegions, floor, selectedPoint, setRegions]);

  const onClickMapPoint = useCallback((point: ViewpointsPoint) => {
    let newSelectedPoint: ViewpointsPoint | null = null;

    if (point.id !== selectedPoint?.id) {
      newSelectedPoint = point;
    }

    dispatchProgressRegions({
      type: 'UPDATE_STATE',
      payload: {
        selectedPoint: newSelectedPoint,
      },
    });
  }, [dispatchProgressRegions, selectedPoint]);

  return (
    <TransparentSVG
      width='100%'
      height='100%'
      onMouseMove={onMouseMoveSvg}
      onDoubleClick={onDoubleClickSVG}
      cursor={!!currentlyEditingRegionId ? 'grabbing' : 'default'}
    >
      {(points && !pointsLoading) && (
        <>
          {points.map(point => {
            return (
              <MapPointPickerPoint
                key={point.id}
                id={point.id.toString()}
                x={point.x}
                y={point.y}
                pointSize={18}
                selected={point.id === selectedPoint?.id}
                onMouseDownPoint={() => onClickMapPoint(point)}
              />
            )
          })}
        </>
      )}
      <ProgressRegionsEditorMapViewSVGRegions/>
    </TransparentSVG>
  );
}