import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { completeUpload, deleteObject, getForgePresignedPost, getManifest, getObjectDetails, getPresignedDownload, IManifest, IObjectDetails, startTranslationJob, updateProjectForgeModelUrn, uploadModel, UploadProgressEvent } from "../../../api/autodesk"
import { useNotifications } from "../../../contexts/notificationProvider";
import { useProjectContext } from "../../../contexts/projectContext";
import { FormContainer } from "../../common/FormControls";
import { Button } from "../../common/Inputs/Button";
import { LoadingIndicator } from "../../common/LoadingIndicator";
import { FileUpload } from "../project-setup/project-assets/FileUpload"

const progressBarWidth = 400;
const progressBarHeight = 20;
const progressBarBorderWidth = 1;

export const AutodeskUpload = () => {
  const {
    updateProject,
    state: projectState,
  } = useProjectContext();

  const {
    addNotification,
  } = useNotifications();

  const projectId = projectState.projectId;
  const forge_model_urn = projectState.project.forge_model_urn;

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [manifest, setManifest] = useState<IManifest | null>(null);
  const [manifestLoaded, setManifestLoaded] = useState<boolean>(false);
  const [objectDetails, setObjectDetails] = useState<IObjectDetails | null>(null);
  const [objectDetailsLoaded, setObjectDetailsLoaded] = useState<boolean>(false);
  const [downloadLink, setDownloadLink] = useState<string | null>(null);
  const [downloadLinkLoaded, setDownloadLinkLoaded] = useState<boolean>(false);
  const [translationInitiated, setTranslationInitiated] = useState<boolean>(false);

  const dataLoaded = manifestLoaded && objectDetailsLoaded && downloadLinkLoaded;
  const uploadInProgress = uploadProgress > 0 && uploadProgress < 1;
  const showFileUpload = !uploadInProgress;
  const showObjectDetails = !!objectDetails && !uploadInProgress;
  const showTranslationInProgress = manifest?.status === 'inprogress' || translationInitiated; 
  const showTranslateObject = manifest?.status === 'pending' && !translationInitiated;

  const manifestJson = useMemo(() => {
    if (manifest && manifest.status === 'success') {
      return encodeURIComponent(JSON.stringify(manifest));
    }

    return ''
  }, [manifest]);

  const checkManifestStatus = useCallback((getManifestInterval?: NodeJS.Timeout) => {
    return getManifest(forge_model_urn)
      .then(returnedManifest => {
        if (returnedManifest.status === 'success' && manifest && manifest.status !== 'success') {
          addNotification('Translation completed successfully', 'success');
        }

        setManifest(returnedManifest);
        setManifestLoaded(true);
        
        const statusCheckComplete = returnedManifest.status === 'success' || returnedManifest.status === 'failed';

        if (statusCheckComplete && getManifestInterval) {
          clearInterval(getManifestInterval);
        }

        return returnedManifest;
      })
      .catch(err => {
        setManifestLoaded(true);
        
        if (getManifestInterval) {
          clearInterval(getManifestInterval);
          throw err;
        }
      });
  }, [forge_model_urn, addNotification]);

  useEffect(() => {
    if (forge_model_urn) {
      checkManifestStatus().then(returnedManifest => {
        if (returnedManifest && returnedManifest.status === 'inprogress') {
          const getManifestInterval = setInterval(() => {
            checkManifestStatus(getManifestInterval);
          }, 15000);
        }
      });
    } else {
      setManifestLoaded(true);
    }
  }, [forge_model_urn, checkManifestStatus]);

  useEffect(() => {
    if (forge_model_urn) {
      getObjectDetails(forge_model_urn)
        .then(returnedObjectDetails => {
          setObjectDetails(returnedObjectDetails);
          setObjectDetailsLoaded(true);
        })
        .catch(err => {
          setObjectDetailsLoaded(true);
        });
    } else {
      setObjectDetailsLoaded(true);
    }
  }, [forge_model_urn]);

  useEffect(() => {
    if (forge_model_urn) {
      getPresignedDownload(forge_model_urn)
        .then(returnedDownload => {
          setDownloadLink(returnedDownload.url);
          setDownloadLinkLoaded(true);
        })
        .catch(err => {
          setDownloadLinkLoaded(true);
        });
    } else {
      setDownloadLinkLoaded(true);
    }
  }, [forge_model_urn])

  const onUploadProgress = (e: UploadProgressEvent) => {
    setUploadProgress(e.loaded / e.total);
  }

  const onSaveForgeModel = async (model: File) => {
    setUploadProgress(0.0001);
    setTranslationInitiated(false);

    const previousModelUrn = forge_model_urn;
    const fileName = model.name;
    const presignedPost = await getForgePresignedPost(fileName);
    const uploadUrl = presignedPost.urls[0];
    
    await uploadModel(uploadUrl, model, onUploadProgress)
    const uploadMetaData = await completeUpload(fileName, presignedPost.uploadKey)
    const urn = uploadMetaData.base64_encoded_urn;

    const updateProjectPromise = updateProjectForgeModelUrn(projectId, urn).then(updatedProject => {
      updateProject(updatedProject);
    });

    const startTranslationJobPromise = startTranslationJob(urn).then(() => setTranslationInitiated(true));

    const resolvedPromises = await Promise.all([updateProjectPromise, startTranslationJobPromise])
    
    if (previousModelUrn) {
      return await deleteObject(previousModelUrn);
    } else {
      return resolvedPromises;
    }
  }

  return (
    <FormContainer>
      {!dataLoaded &&
        <LoadingIndicator />
      }
      {dataLoaded &&
        <>
          {uploadInProgress  &&
            <div style={{textAlign: 'center'}}>
              File Upload Progress
              <ProgressBarContainer>
                <ProgressBar progress={uploadProgress}>
                  {uploadProgress >= 0.1 &&
                    <>{`${Math.round(uploadProgress * 100)}%`}</>
                  }
                </ProgressBar>
              </ProgressBarContainer>
            </div>
          }
          {!!showObjectDetails &&
            <div>
              <ObjectDetail
                label="Object ID:"
                value={objectDetails.objectId}
              />
              <ObjectDetail
                label="Object Key:"
                value={objectDetails.objectKey}
              />
              <ObjectDetail
                label="Bucket Key:"
                value={objectDetails.bucketKey}
              />
              <ObjectDetail
                label="Size:"
                value={objectDetails.size}
              />
              <ObjectDetail
                label="SHA1:"
                value={objectDetails.sha1}
              />
              {downloadLink &&
                <ObjectDetail
                  label="Download:"
                  value={
                    <a
                      href={downloadLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Model
                    </a>
                  }
                />
              }
            </div>
          }
          <div style={{marginTop: '40px'}}>
            {showTranslationInProgress &&
              <>
                <p style={{marginBottom: '10px'}}>Translation in progress: {manifest?.progress}</p>
                <LoadingIndicator />
              </>
            }
            {showTranslateObject &&
              <Button
                primary
                text='Translate Object'
                onClick={() => startTranslationJob(forge_model_urn)}
              />
            }
            {manifest?.status === 'failed' &&
              <>
                <div>Translation failed</div>
                <Button
                  primary
                  text='Click here to try again'
                  onClick={() => startTranslationJob(forge_model_urn)}
                />
              </>
            }
            {manifest?.status === 'success' &&
              <a
                href={`data:application/json;charset=utf-8;,${manifestJson}`}
                download="manifest.json"
                target="_blank"
                rel="noreferrer"
              >
                Translation Complete - Download Manifest
              </a>
            }
          </div>
          {showFileUpload &&
            <FileUpload
              hideLoadingIndicator
              onSaveFile={onSaveForgeModel}
              successNotificationText='File uploaded successfully. Translation initiated.'
            />
          }
        </>
      }
    </FormContainer>
  )
}

const ProgressBarContainer = styled.div`
  width: ${progressBarWidth}px;
  height: ${progressBarHeight}px;
  border: ${progressBarBorderWidth}px solid #073c7a;
  margin: 10px 0px;
`;

const ProgressBar = styled.div<{progress: number}>`
  width: ${props => {
    const width = Math.max(0, progressBarWidth * props.progress - 2 * progressBarBorderWidth);

    return Math.round(width);
  }}px;
  height: ${progressBarHeight - 2 * progressBarBorderWidth}px;
  background-color: #073c7a;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`

interface IObjectDetailProps {
  label: string;
  value: JSX.Element | string | number;
}

const ObjectDetail = ({
  label,
  value,
}: IObjectDetailProps) => {
  return (
    <div style={{marginBottom: '10px'}}>
      <div style={{fontWeight: 'bold'}}>{label}</div>
      <div>{value}</div>
    </div>
  )
}