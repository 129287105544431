import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useProjectContext } from "../../../../contexts/projectContext";
import { useGeneratedPaths } from "../../../../hooks/useGeneratedPaths";
import { IViewSelectorOption, OptionPicker } from "../../../common/ViewSelector/components/OptionPicker";
import { ViewSelector } from "../../../common/ViewSelector/ViewSelector";

export const ProjectFloorMenu = () => {
  const {
    state: projectState
  } = useProjectContext();

  const {
    projectId,
    floorId,
    lidarMapId,
  } = projectState;

  const location = useLocation();

  const {
    generateFloorPath,
    generateProjectFloorPlanPath,
    generateProjectFloorSectionsPath,
    generateLidarTransformationPath,
    generateProjectFloorLidarMapsPath,
    generateProjectFloorLidarMapUploadPath,
    generateLidarEditingPath,
    generateLidarReviewPath,
  } = useGeneratedPaths();

  const viewingItemsOptions = useMemo(() => [
    { Id: generateFloorPath(projectId, floorId), Content: 'Floor Details', Options: [] },
    { Id: generateProjectFloorPlanPath(projectId, floorId), Content: 'Floor Plan', Options: [] },
    { Id: generateProjectFloorSectionsPath(projectId, floorId), Content: 'Sections', Options: [] },
    { Id: generateProjectFloorLidarMapsPath(projectId, floorId), Content: 'Lidar Maps', Options: [] },
  ], [
      projectId,
      floorId,
      generateFloorPath,
      generateProjectFloorPlanPath,
      generateProjectFloorSectionsPath,
      generateProjectFloorLidarMapsPath,
  ]);

  useEffect(() => {
    viewingItemsOptions.forEach((option) => {
      if (location.pathname === option.Id) {
        setSelectedItem(option);
      } else if (lidarMapId) {
        const matchesLidarMapPath = location.pathname === generateProjectFloorLidarMapUploadPath(projectId, floorId, lidarMapId) || 
                                    location.pathname === generateLidarEditingPath(projectId, floorId, lidarMapId) ||
                                    location.pathname === generateLidarTransformationPath(projectId, floorId, lidarMapId) ||
                                    location.pathname === generateLidarReviewPath(projectId, floorId, lidarMapId);
        
        if (matchesLidarMapPath) {
          setSelectedItem(viewingItemsOptions[3]);
        }
      }
    })
  }, [
    location.pathname,
    viewingItemsOptions,
    lidarMapId,
    generateProjectFloorLidarMapUploadPath,
    generateLidarEditingPath,
    generateLidarTransformationPath,
    generateLidarReviewPath,
    projectId,
    floorId,
  ]);

  const [viewingExpanded, setViewingExpanded] = useState<boolean>(true);
  const [selectedItem, setSelectedItem] = useState<IViewSelectorOption | undefined>(viewingItemsOptions[0]);
  const history = useHistory();

  let onSelectChild = (selectedChild: IViewSelectorOption) => {
    history.push(selectedChild.Id);
  };

  const viewingItems: IViewSelectorOption = { 
    Id: 'root',
    Content: 'Asset',
    Options: viewingItemsOptions,
    OnSelectChild: onSelectChild,
  }

  const onClickSelectItem = (selectedOption: IViewSelectorOption) => {
    if (viewingItems.OnSelectChild) {
      viewingItems.OnSelectChild(selectedOption);
      setSelectedItem(selectedOption);
    }
  };

  const onHover = (hoverOption: IViewSelectorOption | null) => {
    if (viewingItems.OnHoverChild) {
      viewingItems.OnHoverChild(hoverOption)
    }
  }

  return (
    <ViewSelector
      initialViewSelectorExpanded
      title='Floor Assets'
      viewingExpanded={viewingExpanded}
      setViewingExpanded={setViewingExpanded}
      width={200}
    >
      <OptionPicker
        expanded={viewingExpanded}
        setExpanded={setViewingExpanded}
        viewingItems={viewingItems}
        selectedItem={selectedItem}
        hoverItem={null}
        onSelectOption={onClickSelectItem}
        onHover={onHover}
      />
    </ViewSelector>
  )
}