import { useLocation } from "react-router-dom";
import { useJobTypesQuery } from "../../../../hooks/progressTrackingQueries";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { JobTypesTable } from "./JobTypesTable";

export const ProgressJobsPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const projectParam = params.get('project');

  const {data: jobTypes, isLoading: jobTypesLoading, refetch: refetchJobTypes} = useJobTypesQuery();

  useEffect(() => {
    refetchJobTypes();
  }, [refetchJobTypes, projectParam]);

  return (
    <Box>
      {/* {!!projectParam &&
        <ProjectTrackersTable
          projectId={projectParam}
          trackerTypes={trackerTypes ?? []}
          trackerTypesLoading={trackerTypesLoading}
          refetchTrackerTypes={refetchTrackerTypes}
        />
      } */}
      {!projectParam &&
        <JobTypesTable
          jobTypes={jobTypes ?? []}
          jobTypesLoading={jobTypesLoading}
        />
      }
    </Box>
  );
}