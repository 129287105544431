import {Sidebar} from "../../../common/Sidebar/Sidebar";
import {ImageListCard} from "./ImageListCard";
import {ImageDeatilCard} from "./ImageDetailCard";
import { Link, Route, useLocation } from 'react-router-dom';
import React from 'react';

interface IImagesSidebarProps {
    setImageSelector: (open: boolean) => void;
    paginate: () => void;
}
export const ImagesSidebar = ({setImageSelector, paginate}: IImagesSidebarProps) => {
  let location = useLocation();

  return(
  <Sidebar minWidth={"300px"} maxWidth={"300px"}
           style={{ color: "#fff", background: "#444", padding: "10px", paddingTop: "35px" }}>
    <Link to={(location.state as any)?.prevPath || "/hoop/safety"} style={{color: "#ccc"}}>
      <h1> {'<'} Back</h1>
    </Link>
    <ImageListCard setImageSelector={setImageSelector} paginate={paginate}/>
    <ImageDeatilCard/>
  </Sidebar>)
}
