import { useMemo, useState } from 'react';
import { useFetchSitewalksQuery, useProjectsQuery } from "../../../hooks/projectQueries"
import { Table } from "../../common/Table/Table";
import styled from 'styled-components';
import { Sitewalk } from '../../../api/sitewalks';
import { Button } from '../../common/Inputs/Button';
import { useSitewalkNavigation } from '../../../hooks/useNavigation';
import { LoadingIndicator } from '../../common/LoadingIndicator';
import { Select } from '../../common/Inputs/Select';
import { DashboardCheckbox } from '../project-setup/DashboardFormControls';
import { Project } from '../../../api/projects';

const allProjectsKey = 'All Projects';

export const SitewalkTable = () => {
  const {
    navigateToSitewalkTransformation
  } = useSitewalkNavigation();

  const [selectedProjectId, setSelectedProjectId] = useState<string>(allProjectsKey);
  const [tableCheckboxChecked, setTableCheckboxChecked] = useState<boolean>(true);

  const { data: projects, isLoading: projectsLoading } = useProjectsQuery();
  const {data: walks, isLoading: walksLoading} = useFetchSitewalksQuery('video', tableCheckboxChecked ? 'Awaiting Transformation' : undefined);

  const dataLoaded = !!walks && !walksLoading;

  const projectDropdownOptions = useMemo(() => {
    if (!projects || projectsLoading) {
      return [];
    }

    return [{name: allProjectsKey, public_id: allProjectsKey}, ...projects];
  }, [projectsLoading, projects]);

  const columns = useMemo(() => {
    return [
      { Header: 'Id', accessor: 'id' },
      { Header: 'Project', accessor: 'project.name' },
      { Header: 'Floor', accessor: 'project_floor.floor_code' },
      { Header: 'Group', accessor: 'group_name' },
      { Header: 'Parts', accessor: (walk: Sitewalk) => walk.video?.number_of_parts ?? 1},
      { Header: 'User',  accessor: (walk: Sitewalk) => `${walk.user.first_name} ${walk.user.last_name}` },
      { Header: 'Process Transformation', accessor: (walk: Sitewalk) => {
        return (
          <Button
            style={{
              padding: '0.75em'
            }}
            text="Process Transformation"
            onClick={() => navigateToSitewalkTransformation(walk.id)}
          />
        )
      }},
    ]
  }, [navigateToSitewalkTransformation]);

  const data = useMemo(() => {
    if (walks && !walksLoading) {
      return walks.filter(walk => selectedProjectId === allProjectsKey || walk.project.public_id === selectedProjectId);
    }

    return [];
  }, [walks, walksLoading, selectedProjectId]);

  return (
    <SiteWalkTableContainer>
      <TitleContainer>
        <EmptyContainer/>
        <Title>Sitewalks</Title>
        <SiteWalkControlsContainer>
          <Select
            disabled={projectsLoading || !projects}
            options={projectDropdownOptions}
            value={selectedProjectId}
            onSelect={e => setSelectedProjectId(e.target.value)}
            useInitialEmptyOption
            getOptionLabel={(option: Project) => option.name}
            getOptionValue={(option: Project) => option.public_id}
            selectContainerStyles={{
              width: '250px',
            }}
            selectStyles={{
              margin: 0,
            }}
          />
          <DashboardCheckbox
            checked={tableCheckboxChecked}
            onChangeChecked={() => setTableCheckboxChecked(prevValue => !prevValue)}
            label="Walks Awaiting Transformation"
            containerStyle={{width: 'auto'}}
          />
        </SiteWalkControlsContainer>
      </TitleContainer>

      {!dataLoaded &&
        <LoadingIndicator />
      }
      {dataLoaded &&
        <Table
          columns={columns}
          data={data}
          initialSortBy={[{id: 'id', desc: true}]}
          noRowsMessage='No site walks ready for transformation'
        />
      }
    </SiteWalkTableContainer>
  )
}

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 25px;
`;

const SiteWalkControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  gap: 16px;
`;

const EmptyContainer = styled.div`
  flex: 1;
`;

const SiteWalkTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 1em;
`;

const Title = styled.h1`
  font-size: 32px;
`;