import { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from "react-router-dom"
import { useProjectContext } from "../../../contexts/projectContext";
import { PATH_STRINGS } from "../../../hooks/useGeneratedPaths"
import { LidarManagementDashboard } from './LidarManagementDashboard';
import { MapEditingDashboard } from './MapEditingDashboard';
import { MapsNeedingReviewDashboard } from './MapsNeedingReviewDashboard';
import { MapsNeedingTransformationDashboard } from "./MapsNeedingTransformationDashboard"

interface LidarRouteParams {
  projectId: string,
  floorId: string,
  lidarMapSubId: string
}

export const LidarManagement = () => {
  const { updateState } = useProjectContext();

  const lidarTransformMatch = useRouteMatch<LidarRouteParams>(PATH_STRINGS.lidarTransformation);

  const projectId: string = lidarTransformMatch?.params.projectId || '';
  const floorId: string = lidarTransformMatch?.params.floorId || '';
  const lidarMapId: string = lidarTransformMatch?.params.lidarMapSubId || '';

  useEffect(() => {
    updateState({
      projectId: projectId,
      floorId: floorId,
      lidarMapId: lidarMapId,
    });
  }, [updateState, projectId, floorId, lidarMapId]);

  return (
    <Switch>
      <Route exact path={PATH_STRINGS.lidarManagement}>
        <LidarManagementDashboard />
      </Route>
      <Route exact path={PATH_STRINGS.mapsNeedingTransformation}>
        <MapsNeedingTransformationDashboard/>
      </Route>
      <Route exact path={PATH_STRINGS.mapsNeedingReview}>
        <MapsNeedingReviewDashboard/>
      </Route>
      <Route exact path={PATH_STRINGS.mapEditingDashboard}>
        <MapEditingDashboard/>
      </Route>
    </Switch>
  )
}