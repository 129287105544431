import { useMemo } from "react"
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import styled from "styled-components";
import { Button } from "../../../common/Inputs/Button";
import { Table } from "../../../common/Table/Table";
import { useProjectContext } from "../../../../contexts/projectContext";
import { useProjectFloorSectionsQuery } from "../../../../hooks/projectQueries";
import { ProjectFloorSection } from "../../../../api/projectFloorSections";
import { useProjectNavigation } from "../../../../hooks/useNavigation";
import { FormContainer, FormHeader } from "../../../common/FormControls";

export const ProjectFloorSectionsDashboard = () => {
  const {
    updateFloor,
    state: projectState
  } = useProjectContext();

  const {
    navigateToSection
  } = useProjectNavigation();

  const sections = projectState.floor.sections;
  const { isLoading: sectionsLoading } = useProjectFloorSectionsQuery(updateFloor);

  const columns = useMemo(() => [
    { Header: 'Name', accessor: 'name' },
  ], []);

  const data = useMemo(() => {
    return sections ?? [];
  }, [sections]);

  if (!sections || sectionsLoading) {
    return <LoadingIndicator/>
  }

  const onClickAddNewSection = () => {
    navigateToSection('new');
  }

  const onClickRow = (originalRow: ProjectFloorSection) => {
    navigateToSection(originalRow.sub_id.toString());
  }

  return (
    <>
      <FormContainer>
        <FormHeader>
          {`${projectState.project.name} - ${projectState.floor.name}`}
        </FormHeader>
      </FormContainer>

      <ProjectFloorSectionsDashboardContainer>      
        <AddNewSectionButtonContainer>
          <Button
            primary
            text='Add New Section'
            onClick={onClickAddNewSection}
          />
        </AddNewSectionButtonContainer>

        <Table
          columns={columns}
          data={data}
          onClickRow={onClickRow}
        />
      </ProjectFloorSectionsDashboardContainer>
    </>
  )
}

const ProjectFloorSectionsDashboardContainer = styled.div`
  margin-top: 1em;
`;

const AddNewSectionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 20px 20px 0;
`;