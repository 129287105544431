import styled from 'styled-components';

export const SelectItem = (props: {
  content: string | JSX.Element;
  disclaimer?: string;
  onClick: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  key: React.Key;
  selected?: boolean;
  style?: React.CSSProperties;
}) => {
  return (
    <SelectContainer 
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      selected={props.selected}
      style={props.style}
    >
      <li><span>{props.content}</span></li>
      <Disclaimer>{props.disclaimer}</Disclaimer>
    </SelectContainer>
  )
}

interface ISelectContainerProps {
  selected?: boolean;
}

const SelectContainer = styled.div<ISelectContainerProps>`
  padding: 8px;
  margin: 8px 0px;
  color: #686868;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & li {
    list-style: inside;
  }
  & li::marker {
    color: ${props => props.selected ? '#073c7a' : 'white'};
    transition: color 0.5s;
    font-size: 24px;
  }
  & span {
    transition: padding-left 0.5s;
    margin-left: -25px;
    padding-left: ${props => props.selected ? '15px' : '0px'}
  }
  &:hover {
    & li::marker {
      color: #073C7A;
    }
    & span {
      padding-left: 15px;
    }
  }
`

const Disclaimer = styled.span`
  color: #FB7185;
  font-size: 12px;
`