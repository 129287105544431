import { IconButton } from '../../IconButton';
import iconUpGray from '../../../../assets/images/icons/icon_up_gray_small.svg';
import iconUpWhite from '../../../../assets/images/icons/icon_up_white_small.svg';
import iconDownGray from '../../../../assets/images/icons/icon_down_gray_small.svg';
import iconDownWhite from '../../../../assets/images/icons/icon_down_white_small.svg';

export const IconButtonExpand = (props: {
  expanded: boolean;
  onClick: (newValue: boolean) => void;
  style?: React.CSSProperties;
  size?: number;
}) => {
  const hoverColor: string = '#345eee';

  return (
    <IconButton
      icon={props.expanded ? iconUpGray : iconDownGray}
      hoverIcon={props.expanded ? iconUpWhite : iconDownWhite}
      size={props.size ?? 20}
      onClick={() => props.onClick(!props.expanded)}
      style={{
        position: 'static',
        display: 'inline-block',
        boxShadow: 'none',
        border: `1px solid #E8E8E8`,
        ...props.style,
      }}
      hoverStyle={{ backgroundColor: hoverColor, border: `1px solid ${hoverColor}` }}
    />
  );
};