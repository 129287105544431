import styled from 'styled-components';

export const CardBase = styled.div`
  width: 350px;
  height: 280px;
  cursor: pointer; // remove with BetaLink
  margin: 18px;

  background: #ffffff;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
`;

export const CardImage = styled.img`
  width: 350px;
  height: 172px;
`;

export const CardTitle = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const CardTitleInner = styled.div`
  font-size: 20px;
  line-height: 28px;
  color: #05051d;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const CardDesc = styled.div`
  margin-top: 18px;
  margin-left: 20px;
  margin-right: 20px;

  display: flex;
  justify-content: space-between;

  color: #7e858e;
`;

export const CardDescItem = styled.div`
  font-size: 14px;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;
