import { useMemo } from "react";
import styled from "styled-components";
import iconChecked from '../../assets/images/icons/icon_checked.svg';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 40px 0 40px;
`;

export const StyledLabel = styled.label<{ width?: number }>`
  display: block;
  margin-bottom: 5px;
  width: ${props => props.width ? props.width : 400}px;
`;

export const StyledInput = styled.input<{ width?: number }>`
  padding: 1em;
  border: 1px solid #073c7a;
  margin-bottom: 20px;
  width: ${props => props.width ? props.width : 400}px;
`;

export const StyledTextArea = styled.textarea<{ width?: number }>`
  padding: 1em;
  border: 1px solid #073c7a;
  margin-bottom: 20px;
  width: ${props => props.width ? props.width : 400}px;
`;

export const FormHeader = styled.h1`
  margin-bottom: 25px;
  font-size: 24px;
`;

export const SaveButtonContainer = styled.div`
  margin: 40px 0 25px 0;
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
`;

interface ICheckmarkProps {
  checked?: boolean;
  backgroundColor?: string;
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
}

export const Checkmark = styled.div<ICheckmarkProps>`
  cursor: pointer;
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: 1px solid ${props => props.checked ? props.backgroundColor ?? '#2196F3' : 'black'};
  background-color: ${props => props.checked ? props.backgroundColor ?? '#2196F3' : 'white'};
  ${props => props.checked && `
    &::after {
      content: url(${iconChecked});
      position: relative;
      top: ${props.top}px;
      left: ${props.left}px;
      bottom: ${props.bottom}px;
      right: ${props.right}px;
    }
  `}
`;

export interface ICheckboxProps {
  disabled?: boolean;
  checked: boolean;
  onChangeChecked: (newValue: boolean) => void;
  label?: string;
  checkmarkTop?: number;
  checkmarkRight?: number;
  checkmarkBottom?: number;
  checkmarkLeft?: number;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  labelStyle?: React.CSSProperties;
  checkmarkStyle?: React.CSSProperties;
}

export const Checkbox = ({
  disabled,
  checked,
  onChangeChecked,
  label,
  checkmarkTop = 0,
  checkmarkRight = 0,
  checkmarkBottom = 0,
  checkmarkLeft = 4,
  labelStyle,
  checkmarkStyle,
}: ICheckboxProps) => {
  const checkmarkStyleOverride = useMemo(() => {
    const style = {...checkmarkStyle};

    if (disabled) {
      style.cursor = "auto";
      style.backgroundColor = "lightgray";
      style.borderColor = "lightgray";
    }

    return style;
  }, [checkmarkStyle, disabled]);

  return (
    <CheckboxLabel
      style={labelStyle}
    >
      <CheckboxInput
        disabled={disabled}
        checked={checked}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeChecked(e.target.checked)}
      />

      <Checkmark
        checked={checked}
        top={checkmarkTop}
        right={checkmarkRight}
        bottom={checkmarkBottom}
        left={checkmarkLeft}
        style={checkmarkStyleOverride}
      />

      {label}
    </CheckboxLabel>
  )
}

interface IFormCheckboxProps {
  disabled?: boolean;
  label?: string;
  labelStyle?: React.CSSProperties;
  checked: boolean;
  onChangeChecked: (checked: boolean) => void;
  checkedText?: string;
  unCheckedText?: string;
  checkmarkStyle?: React.CSSProperties;
  checkboxContainerStyle?: React.CSSProperties;
}

export const FormCheckbox = ({
  disabled,
  label,
  labelStyle,
  checked,
  onChangeChecked,
  checkedText = 'Yes',
  unCheckedText = 'No',
  checkmarkStyle,
  checkboxContainerStyle,
}: IFormCheckboxProps) => {
  return (
    <div>
      {!!label &&
        <StyledLabel
          style={labelStyle}
        >
          {label}
        </StyledLabel>
      }
      <FormCheckboxContainer
        style={checkboxContainerStyle}
      >
        <Checkbox
          disabled={disabled}
          checked={checked}
          onChangeChecked={onChangeChecked}
          checkmarkStyle={checkmarkStyle}
        />
        <span
          style={{
            cursor: disabled ? 'auto' : 'pointer'
          }}
          onClick={() => {
            if (!disabled) {
              onChangeChecked(!checked);
            }
          }}
        >
          {checked ? checkedText : unCheckedText}
        </span>
      </FormCheckboxContainer>
    </div>
  )
}

const FormCheckboxContainer = styled.div`
  width: 400px;
  display: flex;
  margin-left: -8px;
  margin-bottom: 20px;
  align-items: center;
  gap: 10px;
`;