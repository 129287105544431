import { useReducer, createContext, useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { useProgressContext } from './progressContext';
import { useProjectFloorProgressRegionsQuery } from '../hooks/progressTrackingQueries';
import { ViewpointsPoint } from '../api/viewpoints';
import { getRandomHexColor } from '../api/utils';
import { ProgressRegion } from '../api/progressRegions';

export interface EditableProgressRegion extends ProgressRegion {
  color: string;
}

interface ProgressRegionsState {
  currentlyCreatingRegionId: number | null;
  currentlyEditingRegionId: number | null;
  currentlyEditingCoodinateIndex: number | null;
  dragStartX: number | null;
  dragStartY: number | null;
  selectedPoint: ViewpointsPoint | null;
}

const initialState: ProgressRegionsState = {
  currentlyCreatingRegionId: null,
  currentlyEditingRegionId: null,
  currentlyEditingCoodinateIndex: null,
  dragStartX: null,
  dragStartY: null,
  selectedPoint: null,
};

interface IProgressRegionsContext {
  state: ProgressRegionsState,
  dispatch: React.Dispatch<ProgressAction>;
  setRegions: React.Dispatch<React.SetStateAction<EditableProgressRegion[]>>;
  regionsLoading: boolean;
  regionEdits: Record<number, EditableProgressRegion>;
  setRegionEdits: React.Dispatch<React.SetStateAction<Record<number, EditableProgressRegion>>>;
  touched: Record<number, number>;
  regionsWithEdits: EditableProgressRegion[];
  onUpdateRegion: (updatedRegion: EditableProgressRegion) => void;
  onUpdateTouched: (touchedRegionId: number) => void;
}

const initialProgressRegionsContext: IProgressRegionsContext = {
  state: {...initialState},
  dispatch: () => {},
  setRegions: () => {},
  regionsLoading: false,
  regionEdits: {},
  setRegionEdits: () => {},
  touched: {},
  regionsWithEdits: [],
  onUpdateRegion: () => {},
  onUpdateTouched: () => {},
}

const UPDATE_STATE = 'UPDATE_STATE';

type ProgressAction = {
  type: typeof UPDATE_STATE,
  payload: Partial<ProgressRegionsState>,
}

const ProgressRegionsContext = createContext<IProgressRegionsContext>({...initialProgressRegionsContext});

const progressReducer = (state: ProgressRegionsState, action: ProgressAction) => {
  switch (action.type) {
    case UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      }
  }
}

export const ProgressRegionsProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(progressReducer, {...initialState});

  const {
    state: progressState
  } = useProgressContext();

  const {
    projectId,
    floorId,
  } = progressState;

  const [regions, setRegions] = useState<EditableProgressRegion[]>([]);
  const [regionEdits, setRegionEdits] = useState<Record<number, EditableProgressRegion>>({});
  const [touched, setTouched] = useState<Record<number, number>>({});

  const regionsOnSuccess = (data: ProgressRegion[]) => {
    const regionsWithColors = data.map(region => ({
      ...region,
      color: getRandomHexColor(),
    }));

    setRegions(regionsWithColors);
  }

  const {isLoading: regionsLoading} = useProjectFloorProgressRegionsQuery(projectId, floorId ?? '', regionsOnSuccess);

  useEffect(() => {
    dispatch({
      type: UPDATE_STATE,
      payload: {
        currentlyCreatingRegionId: null,
        currentlyEditingRegionId: null,
        currentlyEditingCoodinateIndex: null,
        dragStartX: null,
        dragStartY: null,
        selectedPoint: null,
      }
    })
  }, [projectId, floorId]);

  const onUpdateRegion = useCallback((updatedRegion: EditableProgressRegion) => {
    setRegionEdits(prevRegionEdits => {
      return {
        ...prevRegionEdits,
        [updatedRegion.id]: updatedRegion,
      };
    });
  }, []);

  const onUpdateTouched = useCallback((regionId: number) => {
    setTouched(prevTouched => {
      return {
        ...prevTouched,
        [regionId]: Date.now(),
      }
    })
  }, []);

  const regionsWithEdits = useMemo(() => {
    return regions.map(region => {
      if (regionEdits[region.id]) {
        return regionEdits[region.id];
      } else {
        return region;
      }
    });
  }, [regionEdits, regions]);

  return (
    <ProgressRegionsContext.Provider
      value={{
        state,
        dispatch,
        setRegions,
        regionsLoading,
        regionEdits,
        setRegionEdits,
        regionsWithEdits,
        touched,
        onUpdateRegion,
        onUpdateTouched,
      }}
    >
      {children}
    </ProgressRegionsContext.Provider>
  )
}

export const useProgressRegionsContext = () => {
  return useContext(ProgressRegionsContext);
};