import { Route, Switch, useRouteMatch } from "react-router-dom"
import { PATH_STRINGS } from "../../../hooks/useGeneratedPaths"
import { SitewalkTable } from "./SitewalkTable"
import { ProcessSiteWalkTransformation } from "./ProcessSitewalkTransformation"
import { useFetchSitewalkQuery, useProjectFloorQuery } from "../../../hooks/projectQueries"
import { LoadingIndicator } from "../../common/LoadingIndicator"

export const Sitewalks = () => {
  const sitewalkIdMatch = useRouteMatch<{sitewalkId: string}>(PATH_STRINGS.sitewalkTranslation);
  const sitewalkId = sitewalkIdMatch?.params.sitewalkId;

  const {data: walk, isLoading: sitewalkLoading} = useFetchSitewalkQuery(sitewalkId);
  const {data: floor, isLoading: floorLoading} = useProjectFloorQuery(walk?.project.public_id, walk?.project_floor.floor_code);

  const dataLoaded = !!sitewalkId && !!walk && !sitewalkLoading && !!floor && !floorLoading;

  return (
    <Switch>
      <Route
        path={PATH_STRINGS.sitewalk}
        exact
      >
        <SitewalkTable/>
      </Route>
      <Route
        path={PATH_STRINGS.sitewalkTranslation}
        exact
      >
        {!dataLoaded &&
          <LoadingIndicator/>
        }
        {dataLoaded && 
          <ProcessSiteWalkTransformation
            floor={floor}
            sitewalk={walk}
          />
        }
      </Route>
    </Switch>
  )
}