import { MapLine } from "./MapPointPicker";

interface MapPointPickerLineLegendProps {
  lines: MapLine[];
  onClickLabel?: (line: MapLine) => void;
}

export const MapPointPickerLineLegend = ({
  lines,
  onClickLabel,
}: MapPointPickerLineLegendProps) => {
  return (
    <div>
      {lines.map(line => {
        return (
          <div
            key={line.name}
            style={{
              color: line.color,
              padding: '5px 3px',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (onClickLabel) {
                onClickLabel(line)
              }
            }}
          >
            {line.name}
          </div>
        )
      })}
    </div>
  );
}