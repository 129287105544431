import { useCallback, useMemo } from "react";
import { EditableItem, useProgressItemsContext } from "../../../../../../contexts/progressItemsContext";

interface IProgressItemsEditorMapViewSVGGhostPointsProps {
  item: EditableItem;
}

export const ProgressItemsEditorMapViewSVGGhostPoints = ({
  item,
}: IProgressItemsEditorMapViewSVGGhostPointsProps) => {
  const {
    state: progressItemsState,
    dispatch: dispatchProgressItems,
    onUpdateItem,
    onUpdateTouched,
  } = useProgressItemsContext();

  const {
    renderItemsAsPath,
  } = progressItemsState;

  const onMouseDownGhostPoint = useCallback((e: React.MouseEvent<SVGGElement, MouseEvent>, index: number, x: number, y: number) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    const itemToUpdate = {...item};
    const newCoordinates = [...item.position];
    newCoordinates.splice(index, 0, { x: x.toString(), y: y.toString(), order: index - 1 });
    newCoordinates.forEach((point, i) => point.order = i-1);
    itemToUpdate.position = newCoordinates;

    onUpdateItem(itemToUpdate);
    onUpdateTouched(itemToUpdate.id)

    dispatchProgressItems({
      type: 'UPDATE_STATE',
      payload: {
        currentlyEditingItemId: item.id,
        currentlyEditingCoodinateIndex: index,
      },
    });
  }, [dispatchProgressItems, item, onUpdateItem, onUpdateTouched]);

  const expandedCoordinates = useMemo(() => {
    let coordinates = item.position.map(point => [point.x, point.y]);

    if (!renderItemsAsPath && item.position.length > 0) {
      coordinates.push([item.position[0].x, item.position[0].y]);
    }
    return coordinates;
  }, [item.position, renderItemsAsPath]);

  if (expandedCoordinates.length === 0) {
    return <></>
  }

  return (
    <>
      {expandedCoordinates.map((point, index, arr) => {
        if (index === 0) {
          return <></>
        }

        const currentPoint = arr[index];
        const previousPoint = arr[index - 1];

        const x = (parseFloat(currentPoint[0]) + parseFloat(previousPoint[0])) / 2;
        const y = (parseFloat(currentPoint[1]) + parseFloat(previousPoint[1])) / 2;

        return (
          <g
            key={`${item.id}-${index}-ghost-point-g`}
            onMouseDown={e => onMouseDownGhostPoint(e, index, x, y)}
            cursor="cell"
          >
            <circle
              cx={x}
              cy={y}
              r={renderItemsAsPath ? 2 : 5}
              fill="black"
              stroke="black"
            />
            <text
              x={x}
              y={y}
              textAnchor="middle"
              stroke="white"
              strokeWidth={0.25}
              fontSize="6px"
              fill="white"
              dy="1.5px"
            >
              +
            </text>
          </g>
        );
      })};
    </>
  );
}