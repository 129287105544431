interface MapPointPickerPointProps {
  x: string | number;
  y: string | number;
  id: string;
  pointSize: number;
  stroke?: string;
  fill?: string;
  selected?: boolean;
  label?: string | number;
  fontSize?: number;
  onDoubleClickPoint?: (e: React.MouseEvent<SVGGElement>) => void;
  onMouseDownPoint?: (e: React.MouseEvent<SVGGElement>, id: string) => void;
  onMouseUpPoint?: (e: React.MouseEvent<SVGGElement>, id: string) => void;
  onMouseMovePoint?: (e: React.MouseEvent<SVGGElement>) => void;
}

export const MapPointPickerPoint = ({
  x,
  y,
  id,
  pointSize,
  stroke = '#dbdbdb',
  fill = '#073c7a',
  selected,
  label,
  fontSize = pointSize,
  onDoubleClickPoint,
  onMouseDownPoint,
  onMouseUpPoint,
  onMouseMovePoint,
}: MapPointPickerPointProps) => {
  const respondsToEvents = !!onDoubleClickPoint || !!onMouseDownPoint || !!onMouseUpPoint || !!onMouseMovePoint;

  return (
    <g
      onDoubleClick={onDoubleClickPoint}
      onMouseDown={e => {
        if (onMouseDownPoint) {
          onMouseDownPoint(e, id);
        }
      }}
      onMouseUp={e => {
        if (onMouseUpPoint) {
          onMouseUpPoint(e, id);
        }
      }}
      onMouseMove={onMouseMovePoint}
      cursor={respondsToEvents ? 'pointer' : 'auto'}
    >
      <circle
        cx={x}
        cy={y}
        r={pointSize}
        stroke={stroke}
        strokeWidth={pointSize / 3}
        fill={fill}
      />
      {selected &&
        <circle
          cx={x}
          cy={y}
          r={pointSize + pointSize / 3}
          fill="transparent"
          stroke="rgba(7,60,122,0.1)"
          strokeWidth={pointSize * 2}
        />
      }
      {label !== undefined &&
        <text
          x={x}
          y={y}
          textAnchor="middle"
          stroke="white" 
          strokeWidth={0.25}
          fontSize={`${fontSize}px`}
          fill="white"
          dy=".3em"
        >
          {label}
        </text>
      }
    </g>
  );
}