import React, { useState } from 'react';
import { Flex } from '@react-css/flex';
import { useUserContext } from '../../../contexts/userContext';
import { LoadingIndicator } from '../../common/LoadingIndicator';
import { Content, LoginButton, LoginCard, LoginCardBody, LoginError, LoginLabel } from './styles';
import { login, permissions } from '../../../api/auth';
import axios from 'axios';

export const LoginPage = () => {
  const userContext = useUserContext();
  const [localLoad, setLocalLoad] = useState<boolean>(false);
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const _login = (e: any) => {
    e.preventDefault();
    delete axios.defaults.headers.common['Authorization'];
    setLocalLoad(true);
    if (email && password) {
      login(email, password)
        .then(user => {
          localStorage.setItem('db_tkn', user.token);
          axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;

          permissions(user.public_id).then(data => {
            userContext.updateUser({
              ...user,
              token: user.token,
              isAuthenticated: true,
              permissions: data.data,
              loading: false,
            });
          });
        })
        .catch(e => {
          setError(e.message);
          setLocalLoad(false);
        });
    }
  };

  if (localLoad) return <LoadingIndicator />;

  return (
    <>
      <Content>
        <Flex justifyContent="center">
          <LoginCard>
            <LoginCardBody>
              <form onSubmit={_login}>
                <Flex flexDirection="column">
                  {error && <LoginError id="error">{error}</LoginError>}
                  <LoginLabel htmlFor="email">Email</LoginLabel>
                  <input
                    name="email"
                    type="email"
                    className="login-input"
                    onChange={e => setEmail(e.target.value)}
                  />
                  <LoginLabel htmlFor="password">Password</LoginLabel>
                  <input
                    name="password"
                    type="password"
                    className="login-input"
                    onChange={e => setPassword(e.target.value)}
                  />
                  <LoginButton type="submit" value="Login" />
                </Flex>
              </form>
            </LoginCardBody>
          </LoginCard>
        </Flex>
      </Content>
    </>
  );
};
