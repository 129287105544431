import * as THREE from 'three';
import { FlyControls } from 'three/examples/jsm/controls/FlyControls';
import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader';
import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { BlurableMainWindow, Modal } from '../../common/Modal/Modal';
import { MapPointPickerContainer, TranslationPoint, TranslationPointType } from '../project-setup/project-floors/LidarPointPicker/ProjectFloorLidarPointPicker';
import { PanZoomProvider } from '../../common/PanZoom/PanZoomContext';
import { MapPointPicker, PointControlsContainer } from '../project-setup/project-floors/LidarPointPicker/MapPointPicker';
import { ProjectFloor } from '../../../api/projectFloors';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { Button } from '../../common/Inputs/Button';
import { CoordinatesContainer } from '../project-setup/project-floors/LidarPointPicker/MapPointPicker';
import { PanZoomContainer } from '../project-setup/project-floors/LidarPointPicker/MapPointPicker';
import { Select } from '../../common/Inputs/Select';
import { SiteWalkPointCloud, SiteWalkTrajectory, Sitewalk, confirmSiteWalkTransformation, fetchSiteWalkPointClouds, fetchSiteWalkTrajectories, mapSiteWalkPoints, processSiteWalkTransformation, updatePointCloud } from '../../../api/sitewalks';
import { LoadingIndicator } from '../../common/LoadingIndicator';
import { SitewalkTransformationConfirmation } from './SitewalkTransformationConfirmation';
import { useNotifications } from '../../../contexts/notificationProvider';
import { ClearAllPointsModal } from './ClearAllPointsModal';

const lineColors = ['hotpink', 'red', 'orange', 'gold', 'green', 'blue', 'indigo', 'violet', 'aqua', 'chartreuse']

type Dimension = 'x' | 'y' | 'z';

export interface PCDPoint extends TranslationPoint {
  type: TranslationPointType.siteWalk;
  z: number;
}

const defaultMovementSpeed = 2;
const defaultRollSpeed = Math.PI / 8;

const initialPosition = new THREE.Vector3(0, 0, 0);
const initialRotation = new THREE.Euler(-Math.PI / 2, 0, 0);
const camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.001, 100);
camera.position.set(initialPosition.x, initialPosition.y, initialPosition.z);
camera.rotation.set(initialRotation.x, initialRotation.y, initialRotation.z);

const scene = new THREE.Scene();
scene.background = new THREE.Color(0xffffff);

const renderer = new THREE.WebGLRenderer({antialias: true});
renderer.setPixelRatio(window.devicePixelRatio);

const flyControls = new FlyControls(camera, renderer.domElement);
flyControls.movementSpeed = defaultMovementSpeed;
flyControls.domElement = renderer.domElement;
flyControls.rollSpeed = defaultRollSpeed;
flyControls.autoForward = false;
flyControls.dragToLook = true;

const clock = new THREE.Clock();

const loader = new PCDLoader();

const render = () => {
  flyControls.update(clock.getDelta());
  renderer.render(scene, camera);
}

const animate = () => {
  requestAnimationFrame(animate);
  render();
}

const performWidthAndHeightUpdates = (width: number, height: number) => {
  camera.aspect = width / height;
  camera.updateProjectionMatrix();

  renderer.setSize(width, height);
}

interface IProcessSiteWalkTransformationProps {
  floor: ProjectFloor;
  sitewalk: Sitewalk;
}

export const ProcessSiteWalkTransformation = ({
  floor,
  sitewalk,
}: IProcessSiteWalkTransformationProps) => {
  const sitewalkId = sitewalk.id;

  const {addNotification} = useNotifications();

  const [pointClouds, setPointClouds] = useState<SiteWalkPointCloud[]>([]);
  const [pointCloudsLoaded, setPointCloudsLoaded] = useState<boolean>(false);
  const [trajectories, setTrajectories] = useState<SiteWalkTrajectory[]>([]);
  const [trajectoriesLoaded, setTrajectoriesLoaded] = useState<boolean>(false);
  const [floorPoints, setFloorPoints] = useState<TranslationPoint[]>([]);
  const [siteWalkPoints, setSiteWalkPoints] = useState<PCDPoint[]>([]);
  const [selectedSiteWalkPointIndices, setSelectedSiteWalkPointIndices] = useState<Set<number>>(new Set());
  const [geoms, setGeoms] = useState<THREE.SphereGeometry[]>([]);
  const [materials, setMaterials] = useState<THREE.MeshBasicMaterial[]>([]);
  const [meshes, setMeshes] = useState<THREE.Mesh[]>([]);
  const [currentlyEditingPoint, setCurrentlyEditingPoint] = useState<string | null>(null);
  const [selectedPointCloud, setSelectedPointCloud] = useState<SiteWalkPointCloud | undefined>();
  const [selectedPointCloudLoading, setSelectedPointCloudLoading] = useState<boolean>(true);
  const [processingTransformation, setProcessingTransformation] = useState<boolean>(false);
  const [reviewingTransformation, setReviewingTransformation] = useState<boolean>(false);
  const [confirmationImageUrl, setConfirmationImageUrl] = useState<string | undefined>();
  const [initialPointCloudSet, setInitialPointCloudSet] = useState<boolean>(false);
  const [selectedPartNumber, setSelectedPartNumber] = useState<number | undefined>();
  const [rejectingPointCloud, setRejectingPointCloud] = useState<boolean>(false);
  const [displayClearAllPointsModal, setDisplayClearAllPointsModal] = useState<boolean>(false);

  const updateSiteWalkPointsDisabled = reviewingTransformation || processingTransformation || selectedPointCloudLoading;

  useEffect(() => {
    fetchSiteWalkPointClouds(sitewalkId).then(returnedPointClouds => {
      setPointClouds(returnedPointClouds);
      setPointCloudsLoaded(true);
    });
  }, [sitewalkId]);

  useEffect(() => {
    fetchSiteWalkTrajectories(sitewalkId).then(returnedTrajectories => {
      setTrajectories(returnedTrajectories);
      setTrajectoriesLoaded(true);
    })
  }, [sitewalkId]);

  const trajectoriesFormatted = useMemo(() => {
    if (trajectoriesLoaded && pointCloudsLoaded) {
      const pointCloudMap = new Map<number, SiteWalkPointCloud>();

      pointClouds.forEach(pcd => {
        pointCloudMap.set(pcd.sub_id, pcd);
      });

      return trajectories.map((trajectory, i) => {
        const pcd = pointCloudMap.get(trajectory.pcd_sub_id);
        const partNumbersFormatted = `${(pcd as SiteWalkPointCloud).part_numbers.join(', ')}`;

        return {
          name: `Part Number${pcd?.part_numbers?.length !== 1 ? 's' : ''} ${partNumbersFormatted} (PCD ${pcd?.sub_id})`,
          color: lineColors[i % lineColors.length],
          points: trajectory.trajectories
        }
      });
    }

    return [];
  }, [pointClouds, pointCloudsLoaded, trajectories, trajectoriesLoaded]);

  const validPointClouds = useMemo(() => {
    if (pointCloudsLoaded) {
      return pointClouds.filter(pcd => !pcd.is_rejected);
    }

    return [];
  }, [pointClouds, pointCloudsLoaded]);

  const pointCloudOptions = useMemo(() => {
    const partNumberPointClouds = validPointClouds.filter(pointCloud => selectedPartNumber === undefined || pointCloud.part_numbers.includes(selectedPartNumber));

    return partNumberPointClouds.map(pointCloud => {
      const hasTransformation = pointCloud.transformation_json !== null;
      const isSelected = selectedPointCloud?.id === pointCloud.id;

      return {
        pointCloud: pointCloud,
        style: {
          backgroundColor: hasTransformation ? 'green' : undefined,
          color: hasTransformation ? 'white' : undefined,
          fontWeight: isSelected ? 'bold' : undefined,
        },
      }
    });
  }, [validPointClouds, selectedPartNumber, selectedPointCloud]);

  const partNumberOptions = useMemo(() => {
    const seenPartNumbers = new Set<number>();

    pointClouds.forEach(pcd => {
      pcd.part_numbers.forEach(partNumber => seenPartNumbers.add(partNumber));
    });

    const seenPartNumbersArr = Array.from(seenPartNumbers);
    seenPartNumbersArr.sort((a,b) => a - b);

    return seenPartNumbersArr;
  }, [pointClouds]);

  const completedPartNumbers = useMemo(() => {
    const completedPartNumbersSet = new Set<number>();

    validPointClouds.forEach(pointCloud => {
      if (pointCloud.transformation_json !== null) {
        pointCloud.part_numbers.forEach(partNumber => {
          completedPartNumbersSet.add(partNumber);
        });
      }
    });

    return completedPartNumbersSet;
  }, [validPointClouds]);

  const fullyRejectedPartNumbers = useMemo(() => {
    const fullyRejectedPartNumbersSet = new Set<number>();

    partNumberOptions.forEach(partNumber => {
      const partNumberPCDs = pointClouds.filter(pcd => pcd.part_numbers.includes(partNumber));
      const allRejected = partNumberPCDs.every(pcd => pcd.is_rejected);

      if (allRejected) {
        fullyRejectedPartNumbersSet.add(partNumber);
      }
    });

    return fullyRejectedPartNumbersSet;
  }, [partNumberOptions, pointClouds]);

  const showStartPoint = useMemo(() => {
    const firstPartNumberOption = partNumberOptions[0];

    if (selectedPointCloud) {
      const selectedPartNumbers = selectedPointCloud.part_numbers;

      return selectedPartNumbers.includes(firstPartNumberOption);
    } else if (selectedPartNumber !== undefined) {
      return selectedPartNumber === firstPartNumberOption;
    }

    return true;
  }, [partNumberOptions, selectedPartNumber, selectedPointCloud]);

  const getPartNumberOptionLabel = useCallback((partNumber: number) => {
    if (completedPartNumbers.has(partNumber)) {
      return `${partNumber} - Completed`;
    } else if (fullyRejectedPartNumbers.has(partNumber)) {
      return `${partNumber} - Fully Rejected`
    } else {
      return partNumber.toString();
    }
  }, [completedPartNumbers, fullyRejectedPartNumbers]);

  const processTransformationDisabled = useMemo(() => {
    return floorPoints.length !== siteWalkPoints.length || floorPoints.length < 4 || selectedPointCloudLoading;
  }, [floorPoints, siteWalkPoints, selectedPointCloudLoading]);

  const pcdContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (validPointClouds && validPointClouds.length > 0 && !initialPointCloudSet) {
      setSelectedPointCloud(validPointClouds[0]);
      setInitialPointCloudSet(true);
    }
  }, [validPointClouds, initialPointCloudSet]);

  const roundPointValue = (value: number) => {
    return +value.toFixed(4);
  }

  const updateSiteWalkPointState = useCallback((newGeom: THREE.SphereGeometry, newMaterial: THREE.MeshBasicMaterial, newMesh: THREE.Mesh) => {
    const newSiteWalkPoint: PCDPoint = {
      id: uuidv4(),
      x: roundPointValue(camera.position.x),
      y: roundPointValue(camera.position.y),
      z: roundPointValue(camera.position.z),
      type: TranslationPointType.siteWalk,
    };

    setGeoms(prevGeoms => [...prevGeoms, newGeom]);
    setMaterials(prevMaterials => [...prevMaterials, newMaterial]);
    setMeshes(prevMeshes => [...prevMeshes, newMesh]);
    setSiteWalkPoints(prevSiteWalkPoints => [...prevSiteWalkPoints, newSiteWalkPoint]);
  }, []);

  const disposeIfBeingRemoved = useCallback((destroyAll: boolean, index: number, asset: THREE.SphereGeometry | THREE.MeshBasicMaterial | THREE.Mesh) => {
    if (destroyAll || selectedSiteWalkPointIndices.has(index)) {
      if (asset instanceof THREE.SphereGeometry || asset instanceof THREE.MeshBasicMaterial) {
        asset.dispose();
      } else {
        scene.remove(asset);
      }
    }
  }, [selectedSiteWalkPointIndices]);

  const filterRemovedAssets = useCallback((destroyAll: boolean, index: number) => {
    return !destroyAll && !selectedSiteWalkPointIndices.has(index);
  }, [selectedSiteWalkPointIndices]);

  const destroySiteWalkPoints = useCallback((destroyAll?: boolean) => {
    if (geoms.length > 0 && materials.length > 0 && meshes.length > 0) {
      geoms.forEach((geom, i) => disposeIfBeingRemoved(!!destroyAll, i, geom));
      materials.forEach((material, i) => disposeIfBeingRemoved(!!destroyAll, i, material));
      meshes.forEach((mesh, i) => disposeIfBeingRemoved(!!destroyAll, i, mesh));

      setGeoms(prevGeoms => prevGeoms.filter((_, i) => filterRemovedAssets(!!destroyAll, i)));
      setMaterials(prevMaterials => prevMaterials.filter((_, i) => filterRemovedAssets(!!destroyAll, i)));
      setMeshes(prevMeshes => prevMeshes.filter((_, i) => filterRemovedAssets(!!destroyAll, i)));
      setSiteWalkPoints(prevSiteWalkPoints => prevSiteWalkPoints.filter((_, i) => filterRemovedAssets(!!destroyAll, i)));

      setSelectedSiteWalkPointIndices(new Set());
    }
  }, [geoms, materials, meshes, disposeIfBeingRemoved, filterRemovedAssets]);

  const clearAllPoints = () => {
    setFloorPoints([]);
    destroySiteWalkPoints(true);
  }

  const reCenter = () => {
    camera.position.copy(initialPosition);
    camera.rotation.copy(initialRotation);
  }

  const onPlaceSiteWalkPoint = useCallback(() => {
    if (!updateSiteWalkPointsDisabled) {
      const geom = new THREE.SphereGeometry(0.1, 20, 20);
      const material = new THREE.MeshBasicMaterial({ color: 0x073c7a });
      const sphereMesh = new THREE.Mesh(geom, material);
    
      sphereMesh.position.set(camera.position.x, camera.position.y, camera.position.z);
      scene.add(sphereMesh);

      updateSiteWalkPointState(geom, material, sphereMesh);
    }
  }, [updateSiteWalkPointsDisabled, updateSiteWalkPointState]);

  const onRejectPCD = async () => {
    if (selectedPointCloud) {
      setRejectingPointCloud(true);

      try {
        const updatedPointCloud = await updatePointCloud(sitewalkId, selectedPointCloud?.sub_id, {is_rejected: true});

        setPointClouds(prevPointClouds => prevPointClouds.map(pointCloud => {
          return pointCloud.id === updatedPointCloud.id ? updatedPointCloud : pointCloud;
        }));

        const newPointCloudOptions = pointCloudOptions.filter(pointCloud => pointCloud.pointCloud.id !== updatedPointCloud.id);
        
        if (newPointCloudOptions.length > 0) {
          setSelectedPointCloud(newPointCloudOptions[0].pointCloud);
        } else {
          setSelectedPointCloud(undefined);
        }

        addNotification('Point cloud rejected successfully', 'success');
        clearAllPoints();
      } catch {
        addNotification('Error rejecting point cloud', 'error');
      } finally {
        setRejectingPointCloud(false);
      }
    }
  }
  
  const onWindowResize = useCallback(() => {
    const container = pcdContainerRef.current;

    const width = container?.offsetWidth ?? window.innerWidth;
    const height = container?.offsetHeight ?? window.innerHeight;
  
    performWidthAndHeightUpdates(width, height);
  }, []);

  const handleClickOutside = useCallback((e: MouseEvent) => {
    const currentlyEditingDiv = document.getElementById('currently-editing');

    if (currentlyEditingDiv && !currentlyEditingDiv.contains(e.target as HTMLDivElement)) {
      setCurrentlyEditingPoint(null);
    }
  }, []);

  const handleKeyUp = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Shift') {
      flyControls.movementSpeed = defaultMovementSpeed;
      flyControls.rollSpeed = defaultRollSpeed;
    }
  }, []);

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Shift') {
      flyControls.movementSpeed = defaultMovementSpeed * 2;
      flyControls.rollSpeed = defaultRollSpeed * 2;
    } else if (e.key === 'Escape') {
      console.log('DEBUG: ', {
        pcd_points: siteWalkPoints.map(mapSiteWalkPoints),
        floor_points: floorPoints.map(mapSiteWalkPoints),
      });
    }
  }, [floorPoints, siteWalkPoints]);

  const handleKeyPress = useCallback((e: KeyboardEvent) => {
    if (e.key === ' ') {
      onPlaceSiteWalkPoint();
    }
  }, [onPlaceSiteWalkPoint]);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize, false);

    return () => {
      window.removeEventListener('resize', onWindowResize, false);
    }
  }, [onWindowResize]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown, false);
    window.addEventListener('keyup', handleKeyUp, false);
    window.addEventListener('keypress', handleKeyPress, false);

    return () => {
      window.removeEventListener('keydown', handleKeyDown, false);
      window.removeEventListener('keyup', handleKeyUp, false);
      window.removeEventListener('keypress', handleKeyPress, false);
    }
  }, [handleKeyPress, handleKeyDown, handleKeyUp]);

  useEffect(() => {
    window.addEventListener('click', handleClickOutside, false);

    return () => {
      window.removeEventListener('click', handleClickOutside, false);
    }
  }, [handleClickOutside]);

  useEffect(() => {
    const container = pcdContainerRef.current;

    if (container && selectedPointCloud && selectedPointCloud.point_cloud_url) {
      setSelectedPointCloudLoading(true);

      const width = container.offsetWidth;
      const height = container.offsetHeight;
      const sceneName = `${selectedPointCloud.id}.pcd`;

      performWidthAndHeightUpdates(width, height);

      container?.appendChild(renderer.domElement);

      loader.load(selectedPointCloud.point_cloud_url, points => {
        points.name = sceneName;
        (points.material as any).size = 0.05;

        scene.add(points);
        
        setSelectedPointCloudLoading(false);
      });
  
      animate();

      return () => {
        container.removeChild(renderer.domElement);
        const pointsPcd = scene.getObjectByName(sceneName);

        if (pointsPcd) {
          scene.remove(pointsPcd);
        }
      }
    }
  }, [selectedPointCloud]);

  useEffect(() => {
    return () => {
      destroySiteWalkPoints(true);
    }
  }, []);

  const colorMaterial = (material: THREE.MeshBasicMaterial, color: number) => {
    if (material) {
      material.color.set(color);
    }
  }

  useEffect(() => {
    const currentlyEditingPointIndex = siteWalkPoints.findIndex(point => point.id === currentlyEditingPoint);

    materials.forEach((material, i) => {
      if (i === currentlyEditingPointIndex) {
        colorMaterial(material, 0x50C67F);
      } else if (selectedSiteWalkPointIndices.has(i)) {
        colorMaterial(material, 0xFB7185);
      } else {
        colorMaterial(material, 0x073c7a);
      }
    });
  }, [materials, selectedSiteWalkPointIndices, currentlyEditingPoint, siteWalkPoints]);

  const onDoubleClickMap = (e: React.MouseEvent, type: TranslationPointType, x: number, y: number) => {
    const newPoint = {id: uuidv4(), x, y, type};

    setFloorPoints(prevPoints => [...prevPoints, newPoint]);
  }

  const onMouseMoveMap = (e: React.MouseEvent, currentDraggingPointId: string | null, x: number, y: number) => {
    if (currentDraggingPointId !== null) {
      const updatedPoints = floorPoints.map(point => {
        if (point.id !== currentDraggingPointId) {
          return point;
        } else {
          return {...point, x, y};
        }
      });

      setFloorPoints(updatedPoints);
    }
  }

  const onClearSelectedPoints = (selectedPoints: Set<string>) => {
    setFloorPoints(prevPoints => prevPoints.filter(point => !selectedPoints.has(point.id)));
  }

  const onClickSiteWalkCoordinate = useCallback((index: number) => {
    if (currentlyEditingPoint !== null) {      
      setCurrentlyEditingPoint(null);
    }

    if (!selectedSiteWalkPointIndices.has(index)) {
      const updatedSelectedSiteWalkPointIndices = new Set(selectedSiteWalkPointIndices);
      updatedSelectedSiteWalkPointIndices.add(index);

      setSelectedSiteWalkPointIndices(updatedSelectedSiteWalkPointIndices);
    } else {
      const updatedSelectedSiteWalkPointIndices = new Set(selectedSiteWalkPointIndices);
      updatedSelectedSiteWalkPointIndices.delete(index);

      setSelectedSiteWalkPointIndices(updatedSelectedSiteWalkPointIndices);
    }
  }, [selectedSiteWalkPointIndices, currentlyEditingPoint]);

  const onCtrlClickSiteWalkCoordinate = useCallback((index: number) => {
    const point = siteWalkPoints[index];

    setCurrentlyEditingPoint(point.id);
    setSelectedSiteWalkPointIndices(new Set());
  }, [siteWalkPoints]);

  const handleSiteWalkCoordinateClick = useCallback((e: React.MouseEvent<HTMLSpanElement>, index: number) => {
    if (e.ctrlKey || e.metaKey) {
      onCtrlClickSiteWalkCoordinate(index);
    } else {
      onClickSiteWalkCoordinate(index);
    }
  }, [onClickSiteWalkCoordinate, onCtrlClickSiteWalkCoordinate]);

  const onChangeCoordinateValue = useCallback((index: number, dimension: Dimension, value: number) => {
    if (!updateSiteWalkPointsDisabled) {
      const point = {...siteWalkPoints[index]};
      point[dimension] = value;

      setSiteWalkPoints(prevPoints => {
        const updatedPoints = [...prevPoints];
        updatedPoints[index] = point;

        return updatedPoints;
      });

      if (!isNaN(value)) {
        const sphereMesh = meshes[index];
        
        sphereMesh.position[dimension] = value;
      }
    }
  }, [meshes, siteWalkPoints, updateSiteWalkPointsDisabled]);

  const onBlurPointEditorInput = () => {
    setCurrentlyEditingPoint(null);
  }

  const siteWalkCoordinateDisplay = useMemo(() => {
    return siteWalkPoints
      .map((point, i, arr) => {
        const isSelected = selectedSiteWalkPointIndices.has(i);
        const currentlyEditing = currentlyEditingPoint === point.id; 
        let coordinatePrintout = `(${point.x}, ${point.y}, ${point.z})`;

        if (i < arr.length - 1) {
          coordinatePrintout += ', ';
        }

        return (
          <div
            id={currentlyEditing ? 'currently-editing' : undefined}
            key={point.id}
            style={{cursor: 'pointer', fontWeight: isSelected ? 'bold' : 'normal'}}
          >
            <span
              onClick={e => handleSiteWalkCoordinateClick(e, i)}
              style={{
                display: currentlyEditing ? 'none' : 'inline',
              }}
            >
              {coordinatePrintout}
            </span>
            <div
              style={{
                display: currentlyEditing ? 'flex' : 'none',
                gap: '5px'
              }}
            >
              <PointEditorInput
                label="x"
                value={point.x}
                onChange={e => onChangeCoordinateValue(i, 'x', parseFloat(e.target.value))}
                onBlur={onBlurPointEditorInput}
              />
              <PointEditorInput
                label="y"
                value={point.y}
                onChange={e => onChangeCoordinateValue(i, 'y', parseFloat(e.target.value))}
                onBlur={onBlurPointEditorInput}
              />
              <PointEditorInput
                label="z"
                value={point.z}
                onChange={e => onChangeCoordinateValue(i, 'z', parseFloat(e.target.value))}
                onBlur={onBlurPointEditorInput}
              />
            </div>
          </div>
        )
      });
  }, [
    siteWalkPoints,
    handleSiteWalkCoordinateClick,
    selectedSiteWalkPointIndices,
    onChangeCoordinateValue,
    currentlyEditingPoint
  ]);

  const onClickProcessTransformation = async () => {
    if (selectedPointCloud) {
      try {
        setProcessingTransformation(true);

        const confirmationImage = await processSiteWalkTransformation(siteWalkPoints, floorPoints, sitewalkId, selectedPointCloud.sub_id);
        const imageUrl = URL.createObjectURL(confirmationImage);
        setConfirmationImageUrl(imageUrl);

        setReviewingTransformation(true);
      } catch (e) {
        console.error(e);
        addNotification("Error processing transformation", "error");
      } finally {
        setProcessingTransformation(false);
      }
    }
  }

  const getPointCloudOptionLabel = (pointCloud: {pointCloud: SiteWalkPointCloud}) => {
    const fileNameRegex = /[^/]+$/;
    const match = pointCloud.pointCloud.point_cloud_s3_key.match(fileNameRegex);

    return `${pointCloud.pointCloud.sub_id}: ${match ? match[0] : ''}`;
  }

  const onSelectPartNumber = (partNumber: number) => {
    setSelectedPartNumber(partNumber);

    if (validPointClouds) {
      const partNumberPointClouds = validPointClouds.filter(cloud => cloud.part_numbers.includes(partNumber));

      if (partNumberPointClouds.length > 0) {
        const firstPointCloud = partNumberPointClouds[0];
        const selectedPointCloudPartNumbers = selectedPointCloud?.part_numbers ?? [];

        if (!selectedPointCloudPartNumbers.includes(partNumber)) {
          setSelectedPointCloud(firstPointCloud);
        }
      } else {
        setSelectedPointCloud(undefined);
      }
    }
  }

  const onSelectPointCloud = (selectedPointCloudId: string | undefined) => {
    if (validPointClouds) {
      const newSelectedPointCloud = validPointClouds.find(pointCloud => pointCloud.id.toString() === selectedPointCloudId);

      setSelectedPointCloud(newSelectedPointCloud);
      reCenter();
    }
  }

  const onConfirmTransformation = async () => {
    if (selectedPointCloud) {
      try {
        const confirmedPointCloud = await confirmSiteWalkTransformation(sitewalkId, selectedPointCloud.sub_id, siteWalkPoints, floorPoints);

        setPointClouds(prevPointClouds => {
          return prevPointClouds.map(pointCloud => {
            if (pointCloud.id === confirmedPointCloud.id) {
              return confirmedPointCloud
            } else {
              return pointCloud;
            }
          });
        });
        
        addNotification("Transformation confirmed successfully", "success");
        clearAllPoints();

        const updatedTrajectories = await fetchSiteWalkTrajectories(sitewalkId);
        setTrajectories(updatedTrajectories);
      } catch {
        addNotification("Error confirming transformation", "error");
      }
    }
  }

  if (!pointCloudsLoaded) {
    return <LoadingIndicator/>
  }

  return (
    <div>
      <Title>Site Walk {sitewalkId} - {sitewalk.project.name} - {sitewalk.group_name}</Title>
      <BlurableMainWindow
        top={75}
        right={25}
        left={25}
        blur={displayClearAllPointsModal}
      >
        
        <MapPointPickerContainer
          float="left"
        >
          <PanZoomContainer>
            <PCDContainer
              ref={pcdContainerRef}
            >
              {(pointCloudOptions.length > 0 && selectedPointCloudLoading) &&
                <LoadingIndicator/>
              }
              {(pointCloudsLoaded && pointCloudOptions.length === 0) &&
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  No Point Clouds
                </div>
              } 
            </PCDContainer>
            <PointControlsContainer
              style={{
                gap: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <Button 
                  disabled={selectedSiteWalkPointIndices.size === 0 || selectedPointCloudLoading}
                  text="Clear Selected"
                  onClick={() => destroySiteWalkPoints()}
                />

                <Button
                  disabled={selectedPointCloudLoading}
                  text="Re-Center"
                  onClick={reCenter}
                />
              </div>
              <Button
                disabled={rejectingPointCloud || selectedPointCloudLoading}
                text="Reject PCD"
                onClick={onRejectPCD}
                style={{
                  backgroundColor: 'red',
                  color: 'white'
                }}
              />
            </PointControlsContainer>
            <PointControlsContainer>
              <SelectContainer>
                {partNumberOptions.length > 0 &&
                  <>
                    <label>Part:</label>
                    <Select
                      useInitialEmptyOption
                      disabled={selectedPointCloudLoading}
                      options={partNumberOptions}
                      value={selectedPartNumber}
                      onSelect={e => onSelectPartNumber(parseInt(e.target.value))}
                      getOptionLabel={getPartNumberOptionLabel}
                    />
                  </>
                }
              </SelectContainer>
              <SelectContainer>
                <label>Selected PCD:</label>
                <Select
                  disabled={!validPointClouds || validPointClouds.length === 0 || selectedPointCloudLoading}
                  useInitialEmptyOption
                  options={pointCloudOptions}
                  value={selectedPointCloud?.id.toString() ?? ''}
                  onSelect={e => {
                    onSelectPointCloud(e.target.value);
                  }}
                  getOptionValue={(option: {pointCloud: SiteWalkPointCloud}) => option.pointCloud.id}
                  getOptionLabel={getPointCloudOptionLabel}  
                  selectStyles={{width: '250px'}}
                />
              </SelectContainer>
            </PointControlsContainer>
            <CoordinatesContainer
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '5px',
                alignItems: 'center'
              }}
            >
              Coordinates:&nbsp;
              <>{siteWalkCoordinateDisplay}</>
            </CoordinatesContainer>
          </PanZoomContainer>
        </MapPointPickerContainer>
        <MapPointPickerContainer
          float="right"
        >
          <PanZoomProvider initialScale={0.3}>
            <MapPointPicker
              minScale={0.1}
              maxScale={3}
              imageUrl={floor.latest_floor_plan?.web_image_url}
              onDoubleClickMap={onDoubleClickMap}
              onMouseMoveMap={onMouseMoveMap}
              points={floorPoints}
              pointType={TranslationPointType.floorPlan}
              onClearSelectedPoints={onClearSelectedPoints}
              startPoint={showStartPoint ? sitewalk.start_point : null}
              lines={trajectoriesFormatted}
              linesLoaded={trajectoriesLoaded}
              defaultShowLines
              showLinesButtonText="Show Trajectories"
              hideLinesButtonText="Hide Trajectories"
            />
          </PanZoomProvider>
        </MapPointPickerContainer>
        <div style={{clear: 'both'}} />
        <ButtonContainer>
          <Button
            text="Clear All Points"
            onClick={() => setDisplayClearAllPointsModal(true)}
          />
          {!processingTransformation &&
            <Button
              disabled={processTransformationDisabled}
              primary
              text="Process Transformation"
              onClick={onClickProcessTransformation}
            />
          }
          {processingTransformation &&
            <LoadingIndicator
              indicatorFlexStyle={{width: '205px'}}
            />
          }
        </ButtonContainer>
      </BlurableMainWindow>

      {confirmationImageUrl &&
        <Modal
          onClose={() => setReviewingTransformation(false)}
          open={reviewingTransformation}
        >
          <PanZoomProvider initialScale={0.25}>
            <SitewalkTransformationConfirmation
              imageUrl={confirmationImageUrl}
              onClose={() => setReviewingTransformation(false)}
              onConfirmTransformation={onConfirmTransformation}
            />
          </PanZoomProvider>
        </Modal>
      }
      {displayClearAllPointsModal &&
        <ClearAllPointsModal
          onClose={() => setDisplayClearAllPointsModal(false)}
          open={displayClearAllPointsModal}
          onConfirm={clearAllPoints}
        />
      }
    </div>
  )
}

interface IPointEditorInputProps {
  label: string;
  value: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
}

const PointEditorInput = ({
  label,
  value,
  onChange,
  onBlur,
}: IPointEditorInputProps) => {
  const handleInputBlur = () => {    
    flyControls.movementSpeed = defaultMovementSpeed;
    flyControls.rollSpeed = defaultRollSpeed;
  }

  const handleInputFocus = () => {
    flyControls.movementSpeed = 0;
    flyControls.rollSpeed = 0;
  }

  return (
    <div>
      <label>{label}: </label>
      <input
        step={0.05}
        type="number"
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        value={value}
        style={{width: '75px'}}
        onChange={e => onChange(e)}
      />
    </div>
  )
}

const Title = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 5px;
`;

const PCDContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 50px;
  padding: 0px 30px;
  gap: 10px;
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
`