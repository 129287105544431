import styled from "styled-components";
import { DEFAULT_CARTOGRAPHER_ROS_LUA, DEFAULT_SLAM_TOOLBOX_YAML, LidarMapConfigurationTextEditorType, ProjectLidarMapConfiguration, createProjectLidarMapConfiguration, updateProjectLidarMapConfiguration } from "../../../../api/projectFloorLidarMaps";
import { useNotifications } from "../../../../contexts/notificationProvider";
import { useProjectContext } from "../../../../contexts/projectContext";
import { useProjectNavigation } from "../../../../hooks/useNavigation";
import { FormCheckbox, FormContainer, FormHeader, SaveButtonContainer, StyledInput, StyledLabel } from "../../../common/FormControls"
import { Button } from "../../../common/Inputs/Button";
import { useState, useCallback } from "react";
import { FullScreenTextEditor } from "../../../common/Modal/FullScreenTextEditor";
import { useFetchProjectLidarMapConfigurationQuery } from "../../../../hooks/projectQueries";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import axios from "axios";

export const ManageLidarMapConfiguration = () => {
  const {
    navigateToProjectLidarMapConfiguration,
  } = useProjectNavigation();

  const { addNotification } = useNotifications();

  const {
    state: projectState,
    updateLidarMapConfig,
  } = useProjectContext();

  const {
    project,
    lidarMapConfig,
    lidarMapConfigId,
  } = projectState;

  const configIsNew = lidarMapConfigId === 'new' || !lidarMapConfigId;

  const [cartographerRosConfigurationText, setCartographerRosConfigurationText] = useState<string>(DEFAULT_CARTOGRAPHER_ROS_LUA);
  const [cartographerRosConfigurationLoading, setCartographerRosConfigurationLoading] = useState<boolean>(!configIsNew);
  const [slamToolboxConfigurationText, setSlamToolboxConfigurationText] = useState<string>(DEFAULT_SLAM_TOOLBOX_YAML);
  const [slamToolboxConfigurationLoading, setSlamToolboxConfigurationLoading] = useState<boolean>(!configIsNew);
  const [configurationTextEditorOpen, setConfigurationTextEditorOpen] = useState<boolean>(false);
  const [configurationTextEditorType, setConfigurationTextEditorType] = useState<LidarMapConfigurationTextEditorType>(LidarMapConfigurationTextEditorType.none);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false); 

  const validForSubmit: boolean = !!lidarMapConfig.name && !!lidarMapConfig.name.trim();
  const saveButtonText: string = configIsNew ? 'Create' : 'Save';

  const fetchConfigurationText = useCallback(async (configurationFileUrl: string) => {
    const awsAxiosInstance = axios.create();
    delete awsAxiosInstance.defaults.headers.common['Authorization'];
    
    const response = await awsAxiosInstance.get(configurationFileUrl);

    return response.data as string;
  }, []);

  const onSuccessfulFetchConfig = async (data: ProjectLidarMapConfiguration) => {
    updateLidarMapConfig(data);

    const promiseArr: Promise<any>[] = [];

    if (data.cartographer_ros_configuration_url) {
      const cartographerRosConfigurationPromise = fetchConfigurationText(data.cartographer_ros_configuration_url).then(returnedText => {
        setCartographerRosConfigurationLoading(false);
        setCartographerRosConfigurationText(returnedText);
      });

      promiseArr.push(cartographerRosConfigurationPromise);
    } else {
      setCartographerRosConfigurationLoading(false);
    }

    if (data.slam_toolbox_configuration_url) {
      const slamToolboxConfigurationPromise = fetchConfigurationText(data.slam_toolbox_configuration_url).then(returnedText => {
        setSlamToolboxConfigurationLoading(false);
        setSlamToolboxConfigurationText(returnedText);
      });

      promiseArr.push(slamToolboxConfigurationPromise);
    } else {
      setSlamToolboxConfigurationLoading(false);
    }

    await Promise.all(promiseArr);
  }

  const {isLoading: configLoading} = useFetchProjectLidarMapConfigurationQuery(project.public_id, lidarMapConfigId, onSuccessfulFetchConfig);

  const onClickEditConfigButton = (configType: LidarMapConfigurationTextEditorType) => {
    setConfigurationTextEditorType(configType);
    setConfigurationTextEditorOpen(true);
  }

  const onCloseTextEditor = () => {
    setConfigurationTextEditorOpen(false);
    setConfigurationTextEditorType(LidarMapConfigurationTextEditorType.none);
  }

  const formatLidarMapData = () => {
    const postData = {...lidarMapConfig};

    if (postData.run_cartographer_ros) {
      postData.cartographer_ros_configuration_text = cartographerRosConfigurationText;
    }

    if (postData.run_slam_toolbox) {
      postData.slam_toolbox_configuration_text = slamToolboxConfigurationText;
    }

    return postData;
  }

  const onCreateNewConfig = async () => {
    try {
      setSaveInProgress(true);

      const postData = formatLidarMapData();

      const createdConfig = await createProjectLidarMapConfiguration(project.public_id, postData);
      navigateToProjectLidarMapConfiguration(project.public_id, createdConfig.id);
      addNotification('Config created successfully', 'success');
    } catch (err) {
      console.log('onCreateNewLidarMapConfig==>>', err);
      addNotification('Error creating new config', 'error');
    } finally {
      setSaveInProgress(false);
    }
  }

  const onUpdateExistingConfig = async () => {
    try {
      setSaveInProgress(true);

      const postData = formatLidarMapData();

      const updatedConfig = await updateProjectLidarMapConfiguration(project.public_id, lidarMapConfig.id, postData);
      navigateToProjectLidarMapConfiguration(project.public_id, updatedConfig.id);
      addNotification('Config updated successfully', 'success');
    } catch (err) {
      console.log('onUpdateExistingConfig==>>', err);
      addNotification('Error updating config', 'error');
    } finally {
      setSaveInProgress(false);
    }
  }

  const onClickSave = () => {
    if (validForSubmit) {
      if (configIsNew) {
        onCreateNewConfig();
      } else {
        onUpdateExistingConfig();
      }
    }
  }

  console.log(configLoading, cartographerRosConfigurationLoading, slamToolboxConfigurationLoading)

  if (configLoading || cartographerRosConfigurationLoading || slamToolboxConfigurationLoading) {
    return <LoadingIndicator/>
  }

  return (
    <FormContainer>
      <FormHeader>
        {`${configIsNew ? 'New Config' : 'Edit Config'} - ${project.name}`}
      </FormHeader>

      <StyledLabel htmlFor='name'>Name:</StyledLabel>
      <StyledInput
        id='name'
        value={lidarMapConfig.name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateLidarMapConfig( { name: e.target.value })}
      />

      <ConfigureRunContainer>
        <FormCheckbox
          label="Run Cartographer Ros"
          labelStyle={{
            width: '175px'
          }}
          checked={lidarMapConfig.run_cartographer_ros}
          onChangeChecked={(checked: boolean) => updateLidarMapConfig({ run_cartographer_ros: checked })}
          checkboxContainerStyle={{
            width: 'auto',
            marginBottom: '0px'
          }}
        />
        <Button
          primary
          disabled={!lidarMapConfig.run_cartographer_ros}
          text="Edit Cartographer Config"
          onClick={() => onClickEditConfigButton(LidarMapConfigurationTextEditorType.cartographer_ros)}
          style={{ display: 'inline-block' }}
        />
      </ConfigureRunContainer>

      <ConfigureRunContainer>
        <FormCheckbox
          label="Run SLAM Toolbox"
          labelStyle={{
            width: '175px'
          }}
          checked={lidarMapConfig.run_slam_toolbox}
          onChangeChecked={(checked: boolean) => updateLidarMapConfig({ run_slam_toolbox: checked })}
          checkboxContainerStyle={{
            width: 'auto',
            marginBottom: '0px'
          }}
        />
        <Button
          primary
          disabled={!lidarMapConfig.run_slam_toolbox}
          text="Edit SLAM Toolbox Config"
          onClick={() => onClickEditConfigButton(LidarMapConfigurationTextEditorType.slam_toolbox)}
          style={{ display: 'inline-block' }}
        />
      </ConfigureRunContainer>

      {configurationTextEditorOpen &&
        <FullScreenTextEditor
          modalStyle={{zIndex: 12}}
          modalOpen={configurationTextEditorOpen}
          onClose={onCloseTextEditor}
          text={configurationTextEditorType === LidarMapConfigurationTextEditorType.cartographer_ros ? cartographerRosConfigurationText : slamToolboxConfigurationText}
          onChangeText={configurationTextEditorType === LidarMapConfigurationTextEditorType.cartographer_ros ? setCartographerRosConfigurationText : setSlamToolboxConfigurationText}
        />
      }

      <SaveButtonContainer>
        {!saveInProgress &&
          <Button
            primary={validForSubmit}
            disabled={!validForSubmit}
            text={saveButtonText}
            onClick={onClickSave}
            style={{ display: 'inline-block' }}
          />
        }
        {saveInProgress &&
          <LoadingIndicator/>
        }
      </SaveButtonContainer>
    </FormContainer>
  )
}

const ConfigureRunContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 25px;
`;