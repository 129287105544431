import { useEffect, useRef } from 'react';
import axios from 'axios';
import * as THREE from 'three';

const lockSettings = {
  orbit: true,
  pan: true,
  zoom: true,
  roll: true,
  fov: true,
  gotoview: true,
  walk: true,
};

export const customSceneKey = 'custom-scene';

interface IForgeViewerProps {
  fileUrn: string;
  setLoading?: (loading: boolean) => void;
  onPlacePoint: (point: THREE.Vector3) => void;
  viewer: React.MutableRefObject<any>;
  onDestroyCustomGeometry: () => void;
}

export const ForgeViewer = ({
  fileUrn,
  setLoading,
  onPlacePoint,
  viewer,
  onDestroyCustomGeometry,
}: IForgeViewerProps) => {
  const initializer: any = useRef();
  const ctrlKeyDown = useRef(false);

  const onClickForgeViewer = (v: any, ev: PointerEvent) => {
    const result = v.clientToWorld(ev.clientX, ev.clientY);
    if (result && ctrlKeyDown.current) {
      onPlacePoint(result.point);
    }
  }

  const initializeViewer = () => {
    if (setLoading) {
      setLoading(true);
    }

    const Autodesk = (window as any).Autodesk;
    //@ts-ignore
    if (!window.Autodesk) return;
    let options = {
      env: 'AutodeskProduction2',
      api: 'streamingV2',
      getAccessToken: function (onTokenReady: any) {
        getForgeToken().then(data => {
          var timeInSeconds = 3600; // Use value provided by Forge Authentication (OAuth) API
          onTokenReady(data.access_token, timeInSeconds);
        });
      },
    };
    initializer.current = Autodesk.Viewing.Initializer(options, function () {
      var htmlDiv = document.getElementById('forgeViewer');
      const guiOptions = {
        disabledExtensions: {
          measure: false,
          viewcube: false,
          layermanage: true,
          explode: true,
          section: true,
          hyperlink: true,
          fusionOrbit: true,
          fullScreen: true,
          viewerSettings: true,
          propertiesManager: true,
          layerManager: true,
        },
        profileSettings: {
          bimWalkToolPopup: false,
          firstPersonToolPopup: false,
        },
        loaderExtensions: { svf: 'Autodesk.MemoryLimited' },
        extensions: ['Autodesk.Viewing.MemoryLimitedDebug'],
      };
      let v = new Autodesk.Viewing.GuiViewer3D(htmlDiv, guiOptions);
      var startedCode = v.start();
      viewer.current = v;
      v.addEventListener(
        //@ts-ignore
        Autodesk.Viewing.PROGRESS_UPDATE_EVENT,
        initializePosition
      );
      // For debugging positions, press esc to print position
      v.addEventListener(Autodesk.Viewing.ESCAPE_EVENT, () => {
        const currentPosition = v.navigation.getPosition();

        onPlacePoint(currentPosition);
      });

      v.container.addEventListener('click', (e: PointerEvent) => onClickForgeViewer(v, e));

      if (startedCode > 0) {
        console.error('Failed to create a Viewer: WebGL not supported.');
        return;
      }
      Autodesk.Viewing.Document.load(fileUrn, onDocumentLoadSuccess, onDocumentLoadFailure);
    });
  };

  const onDocumentLoadSuccess = (viewerDocument: any) => {
    let defaultModel = viewerDocument.getRoot().getDefaultGeometry();
    if (viewer.current) {
      viewer.current.loadExtension('Autodesk.BimWalk');
      viewer.current.loadDocumentNode(viewerDocument, defaultModel);

      if (!viewer.current.overlays.hasScene(customSceneKey)) {
        viewer.current.overlays.addScene(customSceneKey);
      }
    }
  };

  const onDocumentLoadFailure = () => {
    console.error('Failed fetching Forge manifest');
  };

  const initializePosition = (e: { percent: number; state: number; model: any }) => {
    if (e.percent === 1) {
      viewer.current.setNavigationLockSettings(lockSettings);
      viewer.current.setNavigationLock(true);
      viewer.current.setActiveNavigationTool('bimwalk');
      viewer.current.setBimWalkToolPopup(false);

      if (setLoading) {
        setLoading(false);
      }
    }
  };

  const destroy = () => {
    const Autodesk = (window as any).Autodesk;

    onDestroyCustomGeometry();

    if (viewer.current) {
      viewer.current.finish();
      viewer.current = null;
    }
    if (initializer.current) {
      initializer.current.destroy();
      initializer.current = null;
    }
    Autodesk.Viewing.shutdown();
  };

  useEffect(() => {
    if (!document.getElementById('autodeskScript')) {
      var css = document.createElement('link');
      css.rel = 'stylesheet';
      css.href = 'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.51/style.min.css';
      var body = document.getElementsByTagName('body')[0];
      body.appendChild(css);
      var tag = document.createElement('script');
      tag.async = true;
      tag.src =
        'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.51/viewer3D.min.js';
      tag.id = 'autodeskScript';
      body.appendChild(tag);
      tag.addEventListener('load', initializeViewer);
    } else {
      initializeViewer();
    }

    return () => {
      destroy();
    };
  }, []);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Control' || e.key === 'Meta') {
        ctrlKeyDown.current = true;
      }
    }

    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Control' || e.key === 'Meta') {
        ctrlKeyDown.current = false;
      }
    }

    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);

    // return () => {
    //   document.removeEventListener('keydown', onKeyDown);
    //   document.removeEventListener('keyup', onKeyUp);
    // }
  }, []);

  return <div id="forgeViewer" />;
};

// Utility Functions
function getForgeToken() {
  return axios.get('https://services.nexterarobotics.com/core/adsk-token/').then(r => r.data);
}
