import ReactModal from "react-modal";
import styled from "styled-components";
import {Select} from "../../../common/Inputs/Select";
import {Flex} from "@react-css/flex";
import { useEffect, useMemo, useState } from 'react';
import axios from "axios";
import {Checkbox} from "../../../common/Inputs/Checkbox";
import {useEditorContext} from "../../../../contexts/editorContext";
import {Icon} from "../../../common/Icon";
import closeIcon from '../../../../assets/images/icons/icon_close.svg'

interface IImageSelectionModalProps {
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
    onSubmit: (filters: any) => void;
}
export const ImageSelectionModal = ({isOpen, setIsOpen, onSubmit}:IImageSelectionModalProps) => {
    const {state: EditorState} = useEditorContext()
    const [projectsList, setProjectsList] = useState<any[]>([])
    const [filters, setFilters] = useState(EditorState.filters)

    const updateFilters = (filter: any) => {
        setFilters( (prevState: any) => {
            return {...prevState, ...filter}
        })
    }

    const projects = useMemo( () => (
            axios.get('https://api.nexterarobotics.com/api/projects')
                .then( r => r.data)
                .then( d => {
                    setProjectsList(d.data.map((item: any, index: number) => item.name))
                })
                .catch( () => alert('error loading projects'))
        ), []
    )

    useEffect(()=>{
        if(!filters.limit){
            updateFilters({limit: 10})
        }
    }, [filters])

    return (
        <ReactModal isOpen={isOpen} style={styles}>
            <ImageSelectionModalContainer>
                <ImageSelectionModalHeader>
                    <div>
                        <h2 style={{textAlign: 'center'}}>Image Selection</h2>
                        <div onClick={() => setIsOpen(false)} style={{position: 'absolute', right: 5, top: "4px", cursor: 'pointer'}}><Icon icon={closeIcon} size={16}/></div>
                    </div>
                </ImageSelectionModalHeader>
                <ImageSelectionModalBody>
                    <Flex flexDirection="row" style={{width: '100%'}}>
                        <Select label="Project" options={projectsList} value={filters.project} onSelect={(e) => updateFilters({project: e.target.value})} theme="light"/>
                        <Select label="Limit" options={["10", "20", "50"]} value={filters.limit} onSelect={(e) => updateFilters({limit: e.target.value})} theme="light"/>
                    </Flex>
                    <Flex>
                        <Checkbox label={"Safety Verified"}
                                  value={filters.verified}
                                  onSelect={() => updateFilters({verified: !filters.verified})} />
                        <Checkbox label={"Progress Verified"}
                                  value={filters.verified}
                                  onSelect={() => updateFilters({verified: !filters.verified})} />
                        <Checkbox label={"Has Results"}
                                  value={filters.has_results}
                                  onSelect={() => updateFilters({has_results: !filters.has_results})} />
                        <Checkbox label={"Marked for Retrain"}
                                  value={filters.retrain}
                                  onSelect={() => updateFilters({retrain: !filters.retrain})} />
                    </Flex>
                </ImageSelectionModalBody>
                <ImageSelectionModalFooter>
                    <Flex justifyEnd flexDirection="row" style={{width: '100%'}}>
                    <ImageSelectionButton onClick={() => onSubmit(filters)}>Submit</ImageSelectionButton>
                    </Flex>
                </ImageSelectionModalFooter>
            </ImageSelectionModalContainer>
        </ReactModal>
    )
}

const ImageSelectionModalContainer = styled.div`
    width: 100%;
`;
const ImageSelectionModalHeader = styled.div`
    background: #ccc;
    color: #222;
    text-align: center;
    padding: 5px 10px;
    font-family: "Roboto Mono";
`;

const ImageSelectionModalBody = styled.div`
    padding: 10px;
    width: 100%;
`;
const ImageSelectionModalFooter = styled.div`
    position: absolute;
    bottom: 0;
    padding: 10px;
    width: 100%
`;

const ImageSelectionButton = styled.div`
  margin: 10px 0;
  padding: 7px 10px;
  background: #ccc;
  color: #222;
  cursor: pointer;
  text-align: center;
  border-radius: 2px;

  &.value-true{
    background: #0f0;
  }
`;


const styles: any = {
    overlay: {
        position: 'fixed',
        zIndex: 1000,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    content: {
        position: 'absolute',
        border: '1px solid #ccc',
        background: '#222',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '4px',
        outline: 'none',
        width: '600px',
        maxHeight: '500px',
        margin: 'auto',
        padding: '0'
    },
};
