import { Route, Switch } from "react-router-dom"
import { PATH_STRINGS } from "../../../hooks/useGeneratedPaths"
import { EditStairClimberScript } from "./EditStairClimberScript"
import { StairClimberScripts } from "./StairClimberScripts"

export const ManageStairs = () => {
  return (
    <Switch>
      <Route exact path={PATH_STRINGS.stairClimberScripts}>
        <StairClimberScripts/>
      </Route>
      <Route path={PATH_STRINGS.stairClimberScript}>
        <EditStairClimberScript/>
      </Route>
    </Switch>
  )
}