import axios from "axios";
import { TranslationPoint } from "../components/views/project-setup/project-floors/LidarPointPicker/ProjectFloorLidarPointPicker";
import { FloorPoint, ForgePoint } from "../components/views/project-setup/project/ProjectForgeTransformation";
import { API_BASE } from "./constants";
import { uploadToS3 } from "./s3ImageUpload";

interface ProjectFloorPlan {
  scale: number;
  sub_id: number;
  web_image_url: string | null;
}

const initialFloorPlan: ProjectFloorPlan = {
  scale: 1,
  sub_id: 0,
  web_image_url: null
}

export interface ProjectFloor {
  id: number;
  name: string;
  floor_code: string;
  latest_floor_plan: ProjectFloorPlan
  order: number | null;
}

export const initialProjectFloor: ProjectFloor = {
  id: 0,
  name: '',
  floor_code: '',
  latest_floor_plan: {...initialFloorPlan},
  order: null,
}

export interface NoGoZone {
  id: number;
  name: string;
  points: string;
  project_floor: number;
  is_active: boolean;
}

export const fetchProjectFloors = async (projectId: string): Promise<ProjectFloor[]> => {
  const res = await axios.get(
    `${API_BASE}/project/${projectId}/floors/manage_floors`
  );
  const response = await res.data;
  return response.data;
}

export const fetchProjectFloor = async (projectId: string, floorId: string) => {
  const res = await axios.get(
    `${API_BASE}/project/${projectId}/floor/${floorId}/manage_floor`
  );
  const response = await res.data;
  return response.data;
}

export const createProjectFloor = async (projectId: string, floorData: ProjectFloor) => {
  const res = await axios.post(
    `${API_BASE}/project/${projectId}/floor`,
    {
      floor_code: floorData.floor_code,
      name: floorData.name,
      order: floorData.order
    }
  );
  const response = await res.data;
  return response.data;
}

export const updateProjectFloor = async (projectId: string, floorData: ProjectFloor) => {
  const res = await axios.patch(
    `${API_BASE}/project/${projectId}/floor/${floorData.floor_code}`,
    {
      id: floorData.id,
      floor_code: floorData.floor_code,
      name: floorData.name,
      order: floorData.order
    }
  );
  const response = await res.data;
  return response.data;
}

export const uploadProjectFloorPlan = async (projectId: string, floorData: ProjectFloor, image: File) => {
  const endPoint = `project/${projectId}/floor/${floorData.floor_code}/maps/floor-plans/upload_floor_plan_image`;
  const imageKey: string = await uploadToS3(image, endPoint);

  const res = await axios.post(
    `${API_BASE}/project/${projectId}/floor/${floorData.floor_code}/maps/floor-plans/create_new_floor_plan`,
    {
      web_image_s3_key: imageKey
    }
  );
  const response = await res.data;
  return response.data;
}

export interface Transformation {
  linear_transformation: [number, number][];
  rotation: number;
  scaling: [number, number];
  translation: [number, number];
}

export const processLidarMapTransformation = async (floorPoints: TranslationPoint[], lidarPoints: TranslationPoint[]) => {
  const res = await axios.post(
    'https://services.nexterarobotics.com/transformations/lidar/',
    {
      'primary_points': floorPoints.map(point => [point.x, point.y]),
      'secondary_points': lidarPoints.map(point => [point.x, point.y]),
    },
  );

  return res.data;
}

interface TransformationPointLists {
  primary_points: [number, number][];
  secondary_points: [number, number][];
}

interface MultiFloorTransformationFloor {
  floor_code: string;
  points: TransformationPointLists;
  height: number;
}

export const processForgeViewerTransformation = async (floorPoints: FloorPoint[], forgePoints: ForgePoint[]) => {
  const pointMap: Record<string, TransformationPointLists> = {};
  const floorHeights: Record<string, number> = {};

  floorPoints.forEach(point => {
    if (!pointMap[point.floor_code]) {
      pointMap[point.floor_code] = {
        primary_points: [],
        secondary_points: []
      };
    }

    pointMap[point.floor_code].primary_points.push([point.x, point.y]);
  });

  forgePoints.forEach(point => {
    if (!point.selectingFloorHeight) {
      pointMap[point.floor_code].secondary_points.push([point.vector.x, point.vector.y]);
    } else {
      floorHeights[point.floor_code] = point.vector.z;
    }
  });

  const floors: MultiFloorTransformationFloor[] = [];

  Object.getOwnPropertyNames(pointMap).forEach(floorCode => {
    const floor = {
      floor_code: floorCode,
      points: pointMap[floorCode],
      height: floorHeights[floorCode]
    }

    floors.push(floor);
  })

  const res = await axios.post(
    'https://services.nexterarobotics.com/transformations/forge/',
    floors,
  );

  return res.data;
}
