import { Box } from "@mui/material";
import { ProgressRegionsEditorHeader } from "./ProgressRegionsEditorHeader/ProgressRegionsEditorHeader";
import { ProgressRegionsEditorMapView } from "./ProgressRegionsEditorMapView/ProgressRegionsEditorMapView";

export const ProgressRegionsEditor = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        height: '100%'
      }}
    >
      <ProgressRegionsEditorHeader/>
      <ProgressRegionsEditorMapView/>
    </Box>
  );
}

