import axios, {AxiosResponse} from "axios";

// const BASE_URL = 'http://localhost:8000'
const BASE_URL = 'https://api.nexterarobotics.com'

export const listProcessedImages = async (filters: any) => {
    return await axios.get(`${BASE_URL}/api/processed-images`, {params: filters})
        .then( (r: AxiosResponse) => r.data)
        .catch( () => null)
}

export const retrieveProcessedImage = async (imageId: number) => {
    return await axios.get(`${BASE_URL}/api/processed-image/${imageId}`)
        .then( (r: AxiosResponse) => r.data)
        .catch( e => console.log(e))
}
