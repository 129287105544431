import { useRouteMatch } from 'react-router-dom';
import { useEffect } from 'react';
import { useProjectContext } from '../contexts/projectContext';
import { PATH_STRINGS } from './useGeneratedPaths';

/**
 * Parses route parameters into variables
 */
export const useRouteData = () => {
  const { updateState } = useProjectContext();

  const projectIdMatch = useRouteMatch<{projectId: string}>(PATH_STRINGS.project);
  const floorMatch = useRouteMatch<{ floorId: string }>(PATH_STRINGS.floor);
  const sectionMatch = useRouteMatch<{ sectionId: string }>(PATH_STRINGS.section);
  const lidarMatch = useRouteMatch<{lidarMapSubId: string}>(PATH_STRINGS.projectFloorLidarMap);
  const mapConfigMatch = useRouteMatch<{lidarMapConfigId: string}>(PATH_STRINGS.projectLidarMapConfiguration);

  const projectId: string = projectIdMatch?.params.projectId || '';
  const floorId: string = floorMatch?.params.floorId || '';
  const sectionId: string = sectionMatch?.params.sectionId || '';
  const lidarMapId: string = lidarMatch?.params.lidarMapSubId || '';
  const lidarMapConfigId: string = mapConfigMatch?.params.lidarMapConfigId || '';

  useEffect(() => {
    updateState({
      projectId: projectId,
      floorId: floorId,
      sectionId: sectionId,
      lidarMapId: lidarMapId,
      lidarMapConfigId: lidarMapConfigId,
    });
  }, [updateState, projectId, floorId, sectionId, lidarMapId, lidarMapConfigId]);

  return { projectId, floorId, sectionId };
};
