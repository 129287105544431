import { useUserContext } from '../../../contexts/userContext';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useNotifications } from '../../../contexts/notificationProvider';

interface PermissionWrapperProps {
  permission: string;
  project_id?: string;
  children: any;
  useOrInPermissionCheck?: boolean;
}
export const PermissionWrapper = ({ permission, project_id, useOrInPermissionCheck, children }: PermissionWrapperProps) => {
  const { state: userState } = useUserContext();
  const { addNotification } = useNotifications();
  const params = useParams();
  const history = useHistory();
  const [userCanAccess, setUserCanAccess] = useState<boolean>(false);

  const _checkUserPermissions = useCallback(() => {
    if (Array.isArray(permission)) {
      if (useOrInPermissionCheck) {
        //@ts-ignore
        return permission.some((perm) => checkUserPermissions(userState.permissions, perm, project_id || params.projectId));
      } else {
        //@ts-ignore
        return permission.every((perm) => checkUserPermissions(userState.permissions, perm, project_id || params.projectId));
      }
    } else {
      //@ts-ignore
      return checkUserPermissions(userState.permissions, permission, project_id || params.projectId);
    }    
    //@ts-ignore
  }, [params.projectId, permission, project_id, useOrInPermissionCheck, userState.permissions]);

  useEffect(() => {
    if (!_checkUserPermissions()) {
      addNotification('You do not have access to the requested page.', 'warning');
      history.push('/');
    } else {
      setUserCanAccess(true);
    }
  }, [_checkUserPermissions, addNotification, history]);

  if (!userCanAccess) {
    return <></>;
  }

  return children;
};

interface PermissionWrappedComponentProps {
  permission: string | string[];
  useOrInPermissionCheck?: boolean;
  project_id?: string;
  children: any;
}
export const PermissionWrappedComponent = ({ permission, useOrInPermissionCheck, project_id, children }: PermissionWrappedComponentProps) => {
  const { state: userState } = useUserContext();
  const _checkUserPermissions = () => {
    if (Array.isArray(permission)) {
      if (useOrInPermissionCheck) {
        return permission.some((perm) => checkUserPermissions(userState.permissions, perm, project_id));
      } else {
        return permission.every((perm) => checkUserPermissions(userState.permissions, perm, project_id));
      }
    } else {
      return checkUserPermissions(userState.permissions, permission, project_id);
    }    
  };
  if (!_checkUserPermissions()) {
    return <></>;
  }
  return children;
};

export const checkUserPermissions = (
  userPermissions: any,
  permission: string,
  project_id?: string
) => {
  let userLevelMatch = !!userPermissions.user.find(
    (userPermission: string) => userPermission === permission
  );
  // let companyLevelMatch = !!userState.permissions.company.find(
  //   (userPermission: string) => userPermission === permission
  // );
  let projectLevelMatch = false;
  if (project_id) {
    if (userPermissions.project[project_id]) {
      
      projectLevelMatch = !!userPermissions.project[project_id].find(
        (userPermission: string) => userPermission === permission
      );
    }
  }
  return userLevelMatch || projectLevelMatch;
};
