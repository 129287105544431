import { createContext, useContext, useReducer } from 'react';

const initialState = {
    activeImage: null,
    images: [],
    filters: {
        registered_on__gt: null,
        has_results: true,
        project: "225 Wyman"
    },
    labels: [],
    activeLabels: ["category-PPE", "Person"],
    selectedLabel: null,
    showBlur: false,
    labelingMode: "Safety",
    refreshData: false,
    refreshImage: false,
    loading: false
};

const EditorContext = createContext<any>(null);

const UPDATE_EDITOR= 'UPDATE_EDITOR';

const editorReducer = (state: any, action: any) => {
    switch (action.type) {
        case UPDATE_EDITOR:
            return {
                ...state,
                ...action.payload.item,
            };
        default:
            return state;
    }
};

export const EditorProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(editorReducer, initialState);
    return <EditorContext.Provider value={[state, dispatch]}>{children}</EditorContext.Provider>;
};

export const useEditorContext = () => {
    // @ts-ignore
    const [state, dispatch] = useContext(EditorContext);

    const updateEditor = (item: any) => {
        dispatch({
            type: UPDATE_EDITOR,
            payload: {
                item,
            },
        });
    };

    return {
        updateEditor,
        state,
    };
};
